import React from "react";
import Box from "react-bulma-components/lib/components/box";
import {
  Control,
  Field, Input,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import Columns from "react-bulma-components/lib/components/columns";
import SelectComponent from "../../../../tools/select-component";
import ClientsStore from "../../clients-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bulma-components/lib/components/button";

const AddDeliveryClient = ({ values, setValues }) => {
  const listDelivery = [
    { id: 1, name: "nova_poshta" },
    { id: 3, name: "ukr_poshta" },
  ];


  const filterData = (inputValue, list) => {
    if (inputValue.length < 2) return [];

    let result = list.filter((i) =>
      i.label.toLowerCase().split("(")[0].includes(inputValue.toLowerCase())
    );
    return result.slice(0, 30);
  };

  const handleChangeValues = (value, name, index) => {
    const newValue = [...values];
    const item = { ...newValue[index] };
    if (name === "delivery_id") {
      item.city_ref = { value: 0, label: T("select-city") };
      item.post_office = { value: 0, label: T("select-post-office-number") };
    } else if(name === 'city_ref') {
      item.post_office = { value: 0, label: T("select-post-office-number") };
    }

    item[name] = value;
    newValue[index] = item;
    setValues(newValue);
  };

  const handleAddItem = () => {
    const newValue = [...values];
    newValue.push({
      delivery_id: { value: 1, label: T("nova_poshta") },
      city_ref: { value: 0, label: T("select-city") },
      post_office: { value: 0, label: T("select-post-office-number") },
    });
    setValues(newValue);
  };


  return (
    <Box>
      <div className="display-flex-row-gap">
        <Label size="large">{T("delivery-services")}</Label>
        <Button className="button-link-icon" onClick={handleAddItem}>
          <FontAwesomeIcon icon="plus-circle" size="1x" />
        </Button>
      </div>
      {values.map((item, index) => (
        <Columns className="flex-wrap" key={index}>
          <Columns.Column>
            <SelectComponent
              name="delivery_id"
              label="delivery-service"
              onChange={(select, { name }) =>
                handleChangeValues(select, name, index)
              }
              value={item.delivery_id}
              autoComplete="nope"
              list={() => listDelivery}
            />
          </Columns.Column>
          {item.delivery_id.value === 3 && (
            <Columns.Column>
              <Field>
                <Control>
                  <Label>{T("postcode")}</Label>
                  <Input
                    size="large"
                    name="post_office"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder={T("postcode-placeholder")}
                    onChange={({ target: { value, name } }) =>
                      handleChangeValues({ value }, name, index)
                    }
                    value={item.post_office.value || ""}
                  />
                </Control>
              </Field>
            </Columns.Column>
          )}
          {item.delivery_id.value === 1 && (
            <>
              <Columns.Column>
                <Field>
                  <SelectComponent
                    name="city_ref"
                    label="city"
                    onChange={(select, { name }) =>
                      handleChangeValues(select, name, index)
                    }
                    value={item.city_ref}
                    empty="select-city"
                    list={ClientsStore.getCityList}
                    _filters={filterData}
                    async={true}
                  />
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <SelectComponent
                    name="post_office"
                    label="post-office-number"
                    onChange={(select, { name }) =>
                      handleChangeValues(select, name, index)
                    }
                    value={item.post_office}
                    empty="select-post-office-number"
                    list={() => ClientsStore.getPostOfficeByCity(item.city_ref.value)}
                    async={true}
                  />
                </Field>
              </Columns.Column>
            </>
          )}
        </Columns>
      ))}
    </Box>
  );
};

export default AddDeliveryClient;
