import yajax from "yajax";

import { createStore } from "../../tools/reflux-tools";
import Actions from "./transactions-actions";
import ExpensesActions from "modules/expenses/expenses-actions";
import { filterSearch } from "../../tools/filters-helper";
import { getDatePeriod } from "../../tools/date-range";
import UserStore from "../../user/user-store";
import ExpensesStore from "../expenses/expenses-store";
import User from "../../user/user-store";
import T from '../../components/i18n'
import LightStore from "../products/light-store";
// import ProductActions from '../products/products-actions';

// import User from '../../user/user-store';

const CLEAR_FILTER = () => ({
  date_start: getDatePeriod(30),
  date_end: new Date(),
  account: { value: 0, label: T('all') },
  action_id: { value: 0, label: T('all') },
  multiTypeAction: { value: 0 },
  expenses_category: { value: 0, label: T('all-categories') },
  disabledDeleted: "1",
  'amount-from': '',
  'amount-to': '',
  mid: {value: 0, label: T('all')},
  commission: {value: 0, label: T('all')}
});

const TransactionsStore = createStore({
  listenables: Actions,
  _prefix: "/bexpenses/",
  state: {
    transactions: [],
    page: 0,
    count_page: 0,
    count_per_page: 50,
    actionTypeNumberAndCount: {},
    isLoaded: false,
    _filter: CLEAR_FILTER(),
  },

  get(field) {
    return this.state[field];
  },

  getFilter(field) {
    return this.state["_filter"][field];
  },

  onFilter(name, value) {
    let filters = {};
    for (let i in this.state["_filter"]) {
      filters[i] = this.state["_filter"][i];
    }

    let date_start = filters["date_start"];

    filters[name] = value;

    this.setState({
      _filter: filters,
    });

    if (name === "date_start" && value < date_start) {
      Actions.load();
    }
  },

  filterClearField({globalName, filterName}) {
    const filter = this.get('_filter')
    if(globalName) {
      this.setState({
        _filter: {
          ...filter,
          [globalName]: {
            ...filter[globalName],
            [filterName]: {value: 0}
          }
        }
      })
    } else {
      this.setState({
        _filter: {
          ...filter,
          [filterName]: CLEAR_FILTER()[filterName]
        }
      })
    }

    LightStore.clearSelectGroup()
  },

  applySort(data) {
    const sort = this.getFilter("sort");

    return data.sort((a, b) => {
      for (const key in sort) {
        const direction = sort[key];

        const typeString =
          typeof a[key] === "string" && typeof b[key] === "string";

        const ItemA =
          typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const ItemB =
          typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        if (direction === 1) {
          if (ItemA < ItemB) return typeString ? -1 : 1;
          if (ItemA > ItemB) return typeString ? 1 : -1;
        } else if (direction === 0) {
          if (ItemA > ItemB) return typeString ? -1 : 1;
          if (ItemA < ItemB) return typeString ? 1 : -1;
        }
      }
      return 0;
    });
  },

  clearFilter() {
    this.setState({
      _filter: CLEAR_FILTER(),
    });
  },

  applyFilter(expenses) {
    const filter = this.state._filter,
      date = new Date(expenses["created_at"] * 1000);
    let result = true;

    filter["date_start"].setHours(0, 0, 0, 0);
    filter["date_end"].setHours(23, 59, 59, 999);

    if (filter["search"] !== "" && typeof filter["search"] !== "undefined") {
      let search_data = {
        comment: expenses["comment"],
        expensesId: expenses['expenses_id']
      };

      result = filterSearch(filter["search"], ["comment", 'expensesId'], search_data);
    }
    if (date < filter["date_start"] || date > filter["date_end"]) {
      return false;
    }

    if (filter["account"] && filter["account"]["value"] !== 0) {
      if (
        parseInt(filter["account"]["value"]) !==
        parseInt(expenses["account_id"])
      ) {
        result = result && false;
      }
    }

    if (filter["action_id"] && filter["action_id"]["value"] !== 0) {
      if (
        parseInt(filter["action_id"]["value"]) !==
        parseInt(expenses["action_id"])
      ) {
        result = result && false;
      }
    }

    if (
      filter["expenses_category"] &&
      filter["expenses_category"].value !== 0
    ) {
      if(expenses?.expenses_id) {
        const expense = ExpensesStore.getExpensesById(expenses?.expenses_id);
        if(expense && expense?.category_id) {
          result = result && expense?.category_id === filter["expenses_category"].value
        } else {
          result = result && false
        }
      } else {
        result = result && false
      }
    }

    if (
      filter["mid"] &&
      filter["mid"].value !== 0
    ) {
      if(expenses?.expenses_id) {
        const expense = ExpensesStore.getExpensesById(expenses?.expenses_id);
        if(expense && expense?.marketplace_id) {
          result = result && parseFloat(expense?.marketplace_id) === parseFloat(filter["mid"].value)
        } else {
          result = result && false
        }
      } else {
        result = result && false
      }
    }

    if (
      filter["action_id"] &&
      filter["action_id"]["value"] === 0 &&
      filter["multiTypeAction"] &&
      filter["multiTypeAction"][0] &&
      filter["multiTypeAction"][0]?.value !== 0
    ) {
      result =
        result &&
        filter["multiTypeAction"].some(
          (item) => parseInt(item.value) === parseInt(expenses["action_id"])
        );
    }

    if (filter["amount-from"]) {
      result =
        result &&
        parseFloat(expenses["amount"]) >= parseFloat(filter["amount-from"]);
    }

    if (filter["amount-to"]) {
      result =
        result &&
        parseFloat(expenses["amount"]) <= parseFloat(filter["amount-to"]);
    }

    if(parseInt(filter['disabledDeleted'])) {
      result = result && !expenses['is_deleted']
    }

    if(!UserStore.getPermissionEnable('show-all-expenses') && parseInt(expenses?.responsible_user_id) === parseInt(UserStore.get('realId'))) {
      if(expenses.expenses_id) {
        const getExpensesById = ExpensesStore.getExpensesById(expenses.expenses_id);
        if(!getExpensesById) {
          result = result && false;
        } else if(getExpensesById && parseInt(getExpensesById.responsible_user_id) !== parseInt(UserStore.get('realId'))) {
            result = result && false
        }
      }

    }

    if(filter["commission"] && !!filter["commission"]) {
      if(filter["commission"].value === 'with-commission') {
        result = result && !!expenses.commission;
      } else if(filter["commission"].value === 'without-commission') {
        result = result && !expenses.commission;
      }
    }

    return result;
  },

  onSetPage(page) {
    this.setState({
      page: parseInt(page),
    });
  },

  onSetCountPerPage(count) {
    this.setState({
      count_per_page: count,
    });
  },

  onLoad(data) {
    if (this.isLoaded) {
      console.log("start load transactions...", data);
      return this.isLoaded;
    }

    const filter = this.get("_filter");
    const date = {};

    if (filter["date_start"]) {
      const date_start = filter["date_start"].setHours(0, 0, 0, 0);
      date.start = parseInt(`${date_start / 1000}`);
    }

    if (filter["date_end"]) {
      const date_end = filter["date_end"].setHours(23, 59, 59, 999);
      date.end = parseInt(`${date_end / 1000}`);
    }

    yajax
      .get(this._prefix + "transactions", date)
      .then(Actions.load.completed, Actions.load.failed);

    this.setState({
      isLoaded: true,
    });
  },

  onLoadCompleted(data) {
    this.setState({
      isLoaded: false,
    });

    if (data["success"]) {
      const permissionAccount = UserStore.getPermissionAccount();

      const filterDataWithPermissionAccount = data["data"].filter((item) => {
        let result = true;
        if (permissionAccount?.length) {
          result =
            result &&
            permissionAccount.some(
              (permissionID) => permissionID === item?.account_id
            );
        }

        return result;
      });

      const actionTypeNumberAndCount = filterDataWithPermissionAccount.reduce(
        (acc, cur) => {
          if (acc[cur.action_id]) {
            acc[cur.action_id] += 1;
          } else {
            acc[cur.action_id] = 1;
          }

          return acc;
        },
        {}
      );

      this.setState({
        transactions: filterDataWithPermissionAccount,
        count_page: Math.ceil(data["data"].length / this.state.count_per_page),
        actionTypeNumberAndCount,
      });
    }
    console.log("result load transactions: ", data);
  },
  onLoadFailed() {
    this.setState({
      isLoaded: false,
    });
    console.log("failed load transactions");
  },

  onAddAccount(data) {
    yajax
      .put(this._prefix + "accounts", data)
      .then(Actions.addAccount.completed, Actions.addAccount.failed);
  },

  onAddAccountCompleted(data) {
    console.log("completed add account ", data);
    Actions.load();
    ExpensesActions.loadAccounts();
  },

  onAddAccountFailed() {
    console.log("failed add account");
  },

  getList() {
    return this.state.transactions.filter(this.applyFilter);
  },

  getTransactions() {
    const transactions = this.state.transactions.filter(this.applyFilter),
      start = this.state.page * this.state.count_per_page,
      end = start + this.state.count_per_page;

    const transactionsWithSort = this.applySort(transactions);

    this.state.count_page = Math.ceil(
      transactionsWithSort.length / this.state.count_per_page
    );

    if (this.state.count_page <= this.state.page) {
      this.setState({
        page: 0,
      });
    }

    return transactionsWithSort.slice(start, end);
  },
  printTransactionsURL(type) {
    const filter = this.state._filter;
    const start = filter['date_start'].getTime()/1000;
    const end = filter['date_end'].getTime()/1000;

    const data = []

    if(filter.account.value) {
      data.push(`&account=${filter.account.value}`)
    }
    if(filter.search) {
      data.push(`&search=${filter.search}`)
    }
    if(parseInt(filter.disabledDeleted)) {
      data.push(`&is_deleted=${filter.disabledDeleted}`)
    }
    if(filter.action_id.value) {
      data.push(`&action_id=${filter.action_id.value}`)
    }
    if(Array.isArray(filter.multiTypeAction) && !!filter.multiTypeAction[0].value && !filter.action_id.value) {
      data.push(`&action_id=${filter.multiTypeAction.map(item => item.value)}`)
    }
    if(filter.expenses_category.value) {
      data.push(`&expenses_category=${filter.expenses_category.value}`)
    }
    if(filter['amount-from'] || filter['amount-to']) {
      data.push(`&min_amount=${filter['amount-from'] || 0}&max_amount=${filter['amount-to'] || 9999999999999}`)
    }

    if(filter?.sort && !!Object.keys(filter?.sort || {})?.length) {
      const keysSort = Object.keys(filter?.sort || {})
      keysSort.forEach(item => {
        if(filter.sort[item] && parseInt(filter.sort[item]) !== 2) {
          data.push(`&order_by=${item}`)
        } else if(parseInt(filter.sort[item]) !== 2){
          data.push(`&order_by=${item}&sort_reverse=${true}`)
        }
      })
    }


    return `/bprint/finances?format=${type || 'pdf'}&date_start=${Math.round(start)}&date_end=${Math.round(end)}${data.join('')}&token=${encodeURIComponent(User.getToken())}&lang=${UserStore.getLang()}`
  }
});

export default TransactionsStore;
