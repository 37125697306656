import React, {useState} from 'react'
import Card from "react-bulma-components/lib/components/card";
import Columns from "react-bulma-components/lib/components/columns";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import User from "../../../../user/user-store";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import ExpirationDateTag
	from "../../../../components/expiration-date-tag/expiration-date-tag";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";
import ColorControl from "../../../../tools/color-control";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import Box from "react-bulma-components/lib/components/box";
import ProductStore from "../../products-store";
import List from "react-bulma-components/lib/components/list";
import numberFormat from "../../../../tools/number-format";
import getCalcNetPrice from "../../common/calc-netprice";
import ChangeStockModal from "../../change-stock-modal";
import Level from "react-bulma-components/lib/components/level";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../../app-store";
import ViewProductModal from "../../modal/view-product/view-product-modal";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "react-bulma-components/lib/components/image";
import UserStore from "../../../../user/user-store";
import CheckReservShipModal from "../../check-reserv-ship-modal";
import calcMargin from "../../../../tools/calc-margin";
import SalesStore from "../../../sales/sales-store";
import EditVariableModal from "../../modal/add-product/edit-variable-modal";
import BindingIntegrationProductsModal
	from "../../binding-integration-products-modal";
import {LocalIcon} from 'whitelables/wl-name/icons'
import {deleteProduct} from "../../common/function";
import LightStore from "../../light-store";
import LightActions from "../../light-actions";
import ChannelBlock from "../../../../components/channel-block/channel-block";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";
import DateReportChange
	from "../../common/date-report-change/date-report-change";
import EditSalePrice from "../../modal/edit-sale-price/edit-sale-price";
import AddSalesModal from "../../../sales/add-sales-modal";

const GetStock = ({offColor, isFixed, isMinMax, instock, quantity, waiting, pid, mid}) => {
	let value = isFixed ? instock.toFixed(3) : instock;
	if (instock !== quantity) {
		value = (
			<span className={offColor ? "" : "text-success"}>
          {instock + "/" + quantity}
        </span>
		);
	}

	if (waiting) {
		value = (
			<span>
          {value}{" "}
				<span className={offColor ? "" : "text-warning"}>({waiting})</span>
        </span>
		);
	}

	return (
		<span
			className={`${
				!offColor && (instock !== quantity || waiting)
					? "underline-with-color"
					: ""
			} ${isMinMax ? "has-text-danger" : ""}`}
			onClick={
				instock !== quantity || waiting
					? () =>
						AppStore.openModal(
							<CheckReservShipModal
								pid={pid}
								reservLength={quantity - instock}
								mid={mid}
								realQuantity={quantity}
							/>
						)
					: () => {}
			}
		>
        {value}
      </span>
	);
}

const IntegrationButton = ({pid, mid}) => {
	return (
		<Level.Item key={`binding-${pid}`}>
			<Link
				to="#"
				onClick={() =>
					AppStore.openModal(
						<BindingIntegrationProductsModal
							modal="binding-modal"
							product_id={pid}
							product_mid={mid}
							isCreate={true}
						/>
					)
				}
			>
				<LocalIcon icon="integration" size="large" />
			</Link>
		</Level.Item>
	);
}

const IntegrationItem = ({mp, pid, mid}) => {
	let integraions = [];
	for (let i in mp) {
		let iid = mp[i];
		let channel_id = SalesStore.foundChannelsByIntegrationId(iid);

		const isMidInIntegration = UserStore.isIntegrationWithWarehouse({
			mid,
			iid,
		});
		if (!isMidInIntegration) continue;

		integraions.push(
			<ChannelBlock
				onClick={() => UserStore.isAdmin() &&
					AppStore.openModal(
						<BindingIntegrationProductsModal
							modal="binding-modal"
							product_id={pid}
							product_mid={mid}
							integration_id={iid}
						/>
					)
				}
				key={"ii-" + pid + mid + iid}
				channel_id={channel_id}
			/>
		);
	}

	return integraions;
}

const NewProductRowMobile = ({disabledShowAndWrapper, data, stock,backgroundLightBlack, isVariable }) => {
	const [isOpen, setIsOpen] = useState(false)
	const {marketplace_id, product_id, instock, sku, price,waiting, quantity, net_price, id} = stock
	const {type_product, currency_id, units, sale_price, mp} = data

	// let sku = vendorCode ? vendorCode + ". " : null;
	const name = ProductStore.getProductName(product_id)
	const marketplaceName = WarehouseStore.getWarehouseName(marketplace_id)
	const srcImage = ProductStore.makeImageUrl(product_id, 0, 64);
	const img_o = (
		<Image src={srcImage} size={64} />
	);

	const isMinMax = UserStore.isMinimalStock({ mid: marketplace_id, pid: product_id, instock });

	const expirationDate = ProductStore.theExpirationDateByPid(product_id)

	const report = SalesStore.getSaleReportsByProduct(product_id, marketplace_id)

	const margin = calcMargin(
		net_price,
		price,
		sale_price
	);

	const handleChangeCheckbox = (evt) => {
		const value = evt.target.checked;

		LightActions.setProductGroup([product_id, marketplace_id], value);
	}

	const variableIds = isVariable ? ProductStore.getIdsProductVariable(data.parent_id) : null;
	let disabledDelete = false;

	if(data.parent_id === data.id) {
		const parentProduct = ProductStore.getProduct(data.parent_id)
		if(variableIds.length > 1 && Object.keys(parentProduct.stock).length <= 1) {
			disabledDelete = true;
		}
	}

	let classNameBorder = ''

	if(!disabledShowAndWrapper) {
		if(type_product === 4) {
			classNameBorder = 'services-border';
		} else if(type_product === 3) {
			classNameBorder = 'bundle-border'
		}
	}

	return (
		<tr>
			<td colSpan="5" className={`${(isOpen || backgroundLightBlack || isVariable) ? "has-background-light-black" : ""}`}>
				{!disabledShowAndWrapper && (
					<Card
						style={isOpen ? {marginBottom: 10} : {}}
						className={`${isOpen ? "has-background-light-black" : ""} ${classNameBorder} ${expirationDate && instock !== 0 ? expirationDate : ''}`}
					>
						<Card.Content>
							<Columns className="is-mobile product-mobile">
								<Columns.Column size="one-quarter">
									<Field className="absolute">
										<Control>
											<Checkbox
												name="is_products"
												onChange={handleChangeCheckbox}
												checked={LightStore.getProductGroupCheckbox([product_id, marketplace_id])}
											/>
										</Control>
									</Field>
									{!User.getPermissionEnable("editproducts") ? (
										<div>{img_o}</div>
									) : (
										<OpenInsideModal
											size="small"
											text=""
											img={img_o}
											renderAs="b"
										>
											<EditProductsModal pid={product_id} step={1}/>
										</OpenInsideModal>
									)}
								</Columns.Column>
								<Columns.Column
									className="margin-left-5"
									onClick={() => setIsOpen(prev => !prev)}
								>
									<b className="product-name">
										{instock !== 0 && <ExpirationDateTag pid={product_id}/>}{" "}
										<TypeProductTag typeProduct={type_product}/> {sku}{" "}
										{name} ({marketplaceName})
										<br/>
										{!isOpen && (
											<span className="subtitle">
                          {T("available")}:{" "}
												<strong>
                            {WarehouseStore.isDropshipping(marketplace_id) ||
														type_product === 4 ? (
															<>
                                <span className="text-success">
                                  {ColorControl("∞", false, false, false, true)}
                                </span>{" "}
																{T("items-e")}
															</>
														) : (
															<>
																<GetStock
																	pid={product_id}
																	mid={marketplace_id}
																	isFixed={
																		instock !== 0 &&
																		instock % 1 !== 0 &&
																		true
																	}
																	isMinMax={isMinMax || instock <= 0}
																	offColor={true}
																	instock={instock}
																	waiting={waiting}
																	quantity={quantity}
																/>
																{" "}
																{T("items-e")}
															</>
														)}
                          </strong>
												{" | "}
												{T("product-info-price")}:{' '}
                          <strong>
                            <Money amount={price} wrapper={DOM.span}/>
                          </strong>
                        </span>
										)}
									</b>
								</Columns.Column>
							</Columns>
						</Card.Content>
					</Card>
				)}
				{(isOpen || disabledShowAndWrapper) && (
					<Box style={{marginBottom: 10}}>
						<div className='display-flex-row' style={{alignItems: "flex-start"}}>
							{isVariable && <div className="display-flex">
								<Field className="absolute">
									<Control>
										<Checkbox
											name="is_products"
											onChange={handleChangeCheckbox}
											checked={LightStore.getProductGroupCheckbox([product_id, marketplace_id])}
										/>
									</Control>
								</Field>
								{!User.getPermissionEnable("editproducts") ?
									<div>{img_o}</div>
									:
									<OpenInsideModal size="small" text='' img={img_o}
																	 renderAs="b">
										<EditProductsModal pid={product_id} step={1}/>
									</OpenInsideModal>
								}
							</div>}
							<div className='display-flex-column'>
								<p className='product-name'>
									{data.name}
								</p>
								<p
									className="info-bar">{ProductStore.makeInfoBar(product_id, marketplace_id)}</p>
							</div>
						</div>


						{UserStore.getPermissionEnable("incomeinformation") && (
							<List>
							<List.Item>
									<b>
										{T("netcost")}:{" "}
										<span className="text-success">
                        {numberFormat(net_price)}{" "}
											{UserStore.getCurrency(currency_id)}
                      </span>
									</b>{" "}
									{type_product !== 3
										&& getCalcNetPrice(product_id, marketplace_id, net_price)}
								</List.Item>
								{UserStore.getPermissionEnable("salesprice") && (
									<List.Item>
										{T("margin")}:{" "}
										<Money amount={price - net_price} wrapper={DOM.span}/> (
										{ColorControl(margin, false, false, "margin", true)}%)
									</List.Item>
								)}
								<List.Item>
									<Columns className="is-mobile product-mobile">
										<Columns.Column>
											{T("total-sales")}:{" "}
											<b>
												{report["items_count"]} {T("items-p")}
											</b>
											<DateReportChange/>
										</Columns.Column>
										<Columns.Column align="right">
											<div className="product-integration-mobile">
												<IntegrationItem mp={mp} pid={product_id} mid={marketplace_id}/>
											</div>
										</Columns.Column>
									</Columns>
								</List.Item>
							</List>
						)}
						<Columns className="is-mobile product-mobile-block">
							<Columns.Column size="half">
								{WarehouseStore.isDropshipping(marketplace_id) || type_product === 4 ? (
									<Box className="small-number-box">
										<div>{T("available")}</div>
										{ColorControl("∞")}
										<div>{T(units)}</div>
									</Box>
								) : (
									<Box
										className="small-number-box"
										style={isMinMax ? {border: "1px solid red"} : {}}
									>
										<div>{T("available")}</div>
										{ColorControl(
											<GetStock
												pid={product_id}
												mid={marketplace_id}
												offColor={false}
												isFixed={instock !== 0 &&
													instock % 1 !== 0 &&
													true}
												isMinMax={isMinMax || instock <= 0}
												quantity={quantity}
												waiting={waiting}
												instock={instock}
											/>,
										)}
										<div>{T(units)}</div>
										{User.getPermissionEnable("editinstock") &&
											type_product !== 3 && (
												<OpenInsideModal
													size="small"
													text={T("change-stock-button")}
													renderAs="a"
												>
													<ChangeStockModal pid={product_id}
																						mid={marketplace_id}/>
												</OpenInsideModal>
											)}
									</Box>
								)}
							</Columns.Column>
							{User.getPermissionEnable("salesprice") && (
								<Columns.Column>
									<Box className="small-number-box">
										<div>{T("products-price")}</div>
										{ColorControl(numberFormat(price), false, true)}
										<div>{User.getCurrency()}</div>
										{User.getPermissionEnable("editinstock") && (
											<OpenInsideModal
												size="small"
												text={T("change")}
												renderAs="a"
											>
												<EditSalePrice mid={marketplace_id} amount={price} pid={product_id} id={id}/>
												{/*<EditProductsModal pid={product_id} step={0}/>*/}
											</OpenInsideModal>
										)}
									</Box>
								</Columns.Column>
							)}
						</Columns>
						<Level renderAs="nav" breakpoint="mobile" className="is-mobile">
							<Level.Side align="right">
								{User.isCanAddSale() && (
									<Level.Item>
										<Button color="success"
														size="small"
														rounded onClick={() => {
											AppStore.openModal(<AddSalesModal modal='sales' select_product={{
												value: ProductStore.getIdent(product_id, marketplace_id),
											}}/>)
										}}>
											{T("add-sale-btn")}
										</Button>
									</Level.Item>
								)}

								<Level.Item>
									<Button
										size="small"
										rounded
										color="info"
										onClick={() =>
											AppStore.openModal(<ViewProductModal pid={product_id}/>)
										}
									>
										{T("info")}
									</Button>
								</Level.Item>

								{User.getPermissionEnable("editproducts") && (
									<Level.Item>
										{isVariable ? (
											<OpenInsideModal size="2x" renderAs="icon" icon="edit">
												<EditVariableModal pid={product_id}
																					 parent_id={data.parent_id}/>
											</OpenInsideModal>
										) : (
											<OpenInsideModal size="2x" renderAs="icon" icon="edit">
												<EditProductsModal pid={product_id} step={0}/>
											</OpenInsideModal>
										)}
									</Level.Item>
								)}

								{User.getPermissionEnable("editinstock") && (
									<Level.Item>
										<OpenInsideModal
											size="2x"
											renderAs="icon"
											icon={"copy"}
											title={T("copy")}
										>
											<EditProductsModal pid={product_id} step={0} copy={true}/>
										</OpenInsideModal>
									</Level.Item>
								)}

								{!disabledDelete && User.getPermissionEnable("deleteproducts") && (
									<Level.Item>
										<Link
											to="#"
											onClick={() => deleteProduct(product_id, marketplace_id)}
											title={T("del")}
										>
											<FontAwesomeIcon icon="trash-alt" size="2x"/>
										</Link>
									</Level.Item>
								)}
							</Level.Side>
							<Level.Side>
								{User.getProductIntegrationModuleEnable(mp.length) &&
									<IntegrationButton pid={product_id} mid={marketplace_id}/>}
							</Level.Side>
						</Level>
					</Box>
				)}
			</td>
		</tr>
	);
};

export default NewProductRowMobile;