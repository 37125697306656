import React from "react";
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Image from "react-bulma-components/lib/components/image";
import Media from "react-bulma-components/lib/components/media";
import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";
import BaseForm from "components/modules/base-form";

import {
  Field,
  Control,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import { Link } from "react-router-dom";
import ProductsStore from "../../products-store";
import Actions from "../../products-actions";
import SelectProducts from "components/select-products";
import Money from "components/money";
import WarehouseStore from "../../../warehouse/warehouse-store";
import WarehouseActions from "../../../warehouse/warehouse-actions";
import User from "user/user-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import calcMargin from "tools/calc-margin";
import SelectComponent from "tools/select-component";
import {
  withIsMobileSize
} from "../../../../components/is-mobile-context/is-mobile-context";

class AddProductBundle extends BaseForm {
  constructor(props) {
    super(props);
    let data = props.warehouse
      ? props.warehouse[0]
      : { count: {}, price: {}, amount: {}, mid: {} };

    let product_data = props.product_data;

    if (product_data && product_data.product_data) {
      product_data = product_data.product_data;
    }

    this.product_data_list = [];

    for (let i in product_data) {
      this.product_data_list.push(product_data[i]["ident"]);
    }

    this.product_data_list = [...new Set(this.product_data_list)];

    this.state = {
      product_data: product_data || {},
      price: data.price.value || 0,
      count: data.quantity.value || 0,
      amount: data.netprice.value || 0,
      mid: data.mid || WarehouseStore.getDefaultWarehouse(),
    };
  }

  componentWillUnmount() {
    if (typeof this.listen == "function") {
      this.listen();
    }
  }
  componentDidMount() {
    const { bid } = this.props;
    const initPrice = this.state.price;

    if (bid) {
      Actions.getBundle(bid);
    }

    this.listen = Actions.getBundle.completed.listen((res) => {
      let product_data = {};
      for (let ident of res["data"]) {
        let data = ProductsStore.getProductByMid(ident["pid"], ident["mid"]),
          pid_ident = ProductsStore.getIdent(ident["pid"], ident["mid"]);
        product_data[pid_ident] = this.prepareProductData(
          ident["pid"],
          ident["mid"],
          data,
          ident["count"]
        );
      }

      this.updateData(product_data, initPrice);
    });
  }

  onChange(evt) {
    const value = evt.target.value;

    this.updateData(this.state.product_data, value);
  }

  onChangeCount(evt) {
    const value = evt.target.value,
      pid = evt.target.name.split("-")[2];

    let product_data = this.state.product_data;

    product_data[pid]["count"] = this.checkVal(value);

    this.updateData(product_data);
  }

  onChangeSelect(selectedOption, actions) {
    this.setState({
      [actions.name]: selectedOption,
    });

    this.updateData(this.state.product_data);
  }

  onChangeSelectProduct(selectedOption, need_delete) {
    let product_data = this.state.product_data;
    if (selectedOption && "value" in selectedOption) {
      selectedOption = { select_product: selectedOption };
    }

    for (let p in selectedOption) {
      const pid_ident = selectedOption[p]["value"],
        _ident = pid_ident.split("_"),
        pid = _ident[0],
        mid = _ident[1],
        data = ProductsStore.getProductByMid(pid, mid);

      if (need_delete) {
        this.deleteItem(pid_ident);
      } else {
        product_data[pid_ident] = this.prepareProductData(pid, mid, data);
        this.updateData(product_data);
      }
    }
  }

  calcSaleAmount(products) {
    let amount = 0;
    let sale_amount = 0;
    let count = [];
    for (let i in products) {
      const prod = products[i];

      amount += parseFloat(prod["netprice"] * prod["count"]);
      sale_amount += parseFloat(prod["price"] * prod["count"]);

      if (!ProductsStore.isServicesProduct(prod.pid)) {
        if (prod["instock"] > 0 && prod["count"] > 0) {
          count.push(prod["instock"] / prod["count"]);
        } else if (prod["instock"] === 0) {
          count.push(0);
        } else {
          count.push(0);
        }
      }
    }
    return [
      amount,
      count.length > 0 ? Math.min.apply(null, count) : 0,
      sale_amount,
    ];
  }

  updateData(product_data, price) {
    const { onChange } = this.props;
    let _ = this.calcSaleAmount(product_data);
    const state = {
      product_data: product_data,
      products: null,
      amount: this.checkVal(_[0]),
      price: this.checkVal(typeof price !== "undefined" ? price : _[2]),
      count: this.checkVal(_[1] ? Math.floor(_[1]) : _[1]),
      message: null,
    };

    this.product_data_list = [];

    for (let i in product_data) {
      this.product_data_list.push(product_data[i]["ident"]);
    }

    this.product_data_list = [...new Set(this.product_data_list)];

    this.setState(state);

    onChange(state, this.state.mid);
  }

  deleteItem(pid) {
    let product_data = this.state.product_data;

    if (typeof product_data[pid] !== "undefined") {
      delete product_data[pid];
      this.updateData(product_data);
    }
  }

  prepareProductData(pid, mid, data, count) {
    return {
      count: count || 1,
      mid: mid,
      name: data["name"],
      sku: data["sku"],
      units: data["units"],
      pid: pid,
      instock: data["instock"],
      price: data["price"],
      ident: ProductsStore.getIdent(pid, mid),
      netprice: data["netprice"],
    };
  }

  productLineMobile(prod) {
    let name = prod.name;
    const pid = prod.ident;

    let img =
      "/bimages/get/32-" + User.get("id") + "-" + prod["pid"] + "-0.jpg";

    let warehouse = "";
    if (WarehouseStore.getWarehouseList().length > 1) {
      warehouse = (
        <p>
          <b> {T("history-data-marketplace_id")}</b>:{" "}
          {T(WarehouseStore.getWarehouseName(prod["mid"]))}
        </p>
      );
    }

    if (
      !WarehouseStore.isDropshipping(prod["mid"]) &&
      !ProductsStore.isServicesProduct(prod.pid)
    ) {
      name +=
        " (" +
        T("available-small") +
        " " +
        ProductsStore.getStockUnits(
          prod["instock"],
          this.state.product_data[pid]["units"]
        ) +
        ")";
    }

    return (
      <List.Item key={pid}>
        <Media>
          <Media.Item position="left">
            <Image src={img} size={64} />
          </Media.Item>
          <Media.Item>
            <Content>
              {name}
              {warehouse}
            </Content>
          </Media.Item>
        </Media>

        <Columns className={this.props.isMobileSize ? "is-mobile" : null}>
          <Columns.Column>
            <Control iconRight>
              <Input
                size="small"
                key={"count-pid-" + pid}
                name={"count-pid-" + pid}
                type="text"
                onChange={(evt) => this.onChangeCount(evt)}
                value={this.state.product_data[pid]["count"]}
              />
              <Icon align="right">
                <span>
                  {ProductsStore.getUnits(
                    this.state.product_data[pid]["units"]
                  )}
                </span>
              </Icon>
            </Control>
          </Columns.Column>
          <Columns.Column>
            <Heading size="6" className="margin-top-10">
              <Money amount={this.state.product_data[pid]["netprice"]} />
            </Heading>
          </Columns.Column>
          <Columns.Column className="delete-on-bundle">
            <Link
              to="#"
              onClick={() => this.deleteItem(pid)}
              title={T("delete")}
            >
              <FontAwesomeIcon icon="trash-alt" size="2x" />
            </Link>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }
  productLine(prod) {
    let name = prod.name;
    const pid = prod.ident;

    let img =
      "/bimages/get/32-" + User.get("id") + "-" + prod["pid"] + "-0.jpg";

    let warehouse = "";
    if (WarehouseStore.getWarehouseList().length > 1) {
      warehouse = (
        <p>
          <b> {T("history-data-marketplace_id")}</b>:{" "}
          {T(WarehouseStore.getWarehouseName(prod["mid"]))}
        </p>
      );
    }

    if (
      !WarehouseStore.isDropshipping(prod["mid"]) &&
      !ProductsStore.isServicesProduct(prod.pid)
    ) {
      name +=
        " (" +
        T("available-small") +
        " " +
        ProductsStore.getStockUnits(
          prod["instock"],
          this.state.product_data[pid]["units"]
        ) +
        ")";
    }

    return (
      <List.Item key={pid}>
        <Columns className={this.props.isMobileSize ? "is-mobile" : null}>
          <Columns.Column size="three-fifths">
            <Media>
              <Media.Item position="left">
                <Image src={img} size={64} />
              </Media.Item>
              <Media.Item>
                <Content>
                  {name}
                  {warehouse}
                </Content>
              </Media.Item>
            </Media>
          </Columns.Column>
          <Columns.Column>
            <Control iconRight>
              <Input
                size="small"
                key={"count-pid-" + pid}
                name={"count-pid-" + pid}
                onChange={(evt) => this.onChangeCount(evt)}
                value={this.state.product_data[pid]["count"]}
              />
              <Icon align="right">
                <span>
                  {ProductsStore.getUnits(
                    this.state.product_data[pid]["units"]
                  )}
                </span>
              </Icon>
            </Control>
          </Columns.Column>
          <Columns.Column>
            <Heading size={6}>
              <Money amount={this.state.product_data[pid]["netprice"]} />
            </Heading>
          </Columns.Column>
          <Columns.Column className="delete-on-bundle">
            <Link
              to="#"
              onClick={() => this.deleteItem(pid)}
              title={T("delete")}
            >
              <FontAwesomeIcon icon="trash-alt" size="1x" />
            </Link>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  getProductsList() {
    const sp = this.state.product_data;

    let sales_list = [];
    for (let p in sp) {
      let product = sp[p];
      if (product) {
        sales_list.push(
          this.props.isMobileSize ? this.productLineMobile(product) : this.productLine(product)
        );
        // sales_list.push(this.props.isMobileSize ? this.mobileProductLine(product) : this.productLine(product));
      }
    }

    return (
      <List hoverable className="sales-order-list">
        {sales_list}
      </List>
    );
  }

  render() {
    const margin = calcMargin(this.state.amount, this.state.price),
      wlist = WarehouseStore.getWarehouseList();

    const sp = this.state.product_data;

    let pids = [];
    for (let p in sp) {
      let product = sp[p];
      if (product && product["pid"]) {
        pids.push(parseInt(product["pid"]));
      }
    }
    return (
      <Box>
        {User.getModuleEnable("warehouse") && wlist.length > 1 ? (
          <Field>
            <Control>
              <SelectComponent
                name={"mid"}
                label="product-marketplace"
                onChange={(evt, act) => this.onChangeSelect(evt, act)}
                value={this.state.mid}
                list={WarehouseStore.getWarehouseList}
                readOnly={
                  Object.keys(this.state.product_data).length > 0 ? true : false
                }
                // isOptionDisabled={option => disableWid.indexOf(option.value)}
                load={WarehouseActions.load}
                creatable={false}
              />
            </Control>
          </Field>
        ) : (
          ""
        )}
        <SelectProducts
          onChange={(s, n) => this.onChangeSelectProduct(s, n)}
          value={this.state.products}
          source="bundle"
          disabledProductById={this.props.bid || null}
          selected={() => {
            return this.product_data_list;
          }}
          mid={this.state.mid ? this.state.mid.value : false}
          quantity={1}
        />

        {this.getProductsList()}

        <Columns className={this.props.isMobileSize ? "is-mobile" : null}>
          <Columns.Column size="one-quarter">
            <Control>
              <Label>{T("available")}</Label>
              <div className="big-text">
                {WarehouseStore.isDropshipping(this.state.mid.value)
                  ? "∞"
                  : this.state.count}{" "}
                {T("items-p")}
              </div>
            </Control>
          </Columns.Column>
          <Columns.Column>
            <Control>
              <Label>{T("netcost")}</Label>
              <div className="big-text">
                <Money amount={this.state.amount} />
              </div>
            </Control>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>{T("products-price")}</Label>
                <Input
                  size="large"
                  name="price"
                  onChange={(e) => this.onChange(e)}
                  value={this.state.price}
                />
                {!User.getPermissionEnable("incomeinformation") ? null : (
                  <div align="right">
                    {T("margin")} {margin}
                  </div>
                )}
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default connectToStores(withIsMobileSize(AddProductBundle), {
  products: ProductsStore,
});
