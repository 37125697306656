import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";

import InfoBlock from 'components/info-block';
import T from "components/i18n";

import User from 'user/user-store';
import SlipInfo from '../common/slip-info';
import PriceTagsInfo from '../common/pricetags-info';

import Columns from 'react-bulma-components/lib/components/columns';

import { 
    Checkbox,
} from 'react-bulma-components/lib/components/form';

import Heading from 'react-bulma-components/lib/components/heading';
import UserStore from "user/user-store";
import AppStore from "../../../app-store";
// import Box from 'react-bulma-components/lib/components/box';

class DisableModules extends Component {

    constructor(props) {
        super(props);
        this.state = {
          comment: "",
          step: 0   
        };

        // this.goToPay = this.goToPay.bind(this);
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

        User.setModuleEnable(name, value, "modules");
    }

    componentDidMount() {

    }

    renderSlipInfo() {
      return (
          <div>


          </div>
        );

    }

    onChangeMoreSettings({target: {name, checked}}) {
      if(AppStore.isMobileApp()) {
        if(checked) {
          UserStore.postMessage('show-barcode')
        }
      }

      UserStore.setMoreSetting(checked, name)
    }

    render () {
      const valueBarcode = UserStore.getMoreSetting('scanBarcode')
        return (
            
            <div>
                {User.isMainUser() ? <InfoBlock title={'disable-modules'} text={'disable-modules-desk'} /> : null}
                <div align="left" className="todo-cmp">
                        <ol className="gradient-list todo-cmp__list">
                        {User.isMobileApp() && <li>
                          <Heading size={5}
                                   className="has-text-info">{T('scan-barcode')}</Heading>
                          <Checkbox
                            name="scanBarcode"
                            onChange={this.onChangeMoreSettings}
                            checked={valueBarcode === undefined ? true : valueBarcode}
                          >
                            <span/>
                          </Checkbox>
                          {T('modules-disable-scan-barcode')}
                        </li>}
                        {User.isMainUser() ? <li>
                          <Heading size={5}
                                   className="has-text-info">{T('production')}</Heading>
                          <Checkbox
                            name="production"
                            onChange={this.onChangeCheckbox}
                            checked={User.getModuleEnable("production")}
                          >
                            <span/>
                          </Checkbox>
                          <span>{T('production-text')}</span>

                        </li> : null}
                        {User.isMainUser() ? <li>
                            <Heading size={5} className="has-text-info">{T('Shipments')}</Heading>
                            <Checkbox 
                              name="shipments" 
                              onChange={this.onChangeCheckbox} 
                              checked={User.getModuleEnable("shipments")} 
                            >
                              <span/>
                            </Checkbox>
                            {T('modules-disable-shipments')}
                        </li> : null}
                        {User.isMainUser() ? <li>
                            <Heading size={5} className="has-text-info">{T('Clients')}</Heading>
                            <Checkbox 
                              name="clients" 
                              onChange={this.onChangeCheckbox} 
                              checked={User.getModuleEnable("clients")} 
                            >
                              <span/>
                            </Checkbox>
                            {T('modules-disable-clients')}
                        </li> : null}
                        {User.isMainUser() ? <li>
                            <Heading size={5} className="has-text-info">{T('reserve_sale_module')}</Heading>
                            <Checkbox 
                              name="reserv" 
                              onChange={this.onChangeCheckbox} 
                              checked={User.getModuleEnable("reserv")} 
                            >
                              <span/>
                            </Checkbox>
                            {T('modules-disable-reserv')}
                        </li> : null}
                        {AppStore.getWlName() !== "modeler" && User.isMainUser() && <li>
                          <Heading size={5} className="has-text-info">{T('terminal')}</Heading>
                          <Checkbox
                            name="terminal"
                            onChange={this.onChangeCheckbox}
                            checked={User.getModuleEnable("terminal")}
                          >
                            <span/>
                          </Checkbox>
                          {T('modules-disable-terminal')}
                        </li>}
                        <li>
                            <Columns>
                                <Columns.Column>
                                    {!User.isMainUser() && !User.getModuleEnable("slip") ? null : <Heading size={5} className="has-text-info">{T('print_slip')}</Heading>}
                                    {User.isMainUser() ? <>
                                      <Checkbox
                                      name="slip" 
                                      onChange={this.onChangeCheckbox} 
                                      checked={User.getModuleEnable("slip")} 
                                    >
                                        <span/>
                                    </Checkbox>
                                    {T('modules-disable-slip')}
                                    </> : null}

                                    {User.getModuleEnable("slip") ? 
                                    <SlipInfo />
                                    : null }

                                </Columns.Column>
                                <Columns.Column>
                                    {!User.isMainUser() && !User.getModuleEnable("pricetags") ? null : <Heading size={5} className="has-text-info">{T('print-tags')}</Heading>}
                                     {User.isMainUser() ? <>
                                     <Checkbox
                                      name="pricetags" 
                                      onChange={this.onChangeCheckbox} 
                                      checked={User.getModuleEnable("pricetags")} 
                                    >
                                       <span/>
                                    </Checkbox>
                                       {T('modules-disable-pricetags')}
                                     </>: null}
                                     {User.getModuleEnable("pricetags") ? 
                                    <PriceTagsInfo />
                                    : null }
                                </Columns.Column>
                            </Columns>
                        </li>
                    </ol>
                </div>
            </div>
            )}

      }

export default connectToStores(DisableModules, { _: User });
