import React from "react";
import ExpensesStore from "../../expenses/expenses-store";
import T from "../../../components/i18n";
import formatDate from "../../../tools/format-date";
import Money from "../../../components/money";
import User from "../../../user/user-store";
import ActionButtons from "./action-buttons";
import WarehouseStore from "../../warehouse/warehouse-store";
import {Button} from "react-bulma-components";
import TransactionsActions from "../transactions-actions";

const TransactionsRowDesktop = ({ data }) => {
  let expenses = data["comment"];
  let warehouse = null
  let expenses_name = null;
  let exp;

  const handleFilterExpenses = (categoryID) => {
      TransactionsActions.filter('expenses_category', {value: categoryID})
  }

  if (data["expenses_id"]) {
    exp = ExpensesStore.getExpensesById(data["expenses_id"]);
    if (exp) {
      expenses_name = <Button key={exp.category_id} className='button-link-without-style' onClick={() => handleFilterExpenses(exp.category_id)}>
        <b>{exp.category_name}</b>
      </Button>;
    }
    if(exp?.marketplace_id) {
      warehouse = WarehouseStore.getWarehouseName(exp.marketplace_id);
    }
  }

  return (
    <tr key={data["id"]} style={(data.is_deleted || (data["expenses_id"] && !expenses_name)) ? {opacity: 0.5} : {}}>
      <td>{formatDate(data["created_at"], true)}</td>
      <td>
        {T("action-" + data["action_id"])} {!!data["expenses_id"] && `#${data["expenses_id"]}`}
        <br />
        {expenses_name}
      </td>
      <td>{T(ExpensesStore.getAccountName(data["account_id"]))}</td>
      {User.getPermissionEnable("show-cashflow") && (
        <td>
          <Money
            amount={data["amount"]}
            aid={data["account_id"]}
            signClassname={true}
          />
        </td>
      )}
      {User.getPermissionEnable("showbalance") && (
        <td>
          <Money amount={data["balance"]} aid={data["account_id"]} />
        </td>
      )}
      <td>
        <Money amount={data["commission"]} aid={data["account_id"]} />
      </td>
      <td>
         {T(expenses)}
         {warehouse && <div><b>{T('history-data-marketplace_id')}: </b> {warehouse}</div>}
      </td>
      <td>
        <ActionButtons expense={exp} isDeleted={data.is_deleted}/>
      </td>
    </tr>
  );
};

export default TransactionsRowDesktop;
