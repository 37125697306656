import yajax from "yajax";
import {createStore} from "../../../tools/reflux-tools";
import Actions from "./integration-binding-actions";

const IntegrationBindingStore = createStore({
	listenables: Actions,
	_prefix_remote: '/bremote/',
	state: {
		dictBindingProducts: {},
		remoteProduct: null,
		isLoading: false
	},

	getListForSelectWithoutBindingProduct(iid) {
		const list = this.getBindingProduct(iid)

		return list.reduce((acc, cur) => {
			if(!cur.product_id) {
				acc.push({id: cur.remote_id, name: `ID: ${cur.remote_id} ${cur.name ? cur.name : ''}`})
			}
			return acc
		}, [])
	},

	getBindingProduct(iid) {
		const list = this.get('remoteProduct')
		if(!list) {
			Actions.remoteProductsByIid(iid)
			this.setState({
				remoteProduct: []
			})
		}

		return list || []
	},

	isBindingProductByPid(pid) {
		const listBinding = this.get('dictBindingProducts')
		if(listBinding[pid]) {
			return {value: listBinding[pid].remote_id, label: `ID: ${listBinding[pid].remote_id} ${listBinding[pid].name ? listBinding[pid].name : ''}`}
		}
		return false
	},

	onRemoteProductsByIid(iid) {
		if(this.state.isLoading) return
		const data = {}
		if(iid) {
			data.iid = iid
		}
		this.setState({
			isLoading: true
		})
		yajax.get(this._prefix_remote + 'link_products', {sync_iid: iid}).then(Actions.remoteProductsByIid.completed)
	},

	onRemoteProductsByIidCompleted(data) {
		this.setState({
			remoteProduct: data.map(item => ({...item, id: item.remote_id})),
			dictBindingProducts: data.reduce((acc, cur) => {
				if(cur.product_id) {
					acc[cur.product_id] = cur
				}
				return acc
			}, {}),
			isLoading: false
		})
	},

	onRemoteProductsByIidFailed(data) {
		this.setState({
			isLoading: false
		})
	},

	onBinding({pid, iid,rid}) {

		return yajax.post(this._prefix_remote+"binding", {pid, iid,rid}).then(
			() => Actions.binding.completed(iid), Actions.binding.failed
		);
	},
	onBindingCompleted(iid) {
		Actions.remoteProductsByIid(iid)
	},
	onBindingFailed(){
	},

	onDeleteBinding({pid, iid,rid}) {
		console.log("start delete Binding", {pid, iid,rid})
		const params = {
			method: "DELETE",
			url: this._prefix_remote+"binding",
			data: {pid, iid,rid}
		};

		return yajax(params).then(
			() => Actions.deleteBinding.completed(iid), Actions.deleteBinding.failed
		);
	},
	onDeleteBindingCompleted(iid) {
		Actions.remoteProductsByIid(iid)
		console.log("remove binding completed")
	},
	onDeleteBindingFailed() {
		console.log("remove binding filed")
	},

})

export default IntegrationBindingStore