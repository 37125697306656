import React, { Component } from 'react';
// import OpenModalButton from "components/modal/open-modal-button";
import Box from 'react-bulma-components/lib/components/box';
// import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';
import Tile from 'react-bulma-components/lib/components/tile';
import T from "components/i18n";
// import Actions from "./start-actions"

import { Link } from "react-router-dom";
// import Image from 'react-bulma-components/lib/components/image';

import AppStore from "app-store"
import {MyLink} from 'tools/my-link';
import OpenInsideModal from "components/modal/open-inside-modal";
import AddAccountModal from "modules/transactions/add-account-modal";
// import AddProductsModal from "modules/products/add-products-modal";
import AddWarehouseModal from "modules/warehouse/add-warehouse-modal";
import AddSaleModal from "modules/sales/add-sales-modal";
import LoadExcelModal from "modules/products/load-excel-modal";
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import AddFundsModal from "modules/expenses/add-funds-modal";

import OpenModalButton from "components/modal/open-modal-button";


class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        // Actions.loadMarketplaces();
        // User.startCompleted();
    }

    render () {
        return (
                <div align="left" className="welcome-block">
                    <Box><Heading position="left">{T('welcome', {'name': AppStore.getAppName()})}</Heading></Box>
                    <Tile kind="ancestor">
                        <Tile kind="parent">
                            <Tile kind="child" notification color="white">
                                <Heading size={4}>{T('how-to-start')}</Heading>
                                {!AppStore.isForeign() ?
                                <iframe title="video-help-main" width="100%" src="https://www.youtube.com/embed/cV-65bc1Ogk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                : null}
                                <ul>
                                    <li><b>1. {T('tips-one')} </b> 
                                        <div>{T('tips-one-desk')}</div>
                                        <div className="button-block">
                                            <OpenInsideModal size="small" text={T('add-new-account-btn')} icon="plus-circle">
                                                <AddAccountModal />
                                            </OpenInsideModal>
                                        </div>
                                    </li>
                                    <li><b>2. {T('tips-tree')} </b> 
                                        <div>{T('tips-tree-desk')}</div>
                                        <div className="button-block">
                                            <OpenInsideModal size="small" text={T('add-warehouse-btn-label')} icon="plus-circle">
                                                <AddWarehouseModal />
                                            </OpenInsideModal>
                                        </div>
                                    </li>
                                    <li><b>3. {T('tips-second')} </b> 
                                        <div>{T('tips-second-desk')}</div>
                                        <div className="button-block">
                                            <OpenModalButton size="small" className="margin-right-5" link="/products/add-products" text={T('add-product-btn')} icon="plus-circle"/>
                                            <OpenInsideModal size="small" text={T('load-from-excel')} icon="plus-circle">
                                                <LoadExcelModal />
                                            </OpenInsideModal>
                                        </div>
                                    </li>
                                    <li><b>4. {T('tips-four')} </b> 
                                        <div>{T('tips-four-desk')}</div>
                                        <div className="button-block">
                                            <OpenInsideModal size="small" text={T('add-sale-btn')} color="primary" icon="plus-circle">
                                                <AddSaleModal modal='sale-modal'/>
                                            </OpenInsideModal>
                                        </div>
                                    </li>
                                    <li><b>5. {T('tips-five')} </b> 
                                        <div>{T('tips-five-desk')}</div>
                                        <div className="button-block">
                                            <OpenInsideModal size="small" text={T('add-expenses-btn')} color="danger" icon="minus-circle">
                                                <AddExpensesModal />
                                            </OpenInsideModal>
                                            <OpenInsideModal size="small" text={T('add-funds-btn')} icon="plus-circle">
                                                <AddFundsModal />
                                            </OpenInsideModal>
                                        </div>
                                    </li>
                                    <li><b>6. {T('tips-six')} </b> 
                                        <div>{T('tips-six-desk')}</div>
                                        <div className="button-block">
                                            <Button renderAs={Link} to="/reports"size="small" rounded color="info">{T('reports')}</Button>
                                        </div>
                                    </li>
                                </ul>
                            </Tile>
                        </Tile>
                        <Tile vertical kind="parent">
                            {AppStore.isEnableForWhiteLable('contacts') ?
                            <Tile kind="child" notification color="white">
                                <Heading size={4}>{T('help-and-support')}</Heading>
                                <div>
                                    {T('help-and-support-desk')}<br />
                                    <b>Viber, Telegram:</b> {AppStore.getContactData('messanger')}<br />
                                    <b>{T('phone')}:</b> {AppStore.getContactData('phone')}
                                    <br /><br />
                                    <b>{T('telegram-help')}</b><br />
                                    <p>
                                    {MyLink(AppStore.getContactData('telegram_page'), T('telegram-channel'))}
                                    </p>
                                </div>
                            </Tile> : null}

                            <Tile  kind="child" notification color="white">
                                <Heading size={4}>{T('video-instruction')}<span className="soon">{T('_soon')}</span></Heading>
                                <div>
                                    {T('video-help-desk')}<br /><br />

                                    <p>
                                    {MyLink("https://www.youtube.com/channel/UCpjJ1aQ9y3Ob5eEi62lR7oA", T('youtube-channel'))}
                                    </p>
                                </div>
                            </Tile>
                            <Tile  kind="child" notification color="white">
                                <Heading size={4}>FAQ</Heading>
                                <div>
                                    {T('faq-desk')}<br /><br />

                                    <p>{MyLink(AppStore.getDocsLink('faq'), T('faq-link'))}</p>
                                </div>
                            </Tile>
                            <Tile  kind="child" notification color="white">
                                <Heading size={4}>{T('documentation')}</Heading>
                                <div>
                                    {T('documentation-desk', {'name': AppStore.getAppName()})}<br /><br />

                                     <p>{MyLink(AppStore.getDocsLink('docs'), T('documentation-link'))}</p>
                                </div>
                            </Tile>
                        </Tile>
                    </Tile>
                </div>
			 );

    }
}

export default Start;
