import React from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import formatDate from "../../../tools/format-date";
import List from "react-bulma-components/lib/components/list";
import ExpensesStore from "../../expenses/expenses-store";
import Money from "../../../components/money";
import DOM from "react-dom-factories";
import Columns from "react-bulma-components/lib/components/columns";
import NumberBlock from "../../../components/number-block";
import User from "../../../user/user-store";
import ActionButtons from "../desktop/action-buttons";
import WarehouseStore from "../../warehouse/warehouse-store";
import TransactionsActions from "../transactions-actions";
import {Button} from "react-bulma-components";

const TransactionsRowMobile = ({ data }) => {
  let expenses = data['comment'];
  let warehouse = null
  let expenses_name = null;
  let exp;
  if (data["expenses_id"]) {
    exp = ExpensesStore.getExpensesById(data["expenses_id"]);
    if (exp) {
      const handleFilterExpenses = () => {
        TransactionsActions.filter('expenses_category', {value: exp.category_id})
      }

      expenses_name = <Button className='button-link-without-style' onClick={handleFilterExpenses}>
        <b>{exp.category_name}</b>
      </Button>;
    }

    if(exp?.marketplace_id) {
      warehouse = WarehouseStore.getWarehouseName(exp.marketplace_id);
    }
  }

  return (
    <tr key={data["id"]} style={(data.is_deleted || (data["expenses_id"] && !expenses_name)) ? {opacity: 0.5} : {}}>
      <td colSpan="5">
        <div style={{ padding: "10px" }}>
          <Heading size={4} className="margin-top-10">
            {T("action-" + data["action_id"])} {!!data["expenses_id"] && `#${data["expenses_id"]}`} ({formatDate(data["created_at"], true)}
            )
            <br />
            <small style={{ fontSize: "0.8em" }}>{expenses_name}</small>
          </Heading>

          <List>
            <List.Item>
              <b>{T("account")}:</b>{" "}
              {T(ExpensesStore.getAccountName(data["account_id"]))}
            </List.Item>
            <List.Item>
              <b>{T("commission")}:</b>{" "}
              <Money
                amount={data["commission"]}
                aid={data["account_id"]}
                wrapper={DOM.span}
              />
            </List.Item>
            <List.Item>
              <b>{T("comment")}:</b>  {expenses}
            </List.Item>
            {warehouse && <List.Item>
              <b>{T("history-data-marketplace_id")}:</b> {warehouse}
            </List.Item>}
          </List>

          <Columns className="is-mobile product-mobile-block">
            {User.getPermissionEnable("show-cashflow") && (
              <Columns.Column size="half">
                <NumberBlock
                  top="amount"
                  number={data["amount"] || 0}
                  bottom={ExpensesStore.getAccountCurrency(data["account_id"])}
                  money={true}
                  className="small-number-box"
                />
              </Columns.Column>
            )}
            {User.getPermissionEnable("showbalance") && (
              <Columns.Column>
                <NumberBlock
                  top="balance"
                  number={data["balance"] || 0}
                  bottom={ExpensesStore.getAccountCurrency(data["account_id"])}
                  money={true}
                  className="small-number-box"
                />
              </Columns.Column>
            )}
          </Columns>
          <ActionButtons expense={exp} isDeleted={data.is_deleted}/>
        </div>
      </td>
    </tr>
  );
};

export default TransactionsRowMobile;
