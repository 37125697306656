import React, {useEffect, useState} from 'react'
import TabsIntegration from "./components/tabs-integration";
import UserStore from "../../../user/user-store";
import FormCommon from "./components/form-common";
import AppStore from "../../../app-store";
import UserActions from "../../../user/user-actions";
import {connectToStores} from "../../../tools/reflux-tools";
import NovaPoshtaModal from "./modal/nova-poshta/nova-poshta";
import ClientsActions from "../../clients/clients-actions";
import ButtonAddAnotherIntegration
	from "./modal/common/button-add-another-integration";
import WooCommerce from "./modal/woocommeerce/woocommeerce";
import Prom from "./modal/prom/prom";
import Rozetka from "./modal/rozetka/rozetka";
import Horoshop from "./modal/horoshop/horoshop";
import OpenCart from "./modal/opencart/opencart";
import TurboSMS from "./modal/turbo-sms/turbo-sms";
import Telegram from "./modal/telegram/telegram";
import UkrPoshta from "./modal/urk-poshta/ukr-poshta";
import ActionsUploadDownloadProducts
	from "./components/actions-upload-download-products";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../components/i18n";
import SmsNotification
	from "./modal/turbo-sms/sms-notification/sms-notification";
import Box from "react-bulma-components/lib/components/box";
import CheckboxModal from "./modal/checkbox/checkbox-modal";
import ApiModal from "./modal/api-form/api-modal";
import LoyalCadsModal from "./modal/loyal-cards/loyal-cads-modal";
import SalesStore from "../../sales/sales-store";
import Viber from "./modal/viber/viber";
import OpenBindingPage from "../mass-binding/open-binding-page";
import Facebook from "./modal/facebook/facebook";
import FacebookButton from "./modal/facebook/facebook-button";
import VchasnoKasa from "./modal/vchasno-kasa/vchasno-kasa";
import Binotel from "./modal/binotel/binotel";
import OlxChats from "./modal/olx-chats/olx-chats";
import Ringostat from "./modal/ringostat/ringostat";
import ShopExpress from "./modal/shop-express/shop-express";


const keyIntegration = [
	{
		name: "default",
		link: "default"
	},
	{
		name: "delivery-1",
		link: "np",
		modal: (data) => <NovaPoshtaModal modal='integrations' data={data}/>,
		notAccesses: ['modeler']
	},
	{
		name: "WooCommerce",
		link: "woocommerce",
		modal: (data) => <WooCommerce modal='integrations' data={data}/>,
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data} isPutProducts/>,
		additionalButton: (data) => <OpenBindingPage data={data}/>
	},
	{
		name: "delivery-3",
		link: "ukrposhta",
		modal: (data, wooID) => <UkrPoshta data={data} wooID={wooID}/>,
		notAccesses: ['modeler']
	},
	{
		name: "integration-4",
		link: "checkbox",
		modal: (data) => <CheckboxModal
																id={data?.id}
																ident={data?.ident}
																is_active={data?.is_active}
																data={data}
										/>,
		notAccesses: ['modeler']
	},
	{
		name: "Prom.Ua",
		link: "prom",
		modal: (data, wooID) => <Prom data={data} wooID={wooID} modal='integrations'/>,
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data} isPutProducts disabledPutProduct/>,
		additionalButton: (data) => <OpenBindingPage data={data}/>,
		notAccesses: ['modeler'],
	},
	{
		name: "integration-6",
		link: "rozetka",
		modal: (data, wooID) => <Rozetka data={data} wooID={wooID} modal='integrations'/>,
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data}  isPutProducts disabledPutProduct/>,
		additionalButton: (data) => <OpenBindingPage data={data}/>,
		notAccesses: ['modeler'],
	},
	{
		name: "OpenCart",
		link: "opencart",
		modal: (data, wooID) => <OpenCart data={data} wooID={wooID} modal='integrations'/>,
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data} isPutProducts/>,
		additionalButton: (data) => <OpenBindingPage data={data}/>
	},
	{
		name: "integration-8",
		link: "horoshop",
		modal: (data, wooID) => <Horoshop data={data} wooID={wooID} modal='integrations'/>,
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data} isPutProducts/>,
		additionalButton: (data) => <OpenBindingPage data={data}/>
	},
	{
		name: "integration-9",
		link: "vchasno-kassa",
		modal:(data,wooID) => <VchasnoKasa data={data} wooID={wooID}/>,
		notAccesses: ['navkolo']
	},
	{
		name: "Instagram/Facebook",
		link: "instagram",
		notAccesses: ['navkolo']
	},
	{
		name: "TurboSms",
		link: "turbosms",
		modal: (data, wooID) => <TurboSMS data={data} wooID={wooID} modal='integrations'/>,
		additionalButton: (data) => <Button
																				rounded
																				color="success"
																				onClick={() => AppStore.openModal(<SmsNotification data={data}/>)}
																>{T('settings-sms')}</Button>
	},
	{
		name: "Instagram/Facebook",
		link: "instagram",
		notAccesses: ['navkolo'],
		modal: (data, wooID) => <Facebook data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: "Telegram",
		link: "telegram",
		modal: (data, wooID) => <Telegram data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: "Instagram/Facebook",
		link: "instagram",
		// notAccesses: ['navkolo'],
		modal: (data, wooID) => <Facebook data={data} wooID={wooID} modal='integrations'/>,
		submitButton:  (data, wooID) => <FacebookButton wooID={wooID}/>
	},
	{
		name: 'integration-15',
		link: 'api',
		modal: (data, wooID) => <ApiModal data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: 'integration-16',
		link: 'loyalcards',
		modal: (data, wooID) => <LoyalCadsModal data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: "Viber",
		link: "viber",
		modal: (data, wooID) => <Viber data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: 'binotel',
		link: "binotel",
		modal: (data, wooID) => <Binotel data={data} wooID={wooID} modal='integrations'/>,
		disabledAddMore: true,
		notAccesses: ['modeler']
	},
	{
		name: 'olx',
		link: "olx",
		disabledAddMoreAfterCount: 3,
		modal: (data, wooID) => <OlxChats data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: 'ringostat',
		link: "ringostat",
		disabledAddMore: true,
		modal: (data, wooID) => <Ringostat data={data} wooID={wooID} modal='integrations'/>
	},
	{
		name: 'shopexpress',
		link: "shopexpress",
		additionalSetting: (data) => <ActionsUploadDownloadProducts data={data} isPutProducts/>,
		modal: (data, wooID) => <ShopExpress data={data} wooID={wooID} modal='integrations'/>
	}
]

const WHITELABLE = process.env.REACT_APP_WHITELABLE;


const Integration = () => {
	const [activeTab, setActiveTab] = useState('all')
	const [IntegrationData, setIntegrationData] = useState([])
	const remoteSystem = UserStore.getNewRemoteSystem()

	const getActiveRemoteSystem = (tabs) => {
		return tabs === 'all' ? remoteSystem : UserStore.getIntegrationGroup(tabs)
	}

	const getActiveIntegrationData = (tabs) => {
		const activeRemoteSystem = getActiveRemoteSystem(tabs)

		return activeRemoteSystem.map(system_id => {
			let getData
			if(system_id === 1) {
				getData = UserStore.getNpSender()
			} else if(system_id === 3) {
				getData = UserStore.getUpSender()
			} else {
				getData = UserStore.getIntegrationData(system_id)
			}

		return {wooID: system_id, data: getData}})
	}

	const handleADDIntegration = (wooID) => {
		const newData = IntegrationData.map(item => {
			if(item?.wooID === wooID) {
				const data = item.data?.length ? [...item.data, {}] : [item.data, {}]
				return {wooID, data}
			}
			return  item
		})

	setIntegrationData(newData)
	}

	useEffect(() => {
		setIntegrationData(getActiveIntegrationData(activeTab))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	useEffect(() => {
		const updateProfile = UserActions.loadProfile.completed.listen(() => {
			setIntegrationData(getActiveIntegrationData(activeTab))
		})
		const updateDeliver = ClientsActions.addDeliveryService.completed.listen(() => {
			UserActions.loadProfile()
		})

		return () => {
			updateProfile()
			updateDeliver()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	return (
		<div className='integrations-page'>
			<TabsIntegration active={activeTab} onChange={setActiveTab}/>
			<div className='integrations-wrapper'>
				{IntegrationData.map((integration, index) => {
					if(!keyIntegration[integration.wooID]?.notAccesses?.some(item => item === WHITELABLE)) {
						if (keyIntegration[integration.wooID]) {
							if (integration?.data?.length) {
								return <Box key={`wrapper-${integration.wooID}`}>
									{integration.data.map((integrationItem, index) => {
										return (
											<React.Fragment
												key={'form-item' + (integrationItem?.id || Math.random() * 100)}>
												<FormCommon
													wooID={integration.wooID}
													data={integrationItem}
													name={keyIntegration[integration.wooID].name}
													link={keyIntegration[integration.wooID].link}
													active={integrationItem?.is_active}
													id={integrationItem?.id}
													onClickButtonActions={keyIntegration[integration.wooID].modal ? () => AppStore.openModal(keyIntegration[integration.wooID].modal(integrationItem, integration.wooID)) : () => {
													}}
													submitButton={keyIntegration[integration.wooID].submitButton  ? keyIntegration[integration.wooID]?.submitButton(integration.data, integration.wooID) : false}
													additionSetting={keyIntegration[integration.wooID].additionalSetting && !!integrationItem?.is_active ? keyIntegration[integration.wooID]?.additionalSetting(integrationItem) : false}
													additionalButton={keyIntegration[integration.wooID].additionalButton && !!integrationItem?.is_active ? keyIntegration[integration.wooID]?.additionalButton(integrationItem) : false}
												/>
												{index !== integration.data.length - 1 && <hr/>}
											</React.Fragment>
										)
									})}
									{!(keyIntegration[integration.wooID]?.disabledAddMore || keyIntegration[integration.wooID]?.disabledAddMoreAfterCount <= integration?.data?.length) && <ButtonAddAnotherIntegration
										onClick={() => handleADDIntegration(integration.wooID)}/>}
								</Box>
							} else {
								return (
									<Box key={`wrapper-${integration.wooID}`}>
										<FormCommon
											wooID={integration.wooID}
											data={integration.data}
											name={keyIntegration[integration.wooID].name}
											link={keyIntegration[integration.wooID].link}
											active={integration.data.is_active}
											id={integration.data?.id}
											onClickButtonActions={keyIntegration[integration.wooID].modal ? () => AppStore.openModal(keyIntegration[integration.wooID].modal(integration.data, integration.wooID)) : () => {
											}}
											submitButton={keyIntegration[integration.wooID].submitButton  ? keyIntegration[integration.wooID]?.submitButton(integration.data, integration.wooID) : false}
											additionSetting={keyIntegration[integration.wooID].additionalSetting && !!integration.data?.is_active ? keyIntegration[integration.wooID]?.additionalSetting(integration.data) : false}
											additionalButton={keyIntegration[integration.wooID].additionalButton && !!integration.data?.is_active ? keyIntegration[integration.wooID]?.additionalButton(integration.data) : false}
										/>
										{(integration.data.is_active === 1 || integration.data.is_active === 0) && !keyIntegration[integration.wooID]?.disabledAddMore &&
											<ButtonAddAnotherIntegration
												onClick={() => handleADDIntegration(integration.wooID)}/>}
									</Box>)
							}
						} else {
							return <React.Fragment key={`not-have${index}`}/>
						}
					} else {
						return <React.Fragment key={`not-access${index}`}/>
					}
					})
				}
			</div>
		</div>
	);
};

export default connectToStores(Integration, { _: UserStore, sales: SalesStore })
