// import React, { Suspense, lazy } from 'react';
import React, {Suspense, useEffect} from 'react';
import { connectToStores } from "tools/reflux-tools";

import {BrowserRouter as Router} from "react-router-dom";

import UserStore from 'user/user-store';
import UserActions from 'user/user-actions';
import BaseLayout from './base-layout.js'
// import DefaultLayout from './default-layout.js'
// // import AccountLayout from './account-layout.js'
// import MobileLayout from './mobile-layout.js'

// import { Button } from 'react-bulma-components'

import { setLang } from "components/i18n";
import Money from "components/money";
import T from "components/i18n";
import AppStore from "./app-store";

import { DEFAULT_LANG } from "./configs/i18n";
import sessionConfig from "./configs/session";
// import { redirectTo } from 'tools/redirect-to'



// import {GoogleAPI} from 'react-google-oauth'

// yajax
import yajax from "yajax";
import dataEncoder from "yajax/form-encoder";

// // import 'bulma/css/bulma.min.css';
import './css/mobile.scss';
import './css/app.scss';
import './css/wl-name/styles.scss';
import './css/wl-name/mobile.scss';

import ru from 'date-fns/locale/ru';
import ua from 'date-fns/locale/uk';

import { registerLocale } from  "react-datepicker";
// import moment from "moment";

// import { isMobileOnly, isIOS } from "react-device-detect";
import { isMobileOnly } from "react-device-detect";
import {Helmet} from "react-helmet";

// import { browserHistory } from 'react-router';

// import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// const whitelable = process.env.REACT_APP_WHITELABLE;

// const AccountLayout = lazy(() => import('./account-layout.js'));
// const DefaultLayout = lazy(() => import('./default-layout.js'));
// const MobileLayout = lazy(() => import('./mobile-layout.js'));

// library.add(faCheckCircle);


if (AppStore.isEnableForWhiteLable("tagmanager")) {
  let tagManagerArgs = {
    gtmId: AppStore.isEnableForWhiteLable("tagmanager")
  }

  if (!UserStore.isGuest()) {
    tagManagerArgs['dataLayer'] = {'userId': UserStore.getUserId()}
  }

  TagManager.initialize(tagManagerArgs);

  let path_page = window.location.pathname + window.location.search;

  const eventData = {
    event: 'pageview',
    page_location: path_page,
    page_path: path_page
  };


  TagManager.dataLayer({ dataLayer: eventData });

}

if (AppStore.isEnableForWhiteLable("ga")) {
  // ReactGA.initialize(AppStore.isEnableForWhiteLable("ga"));
  
  // ReactGA.send({ hitType: "pageview", page: path_page });
  // ReactGA.pageview(window.location.pathname + window.location.search);
}



// Date.prototype.getWeekNumber = function(){
//   var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
//   var dayNum = d.getUTCDay() || 7;
//   d.setUTCDate(d.getUTCDate() + 4 - dayNum);
//   var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
//   return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
// };

// eslint-disable-next-line
Date.prototype.getMonday = function() {
  var day = this.getDay(),
      diff = this.getDate() - day + (day === 0 ? -6:1); 
  return new Date(this.setDate(diff));
}

// eslint-disable-next-line
Date.prototype.getRealMonth = function() {
  var month = this.getMonth()+1;    
  return month < 10 ? "0"+month : month;
}

registerLocale('ru', ru)
registerLocale('ua', ua)

sessionConfig.onSessionDestroy = UserActions.logout;
sessionConfig.onTokenChange = UserActions.changeToken;

setLang(DEFAULT_LANG || "ua");
UserStore._onSetLang(DEFAULT_LANG || "ua");
UserActions.initSession(sessionConfig)

AppStore.setIsMobileApp();

console.log("MOBILE APP ", AppStore.isMobileApp());

// moment().format("DD-MM-YYYY HH:mm");
// moment().utcOffset(UserStore.getTimeZone()).format("DD-MM-YYYY HH:mm");

// function _toUserTimeZone(date) {
//     return moment(date).utcOffset(User.getTimeZone()).format("DD-MM-YYYY HH:mm");
// }

function configureJajax(isGuest) {
	let headers = {
        "Content-type": "application/x-www-form-urlencoded",
        "X-Source-Type": "desktop"
    }

	if (!isGuest) {
		headers["X-Auth-Token"] = encodeURIComponent(UserStore.getToken())
	}

	yajax.configure({
	    headers: headers,
	    dataEncoder
	});
}

const moneyPattern = {
    ru: "{amount} {currency}",
    en: "{amount} {currency}",
    ua: "{amount} {currency}"
};

const reconfigureMoney = () => {
    Money.configure({
        1: T("currency-1"),
        2: T("currency-2"),
        3: T("currency-3"),
        4: T("currency-4"),
        5: T("currency-5"),
        6: T("currency-6"),
        7: T("currency-7"),
        8: T("currency-8"),
        9: T("currency-9"),
        10: T("currency-10"),
        11: T("currency-11"),
        12: T("currency-12"),
        13: T("currency-13"),
        14: T("currency-14"),
        15: T("currency-15"),
        16: T("currency-16")
    }, UserStore.get("currency_id"), moneyPattern[UserStore.get("lang")]);
};

UserActions.login.completed.listen((data) => {
  reconfigureMoney();
});


//Your initialization

// browserHistory.listen( location =>  {
//     console.log("location, ", location);
// });

function App() {
  const isGuest = UserStore.isGuest();
  configureJajax(isGuest);
  reconfigureMoney();

  let className = "App", canonical = null;

  if (isMobileOnly) {
    className += " mobile";
  }

  if (AppStore.isMobileApp()) {
      // className += " ios-app";
  }

  if (window.location.pathname === "/") {
    let link = "https://"+window.location.host+"/"+UserStore.getLang()+"/";

    canonical = (
         <link rel="canonical" href={link} />
      );
  }

  useEffect(() => {
    document.documentElement.lang = window.localStorage.getItem('lang') || 'ua'
  }, []);

  const favicon = "/img/"+AppStore.getWlName()+"/favicon.ico";

  return (
  	 <Router>
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="apple-touch-icon" href="apple-touch-icon.png" />
            <link rel="icon" href={favicon} />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <title>{AppStore.getTitle() || T('default-title', {name: AppStore.getAppName()})}</title>
            {canonical}
            <meta name="description" content={AppStore.getDesc() || T('default-desc', {name: AppStore.getAppName()})}/>
            <meta name="keywords" content={T('home-keywords')}/>
        </Helmet>

        <Suspense fallback={<div className="is-vcentered my-vcenter">
            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
          </div>}>
    	    <div className={className}>
    	    	<BaseLayout />
    	    </div>
        </Suspense>
    </Router>
  );
}

export default connectToStores(App, {
    user: UserStore,
    app: AppStore,
});
