//It's a very important to have variable 'messages'
//because our "lang compiler" expects such format
import React from "react";
import { Link } from "react-router-dom";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";

const messages = {
    // form controls
    "dashboard": {
        ru: "Главная",
        en: "Dashboard",
        ua: "Головна"
    },
    "start": {
        ru: "Начало",
        en: "Start",
        ua: "Початок"
    },
    "sales": {
        ru: "Продажи",
        en: "Sales",
        ua: "Продажі"
    },
    "Expenses": {
        ru: "Расходы",
        en: "Expenses",
        ua: "Витрати"
    },
    "Payments": {
        ru: "Платежи",
        en: "Payments",
        ua: "Платежі"
    },
    "payments": {
        ru: "Платежи",
        en: "Payments",
        ua: "Платежі"
    },
    "payment": {
        ru: "Платеж",
        en: "Payment",
        ua: "Платіж"
    },
    "product-label": {
        ru: "товар",
        en: "product",
        ua: "товар"
    },
    "products-label": {
        ru: "товарa",
        en: "product",
        ua: "товарa"
    },
    "products": {
        ru: "Товары",
        en: "Products",
        ua: "Товари"
    },
    "Investors": {
        ru: "Инвесторы",
        en: "Investors",
        ua: "Інвестори"
    },
    "Warehouse": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "warehouse": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "history": {
        ru: "История",
        en: "History",
        ua: "Історія"
    },
    "Shipments": {
        ru: "Поставки",
        en: "Shipments",
        ua: "Поставки"
    },
    "shipments": {
        ru: "Поставки",
        en: "Shipments",
        ua: "Поставки"
    },
    "reports": {
        ru: "Отчеты",
        en: "Reports",
        ua: "Звіти"
    },
    "Clients": {
        ru: "Клиенты",
        en: "Clients",
        ua: "Клієнти"
    },
    "clients": {
        ru: "Клиенты",
        en: "Clients",
        ua: "Клієнти"
    },
    "chats": {
        ru: "Чаты",
        en: "Chats",
        ua: "Чати"
    },
    "settings": {
        ru: "Настройки",
        en: "Settings",
        ua: "Налаштування"
    },
    "settings-sms": {
        ru: "Настроить SMS",
        en: "Settings SNS",
        ua: "Налаштувати SMS"
    },
    "subscribe": {
        ru: "Оплата",
        en: "Payment",
        ua: "Оплата"
    },
    "client": {
        ru: "Клиент",
        en: "Client",
        ua: "Клієнт"
    },
    "catalog": {
        ru: "Справочники",
        en: "Catalogs",
        ua: "Довідники"
    },
    "partners": {
        ru: "Партнерка",
        en: "Partners",
        ua: "Партнерка"
    },
    "partners-programm": {
        ru: "Партнерcкая программа",
        en: "Partners programm",
        ua: "Партнерська програма"
    },
    "vchastno-alert-client": {
        ru: "Оповещение клиента",
        en: "Client notification",
        ua: "Оповіщення клієнта"
    },
    "partners-link-desk": {
        ru: {
            var: "Расскажите друзьям о {var} и получайте пожизненно 15% с каждого платежа приглашенного пользователя!"
        },
        en: {
            var: "Tell your friends about {var} and receive 15% for life from each payment of the invited user!"
        },
        ua: {
            var: "Розкажіть друзям про {var} та отримуйте довічно 15% з кожного платежу запрошеного користувача!"
        },
    },
    "partners-link-label": {
        ru: "Достаточно просто поделиться следующей ссылкой:",
        en: "Just share the following link:",
        ua: "Достатньо лише поділитись наступним посиланням:"
    },
    "partners-list-heading": {
        ru: "Привлеченные партнеры и заработанные бонусы",
        en: "Involved partners and received bonuses",
        ua: "Залучені партнери і отримані бонуси"
    },
    "no-partners": {
        ru: "По Вашей ссылке пока никто не зарегистрировался",
        en: "No user has registered using your link yet",
        ua: "За Вашим посиланням ще не зареєструвався жоден користувач"
    },
    "partner-id": {
        ru: "ID Партнера",
        en: "Partner's ID",
        ua: "ID Партнера"
    },
    "partners-balance": {
        ru: "Начислено",
        en: "Credited",
        ua: "Нараховано"
    },
    "remote": {
        ru: "Внешний",
        en: "External",
        ua: "Зовнішній"
    },
    "partners-balance-total": {
        ru: "Всего начислено",
        en: "Credited total",
        ua: "Всього нараховано"
    },
    "partners-pay-out": {
        ru: "Выплачено",
        en: "Paid out",
        ua: "Виплачено"
    },
    "partners-pay-out-total": {
        ru: "Всего выплачено",
        en: "Paid out total",
        ua: "Всього виплачено"
    },
    "partner-email": {
        ru: "E-mail Партнера",
        en: "Partner's e-mail",
        ua: "E-mail Партнера"
    },
    "how-to-get-pay-out": {
        ru: "(Для получения выплаты обратитесь к службе поддержки)",
        en: "(In order to get a pay off contact the support)",
        ua: "(Для отримання виплати зверніться в службу підтримки)"
    },
    "link-copied": {
        ru: "Ссылка скопирована в буфер обмена",
        en: "The link is copied to the clipboard",
        ua: "Посилання скопійоване до буферу обміну"
    },
    "unable-to-copy-link": {
        ru: "Не удалось скопировать ссылку в буфер обмена",
        en: "Unable to copy link to the clipboard",
        ua: "Не вдалося скопіювати посилання до буферу обміну"
    },
    "disable-modules": {
        ru: "Отключение разделов",
        en: "Disable Modules",
        ua: "Відключення розділів"
    },
    "reports-actions": {
        ru: "Действия",
        en: "Actions",
        ua: "Дії"
    },
    "shipment-product": {
        ru: "Поставки товара",
        en: "Delivery of item ",
        ua: "Поставки товара"
    },
    "start-label": {
        ru: "Начать",
        en: "Get started",
        ua: "Почати"
    },
    "start-work": {
        ru: "Начать работу",
        en: "Get started",
        ua: "Почати роботу"
    },
    "hide-menu": {
        ru: "Спрятать меню",
        en: "Hide menu",
        ua: "Сховати меню"
    },
    "disable-modules-desk": {
        ru: (
            <span>Наша цель - сделать максимально простой и удобный сервис,
            которым Вам лично будет просто и комфортно пользоваться.
            Поэтому, если Ваш бизнес не нуждается в каких-то функциях, давайте их отключим, чтобы они не загромождали место и не отвлекали от основного.<br />
            <b>Любой раздел Вы можете включить в любое время!</b></span>
            ),
        en: (
            <span> Our goal is to make the service as simple and convenient as possible,
             which you personally will be easy and comfortable to use.
             Therefore, if your business does not need any functions, let's disable them so that they do not clutter up the place and distract from the main one.<br />
             <b> You can include any section at any time! </b></span>
            ),
        ua: (
            <span> Наша мета - зробити максимально простий і зручний сервіс,
             яким Вам особисто буде просто і комфортно користуватися.
             Тому, якщо Ваш бізнес не потребує якихось функціях, давайте їх відключимо, щоб вони не захаращували місце і не відволікали від основного.<br />
             <b> Будь-який розділ Ви можете включити в будь-який час! </b></span>
            )
    },
    "modules-disable-shipments": {
        ru: (
            <span>Планируете ли Вы закупать товар у поставщиков и хранить его в магазине, дома или на своем складе?
            Если Вы работаете по схеме Dropshipping (дропшипинг), т.е отправляете товар напрямую от поставщика, производителя или со склада прямо клиенту и не храните товар у себя,
            Вам раздел Поставки не нужен. <b>Отключите его</b>.<br /><br />
            Но если Вы покупаете товар у разных поставщиков, его привозят к Вам и Вы самостоятельно продаете и отправляете его покупателям,
            Вам этот раздел очень поможет контролировать все Ваши поставки товара: <b>стоимости закупок, оплаты доставки товара к Вам от поставщиков, изменение закупочной цены,
            остатков товара в каждой поставке и многое другое.</b></span>
            ),
        en: (
            <span> Do you plan to purchase goods from suppliers and store them in a store, at home or in your warehouse?
            If you work under the Dropshipping scheme (dropshipping), that is, you send goods directly from a supplier, manufacturer or from a warehouse directly to a client and do not store the goods with you,
            You don't need the Shipments section. Disable it. <br />
            But if you buy a product from different suppliers, it is brought to you and you independently sell and send it to customers,
            This section will greatly help you to control all your supplies of goods: the cost of purchases, payment for delivery of goods to you from suppliers, changes in the purchase price,
            leftover goods in each delivery and much more. </span>
            ),
        ua: (
            <span> Чи плануєте Ви купувати товар у постачальників і зберігати його в магазині, вдома або на своєму складі?
            Якщо Ви працюєте за схемою Dropshipping (дропшіпінг), тобто відправляєте товар безпосередньо від постачальника, виробника або зі складу прямо клієнтові і не зберігайте товар у себе,
            Вам розділ Поставки не потрібен. Вимкніть його. <br />
            Але якщо Ви купуєте товар у різних постачальників, його привозять до Вас і Ви самостійно продаєте і відправляєте Його покупцям,
            Вам цей розділ дуже допоможе контролювати всі Ваші поставки товару: вартості закупівель, оплати доставки товару до Вас від постачальників, зміна закупівельної ціни,
            залишків товару в кожній поставці і багато іншого. </span>
            )
    },
    "modules-disable-clients": {
        ru: (
            <span>Планируете ли Вы вести учет Ваших клиентов и делать программу лояльности для них? Хранить их имена, дни рождения, адреса доставок, делать <b>ПЕРСОНАЛЬНЫЕ СКИДКИ</b>?
            Если нет, смело <b>Отключайте его</b>.<br /><br />
            Но если Вы хотите выстраивать длительные отношения с Вашими покупателями, то этот раздел поможет Вам работать с Вашей базой клиентов и <b>увеличивать их лояльность к Вашему бизнесу</b>.</span>
            ),
        en: (
            <span> Do you plan to keep track of your customers and create a loyalty program for them? Store their names, birthdays, delivery addresses, do <b> PERSONAL DISCOUNTS </b>?
             If not, feel free to <b> Disable it </b>. <br /> <br />
             But if you want to build long-term relationships with your customers, then this section will help you work with your customer base and <b> increase their loyalty to your business </b>. </span>
            ),
        ua: (
            <span> Чи плануєте Ви вести облік Ваших клієнтів і робити програму лояльності для них? Зберігати їх імена, дні народження, адреси доставок, робити <b> ПЕРСОНАЛЬНІ ЗНИЖКИ </b>?
             Якщо ні, сміливо <b> Вимикайте його </b>. <br /> <br />
             Але якщо Ви хочете вибудовувати тривалі відносини з Вашими покупцями, то цей розділ допоможе Вам працювати з Вашою базою клієнтів і <b> збільшувати їх лояльність до Вашого бізнесу </b>. </span>
            )
    },
    "modules-disable-warehouse": {
        ru: (
            <span>Если Вы работаете только по Дропшипингу или у Вас только один магазин, Вам не нужно разделять товары по месту хранения, то Вам этот раздел не нужен. <b>Отключайте его!</b> <br /> <br />

            Но если у Вас несколько магазинов или мест где Вы храните свои товары и Вам нужно контролировать количество остатков товара в каждом из них.
            Или Вы часть товара храните у себя, а часть товара отправляете клиентам сразу от поставщиков (Dropshipping) и Вам нужно эти товары разделить,
            тогда раздел Склад поможет Вам в этих вопросах!</span>
            ),
        en: (
            <span> If you work only for Dropshipping or you have only one store, you do not need to separate goods by storage location, then you do not need this section. <b> Disable it! </b> <br /> <br />

             But if you have several stores or places where you store your goods and you need to control the amount of product leftovers.
             Or you store part of the goods at home, and send part of the goods to customers directly from suppliers (Dropshipping) and you need to separate these goods,
             then the Warehouse section will help you with these questions! </span>
            ),
        ua: (
            <span> Якщо Ви працюєте тільки по Дропшіпінгу або у Вас тільки один магазин, Вам не потрібно розділяти товари за місцем зберігання, то Вам цей розділ не потрібен. <b> Вимикайте його! </b> <br /> <br />

             Але якщо у Вас кілька магазинів або місць де Ви зберігаєте свої товари і Вам потрібно контролювати кількість залишків товару у кожному з них.
             Або Ви частину товару зберігаэте у себе, а частину товару відправляєте клієнтам відразу від постачальників (Dropshipping) і Вам потрібно ці товари розділити,
             тоді розділ Склад допоможе Вам в цих питаннях! </span>
            )
    },
    "modules-disable-reserv": {
        ru: (
            <span>Если Вы никогда не резервируете товар для Ваших клиентов с част. оплата или без. <b>Отключайте этот раздел!</b> <br /> <br />

            Но если Вас клиенты просят отложить товар на какое-то время для них, дают част. оплату, а забирают товар позже, то этот раздел Вам поможет не запутаться в отложенных товарах!</span>
            ),
        en: (
            <span>If you never reserve an item for your customers with or without partial payment. <b> Disable this section! </b> <br /> <br />

             But if customers ask you to postpone the goods for some time for them, give an advance payment, and pick up the goods later, then this section will help you not to get confused in the deferred goods! </span>
            ),
        ua: (
            <span> Якщо Ви ніколи не резервуєте товар для Ваших клієнтів з част. оплатою або без. <b>Вимикайте цей розділ! </b> <br /> <br />

             Але якщо Вас клієнти просять відкласти товар на якийсь час для них, дають част. оплату, а забирають товар пізніше, то цей розділ Вам допоможе не заплутатися в відкладених товари! </span>
            )
    },
    "modules-disable-slip": {
        ru: (
            <span>Если Вам не нужны никакие чеки <b>Отключайте этот раздел!</b> <br /> <br />

            Но если Вам нужно в Ваши отправки вложить товарную накладную или покупатели просят выдать чек, то Вам этот раздел необходим. Заполните ниже информацию, которая должна будет отображаться на чеке.</span>
            ),
        en: (
            <span>If you don't need any checks <b> Disable this section! </b> <br /> <br />

             But if you need to attach a consignment note to your shipments or buyers ask for a check, then you need this section. Fill in the information below that should be displayed on the check. </span>
            ),
        ua: (
            <span> Якщо Вам не потрібні ніякі чеки <b>Вимикайте цей розділ! </b> <br /> <br />

             Але якщо Вам потрібно в Ваші відправки вкласти товарну накладну або покупці просять видати чек, то Вам цей розділ необхідний. Заповніть нижче інформацію, яка повинна буде відображатися на чеку. </span>
            )
    },
    "modules-disable-pricetags": {
        ru: (
            <span>Если Вы продаете только on-line, ценники скорее всего вам не нужны. Отключайте этот раздел. Хотя Вы можете использовать ценники вместе со сканером штрих-кодов для более быстрого поиска товаров!</span>
            ),
        en: (
            <span>If you only sell online, you probably don't need price tags. Disable this section. Although you can use the price tags along with the barcode scanner for a faster product search!</span>
            ),
        ua: (
            <span>Якщо Ви продаєте лише on-line, цінники швидше за все вам не потрібні. Вимкніть цей розділ. Хоча Ви можете використовувати цінники разом зі сканером штрих-кодів для швидкого пошуку товарів!</span>
            )
    },
    "modules-disable-terminal": {
        ru: (
          <span>Если вы не используете терминал, отключите этот раздел.</span>
        ),
        en: (
          <span>If you are not using the terminal turn this section off.</span>
        ),
        ua: (
          <span>Якщо ви не використовуєте термінал, вимкніть цей розділ.</span>
        )
    },
    "back": {
        ru: "Назад",
        en: "Back",
        ua: "Повернутися"
    },
    "onetime": {
        ru: "Разовые",
        en: "One Time",
        ua: "Одноразові"
    },
    "planned": {
        ru: "Запланированные",
        en: "Planned",
        ua: "Заплановані"
    },
    "regular": {
        ru: "Ежемесячные",
        en: "Monthly",
        ua: "Щомісячні"
    },
    "eachmonth": {
        ru: "Ежемесячные",
        en: "Monthly",
        ua: "Щомісячні"
    },
    "eachweek": {
        ru: "Еженедельные",
        en: "Weekly",
        ua: "Щотижневі"
    },
    "name": {
        ru: "Название",
        en: "Name",
        ua: "Назва"
    },
    "name-type-price": {
        ru: "Тип цены",
        en: "Type price",
        ua: "Тип ціни"
    },
    "balance": {
        ru: "Баланс",
        en: "Balance",
        ua: "Баланс"
    },
    "clients-balance": {
        ru: "Баланс",
        en: "Balance",
        ua: "Баланс"
    },
    "sale-account": {
        ru: "Счет",
        en: "Account",
        ua: "Рахунок"
    },
    "sale-accounts": {
        ru: "Счетов",
        en: "Accounts",
        ua: "Рахуноків"
    },
    "sale-channel": {
        ru: "Канал продаж",
        en: "Sale Channel",
        ua: "Канал продажів"
    },
    "sales-channels": {
        ru: "Каналы продаж",
        en: "Sales Channels",
        ua: "Канали продажів"
    },
    "sale-channel-name": {
        ru: "Название канала продаж",
        en: "Sale Channel name",
        ua: "Назва каналу продажів"
    },
    "sale-channel-name-placeholder": {
        ru: "Введите название канала продаж",
        en: "Enter sales channel name",
        ua: "Введіть каналу продажів"
    },
    "type": {
        ru: "Тип",
        en: "Type",
        ua: "Тип"
    },
    "close": {
        ru: "Закрыть",
        en: "Close",
        ua: "Закрити"
    },
    "btn-done": {
        ru: "Готово",
        en: "Done",
        ua: "Готово"
    },
    "btn-save": {
        ru: "Сохранить",
        en: "Save",
        ua: "Зберегти"
    },
    "btn-edit": {
        ru: "Редактировать",
        en: "Edit",
        ua: "Редагувати"
    },
    "btn-active": {
        ru: "Активировать",
        en: "Activate",
        ua: "Активувати"
    },
    "add-brand-btn-label": {
        ru: "Добавить бренд",
        en: "Add brand",
        ua: "Додати бренд"
    },
    "add-category-btn-label": {
        ru: "Добавить категорию",
        en: "Add Category",
        ua: "Додати катеґорію"
    },
    "edit-category-btn-label": {
        ru: "Редактировать категорию",
        en: "Edit category",
        ua: "Редагувати категорію"
    },
    "add-sales-channel-btn-label": {
        ru: "Добавить канал продаж",
        en: "Add sales channel",
        ua: "Додати канал продажів"
    },
    "add-sale-status-btn-label": {
        ru: "Добавить статус продажи",
        en: "Add sale status",
        ua: "Додати статус продажу"
    },
    "add-supplier-btn-label": {
        ru: "Добавить поставщика",
        en: "Add supplier",
        ua: "Додати постачальника"
    },
    "parent_category": {
        ru: "Родительская категория",
        en: "Parent Category",
        ua: "Батьківська категорія"
    },
    "expenses-amount-placeholder": {
        ru: "Укажите сумму расхода",
        en: "Indicate the amount of expense",
        ua: "Зазначте суму витрати"
    },
    "expenses-amount": {
        ru: "Сумма расхода",
        en: "Amount Of Expense",
        ua: "Сума витрати"
    },
    "new-spend-money": {
        ru: "Добавить трату денег",
        en: "New Spend Money",
        ua: "Нова Витрата"
    },
    "comment": {
        ru: "Комментарий",
        en: "Comment",
        ua: "Коментар"
    },
    "copy": {
        ru: "Копировать",
        en: "Copy",
        ua: "Копіювати"
    },
    "copy-sale": {
        ru: "Копировать продажу",
        en: "Copy the sale",
        ua: "Копіювати продаж"
    },
    "expenses-comment-placeholder": {
        ru: "Комментарий по этой трате",
        en: "Comment for this spend",
        ua: "Коментар по цій витраті"
    },
    "expenses-description-placeholder": {
        ru: "Описание этой траты, чтобы вы поняли о чем речь",
        en: "Description of this expense so that you understand what it is about",
        ua: "Опис цієї витрати, щоби Ви зрозуміли, про що йдеться"
    },
    "add-expenses-btn": {
        ru: "Добавить расход",
        en: "Add Expenses",
        ua: "Додати витрату"
    },
    "edit-expenses-btn": {
        ru: 'Редактировать расход',
        en: 'Edit expenses',
        ua: 'Редагувати витрату',
    },
    "add-funds-btn": {
        ru: "Поступление средств",
        en: "Add funds",
        ua: "Додати надходження"
    },
    "edit-funds-btn": {
        ru: "Редактировать поступление средств",
        en: "Edit funds",
        ua: "Редагувати надходження"
    },
    "middle_name-requied": {
        ru: "Для создания Денежного перевода в Укрпочте обязательно должно быть заполнено Отчество",
        en: "To create a Money transfer in Ukrposhta, the Patronymic name must be filled in",
        ua: "Для створення Грошового переказу в Укрпошті обов'язково має бути заповнено По-батькові"
    },
    "receipts-category-prefix": {
        ru: "Зачисление средств",
        en: "Add Funds",
        ua: "Надходження"
    },
    "add-funds-btn-1": {
        ru: "Зачислить средства",
        en: "Add Funds",
        ua: "Зарахувати кошти"
    },
    "receipt-amount": {
        ru: "Сумма поступлений",
        en: "Amount of Receipts",
        ua: "Сума надходжень"
    },
    "receipt-amount-placeholder": {
        ru: "Укажите сумму для зачисления на Ваш счет",
        en: "Enter the amount to be credited to your account",
        ua: "Введіть суму для переведення на Ваш рахунок"
    },
    "funds-the-account": {
        ru: "Пополнить счет",
        en: "Top up account",
        ua: "Поповнити рахунок"
    },
    "receipt-category": {
        ru: "Тип поступления средств",
        en: "Source of Funds",
        ua: "Джерело надходжень"
    },
    "enrollment": {
        ru:"Зачисление",
        en:"Enrollment",
        ua:"Зарахування"
    },
    "credit": {
        ru: "Кредит",
        en: "Credit",
        ua: "Кредит"
    },
    "invest": {
        ru: "Инвестиции",
        en: "Investments",
        ua: "Інвестиції"
    },
    "other_receipt": {
        ru: "Другое",
        en: "Other",
        ua: "Інше"
    },
    "other_replenish": {
        ru: "Другие поступления",
        en: "Other income",
        ua: "Інші надходження"
    },
    "receipt-comment-placeholder": {
        ru: "Комментарий по этому поступлению",
        en: "Commentary on this receipt",
        ua: "Коментар щодо цього надходження"
    },
    "add-plan-expenses-btn": {
        ru: "Запланировать расходы",
        en: "Plan expenses",
        ua: "Заплановані витрати"
    },
    "not-have-expenses": {
        ru: "Отслеживайте ваши расходы, чтобы знать куда уходят Ваши деньги",
        en: "Track your expenses to see how you spend your money",
        ua: "Слідкуйте за вашими витратами, щоб знати куди йдуть Ваші гроші"
    },
    "not-found- rds": {
        ru: "Не найдено записей по данному фильтру",
        en: "No records found for this filter",
        ua: "Жодних записів не знайдено із цим фільтром"
    },
    "click-to-select": {
        ru: "-- Кликните для выбора --",
        en: "-- Click to select --",
        ua: "-- Клацніть, щоби вибрати --"
    },
    "general-expenses": {
        ru: "Распределить на все товары",
        en: "Distribute to all items",
        ua: "Розподілити на всі товари"
    },
    "type-of-expenses": {
        ru: "Тип расходов",
        en: "Type Of Expenses",
        ua: "Типи витрат"
    },
    "expenses-date": {
        ru: "Когда нужно оплатить?",
        en: "When do I need to pay?",
        ua: "Коли мені слід платити?"
    },
    "description": {
        ru: "Описание",
        en: "Description",
        ua: "Опис"
    },
    "id": {
        ru: "ID",
        en: "ID",
        ua: "ID"
    },
    "image": {
        ru: "Картинка",
        en: "Image",
        ua: "Зображення"
    },
    "expenses-category": {
        ru: "Категория расходов",
        en: "Expenses Category",
        ua: "Категорія витрат"
    },
    "payments-category": {
        ru: "Категории платежей",
        en: "Payment Categories",
        ua: "Категорії платежів"
    },
    "product-category": {
        ru: "Категория товаров",
        en: "Item Category",
        ua: "Категорія товарів"
    },
    "product-marketplace": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "produce_samples": {
        ru: "Производство примера продукта (сэмпла)",
        en: "Produce sample",
        ua: "Виготовити пробника"
    },
    "Product": {
        ru: "Товар",
        en: "Item",
        ua: "Товар"
    },
    "category-name": {
        ru: "Название категории",
        en: "Category Name",
        ua: "Назва катеґорії"
    },
    "category": {
        ru: "Категория",
        en: "Category",
        ua: "Категорія"
    },
    "category-name-placeholder": {
        ru: "Введите название категории расходов",
        en: "Enter expenses category name",
        ua: "Введіть назву катеґорії витрат"
    },
    "expense-category": {
        ru: "Категория расходов",
        en: "Expense category",
        ua: "Категорія витрат"
    },
    "reports-date": {
        ru: "Дата",
        en: "Date",
        ua: "Дата"
    },
    "reports-balance": {
        ru: "Баланс",
        en: "Balance",
        ua: "Баланс"
    },
    "reports-account": {
        ru: "Счет",
        en: "Account",
        ua: "Рахунок"
    },
    "reports-name": {
        ru: "Расход",
        en: "Expenses",
        ua: "Витрати"
    },
    "expenses-name": {
        ru: "Описание расхода",
        en: "Desc of Expenses",
        ua: "Опис витрати"
    },
    "reports-category": {
        ru: "Категория",
        en: "Category",
        ua: "Категорія"
    },
    "reports-comment": {
        ru: "Комментарий",
        en: "Comment",
        ua: "Коментар"
    },
    "reports-product": {
        ru: "Товар/Услуга",
        en: "Item/Service",
        ua: "Товар/Послуга"
    },
    "reports-type": {
        ru: "Тип расходов",
        en: "Expenses Type",
        ua: "Тип витрат"
    },
    "reports-amount": {
        ru: "Сумма",
        en: "Amount",
        ua: "Загалом"
    },
    "reports-action": {
        ru: "Удалить",
        en: "Delete",
        ua: "Вилучити"
    },
    "Marketplaces": {
        ru: "Площадка",
        en: "Marketplaces",
        ua: "Склади"
    },
    "seller-id": {
        ru: "Seller ID",
        en: "Seller ID",
        ua: "Seller ID"
    },
    "seller_id-placeholder": {
        ru: "Seller ID с Amazon",
        en: "Seller ID from Amazon",
        ua: "Seller ID з Amazon'у"
    },
    "mws-token": {
        ru: "MWS Auth Token",
        en: "MWS Auth Token",
        ua: "MWS Auth Token"
    },
    "mws_token-placeholder": {
        ru: "MWS Auth Token с Amazon",
        en: "MWS Auth Token from Amazon",
        ua: "MWS Auth Token з Amazon'у"
    },
    "martketplace-name": {
        ru: "Название (для вашего удобства)",
        en: "Name (for you comfort)",
        ua: "Назва (для Вашої зручності)"
    },
    "add-martketplace-btn-label": {
        ru: "Добавить аккаунт",
        en: "Add Account",
        ua: "Додати акаунт"
    },
    "add-marketplace-btn": {
        ru: "Подключить новый маркетплейс",
        en: "Add New Marketplace",
        ua: "Додати новий майданчик"
    },
    "not-have-product": {
        ru: "У Вас нет товаров для отображения",
        en: "You have no items to display",
        ua: "Ви не маєте товарів для відображення"
    },
    "not-have-warehouse": {
        ru: "У Вас не добавлено ни одного склада\\магазина",
        en: "You have not added any warehouse\\store",
        ua: "Ви не додали жодного складу\\крамниці"
    },
    "planned-expenses": {
        ru: "Запланированные расходы на месяц",
        en: "Expected Monthly Expenditures",
        ua: "Очікувані місячні витрати"
    },
    "dashboard-your-profits": {
        ru: "Ваши доходы",
        en: "Your Profits",
        ua: "Ваші доходи"
    },
    "dashboard-expenses-on": {
        ru: "Расходов на",
        en: "Expenses",
        ua: "Витрат на"
    },
    "expenses-on-amount": {
        ru: "Финансовых затрат",
        en: "Financial Costs",
        ua: "Фінансових витрат"
    },
    "dashboard-total-shipments": {
        ru: "Всего поставок",
        en: "Total Shipments",
        ua: "Поставок загалом"
    },
    "total-sales": {
        ru: "Всего продаж",
        en: "Total Sales",
        ua: "Продажів загалом"
    },
    "total-sales-report": {
        ru: "Кол. продаж",
        en: "Number of sales",
        ua: "Кіл. продажів"
    },
    "dashboard-total-shipments-quantity": {
        ru: "Закуплено",
        en: "Purchased",
        ua: "Закуплено"
    },
    "dashboard-total-shipments-amount": {
        ru: "Закуплено товара на",
        en: "Purchased goods on",
        ua: "Закуплено товару на"
    },

    "product_on": {
        ru: "товара на",
        en: "goods on",
        ua: "товару на"
    },
    "dashboard-delivery-good": {
        ru: "Последнии поставки товаров",
        en: "Latest shipments of goods",
        ua: "Останні поставки товарів"
    },
    "dashboard-money-for-good": {
        ru: "Доступно денег для закупки",
        en: "Available money for purchase",
        ua: "Наявні гроші на закупівлі"
    },
    "dashboard-your-products": {
        ru: "Ваши товары",
        en: "Your Products",
        ua: "Ваші товари"
    },
    "dashboard-best-sell": {
        ru: "Самый продаваемый товар",
        en: "Best Selling Item",
        ua: "Найпродаваніший товар"
    },
    "dashboard-sales-profit": {
        ru: "Прибыль с продаж",
        en: "Sales Profit",
        ua: "Прибуток з продажів"
    },
    "dashboard-sales-discount": {
        ru: "Сумма скидок",
        en: "Sales Discount",
        ua: "Сума знижок"
    },
    "dashboard-best-margin": {
        ru: "Самый прибыльный товар",
        en: "The most profitable item",
        ua: "Найприбутковіший товар"
    },
    "dashboard-need-to-order": {
        ru: "Необходимо заказывать",
        en: "Must be Ordered",
        ua: "Треба замовляти"
    },
    "search-string": {
        ru: "Поиск",
        en: "Search",
        ua: "Пошук"
    },
    "products-name": {
        ru: "Товар/Услуга",
        en: "Item/Service",
        ua: "Товар/Послуга"
    },
    "products-name-date": {
        ru: "Товар/Услуга/Дата",
        en: "Item/Service/Date",
        ua: "Товар/Послуга/Дата"
    },
    "orders": {
        ru: "Заказы",
        en: "Orders",
        ua: "Замовлення"
    },
    "sales-pay": {
        ru: {
            plural: "Оплата, {curr}"
            },
        en: {
            plural: "{curr}, Payment"
            },
        ua: {
            plural: "Оплата, {curr}"
            },
    },
    "payment-pay": {
        ru: {
            plural: "Сумма, {curr}"
            },
        en: {
            plural: "{curr}, Amount",
            },
        ua: {
            plural: "Сума, {curr}"
            },
    },
    "_orders": {
        ru: {
            plural: "{count} заказ{count, plural, =0{ов} =1{} =2{а} =3{а}  =4{а} other{ов}}"
        },
        en: {
            plural: "{count}  order{count, plural, =0{} =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "{count} замовлен{count, plural, =0{ь} =1{я} =2{я} =3{я}  =4{я} other{ів}}"
        }
    },
    "order": {
        ru: "Заказ",
        en: "Order",
        ua: "Замовлення"
    },
    "products-expenses": {
        ru: "Расходы на товар",
        en: "Expenses for item",
        ua: "Витрати на товар"
    },
    "products-netcost": {
        ru: "Себест.",
        en: "Cost",
        ua: "Собів."
    },
    "products-price": {
        ru: "Цена продажи",
        en: "Selling Price",
        ua: "Ціна продажу"
    },
    "products-warehouse-fba": {
        ru: "Склад FBA",
        en: "FBA stock",
        ua: "Склад FBA"
    },
    "products-balance-amount": {
        ru: "Сумма остатка",
        en: "Stock Value",
        ua: "Залишок"
    },
    "products-warehouse": {
        ru: "Склад",
        en: "Stock",
        ua: "Склад"
    },
    "info": {
        ru: "Инфо",
        en: "Info",
        ua: "Інфо"
    },

    "available": {
        ru: "Доступно",
        en: "Available",
        ua: "Доступно"
    },
    "supply": {
        ru: "Ожидается",
        en: "Expected",
        ua: "Очікується"
    },
    "expected_goods_worth": {
        ru: "Ожидается товара на сумму",
        en: "The expected value of the goods is",
        ua: "Очікується товару вартістю"
    },
    "expected-amount-of": {
        ru: "Ожидается на сумму",
        en: "Expected in the amount of",
        ua: "Очікується на суму"
    },
    "expected_goods_worth_short": {
        ru: "На сумму",
        en: "Cost",
        ua: "Вартістю"
    },
    "expected_goods_worth_paid": {
        ru: "Ожидалось товара на сумму",
        en: "The expected value of the goods was",
        ua: "Очікувалося товару вартістю"
    },
    "received_goods_worth": {
        ru: "Получили товара на сумму",
        en: "We Received Goods For an Amount Of",
        ua: "Отримали товару вартістю"
    },
    "got-it-on": {
        ru: "Получили на сумму",
        en: "Received for the sum of",
        ua: "Отримали на суму"
    },
    "added": {
        ru: "Добавлено",
        en: "Added",
        ua: "Додано"
    },
    "selling": {
        ru: "Продаж",
        en: "Selling",
        ua: "Продажів"
    },
    "move-money-btn": {
        ru: "Перевести деньги",
        en: "Transfer Money",
        ua: "Переказати кошти"
    },
    "aday": {
        ru: "в день",
        en: "a day",
        ua: "на день"
    },
    "products-sales-velocit": {
        ru: "Продажи",
        en: "Sales",
        ua: "Продажі"
    },
    "products-days-left": {
        ru: "Остаток на складе в днях (FBA)",
        en: "Days of stock left (FBA)",
        ua: "Лишилося днів на складі (FBA)"
    },
    "product-add-expenses": {
        ru: "Добавить расход на товар",
        en: "Add expenses for product",
        ua: "Додати витрати на товар"
    },
    "martketplace-name-placeholder": {
        ru: "Введите название площадки для вашего удобства",
        en: "Enter the name of the marketplace for your convenience.",
        ua: "Введіть назву майданчику для Вашої зручності"
    },
    "warehouse-name-placeholder": {
        ru: "Введите название склада\\магазина для вашего удобства",
        en: "Enter the name of the warehouse\\store for your convenience.",
        ua: "Введіть назву складу\\крамниці для Вашої зручності"
    },
    "you-cant-delete-not-empty-warehouse": {
        ru: "Вы не можете удалить склад в котором есть товары. Сперва перенесите товары на другой склад.",
        en: "You cannot delete a warehouse that contains goods. First, transfer the goods to another warehouse.",
        ua: "Ви не можете видалити склад в якому є товари. Спершу перенесіть товари на інший склад."
    },
    "add-warehouse-btn-label": {
        ru: "Добавить склад\\магазин",
        en: "Add Warehouse\\Store",
        ua: "Додати склад\\крамницю"
    },
    "edit-warehouse-btn-label": {
        ru: "Редактировать склад\\магазин",
        en: "Edit Warehouse\\Store",
        ua: "Редагувати склад\\крамницю"
    },
    "warehouse-name": {
        ru: "Название склада\\магазина",
        en: "Name Warehouse\\Store",
        ua: "Назва складу\\крамниці"
    },
    "warehouse-items": {
        ru: "Товаров добавлено",
        en: "Items Added",
        ua: "Товарів додано"
    },
    "warehouse-netcost": {
        ru: "Стоимость товара",
        en: "Items Cost",
        ua: "Вартість товару"
    },
    "warehouse-possible-revenue": {
        ru: "Потенциальная выручка",
        en: "Possible Revenue",
        ua: "Можлива виручка"
    },
    "warehouse-action": {
        ru: "Дейсвия",
        en: "Actions",
        ua: "Дії"
    },
    "available-small": {
        ru: "доступно",
        en: "available",
        ua: "доступно"
    },
    "available-warehouse-small": {
        ru: "на складе",
        en: "InStock",
        ua: "на складі"
    },
    "on-warehouse": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "in-stock": {
        ru: "в наличии",
        en: "InStock",
        ua: "в наявності"
    },
    "out-of-stock": {
        ru: "нет в наличии",
        en: "Out of Stock",
        ua: "нема в наявності"
    },
    "no": {
        ru: "Нет",
        en: "No",
        ua: "Нема"
    },
    "In-stock": {
        ru: 'В наличии',
        en: "InStock",
        ua: "В наявності"
    },
    "Moving": {
        ru: "Переместить",
        en: "Move",
        ua: "Перемістити"
    },
    "moving-comment-placeholder": {
        ru: "Введите комментарий об этом перемещение (необязательно)",
        en: "Enter a comment about this moving (optional)",
        ua: "Введіть коментар про це переміщення (необов'язково)"
    },
    "confirm-password": {
        ru: "Повторите пароль",
        ua: "Повторіть пароль",
        en: "Confirm Password"
    },
    "placeholder-confirm-password": {
        ru: "Пожалуйста, повторите пароль",
        ua: "Будь ласка, повторіть пароль",
        en: "Confirm Password Please"
    },
    "items": {
        ru: "товаров",
        en: "items",
        ua: "товарів"
    },
    "items-count": {
        ru: {
            plural: "{count} товар{count, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}"
        },
        en: {
            plural: "{count} item{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "{count} товар{count, plural, =1{} =2{а} =3{а}  =4{а} other{ів}}"
        }
    },
    "variable-count": {
        ru: {
            plural: "{count} вариант{count, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}"
        },
        en: {
            plural: "{count} variant{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "{count} варіант{count, plural, =1{} =2{а} =3{а}  =4{а} other{ів}}"
        }
    },
    "warehouse-count": {
        ru: {
            plural: "{count} склад{count, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}"
        },
        en: {
            plural: "{count} warehouse{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "{count} склад{count, plural, =1{} =2{а} =3{а}  =4{а} other{ів}}"
        }
    },
    "items-short": {
        ru: "наим.",
        en: "items",
        ua: "найм."
    },
    "items-p": {
        ru: "шт.",
        en: "pcs.",
        ua: "шт."
    },
    "items-e": {
        ru: "ед.",
        en: "pcs.",
        ua: "од."
    },
    "items-full": {
        ru: "единиц",
        en: "units",
        ua: "одиниць"
    },
    "units": {
        ru: "eдиниц",
        en: "units",
        ua: "одиниць"
    },
    "units-count": {
        ru: {
            plural: "{count} eдиниц{count, plural, =1{а} =2{ы} =3{ы} =4{ы} other{}}"
        },
        en: {
            plural: "{count} item{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "{count} одиниц{count, plural, =1{я} =2{і} =3{і} =4{і} other{ь}}"
        }
    },
    "units-short": {
        ru: "шт",
        en: "pcs",
        ua: "шт"
    },
    "kg": {
        ru: "кг",
        en: "kh",
        ua: "кг"
    },
    "gm": {
        ru: "грамм",
        en: "gram",
        ua: "грам"
    },
    "line": {
        ru: "ряд",
        en: "line",
        ua: "ряд"
    },
    "line-short": {
        ru: "ряд",
        en: "line",
        ua: "ряд"
    },
    "plt": {
        ru: "паллета",
        en: "pallet",
        ua: "палета"
    },
    "plt-short": {
        ru: "plt",
        en: "plt",
        ua: "plt"
    },
    "pack": {
        ru: "упаковка",
        en: "package",
        ua: "упаковка"
    },
    "pack-short": {
        ru: "уп.",
        en: "pk.",
        ua: "уп."
    },
    "set": {
        ru: "комплект",
        en: "set",
        ua: "комплект"
    },
    "set-short": {
        ru: "комп.",
        en: "set",
        ua: "комп."
    },
    "cut": {
        ru: "отрез",
        en: "cut",
        ua: "відріз"
    },
    "cut-short": {
        ru: "отр.",
        en: "cur",
        ua: "відр."
    },
    "bobbin": {
        ru: "бобин",
        en: "bobbin",
        ua: "бобін"
    },
    "bobbin-short": {
        ru: "боб.",
        en: "bob.",
        ua: "боб."
    },
    "ton": {
        ru: "тонна",
        en: "ton",
        ua: "тонна"
    },
    "ton-short": {
        ru: "тн",
        en: "ton",
        ua: "тн"
    },
    "L": {
        ru: "литров",
        en: "liters",
        ua: "літрів"
    },
    "L-short": {
        ru: "л",
        en: "L",
        ua: "л"
    },
    "ml": {
        ru: "миллилитров",
        en: "milliliters",
        ua: "мілілітрів"
    },
    "m": {
        ru: "метров",
        en: "meters",
        ua: "метрів"
    },
    "cm": {
        ru: "сантиметров",
        en: "centimeters",
        ua: "сантиметрів"
    },
    "m2": {
        ru: "м2",
        en: "m2",
        ua: "м2"
    },
    "pair": {
        ru: "пар",
        en: "pair",
        ua: "пар"
    },
    "ml-short": {
        ru: "мл",
        en: "ml",
        ua: "мл"
    },
    "g-short": {
        ru: "г",
        en: "g",
        ua: "г"
    },
    "g": {
        ru: "г",
        en: "g",
        ua: "г"
    },
    "gm-short": {
        ru: "г",
        en: "g",
        ua: "г"
    },
    "kg-short": {
        ru: "кг",
        en: "kg",
        ua: "кг"
    },
    "m-short": {
        ru: "м",
        en: "m",
        ua: "м"
    },
    "cm-short": {
        ru: "см",
        en: "cm",
        ua: "см"
    },
    "m2-short": {
        ru: "м2",
        en: "m2",
        ua: "м2"
    },
    "m3": {
        ru: "м3",
        en: "m3",
        ua: "м3"
    },
    "cm3": {
        ru: "cм3",
        en: "cm3",
        ua: "cм3"
    },
    "m3-short": {
        ru: "м3",
        en: "m3",
        ua: "м3"
    },
    "cm3-short": {
        ru: "cм3",
        en: "cm3",
        ua: "cм3"
    },
    "pair-short": {
        ru: "пар",
        en: "pair",
        ua: "пар"
    },
    "units-of-measurement": {
        ru: "Ед. измер.",
        en: "Units",
        ua: "Од. виміру"
    },
    "sold-units": {
        ru: "Продано единиц",
        en: "Sold units",
        ua: "Продано одиниць"
    },
    "add": {
        ru: "Добавить",
        en: "Add",
        ua: "Додати"
    },
    "or": {
        ru: "или",
        en: "or",
        ua: "або"
    },
    "from": {
        ru: "от",
        en: "from",
        ua: "від"
    },
    "stored-for": {
        ru: "Хранится товара на",
        en: "Stored Goods For",
        ua: "Зберігається товарів на"
    },
    "possible-profit": {
        ru: "Возможная прибыль",
        en: "Possible Profit",
        ua: "Можливий дохід"
    },
    "calculate-netcost": {
        ru: "Рассчитать",
        en: "Calculate",
        ua: "Розрахувати"
    },
    "calculate-netcost-full": {
        ru: "Рассчитать себестоимость",
        en: "Calculate Cost Price",
        ua: "Розрахувати собівартість"
    },
    "netcost": {
        ru: "Себестоимость",
        en: "Cost",
        ua: "Собівартість"
    },
    "change-netcost": {
        ru: "Изменить себестоимость",
        en: "Edit Cost",
        ua: "Змінити собівартість"
    },
    "change": {
        ru: "Изменить",
        en: "Edit",
        ua: "Змінити"
    },
    "product-cost": {
        ru: "Продажа",
        en: "Sale",
        ua: "Продаж"
    },
    "file-templates": {
        ru: "Шаблон",
        en: "Templates",
        ua: "Шаблони"
    },
    "file": {
        ru: "Файл",
        en: "File",
        ua: "Файл"
    },
    "all": {
        ru: "Все",
        en: "All",
        ua: "Всі"
    },
    "_all": {
        ru: "все",
        en: "All",
        ua: "всі"
    },
    "logout": {
        ru: "Выход",
        en: "Logout",
        ua: "Вихід"
    },
    "choose a file...": {
        ru: "Выберите файл...",
        en: "Choose a file...",
        ua: "Оберіть файл"
    },
    'choose-delivery-service': {
        ru: "Выберите службу доставки",
        en: "Select a delivery service",
        ua: "Виберіть службу доставки"
    },
    "upload-file": {
        ru: "Загрузить файл",
        en: "Upload a file",
        ua: "Підвантажити файл"
    },
    "new-file": {
        ru: "Новый файл",
        en: "New File",
        ua: "Новий файл"
    },
    "export": {
        ru: "Экспортировать",
        en: "Export",
        ua: "Експортувати"
    },
    "import": {
        ru: "Импортировать",
        en: "Import",
        ua: "Імпортувати"
    },
    "load-from-excel": {
        ru: "Подгрузить с Excel",
        en: "Upload from Excel",
        ua: "Підвантажити з Excel"
    },
    "load-from-excel-button": {
        ru: "Добавить товары с Excel",
        en: "Add items from Excel",
        ua: "Додати товари з Excel"
    },
    "load-clients-from-excel-button": {
        ru: "Добавить клиентов с Excel",
        en: "Add clients from Excel",
        ua: "Додати клієнтів з Excel"
    },
    "load-excel-desk": {
        ru: (
            <div>
            <span>Чтобы добавить товары через Excel, скачайте этот шаблон (<a onClick={() => createMobileAppLink('/crm-products.xlsx')} href="/crm-products.xlsx" target="_blank">Скачать</a>), <b className="has-text-danger">НЕ УДАЛЯЙТЕ И НЕ РЕДАКТРИУЙТЕ ПЕРВУЮ СТРОЧКУ</b>.
            Добавьте всю информацию о товарах в соответствующие колонки. И загрузите файл ниже.</span>
            <p>Если у вас в файле указан <b>Склад</b>, то информация о складе будет браться из ФАЙЛА</p>
            </div>
        ),
        en: (
          <div>
             <span>To add products via Excel, download this template (<a onClick={() => createMobileAppLink('/crm-products.xlsx')}  href="/crm-products.xlsx" target="_blank">Download</a>), <b className="has-text-danger"> DO NOT DELETE OR EDIT THE FIRST LINE</b>.
             Add all product information to the appropriate columns. And upload the file below.</span>
             <p>If <b>Warehouse</b> is specified in your file, then information about the warehouse will be taken from the FILE</p>
             </div>
        ),
        ua: (
            <div>
             <span>Щоб додати товари через Excel, завантажте цей шаблон (<a onClick={() => createMobileAppLink('/crm-products.xlsx')}  href="/crm-products.xlsx" target="_blank">Завантажити</a>), <b className="has-text-danger"> НЕ ВИДАЛЯЙТЕ ТА НЕ РЕДАГУЙТЕ ПЕРШИЙ РЯДОК</b>.
             Додайте всю інформацію про товари у відповідні стовпчики. І завантажте файл нижче.</span>
             <p>Якщо у вас у файлі вказано <b>Склад</b>, то інформація про склад буде братися з ФАЙЛУ</p>
             </div>
        ),
    },
    "load-excel-shipments-desk": {
        ru: (
            <div>
            <span>Чтобы добавить товары через Excel, скачайте этот шаблон (<a onClick={() => createMobileAppLink('/crm-shipment-products.xlsx')}  href="/crm-shipment-products.xlsx" target="_blank">Скачать</a>), <b className="has-text-danger">НЕ УДАЛЯЙТЕ И НЕ РЕДАКТРИУЙТЕ ПЕРВУЮ СТРОЧКУ</b>.
            Добавьте всю информацию о товарах в соответствующие колонки. И загрузите файл ниже.</span>
            </div>
        ),
        en: (
            <div>
            <span>To add products via Excel, download this template (<a onClick={() => createMobileAppLink('/crm-shipment-products.xlsx')}  href="/crm-shipment-products.xlsx" target="_blank">Download</a>), <b className="has-text-danger"> DO NOT DELETE OR EDIT THE FIRST LINE</b>.
            Add all product information to the appropriate columns. And upload the file below.</span>
            </div>
        ),
        ua: (
            <div>
            <span>Щоб додати товари через Excel, завантажте цей шаблон (<a onClick={() => createMobileAppLink('/crm-shipment-products.xlsx')}  href="/crm-shipment-products.xlsx" target="_blank">Завантажити</a>), <b className="has-text-danger"> НЕ ВИДАЛЯЙТЕ ТА НЕ РЕДАГУЙТЕ ПЕРШИЙ РЯДОК</b>.
            Додайте всю інформацію про товари у відповідні стовпчики. І завантажте файл нижче.</span>
            </div>
        ),
    },
    "load-from-xml": {
        ru: "Подгрузить с XML",
        en: "Upload from XML",
        ua: "Підвантажити з XML"
    },
    "load-from-xml-button": {
        ru: "Добавить товары с XML",
        en: "Add items from XML",
        ua: "Додати товари з XML"
    },
    "load-from-xml-text": {
        ru: (<div><span>Для добавления товаров с XML введите URL или загрузите файл ниже.</span><p>Инструкция и требования к структуре файла доступны по ссылке - <a onClick={() => createMobileAppLink('https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/"')}  href="https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/" target="_blank" rel="noopener noreferrer">Открыть</a>.</p></div>),
        en: (<div><span>To add products from XML, enter a URL or upload a file below.</span><p>The instructions and the requirements for the file structure are available at the link - <a onClick={() => createMobileAppLink('https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/"')} href="https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/" target="_blank" rel="noopener noreferrer">щзут</a>.</p></div>),
        ua: (<div><span>Для додавання товарів з XML введіть URL або завантажте файл нижче.</span><p>Інструкція та вимоги до структури файлу доступны за посиланням <a onClick={() => createMobileAppLink('https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/"')} href="https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/" target="_blank" rel="noopener noreferrer">Відкрити</a>.</p></div>)
    },
    "or-option": {
        ru: "или",
        en: "or",
        ua: "або"
    },
    "enter-url": {
        ru: "Введите URL",
        en: "Enter a URL",
        ua: "Введіть URL"
    },
    "load-clients-excel-desk": {
        ru: (
            <span>Чтобы добавить клиентов через Excel, скачайте этот шаблон (<a onClick={() => createMobileAppLink('/crm-clients.xlsx')} href="/crm-clients.xlsx" target="_blank">Скачать</a>), НЕ УДАЛЯЙТЕ ПЕРВУЮ СТРОЧКУ.
            Добавьте всю информацию о клиентах в соответствующие колонки. И загрузите файл ниже.</span>
        ),
        en: (
           <span> To add customers via Excel, download this template (<a onClick={() => createMobileAppLink('/crm-clients.xlsx')} href="/crm-clients.xlsx"> Download </a>), DO NOT DELETE THE FIRST LINE.
             Add all customers information in the appropriate columns. And upload the file below. </span>
        ),
        ua: (
            <span> Щоб додати клієнтів через Excel, завантажте цей шаблон (<a onClick={() => createMobileAppLink('/crm-clients.xlsx')} href="/crm-clients.xlsx"> Завантажити </a>), не видаляйте перший рядок.
             Додайте всю інформацію про клієнтів у відповідні колонки. І завантажте файл нижче. </span>
        ),
    },
    "add-product-btn": {
        ru: "Добавить товар или услугу",
        en: "Add item or service",
        ua: "Додати товар або послугу"
    },
     "add-new-product-btn": {
        ru: "Добавить товар ",
        en: "Add New Item",
        ua: "Додати товар"
    },
    "edit-product-btn": {
        ru: "Изменить товар",
        en: "Edit Item",
        ua: "Редагувати товар"
    },
    "select-product-list": {
        ru: "Выберите товар из списка",
        en: "Select a product from the list",
        ua: "Виберіть товар зі списку"
    },
    "if-not-product": {
        ru: "Если товара нет в списке, Вы можете его создать начав вводить Название товара в поле для выбора товара.",
        en: "If the product is not in the list, you can create it by starting to enter the Product name in the field for selecting the product.",
        ua: "Якщо товару немає в списку, Ви можете його створити, почавши вводити Назва товару в полі для вибору товару."
    },
    "sales-amount-placeholder": {
        ru: "Добавьте проданные товары\\услуги",
        en: "Add Sold Items\\Services",
        ua: "Додайте продані товари\\послуги"
    },
    "sales-amount": {
        ru: "Сумма продажи",
        en: "Amount of Sale",
        ua: "Сума продажу"
    },
    "sales-amounts": {
        ru: "Сумма продано",
        en: "Sum of Sales",
        ua: "Загалом продано"
    },
    "t-sales-main": {
        ru: "Выручка",
        en: "Revenue",
        ua: "Виручка"
    },
    "sales-amounts-short": {
        ru: "Продаж",
        en: "Sales",
        ua: "Продажів"
    },
    "sales-amounts-revenue": {
        ru: "Выручка",
        en: "Revenue",
        ua: "Виручка"
    },
    "sales-amounts-revenue-sale": {
        ru: "Выручка с продаж",
        en: "Revenue from sales",
        ua: "Виручка з продажів"
    },
    "gross-profit": {
        ru: "Валовая прибыль",
        en: "Gross profit",
        ua: "Валовий прибуток"
    },
    "sales-total": {
        ru: "Общая сумма",
        en: "Total",
        ua: "Загалом"
    },
    "transfer-fund-commissin": {
        ru: "Комиссия за перевод",
        en: "The transfer fee",
        ua: "Комісія за переказ"
    },
    "transfer-fund-commissin-from": {
        ru: "Комиссия за снятия со счета",
        en: "The transfer fee",
        ua: "Комісія зі зняття з рахунку"
    },
    "transfer-fund-commissin-to": {
        ru: "Комиссия за зачисление на счет",
        en: "The transfer fee",
        ua: "Комісія зарахування"
    },
    "transfer-fund-finish-amount": {
        ru: "На счет поступит",
        en: "Will be credited to the account",
        ua: "Надійде на рахунок"
    },
    "transfer-fund-amount-from": {
        ru: "Со счета спишет",
        en: "Withdraw from the account",
        ua: "З рахунку візьме"
    },
    "purchase": {
        ru: "Продажа",
        en: "Sale",
        ua: "Продаж"
    },
    "sales-comment-placeholder": {
        ru: "Если необходимо, укажите комментарий к продаже",
        en: "If necessary, provide a comment on the sale.",
        ua: "За необхідності вкажіть коментар до продажу"
    },
    "production-comment-placeholder": {
        ru: "Если необходимо, укажите комментарий к этому шаблону производства",
        en: "If necessary, provide a comment on this production template",
        ua: "За необхідності вкажіть коментар до цього шаблону виробництва"
    },
    "client-comment-placeholder": {
        ru: "Если необходимо, укажите комментарий к клиенту",
        en: "If necessary, provide a comment on the client.",
        ua: "За необхідності вкажіть коментар до клієнту"
    },
    "add-sale-btn": {
        ru: "Добавить продажу",
        en: "Add Sale",
        ua: "Додати продаж"
    },
    "finish-sale-btn": {
        ru: "Завершить продажу",
        en: "Finish Sale",
        ua: "Завершити продаж"
    },
    "sales-discount": {
        ru: "Скидка",
        en: "Discount",
        ua: "Знижка"
    },
    "product-discount": {
        ru: "Скидка на товары",
        en: "Discount on products",
        ua: "Знижка на товари"
    },
    "discount-amount-placeholder": {
        ru: "Укажите скидку в процентах",
        en: "Enter a percentage discount",
        ua: "Зазначте відсоток знижки"
    },
    "total": {
        ru: "Итого",
        en: "Total",
        ua: "Усього"
    },
    "expenses-planning": {
        ru: "Расходы запланир.",
        en: "Expenses planned",
        ua: "Заплановані витрати"
    },
    "expenses-flow": {
        ru: "Расходы текущие",
        en: "Flow Costs",
        ua: "Поточні витрати"
    },
    "expenses-without-shipment": {
        ru: "Остальные",
        en: "The others",
        ua: "Інші",
    },
    "expenses-shipment": {
        ru: "На поставки",
        en: "On shipment",
        ua: "На поставки",
    },
    "amount-of-sale": {
        ru: "Сумма продажи",
        en: "The sales amount",
        ua: "Сума продажу"
    },
    "account": {
        ru: "Счет",
        en: "Account",
        ua: "Рахунок"
    },
    "from-account": {
        ru: "Со счета",
        en: "From Account",
        ua: "З рахунку"
    },
    "to-account": {
        ru: "На счет",
        en: "To Account",
        ua: "На рахунок"
    },
    "_to-account": {
        ru: "на счет",
        en: "to Account",
        ua: "на рахунок"
    },
    "amount-transfer": {
        ru: "Сумма перевода",
        en: "Transfer amount",
        ua: "Сума переказу"
    },
    "transfer-success": {
        ru: "Перевод прошел успешно",
        en: "The transfer was successful",
        ua: "Переказ здійснено успішно"
    },
    "change-for-client": {
        ru: "Сдача покупателю",
        en: "change for buyer",
        ua: "Решта покупцеві"
    },
    "amount-from-buyer": {
        ru: "Сумма от покупателя",
        en: "Amount from buyer",
        ua: "Сума від покупця"
    },
    "sale-date": {
        ru: "Дата",
        en: "Date",
        ua: "Дата"
    },
    "sale-date-from": {
        ru: "Дата c",
        en: "Date from",
        ua: "Дата з"
    },
    "sale-date-to": {
        ru: "по",
        en: "to",
        ua: "по"
    },
    "of-sales": {
        ru: "продаж",
        en: "of sales",
        ua: "продажів"
    },
    "not-have-sales": {
        ru: "Нет продаж",
        en: "No Sales",
        ua: "Немає продажів"
    },
    "not-have-stock": {
        ru: "Товара нет в наличии",
        en: "The product is out of stock",
        ua: "Товару немає в наявності"
    },
    "not-have-operation": {
        ru: "Нет операций",
        en: "No operations",
        ua: "Немає операцій"
    },
    "add-first-sale-btn": {
        ru: "Добавить первую продажу!",
        en: "Add First Sale!",
        ua: "Додати перший продаж"
    },
    "sale-quantity": {
        ru: "Продано единиц",
        en: "Sold Units",
        ua: "Продано одиниць"
    },
    "without-barcode": {
        ru: "без штрих-кода",
        en: "without barcode",
        ua: "без штрих-коду"
    },
    "saled": {
        ru: "Продано",
        en: "Sold",
        ua: "Продано"
    },
    "status-saled": {
        ru: "Продано",
        en: "Sold",
        ua: "Продано"
    },
    "status-delivery": {
        ru: "На отправку",
        en: "For shipment",
        ua: "На відправку"
    },
    "status-delivering": {
        ru: "Передано в службу доставки",
        en: "Transferred to delivery service",
        ua: "Передано в службу доставки"
    },
    "status-on-delivering": {
        ru: "Доставляется",
        en: "On delivery",
        ua: "Доставляється"
    },
    "status-arrived": {
        ru: "Ожидает в отделении",
        en: "Waiting in the postoffice",
        ua: "Чекає в відділенні"
    },
    "status-cod": {
        ru: "Забрали",
        en: "Taken away",
        ua: "Забрали"
    },
    "status-refund": {
        ru: "Отказ",
        en: "Rejection",
        ua: "Відмова"
    },
    "status-backrefund": {
        ru: "Возврат",
        en: "Refund",
        ua: "Повернення"
    },
    "status-cancelled": {
        ru: "Отменен",
        en: "Cancelled",
        ua: "Відмінено"
    },
    'status-refund-label': {
        ru: "Возвраты",
        en: "Refunds",
        ua: "Повернення"
    },
    "status-completed": {
        ru: "Выполнен",
        en: "Completed",
        ua: "Виконано"
    },
    "completed": {
        ru: "Выполнен",
        en: "Completed",
        ua: "Виконано"
    },
    "refunded": {
        ru: "Возврат",
        en: "Refund",
        ua: "Повернення"
    },

    "cancelled": {
        ru: "Отменен",
        en: "Cancelled",
        ua: "Відмінено"
    },
    "processing": {
        ru: "В обработке",
        en: "In processing",
        ua: "В обробці"
    },
    "in-processing": {
        ru: "Обработка...",
        en: "Processing...",
        ua: "Обробка..."
    },
    "on-hold": {
        ru: "На удержании",
        en: "On Hold",
        ua: "На утриманні"
    },
    "pending": {
        ru: "В ожидании",
        en: "Pending",
        ua: "В очікуванні"
    },
    "delivered": {
        ru: "Доставлено",
        en: "Delivered",
        ua: "Доставлено"
    },
    "canceled": {
        ru: "Отменено",
        en: "Canceled",
        ua: "Скасовано"
    },
    "need-make-cod": {
        ru: "Забрать наложки",
        en: "Pick up С.O.D",
        ua: "Забрати післяплату"
    },
    "need-make-refund": {
        ru: "Отказ",
        en: "Rejection",
        ua: "Відмова"
    },
    "parcel-send-success": {
        ru: "Посылка отправлена",
        en: "Package sent",
        ua: "Посилка відправлена"
    },
    "status-reserve_sale": {
        ru: "Зарезервировано",
        en: "Reserved",
        ua: "Зарезервовано"
    },
    "saled-on": {
        ru: "Продано на",
        en: "Sold at",
        ua: "Продано на"
    },
    "earned": {
        ru: "Заработано",
        en: "Earned",
        ua: "Зароблено"
    },
    "sum-discount": {
        ru: "Сумма скидок",
        en: "Discount Amount",
        ua: "Сума знижок"
    },
    "refund-sum": {
        ru: "Сумма возврата",
        en: "Refund Amount",
        ua: "Сума повернення"
    },
    "refund-sum-desk": {
        ru: "Цена продажи товара",
        en: "Product selling price",
        ua: "Ціна продажу товару"
    },
    "sales-profit": {
        ru: "Прибыль",
        en: "Profit",
        ua: "Прибуток"
    },
    "from-sales-profit": {
        ru: "Прибыль c продаж",
        en: "Profit from sales",
        ua: "Прибуток з продажів"
    },
    "from-sales-profit-1": {
        ru: (<span>Прибыль c <br />продаж</span>),
        en: (<span>Profit from <br />sales</span>),
        ua: (<span>Прибуток з <br />продажів</span>)
    },
    "t-profit-main": {
        ru: "Прибыль",
        en: "Profit from sales",
        ua: "Прибуток"
    },
    "normal-consumption": {
        ru:"Обычный расход",
        en:"Normal consumption",
        ua:"Звичайна витрата"
    },
    "profit-on-sales": {
        ru:"Вычитать из Прибыли с продаж",
        en:"Deduct from Profit on sales",
        ua:"Віднімати з Прибутку з продажів"
    },
    "profit-on-sales-info": {
        ru:"Если, вы платите за рекламу, или платите комиссию за продажу, которая не учтена в каналах продаж, то можете записывать эти траты в категорию с такой галочкой, тогда эти суммы будут вычитаться с общей Прибыли с продаж",
        en:"If you pay for advertising, or pay a sales commission that is not included in sales channels, you can record these expenses in the category with this checkbox, then these amounts will be deducted from the total Sales Profit",
        ua:"Якщо ви платите за рекламу, або платите комісію за продаж, яка не врахована в каналах продажів, то можете записувати ці витрати в категорію з такою галочкою, тоді ці суми будуть відніматися із загального Прибутку з продажу",
    },
    "not-to-be-income": {
        ru:"Не учитывать в Доходах ",
        en:"Not to be recognized in Income ",
        ua:"Не враховувати в Доходах "
    },
    "not-to-be-income-info": {
        ru: "Eсли вы хотите выводить прибыль и не хотите, чтобы это влияло на ваши показатели дохода. Либо операция уже учитывается в себестоимости (доставка, производство, комиссии и т.д.)",
        en: "If you want to withdraw profits and don't want it to affect your income figures. Or the operation is already taken into account in the cost price (delivery, production, fee, etc.)",
        ua: "Якщо ви хочете виводити прибуток і не хочете, щоб це впливало на ваші показники доходу. Або операція вже враховується у собівартості (доставка, виробництво, комісії і т.д.)",
    },
    "sales-clean-profit": {
        ru: "Чистый доход",
        en: "Clean Profit",
        ua: "Прибуток"
    },
    "dashboard-sales-clean-profit": {
        ru: "Чистый доход минус расходы (без поставок)",
        en: "Revenue minus expenses (without shipments)",
        ua: "Чистий прибуток мінус витрати (без поставок)"
    },
    "sales-purchase-profit": {
        ru: "Доход минус все расходы",
        en: "Income minus all expenses",
        ua: "Дохід мінус всі витрати"
    },
    "total-funds-received": {
        ru: "Всего поступило средств",
        en: "Total funds received",
        ua: "Усього надійшло коштів"
    },
    "total-funds-received-minus-expenditures": {
        ru: "Всего поступило средств минус все расходы",
        en: "Total funds received minus all expenditures",
        ua: "Усього надійшло коштів мінус усі витрати"
    },

    "purchase-of-items": {
        ru: "Закупка товара",
        en: "Purchase of Items",
        ua: "Закупівля товару"
    },
    "add-shipment-btn": {
        ru: "Оформить поставку",
        en: "To Arrange Shipment",
        ua: "Оформити поставку"
    },
    "pay-shipment-btn": {
        ru: "Оплатить поставку",
        en: "Pay For Shipment",
        ua: "Оплатити поставку"
    },
    "pay-shipment-mobile-btn": {
        ru: "Оплатить",
        en: "Pay",
        ua: "Оплатити"
    },
    "pay-subscrube": {
        ru: "Оплатить",
        en: "Pay",
        ua: "Оплатити"
    },
    "disabled": {
        ru: "Отключено",
        en: "Disabled",
        ua: "Відключено"
    },
    "net-amounts": {
        ru: "Сумма себест.",
        en: "Sum of the Cost",
        ua: "Cума собіварт."
    },
    "finish-shipment-btn": {
        ru: "Принять поставку",
        en: "Complete Shipment",
        ua: "Прийняти поставку"
    },
    "add-to-shipment-btn": {
        ru: "Добавить в поставку",
        en: "Add to Shipment",
        ua: "Додати до поставки"
    },
    "add-to-moving": {
        ru: 'Добавить в перемещение',
        en: 'Add to move',
        ua: 'Додати до переміщення'
    },
    "moving-product": {
      ru: 'товаров',
      en: 'products',
      ua: 'товарів'
    },
    "select-moving-products": {
        ru: "В какое перемещение добавить товары?",
        en: "In which movement to add goods?",
        ua: "Яке переміщення додати товари?"
    },
    "add-moving-btn-label": {
        ru:"Создать новое перемещение",
        en:"Create a new move",
        ua: "Створити нове переміщення",
    },
    "price-shipment-btn": {
        ru: "Редактировать поставку",
        en: "Edit Shipment",
        ua: "Редагувати поставку"
    },
    "shipment": {
        ru: "Поставка",
        en: "Shipment",
        ua: "Поставка"
    },
    "select-shipment": {
        ru: "К какой поставке относится этот расход?",
        en: "Which shipment does this expense refer to?",
        ua: "До якої поставки відноситься ця витрата?"
    },
    "select-shipment-products": {
        ru: "В какую поставку добавить товары?",
        en: "Which shipment to add goods to",
        ua: "До якої поставки додати ці товари?"
    },
    "not-have-shipments": {
        ru: "У Вас еще нет поставок",
        en: "No shipments to your store yet",
        ua: "Ви ще не маєте поставок"
    },
    "add-first-shipment-btn": {
        ru: "Оформить первую закупку товара",
        en: "Checkout the First Purchase of Goods",
        ua: "Оформити перше придбання товару"
    },
    "shipment-from": {
        ru: "Поставка от",
        en: "Shipment From",
        ua: "Поставка від"
    },
    "shipments-delivered_date": {
        ru: "Дата доставки на склад",
        en: "Date of delivery to the warehouse",
        ua: "Дата доставки на склад"
    },
    "shipments-warehouse": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "shipments-price-item": {
        ru: "Цена закупки",
        en: "Purchase Price",
        ua: "Ціна закупки"
    },
    "shipments-quantity": {
        ru: "Кол. товара",
        en: "Qnt. Goods",
        ua: "Кіл. товару"
    },
    "shipments-name": {
        ru: "Название поставки",
        en: "Shipments Name",
        ua: "Назва поставки"
    },
    "shipments-amount": {
        ru: "Сумма поставки",
        en: "Delivery Amount",
        ua: "Сума поставки"
    },
    "shipments-delivery": {
        ru: "Перевозчик",
        en: "Carrier",
        ua: "Перевізник"
    },
    "shipments-comment": {
        ru: "Комментарий",
        en: "Comment",
        ua: "Коментар"
    },
    "margin": {
        ru: "Наценка",
        en: "Margin",
        ua: "Націнка"
    },
    "average-margin": {
        ru: "Средняя маржа",
        en: "Average margin",
        ua: "Середня маржа"
    },
    "average-check": {
        ru: "Средний чек",
        en: "Average check",
        ua: "Середній чек"
    },
    "calc-netprice-rate": {
        ru: "Расчет себестоимости товара в валюте",
        en: "Calculation of the cost of goods in foreign currency",
        ua: "Розрахунок собівартості товару у валюті"
    },
    "global-rate": {
        ru: "По общему курсу",
        en: "At the general rate",
        ua: "За загальним курсом"
    },
    "shipment-rate": {
        ru: "По курсу в поставке",
        en: "Shipment rate",
        ua: "За курсом у поставці"
    },
    "quantity": {
        ru: "Количество",
        en: "Quantity",
        ua: "Кількість"
    },
    "quantity-ord": {
        ru: "Количество заказано",
        en: "Quantity ordered",
        ua: "Замовлено кількість"
    },
    "quantity-res": {
        ru: "Количество получено",
        en: "Quantity Received",
        ua: "Отримано кількість"
    },
    "quantity-ord-mob": {
        ru: "Заказано",
        en: "Quantity",
        ua: "Замовлено"
    },
    "quantity-res-mob": {
        ru: "Получено",
        en: "Received",
        ua: "Отримано"
    },
    "all-received": {
        ru: "Получили все ожидаемые товары",
        en: "Received all expected items",
        ua: "Отримали всі очікувані товари"
    },
    "delete-from-list": {
        ru: "Удалить из списка",
        en: "Delete from list",
        ua: "Видалити зі списку"
    },
    "next-btn": {
        ru: "Далее",
        en: "Next",
        ua: "Далі"
    },
    "del": {
        ru: "Удалить",
        en: "Delete",
        ua: "Видалити"
    },
    "back-btn": {
        ru: "Назад",
        en: "Back",
        ua: "Назад"
    },
    "select-supplier": {
        ru: "Выберите поставщика",
        en: "Select supplier",
        ua: "Оберіть постачальника"
    },
    "suppliers": {
        ru: "Поставщики",
        en: "Suppliers",
        ua: "Постачальники"
    },
    "shipments-supplier": {
        ru: "Поставщик",
        en: "Supplier",
        ua: "Постачальник"
    },
    "shipments-supplier-name": {
        ru: "Имя (название) поставщика",
        en: "Supplier's name",
        ua: "Ім'я (назва) постачальника"
    },
    "shipments-supplier-name-placeholder": {
        ru: "Введите имя (название) поставщика",
        en: "Enter a supplier's name",
        ua: "Введіть ім'я (назву) постачальника"
    },
    "supplier-phone": {
        ru: "Телефон поставщика",
        en: "Supplier's phone",
        ua: "Телефон постачальника"
    },
    "supplier-phone-placeholder": {
        ru: "Введите телефон поставщика",
        en: "Enter a supplier's phone",
        ua: "Введіть телефон постачальника"
    },
    "supplier-address": {
        ru: "Адрес поставщика",
        en: "Supplier's address",
        ua: "Адреса постачальника"
    },
    "supplier-address-placeholder": {
        ru: "Введите адрес поставщика",
        en: "Enter a supplier's address",
        ua: "Введіть адресу постачальника"
    },
    "supplier-comment-placeholder": {
        ru: "Введите коментарий (необязательно)",
        en: "Enter a comment (optional)",
        ua: "Введіть коментар (за бажанням)"
    },
    "product-barcode": {
        ru: "Штрих-код",
        en: "Barcode",
        ua: "Штрих-код"
    },
    "barcode-sale-label": {
        ru: "Штрих-код продажи",
        en: "Sales barcode",
        ua: "Штрих-код продажу"
    },
    "barcode-product-label": {
        ru: "Штрих-код товара",
        en: "Product barcode",
        ua: "Штрих-код товару"
    },
    "product-brand": {
        ru: "Бренд",
        en: "Brand",
        ua: "Бренд"
    },
    "product-name": {
        ru: "Название товара",
        en: "Product's Name",
        ua: "Назва товару"
    },
    "new-product-name": {
        ru: "Название нового товара",
        en: "New Product's Name",
        ua: "Назва нового товару"
    },
    "product-vendor-code": {
        ru: "Артикул",
        en: "Vendor Code",
        ua: "Артикул"
    },
    "product-quantity": {
        ru: "Ед. на складе",
        en: "Units in Stock",
        ua: "Од. на складі"
    },
    "product-info-warehouse": {
        ru: "На складах",
        en: "InStock",
        ua: "На складах"
    },
    "product-info-warehouse-one": {
        ru: "На складе",
        en: "InStock",
        ua: "На складі"
    },
    "product-info-netcost": {
        ru: "Себест.",
        en: "Cost",
        ua: "Собів."
    },
    "make-delivery-btn": {
        ru: "Оформить поставку",
        en: "Make Delivery",
        ua: "Оформити поставку"
    },
    "product-info-sales": {
        ru: "Продалось",
        en: "Sold Out",
        ua: "Продалося"
    },
    "product-info-profit": {
        ru: "Доход",
        en: "Profit",
        ua: "Прибуток"
    },
    "product-info-price": {
        ru: "Цена",
        en: "Price",
        ua: "Ціна"
    },
    "cost-for": {
        ru: "Цена за ед",
        en: "Price per one",
        ua: "Ціна за од"
    },
    "netprice-price": {
        ru: "Стоимость",
        en: "Price",
        ua: "Вартість"
    },
    "product-info-report": {
        ru: "Отчет",
        en: "Report",
        ua: "Звіт"
    },
    "product-info-edit": {
        ru: "Редактировать",
        en: "edit",
        ua: "Редагувати"
    },
    "shipment-date": {
        ru: "Дата поставки на склад",
        en: "Delivery Date",
        ua: "Дата поставки"
    },
    "delivery-cost": {
        ru: "Цена доставки",
        en: "Shipping cost",
        ua: "Вартість доставки"
    },
    "delivery-service": {
        ru: "Служба доставки",
        en: "Delivery Service",
        ua: "Служба доставки"
    },
    "delivery-services": {
        ru: "Службы доставки",
        en: "Delivery Services",
        ua: "Служби доставки"
    },
    "nova_poshta": {
        ru: "Новая почта",
        en: "Nova Poshta",
        ua: "Нова Пошта"
    },
    "ukr_poshta": {
        ru: "Укрпочта",
        en: "Ukrposhta",
        ua: "Укрпошта"
    },
    "delivery-1": {
        ru: "Новая почта",
        en: "Nova Poshta",
        ua: "Нова Пошта"
    },
    "delivery-3": {
        ru: "Укрпочта",
        en: "Ukrposhta",
        ua: "Укрпошта"
    },
    "delivery-company": {
        ru: "Деливери",
        en: "Delivery",
        ua: "Делівері"
    },
    "international": {
        ru: "Международная",
        en: "International",
        ua: "Міжнародна"
    },
    "delivery-car": {
        ru: "Своим автомобилем",
        en: "Own Car",
        ua: "Власним авто"
    },
    "delivery-supplier": {
        ru: "Доставляет поставщик",
        en: "Delivers by supplier",
        ua: "Доставляє постачальник"
    },
    "debit-the-account": {
        ru: "Списать со счета",
        en: "To Debit From an Account",
        ua: "Списати з рахунку"
    },
    "production-debit-the-account": {
        ru: "Расходы на производство списать со счета",
        en: "Debit Production Costs",
        ua: "Витрати виробництва списати з рахунку"
    },
    "debit-the-account-for-delivery": {
        ru: "Списать за доставку со счета",
        en: "Charge for delivery from the account",
        ua: "Списати за доставку з рахунку"
    },
    "debit-the-account-for-shipment": {
        ru: "Списать за товар со счета",
        en: "Charge for products from the account",
        ua: "Списати за товар з рахунку"
    },
    "not-paid": {
        ru: "Не оплачено",
        en: "Not paid",
        ua: "Не оплачено"
    },
    "clients-debt": {
        ru: "Долг",
        en: "Debt",
        ua: "Борг"
    },
    "debt_from": {
        ru: "Долг от",
        en: "Debt from",
        ua: "Борг від"
    },
    "debt_to": {
        ru: "Долг до",
        en: "Debt to",
        ua: "Борг до"
    },
    "shipment-not-paid-status": {
        ru: "Поставка не оплачена",
        en: "Shipment Not Paid",
        ua: "Поставка не оплачена"
    },
    "sales-created": {
        ru: "Продажа успешно добавлена",
        en: "Sale successfully added",
        ua: "Продаж успішно додано"
    },
    "sales-created-edit": {
        ru: "Продажа успешно отредактирована",
        en: "Sale successfully edited",
        ua: "Продаж успішно відредаговано"
    },
    "shipments-comment-placeholder": {
        ru: "Введите комментарий относительно этой доставки (необязательно)",
        en: "Enter a comment regarding this delivery (optional)",
        ua: "Введіть комантар щодо цієї доставки (за бажанням)"
    },

    "shipments-created": {
        ru: "Поставка успешно оформлена",
        en: "Shipment Completed Successfully",
        ua: "Поставка успішно оформлена"
    },
    "not-necessary": {
        ru: "(необязательно)",
        en: "(optional)",
        ua: "(за бажанням)"
    },
    "shipment-select-account": {
        ru: "Укажите с какого счета списывать средства за эту закупку",
        en: "Indicate from which account to debit funds for this purchase",
        ua: "Вкажіть, з якого рахунку списувати кошти за цю закупку"
    },
    "product-description-placeholder": {
        ru: "Подробное описание товара",
        en: "Detailed Product Description",
        ua: "Детальний опис товару"
    },
    "product-description": {
        ru: "Описание товара",
        en: "Product Description",
        ua: "Опис товару"
    },
    "upload-images": {
        ru: "Загрузить изображения",
        en: "Upload Images",
        ua: "Завантажити зображення"
    },
    "images": {
        ru: "Изображения",
        en: "Images",
        ua: "Зображення"
    },
    "choose-a-file": {
        ru: "Выберите файл",
        en: "Сhoose a file...",
        ua: "Оберіть файл..."
    },
    "choose-a-images": {
        ru: "Выберите изображения",
        en: "Сhoose a images...",
        ua: "Оберіть зображення..."
    },
    "your-accounts": {
        ru: "Ваши счета",
        en: "Your accounts",
        ua: "Ваші рахунки"
    },
    "your-money": {
        ru: "Ваши деньги",
        en: "Your money",
        ua: "Ваші гроші"
    },
    "today": {
        ru: "Сегодня",
        en: "Today",
        ua: "Сьогодні"
    },
    "yesterday": {
        ru: "Вчера",
        en: "Yesterday",
        ua: "Вчора"
    },
    "week": {
        ru: "Неделя",
        en: "Week",
        ua: "Тиждень"
    },
    "next-week": {
        ru: "Следующая неделя",
        en: "Next week",
        ua: "Наступний тиждень"
    },
    "month": {
        ru: "Месяц",
        en: "Month",
        ua: "Місяць"
    },
    "next-month": {
        ru: "Следующий месяц",
        en: "Next month",
        ua: "Наступний місяць"
    },
    "this-year": {
        ru: "Этот год",
        en: "This Year",
        ua: "Цей рік"
    },
    "last-year": {
        ru: "Прошлый год",
        en: "Last Year",
        ua: "Минулий рік"
    },
    "net-income-for": {
        ru: "Операционная прибыль за",
        en: "Operating profit for",
        ua: "Операційний прибуток за"
    },
    "net-profit": {
        ru: "Чистая прибыль",
        en: "Net profit",
        ua: "Чистий прибуток"
    },
    "30days": {
        ru: "Последние 30 дней",
        en: "Last 30 days",
        ua: "Останні 30 днів"
    },
    "this-month": {
        ru: "Этот месяц",
        en: "This month",
        ua: "Цей місяць"
    },
    "last-month": {
        ru: "Прошлый месяц",
        en: "Last month",
        ua: "Минулий місяць"
    },
    "3-month": {
        ru: "3 месяца",
        en: "3 month",
        ua: "3 місяці"
    },
    "30days-short": {
        ru: "30 дней",
        en: "30 days",
        ua: "30 днів"
    },
    "7days": {
        ru: "Последние 7 дней",
        en: "Last 7 days",
        ua: "Останні 7 днів"
    },
    "7days-short": {
        ru: "7 дней",
        en: "7 days",
        ua: "7 днів"
    },
    "month-current": {
        ru: "Текущий месяц",
        en: "Current Month",
        ua: "Поточний місяць"
    },
    "shipment-currency": {
        ru: "Валюта оплаты за поставку",
        en: "Shipment Currency",
        ua: "Валюта оплати за поставку"
    },
    "month-last": {
        ru: "Прошлый месяц",
        en: "Last Month",
        ua: "Минулий місяць"
    },
    "error-product-exist": {
        ru: "Товар со схожим названием или атрибутами найден. ",
        en: "Product with the same name or attributes found. ",
        ua: "Знайдено товари із таким же ім'ям чи атрибутами."
    },
    "product_sample": {
        ru: "Образец товара (sample)",
        en: "Product Sample",
        ua: "Зразок товару"
    },
    "production": {
        ru: "Производство",
        en: "Manufacturing",
        ua: "Виробництво"
    },
    "production-hugeprofit": {
        ru: "Производство",
        en: "Manufacturing",
        ua: "Виробництво"
    },
    "production-modeler": {
        ru: "Производство",
        en: "Manufacturing",
        ua: "Виробництво"
    },
    "production-navkolo": {
        ru: "Мини-Производство",
        en: "Mini-Manufacturing",
        ua: "Міні-Виробництво"
    },
    "delivery-delivery": {
        ru: "Доставка",
        en: "Delivery",
        ua: "Доставка"
    },
    "handle": {
        ru: "Обработать",
        en: "Handle",
        ua: "Обробити"
    },
    "wait-delivery": {
        ru: "На отправку",
        en: "Awaiting dispatch",
        ua: "На відправку"
    },
    "delivery": {
        ru: "Доставка",
        en: "Delivery",
        ua: "Доставка"
    },
    "make_photo": {
        ru: "Услуги фотографа",
        en: "Photo Service",
        ua: "Послуги фотоґрафа"
    },
    "salary": {
        ru: "Зарплата",
        en: "Salary",
        ua: "Зарплатня"
    },
    "bank_comission": {
        ru: "Комиссия",
        en: "Commission",
        ua: "Комісія"
    },
    "inspection": {
        ru: "Инспекция",
        en: "Inspection",
        ua: "Інспекція"
    },
    "ads": {
        ru: "Реклама",
        en: "Advertising",
        ua: "Реклама"
    },
    "rent": {
        ru: "Аренда",
        en: "Rent",
        ua: "Оренда"
    },
    "general": {
        ru: "Общее",
        en: "General",
        ua: "Загальні"
    },
    "enter-search-term": {
        ru: "Введите поисковый запрос для отображения подходящих товаров или создайте новые",
        en: "Enter a search term to display matching products or create new oness",
        ua: "Введіть пошуковий запит для відображення відповідних товарів або створіть нові"
    },
    "delete": {
        ru: "Удалить",
        en: "Delete",
        ua: "Видалити"
    },
    "undo": {
        ru: "Отменить",
        en: "Undo",
        ua: "Скасувати "
    },
    "no-undo": {
        ru: "Не отменить",
        en: "Not Undo",
        ua: "Не скасовувати"
    },
    "no-delete": {
        ru: "Не удалять",
        en: "Do not delete",
        ua: "Не видаляти"
    },
    "deleted": {
        ru: "Удалено",
        en: "Deleted",
        ua: "Видалено"
    },
    "product_deleted": {
        ru: "Товар удален",
        en: "Product Deleted",
        ua: "Товар видалено"
    },
    "customs_clearance": {
        ru: "Таможенные услуги",
        en: "Сustoms Сlearance",
        ua: "Митні послуги"
    },
    "confirm-delete": {
        ru: "Подтвердите удаление",
        en: "Confirm Deletion",
        ua: "Підтвердіть видалення"
    },
    "confirm-delete-shipment": {
        ru: "Подтвердите удаление поставки",
        en: "Confirm Deletion Shipment",
        ua: "Підтвердіть видалення поставки"
    },
    "confirm-delete-undo": {
        ru: "Подтвердите отмену",
        en: "Confirm undo",
        ua: "Підтвердіть скасування"
    },
    "are-you-sure-remove-reserv": {
        ru: "При отмене резерва ЧАСТ. ОПЛАТА ОСТАНЕТСЯ НА ВАШЕМ СЧЕТУ. Для возврата част. оплаты удалите резерв.",
        en: "If the reservation is cancelled, THE PARTIAL PAYMENT WILL REMAIN ON YOUR ACCOUNT. For a refund Delete the reserve.",
        ua: "При скасуванні резерву ЧАСТ. ОПЛАТА ЗАЛИШЕТЬСЯ НА ВАШОМУ РАХУНКУ. Для повернення част. оплати Видаліть резерв."
    },
    "are-you-sure-to-delete-this": {
        ru: "Вы уверены что хотите удалить эту продажу? Это действие необратимо.",
        en: "Are you sure you want to delete this sale? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цей продаж? Ця дія - незворотня."
    },
    "are-you-sure-remove-sales": {
        ru: "Вы уверены, что хотите удалить эти продажи? Это действие необратимо.",
        en: "Are you sure you want to remove these sales? This action is irreversible.",
        ua: "Ви впевнені, що хочете видалити ці продажі? Ця дія - незворотня."
    },
    "are-you-sure-to-delete": {
        ru: "Вы уверены что хотите удалить эту запись? Это действие необратимо.",
        en: "Are you sure you want to delete this records? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цей запис? Ця дія - незворотня."
    },
    "are-you-sure-to-this-integration": {
        ru: "Вы уверены что хотите удалить эту интеграцию?",
        en: "Are you sure you want to delete this sale?",
        ua: "Ви впевнені що хочете видалити цю інтеграцію?"
    },
    "are-you-sure-to-delete-this-order": {
        ru: "Вы уверены что хотите удалить этот заказ? Заказ исчезнет из списка, но на Источнике никаких обновлений не будет",
        en: "Are you sure you want to delete this order? The order will disappear from the list, but there will be no updates on the Source",
        ua: "Ви впевнені, що хочете видалити це замовлення? Замовлення зникне зі списку, але на Маркетплейсі жодних оновлень не буде"
    },
    "are-you-sure-to-delete-this-expenses": {
        ru: "Вы уверены что хотите удалить эту операцию? Это действие необратимо. Деньги будут списаны или возвращены Вам на счет.",
        en: "Are you sure you want to delete this operations? This action is irreversible. The money will be debited or returned to your account.",
        ua: "Ви впевнені що хочете видалити цю операцію? Ця дія - незворотня. Гроші будуть списані або повернуті Вам на рахунок."
    },
    "are-you-sure-to-delete-this-account": {
        ru: "Вы уверены что хотите удалить этот счет? Это действие необратимо.",
        en: "Are you sure you want to delete this account? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цей рахунок? Ця дія - незворотня. "
    },
    "are-you-sure-to-delete-this-product": {
        ru: "Вы уверены что хотите удалить этот товар? ",
        en: "Are you sure you want to delete this item? ",
        ua: "Ви впевнені що хочете видалити цей товар?"
    },
    "are-you-sure-to-delete-this-images": {
        ru: "Вы уверены что хотите удалить эту картинку? ",
        en: "Are you sure you want to delete this image? ",
        ua: "Ви впевнені що хочете видалити цю картинку?"
    },
    "are-you-sure-to-delete-this-client": {
        ru: "Вы уверены что хотите удалить этого клиента? Это действие необратимо.",
        en: "Are you sure you want to delete this client? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цього клієнта? Ця дія є незворотня"
    },
    "are-you-sure-to-shipment-this-shipment": {
        ru: "Вы уверены что хотите удалить эту поставку? Это действие необратимо.",
        en: "Are you sure you want to delete this shipment? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цю поставку? Ця дія - незворотня."
    },
    "are-you-sure-to-undo-this-shipment": {
        ru: "Вы уверены, что хотите отменить эту поставку? Все товары с этой поставки отнимутся со склада, на который они были зачислены",
        en: "Are you sure you want to cancel this delivery? All goods from this delivery will be taken from the warehouse to which they were credited",
        ua: "Ви впевнені, що хочете скасувати це постачання? Усі товари з цієї поставки віднумуться зі складу, на який вони були зараховані"
    },
    "are-you-sure-to-delete-this-warehouse": {
        ru: "Вы уверены что хотите удалить этот склад? Это действие необратимо.",
        en: "Are you sure you want to delete this warehouse? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цей склад? Ця дія - незворотня."
    },
     "are-you-sure-to-delete-this-chat": {
        ru: "Вы уверены что хотите удалить эту переписку? ",
        en: "Are you sure you want to delete this conversations?",
        ua: "Ви впевнені що хочете видалити цю переписку?"
    },
    "are-you-sure-to-delete-this-reserv": {
        ru: "Вы уверены что хотите удалить этот резерв? Сумма част. оплаты будет снята с Вашего счета.",
        en: "Are you sure you want to delete this reserve? The partial payment amount will be debited from your account.",
        ua: "Ви впевнені що хочете видалити цей резерв? Сума част. оплати буде знята з вашого рахунку."
    },
    "are-you-sure-to-delete-this-delivery": {
        ru: "Вы уверены что хотите удалить эту продажу? Сумма част. оплаты будет снята с Вашего счета.",
        en: "Are you sure you want to delete this sale? The partial payment amount will be debited from your account.",
        ua: "Ви впевнені що хочете видалити цей продаж? Сума част. оплати буде знята з вашого рахунку."
    },
    "are-you-sure-to-delete-this-field": {
        ru: "Вы уверены, что хотите удалить это поле? Оно будет удалено со всех ваших товаров! Отменить это действие будет невозможно.",
        en: "Are you sure you want to delete this field? It will be removed from all your products! It will be impossible to undo this action.",
        ua: "Ви впевнені, що хочете видалити це поле? Воно буде видалено зі всіх ваших товарів! Скасувати цю дію буде неможливо."
    },
    "are-you-sure-to-delete-this-status": {
        ru: "Вы уверены что хотите удалить этот статус? Это действие необратимо.",
        en: "Are you sure you want to delete this status? This action is irreversible.",
        ua: "Ви впевнені що хочете видалити цей статус? Ця дія - незворотня."
    },
    "move-funds-btn": {
        ru: "Перевод между счетами",
        en: "Transfer Between Accounts",
        ua: "Переказ між рахунками"
    },
    "add-new-account-btn": {
        ru: "Добавить новый счет",
        en: "Add New Account",
        ua: "Додати новий рахунок"
    },
    "cash-flow": {
        ru: "Финансы",
        en: "Cash Flow",
        ua: "Фінанси"
    },
    "transactions": {
        ru: "Финансы",
        en: "Cash Flow",
        ua: "Фінанси"
    },
    "transactions-navbar-text": {
        ru: "Транзакции",
        en: "Transactions",
        ua: "Транзакції"
    },
    "cash": {
        ru: "Наличные",
        en: "Cash",
        ua: "Готівка"
    },
    "cod": {
        ru: "Наложка",
        en: "C.O.D",
        ua: "Наложка"
    },
    "debt": {
        ru: "Не оплачено",
        en: "Not paid",
        ua: "Не оплачено"
    },
    "credit-card": {
        ru: "Платежная карта",
        en: "Payment card",
        ua: "Платіжна картка"
    },
    "payment-account": {
        ru: "Платежный счет (IBAN)",
        en: "Payment account (IBAN)",
        ua: "Платіжний рахунок (IBAN)"
    },
    "main-warehouse": {
        ru: "Основной склад",
        en: "Main Warehouse",
        ua: "Основний склад"
    },
    "not-have-transactions": {
        ru: "Нет транзакций для отображения",
        en: "No transactions to display",
        ua: "Немає транзакцій для відображення"
    },
    "move-funds-comment-placeholder": {
        ru: "Введите комментарий к переводу средств",
        en: "Enter a comment on the transfer of funds",
        ua: "Введіть коментар до переказу коштів"
    },
    "date": {
        ru: "Дата",
        en: "Date",
        ua: "Дата"
    },
    "amount": {
        ru: "Сумма",
        en: "Amount",
        ua: "Сума"
    },
    "transactions-type": {
        ru: "Тип операции",
        en: "Transaction Type",
        ua: "Тип операції"
    },
    "expenses-type": {
        ru: "Тип расхода",
        en: "Expenses Type",
        ua: "Тип витрат"
    },
    "commission": {
        ru: "Комиссия",
        en: "Commission",
        ua: "Комісія"
    },
    "not-have-history": {
        ru: "Нет данных для отображения",
        en: "No data to display",
        ua: "Немає даних для відображення"
    },
    "finish-shipment-btn-desc": {
        ru: {
            plural: "Подтвердите принятие поставки. На склад {warehouse} будет зачислено {count} ед. товаров."
        },
        en: {
            plural: "Confirm acceptance of the shipment. {count} units will be added to the warehouse {warehouse}."
        },
        ua: {
            plural: "Підтвердьте прийняття поставки. До складу {warehouse} буде зараховано {count} од. товарів."
        }
    },
    "history-data-category_id": {
        ru: "Категория",
        en: "Category",
        ua: "Категорія"
    },
    "excel": {
        ru: "Excel",
        en: "Excel",
        ua: "Excel"
    },
    "history-data-source": {
        ru: "Источник",
        en: "Source",
        ua: "Джерело"
    },
    "history-data-asin": {
        ru: "Штрих-код",
        en: "Barcode",
        ua: "Штрих-код"
    },
    "history-data-quantity": {
        ru: "Количество",
        en: "Quantity",
        ua: "Кількість"
    },
    "history-data-from_mid": {
        ru: "Со склада",
        en: "From warehouse",
        ua: "Зі складу"
    },
    "history-data-to_mid": {
        ru: "На склад",
        en: "To warehouse",
        ua: "На склад"
    },
    "history-data-mov_id": {
        ru: "Перемещение №",
        en: "Moving No.",
        ua: "Переміщення №"
    },
    "history-data-sku": {
        ru: "Артикул",
        en: "SKU",
        ua: "Артикул"
    },
    "history-data-profit": {
        ru: "Доход",
        en: "Profit",
        ua: "Прибуток"
    },
    "history-data-name": {
        ru: "Название",
        en: "Name",
        ua: "Назва"
    },
    "history-data-balance": {
        ru: "Баланс счета",
        en: "Account balance",
        ua: "Баланс рахунку"
    },
    "history-data-instock": {
        ru: "Доступно на складе",
        en: "Available in stock",
        ua: "Доступно на складі"
    },
    "history-data-net_price": {
        ru: "Себестоимость",
        en: "Cost price",
        ua: "Собівартість"
    },
    "history-data-price": {
        ru: "Цена",
        en: "Price",
        ua: "Ціна"
    },
    "history-data-amount": {
        ru: "Сумма",
        en: "Amount",
        ua: "Сума"
    },
    "history-data-new": {
        ru: "Новое количество на складе",
        en: "New Quantity InStock",
        ua: "Нова кількість на складі"
    },
    "history-data-old": {
        ru: "Было на складе",
        en: "Was InStock",
        ua: "Було на складі"
    },
    "history-data-total": {
        ru: "Изменено на",
        en: "Changed To",
        ua: "Змінено на"
    },
    "history-data-comment": {
        ru: "Комментарий",
        en: "Comment",
        ua: "Коментар"
    },
    "history-data-responsible_user_id": {
        ru: "Произвел операцию",
        en: "He did this action",
        ua: "Цю дію зробив"
    },
    "history-data-integration_id": {
        ru: "Источник",
        en: "Source",
        ua: "Джерело"
    },
    "history-data-reserv": {
        ru: "В резерве",
        en: "In Reserve",
        ua: "У резерві"
    },
    "history-data-changed_to": {
        ru: "Изменено на",
        en: "Changed To",
        ua: "Змінено на"
    },
    "history-data-is_reserved": {
        ru: "Зарезервировано для заказа",
        en: "Reserved for order",
        ua: "Зарезервовано для замовлення"
    },
    "history-data-marketplace_id": {
        ru: "Склад\\Магазин",
        en: "Warehouse\\Shop",
        ua: "Склад\\Магазин"
    },
    'expenses-marketplace-filter': {
        ru: "Расходы Склад\\Магазин",
        en: "Expenses Warehouse\\Shop",
        ua: "Витрати Склад\\Магазин"
    },
    "history-data-mids": {
        ru: "Склады / Магазины",
        en: "Warehouses / Shops",
        ua: "Склади / Магазини"
    },
    "history-data-new_price": {
        ru: "Нова ціна",
        en: "New price",
        ua: "Нова ціна",
    },
    "history-data-old_price": {
        ru: "Старая цена",
        en: "Old price",
        ua: "Стара ціна"
    },
    "history-data-mass_price": {
        ru: "Изменена цена на",
        en: "The price of",
        ua: "Змінено ціну на"
    },
    "history-data-order_status": {
        ru: "Изменение статуса на",
        en: "Change of status to",
        ua: "Зміна статусу на"
    },
    "history-action": {
        ru: "Операция",
        en: "Operation",
        ua: "Операція"
    },
    "history-actions": {
        ru: "Операции",
        en: "Operations",
        ua: "Операції"
    },
    "history-action-1": {
        ru: "Создание товара",
        en: "Create Item",
        ua: "Створення товару"
    },
    "history-action-2": {
        ru: "Удаление товара",
        en: "Delete item",
        ua: "Видалення товару"
    },
    "history-action-3": {
        ru: "Редактирование описание товара",
        en: "Edit item description",
        ua: "Редагування опису товару"
    },
    "history-action-4": {
        ru: "Добавление продажи",
        en: "Add sale",
        ua: "Додавання продажу"
    },
    "history-action-5": {
        ru: "Удаление продажи",
        en: "Delete sale",
        ua: "Видалення продажу"
    },
    "history-action-6": {
        ru: "Возврат продажи",
        en: "Refund sale",
        ua: "Повернення продажу"
    },
    "history-action-7": {
        ru: "Создание поставки",
        en: "Create Shipment",
        ua: "Створення поставки"
    },
    "history-action-8": {
        ru: "Обновление остатков товара",
        en: "Update instock item",
        ua: "Оновлення залишків товару"
    },
    "history-action-9": {
        ru: "Добавление расхода",
        en: "Add expenses",
        ua: "Додавання витрати"
    },
    "history-action-10": {
        ru: "Удаление расхода",
        en: "Delete expense",
        ua: "Видалення витрати"
    },
    "history-action-11": {
        ru: "Завершение резервирования",
        en: "Completion of the reservation",
        ua: "Завершення резервування"
    },
    "history-action-12": {
        ru: "Снятие резервирования",
        en: "Canceling a reservation",
        ua: "Зняття резервування"
    },
    "history-action-13": {
        ru: "Удаление резервирования",
        en: "Delete a reservation",
        ua: "Видалення резервування"
    },
    "history-action-14": {
        ru: "Добавление номера отслеживания",
        en: "Adding a tracking number",
        ua: "Додавання номера відстеження"
    },
    "history-action-15": {
        ru: "Ручное завершение отправки",
        en: "Manual completion of sending",
        ua: "Ручне завершення відправки"
    },
    "history-action-16": {
        ru: "Автоматическое обновление отправки",
        en: "Automatic updating of sending",
        ua: "Автоматичне оновлення відправки"
    },
    "history-action-17": {
        ru: "Уменьшение товара на складе",
        en: "The decrease in goods",
        ua: "Зменшення товару на складі"
    },
    "history-action-18": {
        ru: "Увеличение товара на складе",
        en: "Increase of goods in stock",
        ua: "Збільшення товару на складі"
    },
    "history-action-19": {
        ru: "Обновление баланса",
        en: "Update balance",
        ua: "Оновлення баланса"
    },
    "history-action-20": {
        ru: "Поступление средств",
        en: "Receipt of funds",
        ua: "Надходження коштів"
    },
    "history-action-21": {
        ru: "Удаление поставки",
        en: "Delete shipment",
        ua: "Видалення поставки"
    },
    "history-action-22": {
        ru: "Резервирование товара",
        en: "Reservation of goods",
        ua: "Резервування товару"
    },
    "history-action-23": {
        ru: "Редактирование счета",
        en: "Edit of account",
        ua: "Редагування рахунку"
    },
    "history-action-24": {
        ru: "Перемещение товара",
        en: "Item moving",
        ua: "Переміщення товару"
    },
    "history-action-25": {
        ru: "Инвентаризация",
        en: "Inventory",
        ua: "Інвентаризація"
    },
    "history-action-26": {
        ru: "Отмена перемещения товара",
        en: "Cancellation of goods movement",
        ua: "Скасування переміщення товару"
    },
    "history-action-27": {
        ru: "Редактирование поставки",
        en: "Shipment editing",
        ua: "Редагування поставки"
    },
    "history-action-28": {
        ru: "Отмена поставки",
        en: "Shipment Cancellation",
        ua: "Скасування поставки"
    },
    "history-action-29": {
        ru: "Редактирование продажи",
        en: "Product sale",
        ua: "Редагування продажу"
    },
    "history-action-30": {
        ru: "Производство товара",
        en: "Goods production",
        ua: "Виробництво товару"
    },
    "history-action-31": {
        ru: "Редактирование Платежа",
        en: "Editing a Payment",
        ua: "Редагування Платежу"
    },
    "history-action-32": {
        ru: "Списание товара",
        en: "Write-off of goods",
        ua: "Списання товару"
    },
    "history-action-33": {
        ru: "Отмена списания товара",
        en: "Cancellation of product write-off",
        ua: "Скасування списання товару"
    },
    "history-action-34": {
        ru: "Добавление тега",
        en: "Adding a tag",
        ua: "Додавання тега"
    },
    "history-action-35": {
        ru: "Удаление тега клиента",
        en: "Deleting a client tag",
        ua: "Видалення тега клієнта"
    },
    "history-action-36": {
        ru: "Привязка тега к клиенту",
        en: "Binding a tag to a client",
        ua: "Прив'язка тега до клієнта"
    },
    "history-action-37": {
        ru: "Отвязка тега от клиента",
        en: "Decoupling the tag from the client",
        ua: "Відв'язка тега від клієнта"
    },
    "history-action-38": {
        ru: "Редактирование тега",
        en: "Edit a tag",
        ua: "Редагування тега"
    },
    "history-action-39": {
        ru: "Настройка программы лояльности",
        en: "Setting up a loyalty program",
        ua: "Налаштування програми лояльності"
    },
    "history-action-40": {
        ru: "Пересчет скидки клиенту",
        en: "Recalculation of customer discount",
        ua: "Перерахунок знижки клієнту"
    },
    "history-action-41": {
        ru: "Удаление расхода ",
        en: "Removing the expense",
        ua: "Видалення витрати"
    },
    "history-action-42": {
        ru: "Фискализация чека",
        en: "Check fiscalization",
        ua: "Фіскалізація чека"
    },
    "history-action-43": {
        ru: "Восстановление удаленного товара",
        en: "Restore a deleted product",
        ua: "Відновлення видаленого товару"
    },
    "history-action-44": {
        ru: "Добавление клиента",
        en: "Adding a client",
        ua: "Додавання клієнта"
    },
    "history-action-45": {
        ru: "Редактирование клиента",
        en: "Editing a client",
        ua: "Редагування клієнта"
    },
    "history-action-46": {
        ru: "Редактирование баланса клиента",
        en: "Editing a client's balance",
        ua: "Редагування баланса клієнта"
    },
    "history-action-47": {
        ru: "Создание счета",
        en: "Account creation",
        ua: "Створення рахунку"
    },
    "history-action-48": {
        ru: "Удаление счета",
        en: "Deleting an account",
        ua: "Видалення рахунку"
    },
    "history-action-49": {
        ru: "Восстановление продажи",
        en: "Restoring sales",
        ua: "Відновлення продажу"
    },
    "history-action-50": {
        ru: "Изменение статуса продаж",
        en: "Changing sales status",
        ua: "Зміна статусу продажів"
    },
    "history-action-51": {
        ru: "Изменение цены товара",
        en: "Change in price of products",
        ua: "Зміна ціни товара"
    },
    "history-action-52": {
        ru: "Создание склада",
        en: "Warehouse creation",
        ua: "Створення складу"
    },
    "history-action-53": {
        ru: "Удаление склада",
        en: "Removing a warehouse",
        ua: "Видалення складу"
    },
    "history-action-54": {
        ru: "Удаление клиента",
        en: "Deleting a client",
        ua: "Видалення клієнта"
    },
    "history-action-55": {
        ru: "Перевод с аккаунта",
        en: "Transfer from account",
        ua: "Переказ з акаунта"
    },
    "history-action-56": {
        ru: "Перевод на аккаунт",
        en: "Transfer to account",
        ua: "Переказ на акаунт"
    },
    "history-action-57": {
        ru: "Частичная оплата",
        en: "Partial payment",
        ua: "Часткова оплата"
    },
    "action-1": {
        ru: "Продажа",
        en: "Sale",
        ua: "Продаж"
    },
    "action-2": {
        ru: "Расход",
        en: "Expenses",
        ua: "Витрати"
    },
    "action-3": {
        ru: "Инвестиция",
        en: "Investment",
        ua: "Інвестиція"
    },
    "action-4": {
        ru: "Выплата дивидендов",
        en: "Dividend payment",
        ua: "Виплата дивідендів"
    },
    "action-5": {
        ru: "Поставка",
        en: "Delivery of goods",
        ua: "Доставка товарів"
    },
    "action-6": {
        ru: "Перевод с аккаунта",
        en: "Transfer from account",
        ua: "Переведення з акаунту"
    },
    "action-7": {
        ru: "Перевод на аккаунт",
        en: "Transfer to account",
        ua: "Переведення на акаунт"
    },
    "action-8": {
        ru: "Удаление продажи",
        en: "Delete sale",
        ua: "Вилучити продаж"
    },
    "action-9": {
        ru: "Отмена транзакции",
        en: "Revert Transaction",
        ua: "Відміна транзакції"
    },
    "action-10": {
        ru: "Удаление поставки",
        en: "Delete Shipment",
        ua: "Вилучити поставку"
    },
    "action-11": {
        ru: "Возврат от покупателя",
        en: "Refund from buyer",
        ua: "Повернення від покупця"
    },
    "action-12": {
        ru: "Удаление расхода",
        en: "Delete expenses",
        ua: "Видалення витрати"
    },
    "action-13": {
        ru: "Удаление поступления средств",
        en: "Delete receipt of funds",
        ua: "Видалення надходження коштів"
    },
    "action-14": {
        ru: "Оплата стоимости возврата",
        en: "Payment of refund cost",
        ua: "Оплата вартості повернення"
    },
    "action-15": {
        ru: "Част. оплата",
        en: "Partial payment",
        ua: "Част. оплата"
    },
    "action-16": {
        ru: "Возврат средств за резервацию/част. оплаты",
        en: "Refunds for the reservation/partial payment",
        ua: "Повернення коштів за бронювання/част. оплати"
    },
    "create-product-delivery": {
        ru: "Создать поставку товара",
        en: "Create Shipment",
        ua: "Створити поставку товару"
    },
    "gen-sku": {
        ru: "Сгенерировать артикул",
        en: "Generate SKU",
        ua: "Згенерувати артикул"
    },
    "gen-barcode": {
        ru: "Сгенерировать штрих-код",
        en: "Generate Barcode",
        ua: "Згенерувати штрих-код"
    },
    "select-brand": {
        ru: "Выберите бренд",
        en: "Select Brand",
        ua: "Оберіть бренд"
    },
    "all-brands": {
        ru: "Все бренды",
        en: "All brands",
        ua: "Всі бренди"
    },
    "select-warehouse": {
        ru: "Выберите магазин",
        en: "Select Shop",
        ua: "Оберіть магазин"
    },
    "select-account": {
        ru: "Выберите доступные счета",
        en: "Select an account available",
        ua: "Виберіть доступні рахунки"
    },
    "shipment-name": {
        ru: "Название поставки",
        en: "Shipment Name",
        ua: "Назва поставки"
    },
    "create-shipment": {
        ru: "Создать поставку товара",
        en: "Create Shipment",
        ua: "Створити поставку товару"
    },
    "create": {
        ru: "Создать",
        en: "Create",
        ua: "Створити"
    },
    "create_new": {
        ru: "Создать новую",
        en: "Create new",
        ua: "Створити нову"
    },
    "shipment-count-item": {
        ru: "Ожидается ед.",
        en: "Units expected",
        ua: "Очікується од."
    },
    "shipment-count-item-added": {
        ru: "Добавлено товара",
        en: "Added items",
        ua: "Додані товари"
    },
    "shipment-not-paid": {
        ru: "Деньги за поставку еще не были списаны с Ваших счетов",
        en: "Money for shipment has not yet been debited from your accounts.",
        ua: "Гроші за поставку ще не було списано з Ваших рахунків"
    },
    "expect": {
        ru: "Ожидаем",
        en: "Expect",
        ua: "Очікуємо"
    },
    "expected": {
        ru: "Ожидалось",
        en: "Expected",
        ua: "Очікувалося"
    },
    "received": {
        ru: "Получили",
        en: "Received",
        ua: "Отримано"
    },
    "сomponents": {
        ru: "Компонентов",
        en: "Components",
        ua: "Компонентів"
    },
    "product-on-amount": {
        ru: "Товара на сумму",
        en: "Item amount",
        ua: "Товара на суму"
    },
    "shipment-cost": {
        ru: "Сумма поставки\\Предоплата",
        en: "Shipment Amount\\Prepaid",
        ua: "Сума поставки\\Передплата"
    },
    "shipments-status": {
        ru: "Статус",
        en: "Status",
        ua: "Статус"
    },
    "sale-status": {
        ru: "Статус продажи",
        en: "Sale Status",
        ua: "Статус продажу"
    },
    "sales-status": {
        ru: "Статус продажів",
        en: "Sales status",
        ua: "Статус продажів"
    },
    "sales-statuses": {
        ru: "Статусы продаж",
        en: "Sale statuses",
        ua: "Статуси продажів"
    },
    "status-type": {
        ru: "Тип статуса",
        en: "Status type",
        ua: "Тип статусу"
    },
    "common-statuses": {
        ru: "Общие",
        en: "Common",
        ua: "Спільні"
    },
    "status": {
        ru: "Статус",
        en: "Status",
        ua: "Статус"
    },
    "statuses": {
        ru: "Статусы",
        en: "Statuses",
        ua: "Статуси"
    },
    "not-valid-status-name-error": {
        ru: "Введите название статуса",
        en: "Enter a status name",
        ua: "Ввеедіть назву статусу"
    },
    "choose-status-type-error": {
        ru: "Выберите тип статуса",
        en: "Choose a status type",
        ua: "Оберіть тип статусу"
    },
    "status-has-orders-error": {
        ru: "Невозможно изменить тип на 'Статус продаж', так как статус используется в Заказах. Сначала установите другой статус для существующих заказов.",
        en: "Status type can not be changed to 'Sales status', because the status is used in Orders. First set a different status for the existing orders.",
        ua: "Неможливо змінити тип на 'Статус продажів', оскільки статус використовується у Замовленнях. Спочатку встановіть інший статус для існуючих замовлень."
    },
    "status-has-sales-error": {
        ru: "Невозможно изменить тип на 'Статус заказов', так как статус используется в Продажах. Сначала установите другой статус для существующих продаж",
        en: "Status type can not be changed to 'Orders status' because the status is used in Sales. First set a different status for the existing sales.",
        ua: "Неможливо змінити тип на 'Статус замовлень', оскільки статус використовується у Продажах. Спочатку встановіть інший статус для існуючих продажів."
    },
    "file_need_wait": {
        ru: "Начался процесс импорта товаров. Это может занять до 15 минут",
        en: "The process of importing goods has begun. This may take up to 15 minutes",
        ua: "Процес імпорту товарів розпочався. Це може тривати до 15 хвилин"
    },
    "complete": {
        ru: "Завершена",
        en: "Complete",
        ua: "Завершено"
    },
        // "pending": {
        //     ru: "Ожидает прибытия",
        //     en: "Awaiting arrival",
        //     ua: "В очікуванні прибуття"
        // },
    "old_netprice": {
        ru: "Старая себест.",
        en: "Old Cost",
        ua: "Стара собівартість"
    },
    "new_netprice": {
        ru: "Новая себест.",
        en: "New Cost",
        ua: "Нова собівар."
    },
    "new_netprice_full": {
        ru: "Новая себестоимость",
        en: "New Cost",
        ua: "Нова собівартість"
    },
    "shipments-created_at": {
        ru: "Создана",
        en: "Created",
        ua: "Створено"
    },
    "created_at": {
        ru: "Дата добавления",
        en: "Date Added",
        ua: "Дата додавання"
    },
    "shipment-title-step-0": {
        ru: "Шаг 1. Оформление поставки",
        en: "Step 1. Adding Shipment",
        ua: "Крок 1. Оформлення поставки"
    },
    "shipment-help-step-0": {
        ru: "Заполните данные о Вашей закупке товара и укажите оплачена она или нет и если оплачена, то с какого счета списать деньги",
        en: "Fill in the information about your purchase of goods and indicate whether it was paid or not, and if paid, from which account to write off the money",
        ua: "Заповніть дані про Вашу закупівлю товару та вкажіть, оплачена вона чи ні, та якщо оплачена - то з якого рахунку списати гроші"
    },

    "not-received-product": {
        ru: "Вы не указали, сколько и какого товара Вы получили.",
        en: "You did not indicate how much and what kind of goods you received.",
        ua: "Ви не вказали, скільки і якого товару Ви отримали."
    },
    "responseble": {
        ru: "Ответственный",
        en: "Responsible",
        ua: "Відповідальний"
    },
    "shipment-title-step-1": {
        ru: "Шаг 2. Товары поставки",
        en: "Step 2. Delivery items",
        ua: "Крок 2. Товари поставки"
    },
    "shipment-title-step-1-received": {
        ru: "Шаг 1. Подтверждение прибытия товаров на склад",
        en: "Step 1. Confirmation of the arrival of items to the warehouse",
        ua: "Крок 1. Підтвердження прибуття товарів на склад"
    },
    "shipment-help-step-1": {
        ru: "Добавьте товары ожидаемые в этой поставке.",
        en: "Add the items expected in this delivery.",
        ua: "Додайте товари, очікувані в цій поставці"
    },
    "shipment-help-step-1-received": {
        ru: "Укажите какие товары уже прибыли на склад и по какой закупочной цене",
        en: "Indicate what items have already arrived at the warehouse and at what purchase price",
        ua: "Вкажіть, які товари вже прибули на склад і за якою закупівельною ціною"
    },
    "shipment-title-step-2": {
        ru: "Шаг 2. Укажите стоимость доставки",
        en: "Step 2. Indicate the cost of delivery",
        ua: "Крок 2. Вкажіть вартість доставки"
    },
    "shipment-title-step-2-part-2": {
        ru: " и стоимость товара",
        en: "and cost of goods",
        ua: "і вартість товару"
    },
    "shipment-help-step-2": {
        ru: "Выберите способ доставки товара на склад и укажите стоимость доставки. Стоимость доставки может быть 0.",
        en: "Choose the method of delivery of goods to the warehouse and specify the cost of delivery. Shipping cost may be 0.",
        ua: "Оберіть спосіб доставки товару на склад та вкажіть вартість доставки. Вартість доставки може бути 0."
    },
    "shipment-help-step-2-part-2": {
        ru: "Также стоимость товара еще не была списана с Вашего счета. Введите общую сумму этой поставки и с какого счета должны быть списаны средства.",
        en: "Also, the value of the goods has not yet been debited from your account. Enter the total amount of this delivery and from which account the funds should be debited.",
        ua: "Також вартість товару ще не була списана з Вашого рахунку. Введіть загальну суму цієї поставки та з якого рахунку мають бути списані кошти."
    },
    "shipment-title-step-3": {
        ru: "Шаг 3. Проверьте цены продажи товаров",
        en: "Step 3. Check the sale prices of items",
        ua: "Крок 3. Перевірте ціни продажу товарів"
    },
    "shipment-help-step-3": {
        ru: "В связи с тем, то закупочные цены могли измениться, проверьте правильно ли у Вас выставлены цены продажи новых товаров.",
        en: "Due to the fact that the purchase prices could change, check whether you have correctly set the selling prices of the delivered goods.",
        ua: "Через те, що закупівельні ціни могли змінитися, перевірте, чи правильно у Вас виставлені ціни продажу нових товарів."
    },
    "channel": {
        ru: "Канал продаж",
        en: "Sales channel",
        ua: "Канал продажів"
    },
    "channel-tag": {
        ru: "Канал продаж/Теги",
        en: "Sales channel/Tags",
        ua: "Канал продажів/Теги"
    },
    "sales-channel-general": {
        ru: "Магазин",
        en: "Shop",
        ua: "Крамниця"
    },
    "price-from": {
        ru: "Цена от",
        en: "Price From",
        ua: "Ціна від"
    },
    "price-to": {
        ru: "Цена до",
        en: "Price To",
        ua: "Ціна до"
    },
    "margin-from": {
        ru: "Маржа от",
        en: "Margin From",
        ua: "Маржа від"
    },
    "margin-to": {
        ru: "Маржа до",
        en: "Margin To",
        ua: "Маржа до"
    },
    "page-previous": {
        ru: "Предыдущая",
        en: "Previous",
        ua: "Попередня"
    },
    "page-next": {
        ru: "Следующая",
        en: "Next",
        ua: "Наступна"
    },
    "warehouse-type": {
        ru: "Тип склада",
        en: "Warehouse Type",
        ua: "Тип складу"
    },
    "residue-control": {
        ru: "Контроль остатков",
        en: "Residue Control",
        ua: "Контроль залишків"
    },
    "residue-control-negative": {
        ru: "Не жесткий контроль остатков (разрешить остаток в минус)",
        en: "Not strict control of residues (allow residual to be negative)",
        ua: "Не жорсткий контроль залишків (дозволити залишок у мінус)"
    },
    "excluding-balances": {
        ru: "Без учета остатков (дропшипинг)",
        en: "Excluding Balances (dropshipping)",
        ua: "Без урахування залишків (дропшипінґ)"
    },
    "account-commission": {
        ru: "Комиссия",
        en: "Commission",
        ua: "Комісія"
    },
    "account-commission-in": {
        ru: "При Пополнении",
        en: "When Replenishing",
        ua: "При Поповненні"
    },
    "account-commission-out": {
        ru: "При Снятии",
        en: "When Withdrawing",
        ua: "При Знятті"
    },
    "account-begin-balance": {
        ru: "Начальный баланс",
        en: "Starting Balance",
        ua: "Початковий баланс"
    },
    "account-name": {
        ru: "Название счета",
        en: "Account Name",
        ua: "Назва рахунку"
    },
    "add-account-btn-label": {
        ru: "Добавить счет",
        en: "Add Account",
        ua: "Додати рахунок"
    },
    "edit-account-btn-label": {
        ru: "Редактировать счет",
        en: "Edit Account",
        ua: "Редагувати рахунок"
    },
    "include_sales_commision": {
        ru: "Учитывать комиссию при добавлении продажи",
        en: "Consider commission when adding sales",
        ua: "Враховувати комісію при додаванні продажу"
    },
    "account already exist": {
        ru: "Счет с таким названием уже существует",
        en: "An account with the same name already exists",
        ua: "Рахунок із такою назвою вже існує"
    },
    "clients-tags": {
        ru: "Теги",
        en: "Tags",
        ua: "Теги"
    },
    "tags": {
        ru: "Теги",
        en: "Tags",
        ua: "Теги"
    },
    "clients-tags-label": {
        ru: "Теги для клиента",
        en: "Tags for the client",
        ua: "Теги для клієнта"
    },
    "client-tags": {
        ru: "Теги клиента",
        en: "Client Tags",
        ua: "Теги клієнта"
    },
    "tags-to-clients-label": {
        ru: "Добавить тег к клиенту",
        en: "Add a tag to a client",
        ua: "Додати тег до клієнта"
    },
    "edit-clients-tags": {
        ru: "Редактировать клиентский тег",
        en: "Edit a client tag",
        ua: "Редагувати клієнтський тег"
    },
    "add-clients-tags": {
        ru: "Добавить клиентский тег",
        en: "Add a client tag",
        ua: "Додати клієнтський тег"
    },
    "tag-name": {
        ru: "Имя тега",
        en: "Tag name",
        ua: "Ім'я тега"
    },
    "tag-color": {
        ru: "Цвет тега",
        en: "Tag color",
        ua: "Колір тега"
    },
    "tag-add-btn": {
        ru: "Добавить тег",
        en: "Add tag",
        ua: "Додати тег"
    },

    "clients-date": {
        ru: "Дата добавления",
        en: "Date Added",
        ua: "Дата додавання"
    },
    "clients-name": {
        ru: "Имя клиента",
        en: "Name",
        ua: "Ім'я клієнта"
    },
    "clients-phone": {
        ru: "Телефон",
        en: "Phone",
        ua: "Телефон"
    },
    "clients-discount": {
        ru: "Скидка",
        en: "Discount",
        ua: "Знижка"
    },
    "clients-to-pay": {
        ru: "К оплате",
        en: "To pay",
        ua: "До сплати"
    },
    "clients-total_amount": {
        ru: "Куплено на сумму",
        en: "Purchased for the amount",
        ua: "Придбано на суму"
    },
    "purchased": {
        ru: "Куплено",
        en: "Purchased",
        ua: "Придбано"
    },
    "total_amount_from": {
        ru: "Куплено на сумму от",
        en: "Purchased in the amount of",
        ua: "Придбано на суму від"
    },
    "total_amount_to": {
        ru: "Куплено на сумму до",
        en: "Purchased up to",
        ua: "Придбано на суму до"
    },
    "add-clients-btn": {
        ru: "Добавить клиента",
        en: "Add New Client",
        ua: "Додати клієнта"
    },
    "edit-client-btn": {
        ru: "Редактировать клиента",
        en: "Edit Client",
        ua: "Редагувати клієнта"
    },
    "not-have-clients": {
        ru: "Не добавлено ни одного клиента",
        en: "No clients Added",
        ua: "Жодного клієнта не додано"
    },
    "client-name": {
        ru: "Имя",
        en: "Name",
        ua: "Ім'я"
    },
    "client-name-placeholder": {
        ru: "Введите имя клиента",
        en: "Enter customer name",
        ua: "Введіть ім'я клієнта"
    },
    "firstname-placeholder": {
        ru: "Введите имя клиента",
        en: "Enter customer First Name",
        ua: "Введіть ім'я клієнта"
    },
    "lastname-placeholder": {
        ru: "Введите фамилию клиента",
        en: "Enter customer last name",
        ua: "Введіть прізвище клієнта"
    },
    "nickname-placeholder": {
        ru: "Введите ник или логин клиента",
        en: "Enter the nickname or username of the customer",
        ua: "Введіть нік або логін клієнта"
    },
    "balance-placeholder": {
        ru: "Введите баланс",
        en: "Enter balance",
        ua: "Введіть баланс"
    },
    "nickname": {
        ru: "Ник или логин",
        en: "Nickname",
        ua: "Нік або логін"
    },
    "company": {
        ru: "Компания",
        en: "Company",
        ua: "Компанія"
    },
    "first-name": {
        ru: "Имя",
        en: "First Name",
        ua: "Ім'я"
    },
    "last-name": {
        ru: "Фамилия",
        en: "Last Name",
        ua: "Прізвище"
    },
    "middle-name": {
        ru: "Отчество",
        en: "Middle Name",
        ua: "По-батькові"
    },
    "middlename-placeholder": {
        ru: "Введите отчество клиента",
        en: "Enter customer middle name",
        ua: "Введіть по-батькові клієнта"
    },
    "wrong-firstname": {
        ru: "Для служб доставок нужно ввести имя и фамилию",
        en: "For delivery services, you need to enter the first and last name",
        ua: "Для служб доставки необхідно ввести ім'я та прізвище"
    },
    "client-phone": {
        ru: "Телефон",
        en: "Phone",
        ua: "Телефон"
    },
    "client-phone-placeholder": {
        ru: "Введите телефон клиента",
        en: "Enter customer phone",
        ua: "Введіть телефон клієнта"
    },
    "client-company-placeholder": {
        ru: "Введите название компании",
        en: "Enter Company Name",
        ua: "Введіть назву компанії"
    },
    "client-email": {
        ru: "E-mail",
        en: "E-mail",
        ua: "E-mail"
    },
    "client-email-placeholder": {
        ru: "Введите E-mail клиента",
        en: "Enter customer E-mail",
        ua: "Введіть E-mail клієнта"
    },
    "client-birhday": {
        ru: "Д.р. клиента",
        en: "Birthday",
        ua: "Д.н. клієнта"
    },
    "client-birth_day_child_1": {
        ru: "Д.р. ребенка",
        en: "Child's  birthday",
        ua: "Д.н. дитини"
    },
    "client-birth_day_child_2": {
        ru: "Д.р. ребенка 2",
        en: "Child's  birthday 2",
        ua: "Д.н. дитини 2"
    },
    "client-birth_day_child_3": {
        ru: "Д.р. ребенка 3",
        en: "Child's  birthday 3",
        ua: "Д.н. дитини 3"
    },
    "client-discount": {
        ru: "Скидка",
        en: "Discount",
        ua: "Знижка"
    },
    "discount": {
        ru: "Скидка",
        en: "Discount",
        ua: "Знижка"
    },
    "adjust-discounts-btn": {
        ru: "Настроить скидки",
        en: "Adjust discounts",
        ua: "Налаштувати знижки"
    },
    "discounts-settings": {
        ru: "Наcтройка системы скидок",
        en: "Adjusting of a discounts system",
        ua: "Налаштування системи знижок"
    },
    "discounts-settings-bonus": {
        ru: "Укажите общую сумму покупок клиента и размер отчислений на бонусы клиенту при превышении этой суммы. С каждой покупки клиенту будет отчиститься на бонусный счет указанный процент. Если вы хотите отчислять процент с первой покупки, укажите в поле Суммы, 1 грн. Накопленный баланс вы можете списать с клиента при следующей покупки, указав, при оформлении покупки Фиксированную скидку. ",
        en: "Indicate the total amount of the client's purchases and the amount of deductions for bonuses to the client if this amount is exceeded. From each purchase the client will receive the specified percentage into the bonus account. If you want to deduct a percentage from the first purchase, indicate in the Amounts field, 1 UAH. You can write off the accumulated balance from the client for the next purchase by specifying a Fixed discount when making a purchase.",
        ua: "Вкажіть загальну суму покупок клієнта та розмір відрахувань на бонуси клієнту при перевищенні цієї суми. З кожної покупки клієнту відчиститиметься на бонусний рахунок зазначений відсоток. Якщо ви бажаєте відраховувати відсоток з першої покупки, вкажіть у полі Суми, 1 грн. Накопичений баланс ви можете списати з клієнта при наступній покупці, вказавши при оформленні покупки Фіксовану знижку."
    },
    "discounts-settings-discount": {
        ru: "Укажите общую сумму покупок и размеры скидки, который ей соответствует. Система будет автоматически добавлять клиенту скидку при достижении общей суммой покупок установленного порога. Скидка будет применяться на следующую покупку",
        en: "Indicate the total amount of purchases and the amount of the discount that corresponds to it. The system will automatically add a discount to the client when the total amount of purchases reaches the established threshold. The discount will be applied to your next purchase",
        ua: "Вкажіть загальну суму покупок та розміри знижки, що їй відповідає. Система автоматично додаватиме клієнту знижку при досягненні загальною сумою покупок встановленого порога. Знижка буде застосовуватись на наступну покупку"
    },
    "discounts-settings-dashboard": {
        ru: "Тут вы можете настроить систему лояльности для ваших клиентов.  Есть два типа лояльности, добавление покупателям размера скидки на следующую покупку, и накопление бонусов.",
        en: "Here you can set up a loyalty system for your customers. There are two types of loyalty, adding a discount amount to customers on their next purchase, and accumulating bonuses.",
        ua: "Тут можна налаштувати систему лояльності для ваших клієнтів. Є два типи лояльності, додавання покупцям розміру знижки на наступну покупку та накопичення бонусів."
    },
    "enable-automatic-discounts": {
        ru: "Включить программу лояльности ",
        en: "Enable loyalty program",
        ua: "Включити програму лояльності"
    },
    "disable-automatic-discounts": {
        ru: "Отключить программу лояльности",
        en: "Disable loyalty program",
        ua: "Відключити програму лояльності"
    },
    "are-you-sure-to-disable-discounts": {
        ru: "Вы уверены что хотите отключить автоматическое применение скидок? Скидки конкретных клиентов, установлены им системой ранее, остануться неизменными.",
        en: "Are you sure you want to disable automatic application of discounts? Discounts of specific customers, previously set for them by the system, will remain unchanged.",
        ua: "Ви впевнені, що хочете вимкнути автоматичне застосування знижок? Знижки конкретних клієнтів, встановлені їм системою раніше, залишаться незмінними."
    },
    "confirm-disable-discounts": {
        ru: "Подтвердите отключение",
        en: "Confirm disabling",
        ua: "Підтвердіть відключення"
    },
    "disable-btn": {
        ru: "Отключить",
        en: "Disable",
        ua: "Вимкнути"
    },
    "delete-discount": {
        ru: "Удалить скидку",
        en: "Delete discount",
        ua: "Видалити знижку"
    },
    "specify-at-least-one-discount": {
        ru: "Для включения системы скидок укадите хотя бы одну общую сумму покупок и соответсвующий ей размер скидки",
        en: "To turn on the discount system, please specify at least one total purachses amount and the corresponding discounts size",
        ua: "Для увімкнення системи знижок вкажіть хоча б одну загальну суму покупок та розмір знижки, що відповідає їй"
    },
    "purchases-amount": {
        ru: "Общая сумма покупок клиента",
        en: "Total purchases amount of a client",
        ua: "Загальна сума покупок клієнта"
    },
    "purchases-amount-placeholsder": {
        ru: "Введите общую сумму покупок",
        en: "Enter a total purchases amount",
        ua: "Введіть загальну суму покупок"
    },
    "discount-amount": {
        ru: "Размер скидки",
        en: "Discount amount",
        ua: "Розмір знижки"
    },
    "discount-amount-placeholsder": {
        ru: "Введите размер скидки",
        en: "Enter a discount size",
        ua: "Введіть розмір знижки"
    },
    "add-first-discount": {
        ru: "Добавить первую скидку",
        en: "Add first discount",
        ua: "Додати першу знижку"
    },
    "add-discount": {
        ru: "Добавить ещё одну скидку",
        en: "Add one more discount",
        ua: "Додати ще одну знижку"
    },
    "client-discount-placeholder": {
        ru: "Введите скидку клиента на покупки",
        en: "Enter customer discount on purchases",
        ua: "Введіть знижку клієнта на покупки"
    },
    "discounts-success-message": {
        ru: "Настройки симтемы скидок успешно сохранены",
        en: "The settings of the discount system have been successfully saved",
        ua: "Налаштування системи знижок успішно збережені"
    },
    "select-city": {
        ru: "-- Выберите Город --",
        en: "-- Select City --",
        ua: "-- Оберіть Місто --"
    },
    "filter-with-city": {
        ru: "-- Фильтр по городу --",
        en: "-- Filter by city --",
        ua: "-- Фільтр за містом --"
    },
    "by-city": {
      ru: "По городу",
      en: "By city",
      ua: "За містом"
    },
    "client-address": {
        ru: "Адрес",
        en: "Address",
        ua: "Адреса"
    },
    "client-address-placeholder": {
        ru: "Адрес для доставок",
        en: "Shipping Address",
        ua: "Адреса для доставок"
    },
    "add-client-btn": {
        ru: "Добавить клиента",
        en: "Add client",
        ua: "Додати клієнта"
    },
    "enter-client-phone": {
        ru: "Введите номер телефона клиента",
        en: "Enter client phone number",
        ua: "Введіть номер телефону клієнта"
    },
    "SenderWarehouse is not found RecipientWarehouse is not found": {
        ru: "Не найдено или не работает отделение получателя и отправителя",
        en: "The warehouse of the recipient and the sender was not found or does not work",
        ua: "Не знайдено або не працює відділення одержувача та відправника"
    },
    "RecipientWarehouse is not found": {
        ru: "Не найдено или не работает отделение получателя",
        en: "The warehouse of the recipient was not found or does not work",
        ua: "Не знайдено або не працює відділення одержувача"
    },
    "SenderWarehouse is not found": {
        ru: "Не найдено или не работает отделение отправителя",
        en: "The warehouse of the sender was not found or does not work",
        ua: "Не знайдено або не працює відділення відправника"
    },
    "HTTP 400: Bad Request (Problems with quantity products)": {
        ru: "Товаров не хватает на складе",
        en: "There are not enough goods in stock",
        ua: "Немає стільки товарів на складі"
    },
    "not enought stock": {
        ru: "Товаров не хватает на складе",
        en: "There are not enough goods in stock",
        ua: "Немає стільки товарів на складі"
    },
    "HTTP 400: Bad Request (not enought stock)": {
        ru: "Товаров не хватает на складе",
        en: "There are not enough goods in stock",
        ua: "Немає стільки товарів на складі"
    },
    "HTTP 404: Not Found (not-found-product)": {
        ru: "Один из товаров удален. Действие не может быть завершено",
        en: "One of the products has been removed. The action cannot be completed",
        ua: "Один із товарів видалений. Дія не може бути завершена"
    },
    "HTTP 400: Bad Request (request-proccessing)": {
        ru: "Запрос еще обрабатывается... Пожалуйста, подождите",
        en: "Request is still being processed... Please wait",
        ua: "Запит ще обробляється... Будь ласка, зачекайте"
    },
    "HTTP 400: Bad Request (problem with reserved product)": {
        ru: "Проблема с зарезервированными товарами. Проверьте, количество товара на складе",
        en: "Problem with reserved items. Check the quantity of goods in stock",
        ua: "Проблема із зарезервованими товарами. Перевірте кількість товару на складі"
    },
    "HTTP 400: Bad Request (error-client-exist)": {
        ru: "Клиент с таким номером телефона существует",
        en: "A customer with this phone number exists",
        ua: "Клієнт з таким номером телефону існує"
    },
    "HTTP 400: Bad Request (error-client-delivery-exist)": {
        ru: "Адрес доставки уже добавлен",
        en: "Shipping address already added",
        ua: "Адреса доставки вже додана"
    },
    "HTTP 403: Forbidden (this order already added)": {
        ru: "Этот заказ уже добавлен в Продажи",
        en: "This order has already been added to Sales",
        ua: "Це замовлення вже додано до Продажу"
    },
    "HTTP 404: Not Found (order already deleted)": {
        ru: "Эта продажа уже удалена",
        en: "This sale has already been deleted",
        ua: "Цей продаж вже видален"
    },
    "HTTP 404: Not Found (not-active-warehouse-or-integration)": {
        ru: "У вас не настроена интеграция з ПРРО или нет доступных интеграций для данного Склада\\Магазина",
        en: "You don't have PRRO integration configured or there are no available integrations for this Warehouse\\Store",
        ua: "У вас не налаштована інтеграція з ПРРО чи нема доступних інтеграцій для даного Складу\\Крамницы"
    },
    "do you have different warehouse": {
        ru: "У вас в продаже товары с нескольких складов. Мы не можем фискализировать эту продажу. Разделите на отдельные продажи или измените настройки Интеграции.",
        en: "You have goods for sale from several warehouses. We can't fiscalize this sale. Divide into separate sales or change the Integration settings.",
        ua: "У вас у продажу товари з кількох складів. Ми не можемо фіскалізувати цей продаж. Розділіть на окремі продажі або змініть налаштування Інтеграції."
    },
    "HTTP 404: Not Found (not-found-error)": {
        ru: "Запись не найдена, обновите страницу",
        en: "Record not found, refresh the page",
        ua: "Запис не знайдено, оновіть сторінку"
    },
    "HTTP 404: Not Found (order already finished)": {
        ru: "Этот резерв уже завершен",
        en: "This reservation has already been completed",
        ua: "Цей резерв вже завершен"
    },
    "HTTP 201: Created (not-open-shift)": {
        ru: "Смена в Checkbox открывается... Пожалуйста, повторите попытку фискализации чека",
        en: "Shift in Checkbox is opening... Please try again to add the sale",
        ua: "Зміна в Checkbox відкривається... Будь ласка, спробуйте ще раз фіскалізувати чек"
    },
    "HTTP 400: Bad Request (Limit: sales)": {
        ru: (
            <span key='ct2'>Ох.. Вы исчерпали количество продаж доступных на Вашем тарифе :(. <Link to="/settings/subscribe">Смените тариф</Link>, чтобы можно было добавлять продажи дальше. </span>
            ),
        en: (
            <span key='ct2'>Oh.. You have exhausted the number of sales available on your tariff :(. <Link to="/settings/subscribe">Change tariff</Link> so that you can add sales further.</span>
            ),
        ua: (
            <span  key='ct2'>Ох.. Ви вичерпали кількість продажів доступних на Вашому тарифі :(. <Link to="/settings/subscribe">Змініть тариф</Link>, щоб можна було додавати продажі далі. </span>
            ),
    },
    "HTTP 504: Gateway Timeout": {
        ru: "Сервис временно не отвечает, попробуйте через пару минут.",
        en: "Service is temporarily unresponsive, try again in a couple minutes.",
        ua: "Сервіс тимчасово не відповідає, спробуйте через кілька хвилин."
    },

    "sales-please-change-tarif": {
        ru: (
            <span key='ct1'><Link to="/settings/subscribe">Смените тариф</Link>, чтобы можно было добавлять продажи дальше. </span>
            ),
        en: (
            <span  key='ct1'><Link to="/settings/subscribe">Change tariff</Link> so that you can add sales further.</span>
            ),
        ua: (
            <span key='ct1'><Link to="/settings/subscribe">Змініть тариф</Link>, щоб можна було додавати продажі далі. </span>
            ),
    },

    "sales_limit_warning": {
        ru: {
            plural: "На Вашем тарифе можно добавить еще {sales} продаж{sales, plural, =1{у} =2{и} =3{и}  =4{и} other{}}."
        },
        en: {
            plural: "You can add {sales} more sale on your tariff."
        },
        ua: {
            plural: "На Вашому тарифі можна додати ще {sales} продаж{sales, plural, =1{у} =2{і} =3{і} =4{і} other{ів}}."
        },
    },

    "HTTP 400: Bad Request (Limit: products)": {
        ru: (
            <span>Ох.. Вы исчерпали количество доступных к добавлению товаров на Вашем тарифе :(. <Link to="/settings">Смените тариф</Link>, чтобы можно было добавлять больше товаров. </span>
            ),
        en: (
            <span>Oh.. You have exhausted the number of products available for adding on your tariff :(. <Link to="/settings">Change tariff</Link> so that you can add more products further.</span>
            ),
        ua: (
            <span>Ох.. Ви вичерпали кількість доступних до додавання товарів на Вашому тарифі :(. <Link to="/settings">Змініть тариф</Link>, щоб можна було додавати більше товарів. </span>
            ),
    },

    "HTTP 400: Bad Request (Limit: payments)": {
        ru: (
            <span>Упс, что-то пошло не так... ваш аккаунт не был оплачен в течение 10 дней, и мы с огромнейшим сожалением вынуждены его ограничить. :(. Нужно <Link to="/settings/subscribe">разобраться с оплатой</Link>, чтобы Вы могли продолжить использовать наш сервис и зарабатывать больше денег! </span>
            ),
        en: (
            <span>Oops, something went wrong... your account hasn't been paid for 10 days and we regret to limit it. :(. We need to <Link to="/settings/subscribe">deal with payment</Link> so you can continue to use our service and earn more money!</span>
            ),
        ua: (
            <span>Упс, щось пішло не так... ваш обліковий запис не був оплачений протягом 10 днів, і ми з величезним жалем змушені його обмежити. :(. Потрібно <Link to="/settings/subscribe">розібратися з оплатою</Link>, щоб Ви могли продовжити використовувати наш сервіс та заробляти більше грошей!</span>
            ),
    },

    "products-please-change-tarif": {
        ru: (
            <span key='ct1'><Link to="/settings">Смените тариф</Link>, чтобы можно было добавлять больше товаров </span>
            ),
        en: (
            <span><Link to="/settings">Change tariff</Link>  so that you can add more products further.</span>
            ),
        ua: (
            <span><Link to="/settings">Змініть тариф</Link>, щоб можна було додавати більше товарів. </span>
            ),
    },

    "products_limit_warning": {
        ru: {
            plural: "На Вашем тарифе можно добавить еще {products} товар{products, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}."
        },
        en: {
            plural: "You can add {products} more products on your tariff."
        },
        ua: {
            plural: "На Вашому тарифі можна додати ще {products} товар{sales, plural, =1{} =2{а} =3{а} =4{а} other{ів}}."
        },
    },

    "warning-payment": {
        ru: {
            plural: "Ух... пытались снять с вашей карты оплату за сервис, но не вышло. :( Но мы попробуем еще пару раз! Проверьте, пожалуйста, лимиты по карте и достаточно ли средств для оплаты. У вас уже {days} {days, plural, =1{день} =21{день} =31{день} =2{дня} =22{дня} =32{дня} =3{дня} =33{дня} =4{дня} =24{дня} =34{дня} other{дней}} не оплачено."
        },
        en: {
            plural: "Wow... we tried to charge your card for the service, but it didn't work out. :( But we'll try a couple more times! Please check the limits on the card and whether there are enough funds to pay. You haven't paid for {days} days already."
        },
        ua: {
            plural: "Ух... намагалися зняти з вашої картки оплату за сервіс, але не вийшло. :( Але ми спробуємо ще пару разів! Перевірте, будь ласка, ліміти по карті і чи достатньо коштів для оплати. У вас вже {days} {days, plural, =1{день} =21{день} =31{день} =2{дні} =22{дні} =32{дні} =3{дні} =33{дні} =4{дні} =24{дні} =34{дні} other{днів}} не сплачено."
        },
    },

    "soon-payment": {
        ru: {
            plural: "Круто, что вы с нами! Через пару дней, мы будем списывать оплату за использование сервиса. Проверьте, чтобы на счету было достаточно средств и не стояли лимиты, дабы не возникли трудности :)"
        },
        en: {
            plural: "It's great that you are with us! In a couple of days we will write off the payment for using the service. Make sure that there are enough funds on the account and there are no payment limits, so that there are no difficulties :)"
        },
        ua: {
            plural: "Круто, що ви з нами! За кілька днів ми будемо списувати оплату за використання сервісу. Перевірте, щоб на рахунку було достатньо коштів і не стояли ліміти, щоб не виникло труднощів :)"
        },
    },
    "limited-payment": {
        ru:  (
            <div>
                <b>
                    Ваш аккаунт заблокирован. Пожалуйста, <a href="https://my.navkolo.one/payments">произведите оплату</a>.
                </b>
            </div>
        ),
        en:( <div>
                <b>
                    Your account has been blocked. Please <a href="https://my.navkolo.one/payments">make payment</a>.
                </b>
            </div>),
        ua:  (
            <div>
                <b>
                    Ваш акаунт заблоковано. Будь ласка, <a href="https://my.navkolo.one/payments">проведіть оплату</a>.
                </b>
            </div>
            ),
    },
    "HTTP 400: Bad Request (Limit: navkolo)": {
        ru:  (
            <div>
                <b>
                    Ваш аккаунт заблокирован. Пожалуйста, <a href="https://my.navkolo.one/payments">произведите оплату</a>.
                </b>
            </div>
        ),
        en:( <div>
                <b>
                    Your account has been blocked. Please <a href="https://my.navkolo.one/payments">make payment</a>.
                </b>
            </div>),
        ua:  (
            <div>
                <b>
                    Ваш акаунт заблоковано. Будь ласка, <a href="https://my.navkolo.one/payments">проведіть оплату</a>.
                </b>
            </div>
            ),
    },
    "limited-payment-soon": {
        ru:  (
            <>
                 <b>На ваш e-mail были отправлены счета на оплату услуг за следующий период. </b><br />
                 <span>
                     Для дальнейшего использования сервиса, <a href="https://my.navkolo.one/payments">проведите оплату</a> до
                 </span>
             </>
            ),
        en: (
           <>
                 <b>Invoices for payment of services for the next period have been sent to your e-mail. </b><br />
                 <span>
                     To continue using the service, please <a href="https://my.navkolo.one/payments">make a payment</a> to
                 </span>
             </>
            ),
        ua:  (
            <>
                <b>На ваш e-mail були надіслані рахунки на оплату послуг за наступний період. </b> <br />
                <span>
                    Щоб надалі користуватися сервісом, будь ласка, <a href="https://my.navkolo.one/payments">проведіть оплату</a> до
                </span>
            </>
        ),
    },

    "now-payment": {
        ru: "— Какой сегодня день? — спросил Пух. — Сегодня, — ответил Пятачок. — Мой любимый день, — сказал Пух.",
        en: "- What day is today? Pooh asked. “Today,” said Piglet. “My favorite day“, said Pooh.",
        ua: "- Який сьогодні день? — спитав Пух. — Сьогодні, — відповів Пятачок. - Мій улюблений день, - сказав Пух."
    },

    "not all fields required": {
        ru: "Не все обязательные поля заполнены",
        en: "Not all required fields are filled",
        ua: "Не всі обов'язкові поля заповнені"
    },

    "paid": {
        ru: "Оплачено",
        en: "Paid",
        ua: "Оплачено"
    },
    "wrong-email": {
        ru: "Не верный формат E-mail",
        en: "Invalid Email Format",
        ua: "Хибний формат емейла"
    },
    "delivery_paid": {
        ru: "Доставка оплачена",
        en: "Delivery Paid",
        ua: "Доставка оплачена"
    },
    "from_account": {
        ru: "cо счета",
        en: "from account",
        ua: "з рахунку"
    },
    "select": {
        ru: "Сменить",
        en: "Change",
        ua: "Змінити"
    },
    "active": {
        ru: "Активный",
        en: "Current",
        ua: "Активний"
    },
    "setting-tarif-plan": {
        ru: "Выберите тарифный план",
        en: "Choose a Tariff Plan",
        ua: "Виберіть тарифний план"
    },
    "pay-tarif": {
        ru: "Подписка на тариф",
        en: "Subscription to Tariff",
        ua: "Підписка на тариф"
    },
    "pay-tarif-plan": {
        ru: "Подписка на тарифный план",
        en: "Subscribe to Tariff Plan",
        ua: "Підписка на тарифний план"
    },
    "tarif-success-change": {
        ru: "Тарифный план успешно изменен",
        en: "Tariff plan changed successfully",
        ua: "Тарифний план успішно змінений"
    },
    "your-payments": {
        ru: "Ваши оплаты",
        en: "Your payments",
        ua: "Ваші оплати"
    },
    "your-cards": {
        ru: "Ваши банковские карты",
        en: "Your bank cards",
        ua: "Ваші банківські карти"
    },
    "not-have-card": {
        ru: "У Вас не привязано еще ни одной карты",
        en: "You have not linked any cards yet",
        ua: "У Вас не прив'язане ще жодної карти"
    },
    "account_num": {
        ru: "Номер карты",
        en: "Card Number",
        ua: "Номер картки"
    },
    "cancel-subscribe": {
        ru: "Отменить подписку",
        en: "Unsubscribe",
        ua: "Скасувати підписку"
    },
    "not-cancel-subscribe": {
        ru: "Не отменять",
        en: "Do not cancel",
        ua: "Не скасовувати"
    },
    "confirm-cancel-subscribe": {
        ru: "Подтверждение отмены подписки",
        en: "Subscription cancellation confirmation",
        ua: "Підтвердження скасування підписки"
    },
    "are-you-sure-to-cancel-subscribe": {
        ru: "Вы уверены, что хотите отменить подписку? Если оплата не будет продлена в следующем месяце, Вы не сможете фиксировать новые продажи, добавлять товары и расходы.",
        en: "Are you sure you want to cancel your subscription? If the payment is not renewed next month, you will not be able to record new sales, add products and expenses.",
        ua: "Ви впевнені, що хочете скасувати підписку? Якщо оплата не буде подовжена в наступному місяці, Ви не зможете фіксувати нові продажі, додавати товари і витрати."
    },
    "cancel-subscribe-success": {
        ru: "Ваша подписка успешно отменена",
        en: "Your subscription canceled successfully",
        ua: "Ваша підписка успішно скасовано"
    },
    "cancel-subscribe-error": {
        ru: "При отмене Вашей подписки произошла техническая ошибка. Обратитесь к поддержке через on-line чат, мы быстро решим эту проблему. Извините за неудобства.",
        en: "A technical error occurred while canceling your subscription. Contact support via on-line chat, we will quickly solve this problem. Sorry for the inconvenience.",
        ua: "При скасування Вашої підписки сталася технічна помилка. Зверніться до підтримки через on-line чат, ми швидко вирішимо цю проблему. Вибачте за незручності."
    },
    "current-state": {
        ru: "Текущее состояние",
        en: "Current state",
        ua: "Поточний стан"
    },
    "profit-and-loss": {
        ru: "Доходы и Расходы",
        en: "Profit and Loss",
        ua: "Прибуток і Збитки"
    },
    "problem-with-integration": {
        ru: "Проблемы с интеграцией, обратитесь в поддержку",
        en: "Integration issues, contact support",
        ua: "Проблеми з інтеграцією, зверніться на підтримку"
    },
    "net-income": {
        ru: "Доходы",
        en: "Income",
        ua: "Прибутки"
    },
    "expenses": {
        ru: "Расходы",
        en: "Expenses",
        ua: "Витрати"
    },
    "expenses-for": {
        ru: "Расходы за",
        en: "Expenses for",
        ua: "Витрати за"
    },
    "expenses-for-category": {
        ru: "Расходы по категориям",
        en: "Costs By Category",
        ua: "Витрати за категоріями"
    },
    "expenses_category": {
        ru: "Расходы по категориям",
        en: "Costs By Category",
        ua: "Витрати за категоріями"
    },
    "make-refund": {
        ru: "Возврат",
        en: "Refund",
        ua: "Повернення"
    },
    "show-filter": {
        ru: "Показать фильтр",
        en: "Show Filter",
        ua: "Показати фільтр"
    },
    "filter": {
        ru: "Фильтр",
        en: "Filter",
        ua: "Фільтр"
    },
    "list-print-slip": {
        ru: "Список продаж PDF",
        en: "Sales list PDF",
        ua: "Список продажів PDF"
    },
    "list-print-slip-xls": {
        ru: "Список продаж Excel",
        en: "Sales list Excel",
        ua: "Список продажів Excel"
    },
    "print-slip-pdf": {
        ru: "Печать в PDF",
        en: "Print PDF",
        ua: "Друк в PDF"
    },
    "hide-filter": {
        ru: "Спрятать фильтр",
        en: "Hide Filter",
        ua: "Сховати фільтр"
    },
    "show-more-filter": {
        ru: "Показать больше фильтров",
        en: "Show More Filter",
        ua: "Показати більше фільтрів"
    },
    "hide-more-filter": {
        ru: "Свернуть",
        en: "Hide",
        ua: "Сховати"
    },
    "unfold": {
        ru: "Развернуть",
        en: "Unfold",
        ua: "Розгорнути"
    },
    "disable-unnecessary": {
        ru: "Отключить ненужное",
        en: "Disable Unnecessary",
        ua: "Відключити непотрібне"
    },
    "control-instock": {
        ru: "Учет остатков товара",
        en: "Accounting for product balances",
        ua: "Облік залишків товару"
    },
    "control-instock-text": {
        ru:  (
            <span>
            <p>Если Вы продаете товар по Дропшипингу или напрямую от Поставщика, или Ваш товар это <b>услуги</b> - Вам не нужно вести учет остатков. Отключайте эту функцию! </p>
            <p>Но если Вы храните продаваемый товар у себя дома или в магазине и Вам важно знать сколько и какого товара у Вас осталось - не отключайте!</p>
            </span>
            ),
        en: (
            <span>
             <p> If you are selling a product via Dropshipping or directly from a Supplier, or if your product is <b> services </b> - you do not need to keep track of stock. Disable this feature! </p>
             <p> But if you store the goods for sale at home or in a store and it is important for you to know how much and what kind of goods you have left - do not disable! </p>
             </span>
            ),
        ua: (
            <span>
             <p> Якщо Ви продаєте товар по Дропшіпінгу або безпосередньо від Постачальника, або Ваш товар це <b> послуги </b> - Вам не потрібно вести облік залишків. Вимикайте цю функцію! </p>
             <p> Але якщо Ви зберігаєте товар, що продається у себе вдома або в магазині і Вам важливо знати скільки і якого товару у Вас залишилося - Не вимикайте! </p>
             </span>
            )
    },
    "Contacts": {
        ru: "Контакты",
        en: "Contacts",
        ua: "Контакти"
    },
    "subscribe-success": {
        ru: "Подписка успешно оплачена!",
        en: "Subscription was successfully paid!",
        ua: "Підписка успішно оплачена!"
    },
    "subscribe-success-check": {
        ru: "Идет обработка Вашего платежа",
        en: "Your payment is being processed",
        ua: "Йде обробка Вашого платежу"
    },
    "subscribe-failed": {
        ru: "Что-то пошло не так. Неудачная оплата :(",
        en: "Something went wrong. Unsuccessful payment :(",
        ua: "Щось пішло не так. Невдала оплата :("
    },
    "create-new-product": {
        ru: "Создать новый товар",
        en: "Create new product",
        ua: "Створити новий товар"
    },
    "select-product": {
        ru: "Товар/артикул/бренд/штрих-код",
        en: "The product name/SKU/brand name",
        ua: "Товар/артикул/бренд"
    },
    "contact-text": {
        ru: "Если у Вас возникли какие-то сложности, Вы обнаружили какие-то ошибки или хотите предложить улучшения, пишите нам в онлайн чат или мессенджер в любое время! Мы постараемся максимально быстро разобраться в любой ситуации!",
        en: "If you have any difficulties, you have found any errors or want to suggest improvements, write to us in the online chat at any time! We will try to sort out any situation as quickly as possible!",
        ua: "Якщо у Вас виникли якісь складнощі, Ви виявили якісь помилки або маєте пропозиції щодо вдосконалення, пишіть нам в онлайн чат в будь-який час! Ми постараємося максимально швидко розібратися в будь-якій ситуації!"
    },
    "unhandled_error": {
        ru: "Упсс.. Неизвестная ошибка :( Обратитесь, пожалуйста, к поддержке через Telegram, Viber",
        en: "Oops .. Unknown error :( Please contact support via Online chat",
        ua: "Упсс... Невідома помилка :( Зверніться, будь ласка, до підтримки через Telegram, Viber"
    },

    "internal-service-error": {
        ru: "Упсс.. Неизвестная ошибка :( Обратитесь, пожалуйста, к поддержке через Telegram, Viber",
        en: "Oops .. Unknown error :( Please contact support via Online chat",
        ua: "Упсс... Невідома помилка :( Зверніться, будь ласка, до підтримки через Telegram, Viber"
    },
    "getting_partners_error": {
        ru: "Упсс.. Произошла ошибка при загрузке списка привлеченных пользователей :( Обратитесь, пожалуйста, к поддержке через Telegram, Viber",
        en: "Oops .. An error occurred while loading the list of referred users :( Please contact support via Online chat",
        ua: "Упсс... Виникла помилка при завантаженні списку залучених користувачів :( Зверніться, будь ласка, до підтримки через Telegram, Viber"
    },
    "HTTP 400: Bad Request (no arguments)": {
        ru: "Не заполнены все обязательные поля",
        en: "All required fields are not filled",
        ua: "Не заповнені всі обов'язкові поля"
    },
    "product-remove": {
        ru: "Товар удален",
        en: "Product Deleted",
        ua: "Товар видалений"
    },
    "not-need-pay": {
        ru: "Не нужно оплачивать",
        en: "No need to pay",
        ua: "Не потрібно оплачувати"
    },
    "product-reports": {
        ru: "Отчет по товару",
        en: "Product report",
        ua: "Звіт по товару"
    },
    "are-you-sure-to-delete-this-catalog": {
        ru: "Вы уверены, что хотите удалить эту запись? Если она добавлена к какому-то товару, то все привязки удалятся у всех товаров.",
        en: "Are you sure you want to delete this entry? If it is added to some product, then all links will be deleted for all products.",
        ua: "Ви впевнені, що хочете видалити цей запис? Якщо він доданий до якогось товару, то всі прив'язки будуть видалені у всіх товарів."
    },
    "sales-product": {
        ru: "Продажи товара",
        en: "Product sales",
        ua: "Продажі товару"
    },
    "customer-purchases": {
        ru: "Покупки клиента",
        en: "Customer purchases",
        ua: "Покупки клієнта"
    },
    "delete-error": {
        ru: "Возникла ошибка при удалении. Обратитесь к тех.поддержки в Online-чат",
        en: "An error occurred while delete. Contact technical support in the Online chat",
        ua: "Виникла помилка при видаленні. Зверніться до техпідтримки в Online-чат"
    },
    "select-min-one-item": {
        ru: "Добавьте хотя бы один товар, который Вы ожидаете в этой поставке.",
        en: "Add at least one product that you expect in this shipment.",
        ua: "Додайте хоча б один товар, який Ви очікуєте в цій поставці."
    },
     "select-min-one-item-moving": {
        ru: "Добавьте хотя бы один товар, который Вы хотите переместить на другой склад\\магазин",
        en: "Add at least one product that you want to move to another warehouse\\shop",
        ua: "Додайте хоча б один товар, який Ви бажаєте перемістити на інший склад\\магазин"
    },
    "refund-success": {
        ru: "Возврат успешно оформлен",
        en: "Refund successfully issued",
        ua: "Повернення успішно оформлено"
    },
    "amount-refund": {
        ru: "Дополнительные траты на возврат",
        en: "Additional refund costs",
        ua: "Додаткові витрати на повернення"
    },
    "refund": {
        ru: "Возврат продажи",
        en: "Refund sale",
        ua: "Повернення продажу"
    },
    "refund-btn": {
        ru: "Оформить возврат",
        en: "Make a Refund",
        ua: "Оформити повернення"
    },
    "refund-account": {
        ru: "Деньги спишутся со счета",
        en: "The money will be debited from the account",
        ua: "Гроші спишуться з рахунку"
    },
    "amount-refund-desk": {
        ru: "Если Вам пришлось оплачивать возврат, укажите сумму. Например, оплату за доставку.",
        en: "If you had to pay a refund, indicate the amountю. For example, shipping charges.",
        ua: "Якщо Вам довелося оплачувати повернення, вкажіть суму. Наприклад, оплату за доставку."
    },
    "change-stock": {
        ru: "Вернуть товар на склад?",
        en: "Return the item to the warehouse?",
        ua: "Повернути товар на склад?"
    },
    "change-stock-button": {
        ru: "Добавить\\Списать",
        en: "Add\\Removal",
        ua: "Додати\\Списати"
    },
    "comment-description-placeholder": {
        ru: "Почему Вы делаете списание\\добавление?",
        en: "Why are you removal\\ addition?",
        ua: "Чому Ви робите списання \\ додавання?"
    },
    "on-amount": {
        ru: "На сумму",
        en: "For the amount",
        ua: "На суму"
    },
    "refund-debit-the-account": {
        ru: "Стоимость возврата списать со счета",
        en: "Debit the refund cost",
        ua: "Вартість повернення списати з рахунку"
    },
    "t-refund-fee": {
        ru: "Оплата стоимости возврата",
        en: "Payment of refund cost",
        ua: "Оплата вартості повернення"
    },
    "all-warehouse": {
        ru: "Все склады",
        en: "All warehouses",
        ua: "Всі склади"
    },
    "all-categories": {
        ru: "Все категории",
        en: "All categories",
        ua: "Всі категорії"
    },
     "all-shipment": {
        ru: "Все поставки",
        en: "All shipment",
        ua: "Всі поставки"
    },
    "moving": {
        ru: "Перемещение",
        en: "Moving",
        ua: "Переміщення"
    },

    "see-product-btn": {
        ru: "Просмотреть товары",
        en: "View Products",
        ua: "Переглянути товари"
    },
    "permission": {
        ru: "Доступы",
        en: "Permission",
        ua: "Доступи"
    },
    "viewing": {
        ru: "Просмотр",
        en: "Viewing",
        ua: "Перегляд"
    },
    "editing": {
        ru: "Внесение изменений",
        en: "Editing",
        ua: "Внесення змін"
    },
    "deleting": {
        ru: "Удаление",
        en: "Deleting",
        ua: "Видалення"
    },
    "users": {
        ru: "Пользователи",
        en: "Users",
        ua: "Користувачі"
    },
    "create-new-user": {
        ru: "Добавить пользователя",
        en: "Add User",
        ua: "Додати користувача"
    },
    "user-name": {
        ru: "Имя пользователя",
        en: "User Name",
        ua: "Ім'я користувача"
    },
    "name-placeholder": {
        ru: "Введите имя пользователя",
        en: "Enter Username",
        ua: "Введіть ім'я користувача"
    },
    "section": {
        ru: "Разделы",
        en: "Sections",
        ua: "Розділи"
    },

    "rawcost": {
        ru: "Редактирование себестоимости",
        en: "Editing the cost price",
        ua: "Редагування собівартості"
    },
    "editinstock": {
        ru: "Изменение  остатков товаров",
        en: "Change in stock",
        ua: "Зміна залишків товарів"
    },
    "editproducts": {
        ru: "Изменение товара и цены продажи",
        en: "Changing the item and selling price",
        ua: "Зміна товару та ціни продажу"
    },
    "incomeinformation": {
        ru: "Инф. о доходах",
        en: "Income information",
        ua: "Інф. про доходи"
    },
    "deletesales": {
        ru: "Удаление продажи",
        en: "Delete sale",
        ua: "Видалення продажу"
    },
    "deleteproducts": {
        ru: "Удаление товара",
        en: "Delete product",
        ua: "Видалення товару"
    },
    "deleteshipments": {
        ru: "Удаление поставки",
        en: "Delete shipment",
        ua: "Видалення поставки"
    },
    "onlyview": {
        ru: "Только просмотр",
        en: "View Only",
        ua: "Тільки перегляд"
    },
    "main-user": {
        ru: "Администратор",
        en: "Administrator",
        ua: "Адміністратор"
    },
    "cashier": {
        ru: "Кассир",
        en: "Cashier",
        ua: "Касир"
    },
    "guest": {
        ru: "Гость",
        en: "Guest",
        ua: "Гість"
    },
    "view-only": {
        ru: "Только просмотр",
        en: "View only",
        ua: "Лише перегляд"
    },
    "_manager": {
        ru: "Менеджер",
        en: "Manager",
        ua: "Менеджер"
    },
    "manager": {
        ru: "Менеджер\\Кассир",
        en: "Manager\\Cashier",
        ua: "Менеджер\\Касир"
    },
    "warehouseman": {
        ru: "Складовщик",
        en: "Warehouseman",
        ua: "Складовщик"
    },
    "inspector": {
        ru: "Инспектор",
        en: "Inspector",
        ua: "Инспектор"
    },
    "user-group": {
        ru: "Группа",
        en: "Group",
        ua: "Група"
    },
    "edit-btn": {
        ru: "Редактировать",
        en: "Edit",
        ua: "Редагувати"
    },
    "edit-user": {
        ru: "Редактировать пользователя",
        en: "Edit user",
        ua: "Редагувати користувача"
    },
    "who-add-sale": {
        ru: "Продавец",
        en: "Seller",
        ua: "Продавець"
    },
    "access-denied": {
        ru: "Доступ к разделу запрещен",
        en: "Section access denied",
        ua: "У доступі до розділу відмовлено"
    },
    "main_sale": {
        ru: "Продажа",
        en: "Sale",
        ua: "Продаж"
    },
    "reserve_sale_module": {
        ru: "Резерв",
        en: "Reserve",
        ua: "Резерв"
    },
    "print_slip": {
        ru: "Печать товарного чека",
        en: "Printing a sales receipt",
        ua: "Друк товарного чека"
    },
    "product_slip": {
        ru: "Товарный чек",
        en: "Sales receipt",
        ua: "Товарний чек"
    },
    "slip": {
        ru: "Печать товарного чека",
        en: "Printing a sales receipt",
        ua: "Друк товарного чека"
    },
    "surrender": {
        ru: "Сдача",
        en: "Letting",
        ua: "Решта"
    },
    "bad-prapaid-amount": {
        ru: "У Вас сумма част. оплаты больше стоимости товара. Остаток не может быть отрицательным",
        en: "Your partial payment amount is more than the cost of the goods. Remainder cannot be negative",
        ua: "У Вас сума Част. оплати більше вартості товару. Залишок не може бути негативним"
    },
    "fix": {
        ru: "Фикс.",
        en: "Fixed",
        ua: "Фікс."
    },
    "prepaid_amount": {
        ru: "Сумма част. оплаты",
        en: "Prepayment Amount",
        ua: "Сума част. оплати"
    },
    "need-balance": {
        ru: "Остаток",
        en: "Balance",
        ua: "Залишок"
    },
    "reserv": {
        ru: "Зарезервировано",
        en: "Reserved",
        ua: "Зарезервовано"
    },
    "reserve_sale": {
        ru: "Резерв",
        en: "Reserve",
        ua: "Резерв"
    },
    "delivering": {
        ru: "Доставляется",
        en: "On delivery",
        ua: "Доставляється"
    },
    "on-delivering": {
        ru: "Доставляется",
        en: "On delivery",
        ua: "Доставляється"
    },
    "reserve_sale_long": {
        ru: "Зарезервировано",
        en: "Reserved",
        ua: "Зарезервовано"
    },
    "prepaid": {
        ru: "Част. оплата",
        en: "Partial payment",
        ua: "Част. оплата"
    },
    "finish-reserv": {
        ru: "Завершить",
        en: "To complete",
        ua: "Завершити"
    },
    "finish-reserv-title": {
        ru: "Завершить резерв",
        en: "End reservation",
        ua: "Завершити резерв"
    },
    "remove-reserv": {
        ru: "Снять резерв",
        en: "Remove reserve",
        ua: "Зняти резерв"
    },
    "reserv-sale": {
        ru: "Резервирование товара",
        en: "Item reservation",
        ua: "Резервування товару"
    },
    "reserv-button-btn": {
        ru: "Поставить в резерв",
        en: "Put in reserve",
        ua: "Поставити в резерв"
    },
    "reserv-success": {
        ru: "Резерв успешно завершен",
        en: "Reserve completed successfully",
        ua: "Резерв успішно завершено"
    },
    "sales-success-delete": {
        ru: "Продажа успешно удалена",
        en: "Sale deleted successfully",
        ua: "Продаж успішно видалений"
    },
    "reserv-success-delete": {
        ru: "Резерв успешно удален",
        en: "The reservation deleted successfully",
        ua: "Резерв успішно видалений"
    },
    "customer": {
        ru: "Покупатель",
        en: "Customer",
        ua: "Покупець"
    },
    "cancel-reserv-success": {
        ru: "Резерв успешно снят. Част. оплата осталась на Вашем счете.",
        en: "The reservation has been successfully canceled. The partial payment has remained in your account.",
        ua: "Резерв успішно знята. Част. оплата залишилася на Вашому рахунку."
    },
    "post-office-number": {
        ru: "Номер отделения",
        en: "Post Office Number",
        ua: "Номер відділення"
    },
    "add-delivery-btn": {
        ru: "Добавить службу доставки",
        en: "Add delivery service",
        ua: "Додати службу доставки"
    },
    "add-delivery-address-btn": {
        ru: "Добавить адрес доставки",
        en: "Add delivery address",
        ua: "Додати адресу доставки"
    },
    "select-the-delivery-address": {
        ru: "Выберите адрес доставки",
        en: "Select the delivery address",
        ua: "Оберіть адресу доставки",
    },

    "add-sender": {
        ru: "Добавить отправителя",
        en: "Add sender",
        ua: "Додати відправника"
    },
    "back-to-standard": {
        ru: "Вернутся к списку",
        en: "Back to list",
        ua: "Повернутися до списку",
    },
    "select-delivery-address": {
        ru: "Выберите адрес доставки или введите трекинг-номер",
        en: "Select delivery address or enter tracking number",
        ua: "Виберіть адресу доставки або введіть трекінг-номер"
    },
    "delivery-address": {
        ru: "Доставки",
        en: "Delivery",
        ua: "Доставки"
    },
    "select-post-office-number": {
        ru: "Выберите отделение",
        en: "Select postoffice",
        ua: "Виберіть відділення"
    },
    "not-select-post-office": {
        ru: "Не выбрано отделение",
        en: "Not select postoffice",
        ua: "Не вибранне відділення"
    },
    "process-delivery-title": {
        ru: "Работа над доставкой",
        en: "Delivery work",
        ua: "Робота над доставкою"
    },
    "tracking-number": {
        ru: "Трекинг номер (ТТН)",
        en: "Tracking number (invoice)",
        ua: "Трекінг номер (ТТН)"
    },
    "ttn": {
        ru: "ТТН",
        en: "Tracking Number",
        ua: "ТТН"
    },
    "input-tracking-number": {
        ru: "Введите трекинг номер (ТТН)",
        en: "Enter tracking number (invoice)",
        ua: "Введіть трекінг номер (ТТН)"
    },
    "to-send": {
        ru: "Отправить/Завершить",
        en: "Send parcel/Finish",
        ua: "Відправити/Завершити"
    },
    "send-parcel": {
        ru: "Отправить посылку",
        en: "Send parcel",
        ua: "Відправити посилку"
    },
    "send-parcels": {
        ru: "Отправить посылки",
        en: "Send parcels",
        ua: "Відправити посилки"
    },
    "finish-parcel": {
        ru: "Завершить",
        en: "Finish",
        ua: "Завершити"
    },
    "not-correct-tracking-number": {
        ru: "Неверный трекинг номер",
        en: "Invalid tracking number",
        ua: "Невірний трекінг номер"
    },
    "are-you-sure-to-finish-this-parsel": {
        ru: "Посылка уже доставлена клиенту?",
        en: "Has the package already been delivered to the customer?",
        ua: "Посилка вже доставлена клієнту?"
    },
    "parsel-delivered": {
        ru: "Посылка доставлена",
        en: "Parcel delivered",
        ua: "Посилка доставлена"
    },
    "no-delivery": {
        ru: "Еще не доставлена",
        en: "Not delivered yet",
        ua: "Ще не доставлена"
    },
    "confirm-finish": {
        ru: "Завершить доставку",
        en: "Complete delivery",
        ua: "Завершити доставку"
    },
    "pick-up-transfer": {
        ru: "Забрать наложку",
        en: "Pick up C.O.D.",
        ua: "Забрати наложку"
    },
    "parsel-success-finish": {
        ru: "Посылка успешно переведена в статус Доставлена",
        en: "The parcel has been successfully transferred to the Delivered status",
        ua: "Посилка успішно переведена в статус Доставлена"
    },
    "np_key-placeholder": {
        ru: "Введите ключ с Новой Почты",
        en: "Enter the key from Nova Poshta",
        ua: "Введіть ключ з Нової Пошти"
    },
    "tin-placeholder": {
        ru: "Введите ЕДРПОУ/ИНН вашой организации",
        en: "Enter EDRPOU/RNOKPP of your organization",
        ua: "Введіть ЄДРПОУ/РНОКПП вашої організації"
    },
    "tin": {
        ru: "ЕДРПОУ/ИНН",
        en: "TIN",
        ua: "ЄДРПОУ/РНОКПП"
    },
    "up_key-placeholder": {
        ru: "Введите ключ с Укрпочты",
        en: "Enter the key from Ukrposhta",
        ua: "Введіть ключ з Укрпошти"
    },
    "up_token-placeholder": {
        ru: "Введите токен с Укрпочты",
        en: "Enter the token from Ukrposhta",
        ua: "Введіть токен з Укрпошти"
    },
    "where-i-can-get-np": {
        ru: "Где мне взять ключ к API Новой Почты?",
        en: "Where can I get the key to the  Nova Poshta API?",
        ua: "Як я можу отримати ключ до API Нової Пошти?"
    },
    "where-i-can-get-ch": {
        ru: "Как мне подключится к Checkbox?",
        en: "How can I connect to Checkbox?",
        ua: "Як мені підключитися до Checkbox?"
    },
    "integration-success-updated": {
        ru: "Интеграции успешно обновлены",
        en: "Integrations have been successfully updated",
        ua: "Інтеграції успішно оновлені"
    },
    "active-integration-list": {
        ru: "Список активних интеграций",
        en: "Active integrations list",
        ua: "Список активних інтеграцій"
    },
    "list-products-integration": {
        ru: "Список товаров интеграции",
        en: "List of integration products",
        ua: "Список товарів інтеграції"
    },
    "integration-disabled": {
        ru: "Интеграция из-за ошибок отключена",
        en: "Integration disabled due to errors",
        ua: "Інтеграція через помилки вимкнена"
    },
    "The website is not available or has errors. Integration is disabled": {
        ru: "Сайт недоступен или содержит ошибки. Интеграция отключена",
        en: "The website is not available or has errors. Integration is disabled",
        ua: "Веб-сайт недоступний або містить помилки. Інтеграція вимкнена"
    },
    "how-start": {
        ru: "Как начать?",
        en: "How to start?",
        ua: "Як почати?"
    },
    "video-instruction": {
        ru: "Видео-инструкции",
        en: "Video instructions",
        ua: "Відео-інструкції"
    },
    "add-sale": {
        ru: "Добавление товара",
        en: "Adding a product",
        ua: "Додавання товару"
    },
    "add-delivery-sale": {
        ru: "Добавление товара с доставкой",
        en: "Adding items with delivery",
        ua: "Додавання товару з доставкою"
    },
    "clients-count": {
        ru: "Клиентов",
        en: "Clients",
        ua: "Клієнтів"
    },
    "clients-total": {
        ru: "Купили на",
        en: "Bought on",
        ua: "Купили на"
    },
    "clients-average": {
        ru: "Средний чек",
        en: "Average check",
        ua: "Середній чек"
    },
    "disposal-products": {
        ru: "Списание товара",
        en: "Disposal Items",
        ua: "Списання товару"
    },
    "disposal": {
        ru: "Списано",
        en: "Disposal",
        ua: "Списано"
    },
    "it-was": {
        ru: "Было",
        en: "It was",
        ua: "Було"
    },
    "result": {
        ru: "Стало",
        en: "Result",
        ua: "Стало"
    },
    "not-have-removal": {
        ru: "Не было списаний",
        en: "Haven't removal",
        ua: "Не було списань"
    },
    "not-have-added": {
        ru: "Не было добавлений",
        en: "Haven't added",
        ua: "Не було добавлень"
    },
    "added-product": {
        ru: "Добавлено товара",
        en: "Added product",
        ua: "Додано товару"
    },
    "change-prooducts-stock-add": {
        ru: {
            plural: "Добавить {count} единиц{count, plural, =1{у} =2{ы} =3{ы}  =4{ы} other{}}"
        },
        en: {
            plural: "Add {count} items"
        },
        ua: {
            plural: "Додати {count} одиниц{count, plural, =1{ю} =2{і} =3{і}  =4{і} other{ь}}"
        },
    },
    "change-prooducts-stock-removal": {
        ru: {
            plural: "Списать {count} единиц{count, plural, =1{у} =2{ы} =3{ы}  =4{ы} other{}}"
        },
        en: {
            plural: "Removal {count} items"
        },
        ua: {
            plural: "Списати {count} одиниц{count, plural, =1{ю} =2{і} =3{і}  =4{і} other{ь}}"
        },
    },
    "is-variable-product": {
        ru: "Вариативный (цвет, размер)",
        en: "Variable (color, size)",
        ua: "Варіативний (колір, розмір)"
    },
    "add-attr": {
        ru: "Добавьте атрибуты для вариаций",
        en: "Add attributes for variations",
        ua: "Додайте атрибути для варіацій"
    },
    "attr": {
        ru: "Атрибуты",
        en: "Attributes",
        ua: "Атрибути"
    },
    "add-attr-placeholder": {
        ru: "Введите имя атрибута",
        en: "Enter the name of the attribute",
        ua: "Введіть ім'я атрибута"
    },
    "attr-name": {
        ru: "Имя атрибута",
        en: "Attribute name",
        ua: "Ім'я атрибута"
    },
    "attr-list": {
        ru: "перечень атрибутов",
        en: "attribute list",
        ua: "перелік атрибутів"
    },
    "not-have-attr": {
        ru: "У вас нет атрибутов",
        en: "You have no attributes",
        ua: "У вас немає атрибутів"
    },
    "add-variable": {
        ru: "Добавить вариант товара",
        en: "Add Product Variant",
        ua: "Додати варіант товару"
    },
    "t-color": {
        ru: "Цвет",
        en: "Color",
        ua: "Колір"
    },
    "t-size": {
        ru: "Размер",
        en: "Size",
        ua: "Розмір"
    },
    "variant": {
        ru: "Вариант",
        en: "Variant",
        ua: "Варіант"
    },
    "show-variant": {
        ru: "Просмотреть варианты",
        en: "Show Variant",
        ua: "Дивитись варіанти"
    },
    "hide-variant": {
        ru: "Спрятать варианты",
        en: "Hide Variant",
        ua: "Сховати варіанти"
    },
    "total-amount-expenses": {
        ru: "Сумма расходов по счетам",
        en: "The amount of expenses on accounts",
        ua: "Сума витрат по рахунках"
    },
    "total-amount-receipt": {
        ru: "Сумма поступлений по счетам",
        en: "The amount of receipts on accounts",
        ua: "Сума надходжень по рахунках"
    },
    "add-to-warehouse": {
        ru: "Добавить на другой склад",
        en: "Add on other warehouse",
        ua: "Додати до іншого складу"
    },
    "print-slip": {
        ru: "Распечатать чек",
        en: "Print receipt",
        ua: "Роздрукувати чек"
    },
    "open-print-slip": {
        ru: "Открыть чек",
        en: "Open receipt",
        ua: "Відкрити чек"
    },
    "fiscal-slip": {
        ru: "Фискальный чек",
        en: "Fiscal receipt",
        ua: "Фіскальний чек"
    },
    "print-fiscal-slip": {
        ru: "Распечатать фискальный чек",
        en: "Print fiscal receipt",
        ua: "Роздрукувати фіскальний чек"
    },
    "check-name": {
        ru: "Заголовок чека",
        en: "Check name",
        ua: "Назва чека"
    },
    "company-info": {
        ru: "Информация о Вашем преприятии",
        en: "Information about your company",
        ua: "Інформація про Ваше підприємство"
    },
    "data-saved": {
        ru: "Данные сохранены",
        en: "Data saved",
        ua: "Дані збережені"
    },
    "error-enter-attr": {
        ru: "Вы не заполнили данные атрибутов",
        en: "You did not fill in the attribute data",
        ua: "Ви не заповнили дані атрибутів"
    },
    "refund-item": {
        ru: "Возврат товара",
        en: "Refund item",
        ua: "Повернення товару"
    },
    "item-refunded": {
        ru: "Товар возвращен клиентом",
        en: "Item returned by customer",
        ua: "Товар повернув клієнт"
    },
    "showbalance": {
        ru: "Просмотр баланса",
        en: "View Balance",
        ua: "Перегляд балансу"
    },
    "telegram-owner": {
        ru: "Telegram-канал",
        en: "Telegram Channel",
        ua: "Telegram-канал"
    },
    "display-type": {
        ru: "Отображение",
        en: "Display Type",
        ua: "Відображення"
    },
    "lines": {
        ru: "Линии",
        en: "Lines",
        ua: "Лінії"
    },
    "bars": {
        ru: "Столбцы",
        en: "Bars",
        ua: "Стовпці"
    },
    "combain": {
        ru: "Комбинированный",
        en: "Combined",
        ua: "Комбінований"
    },
    "grouping": {
        ru: "Группировать",
        en: "Group",
        ua: "Групувати"
    },
    "days": {
        ru: "Дни",
        en: "Days",
        ua: "Дні"
    },
    "weeks": {
        ru: "Недели",
        en: "Weeks",
        ua: "Тижні"
    },
    "months": {
        ru: "Месяца",
        en: "Of the month",
        ua: "Місяці"
    },
    "half-year": {
        ru: "Полгода",
        en: "Half a year",
        ua: "Півроку"
    },
    "years": {
        ru: "Года",
        en: "Of the year",
        ua: "Роки"
    },
    "f-year": {
        ru: "Год",
        en: "Year",
        ua: "Рік"
    },
    "all-period": {
        ru: "Весь период",
        en: "All period",
        ua: "Весь період"
    },
    "sales-all": {
        ru: "Общее",
        en: "General",
        ua: "Загальна"
    },
    "report_result": {
        ru: "Результат",
        en: "Result",
        ua: "Результат"
    },
    "inventory-result": {
        ru: "Недостача\\Излишек",
        en: "Shortage\\Surplus",
        ua: "Недолік\\Надлишок"
    },
    "profit-percentage": {
        ru: "Процент прибыли",
        en: "Profit Percentage",
        ua: "Відсоток прибутку"
    },
    "now-netcost": {
        ru: "Актив. себест.",
        en: "Active Cost",
        ua: "Активна собів."
    },
    "netcost-short": {
        ru: "Cебест.",
        en: "Cost",
        ua: "Cобів."
    },
    "welcome": {
        ru: {plural:"Добро пожаловать в {name} - простой сервис для управления вашим бизнесом!"},
        en: {plural:"Welcome to {name} - a simple service for managing your business!"},
        ua: {plural:"Ласкаво просимо в {name} - простий сервіс для управління вашим бізнесом!"}
    },
    "several-tips": {
        ru: "Несколько подсказок с чего начать работу",
        en: "A few tips on how to get started",
        ua: "Декілька підказок з чого почати роботу"
    },
    "how-to-start": {
        ru: "С чего начать",
        en: "Where to Begin",
        ua: "З чого почати"
    },
    "tips-one": {
        ru: "Добавьте ваши счета",
        en: "Add Your Accounts",
        ua: "Додайте ваші рахунки"
    },
    "tips-one-desk": {
        ru: "Клиенты вам оплачивают на карту, наличными или банковским переводом? Деньги хранятся у вас и у вашего партнера? Добавьте все ваши счета, чтобы контролировать денежный поток.",
        en: "Do your customers pay by card, cash, non-cash? Is the money kept by you and your partner? Add all your accounts to keep control of your cash flow.",
        ua: "Клієнти вам оплачують на карту, готівкою, банківським переказом? Гроші зберігаються у вас і у вашого партнера? Додайте всі ваші рахунки, щоб контролювати грошові потоки."
    },
    "tips-second": {
        ru: "Добавьте товары",
        en: "Add Products",
        ua: "Додайте товари"
    },
    "tips-second-desk": {
        ru: "Вы можете импортировать товары, которые  продаете в формате Excel, или добавить их вручную. ",
        en: "You can import the products wich you sell in Excel format or add them manually",
        ua: "Ви можете імпортувати товари, які ви продаєте в форматі Excel або додавати їх вручну"
    },
    "tips-tree": {
        ru: "Добавьте склады\\Магазины",
        en: "Add Warehouses\\Shops",
        ua: "Додайте склади\\Крамниці"
    },
    "tips-tree-desk": {
        ru: "У вас товар хранится на разных складах и в разных магазинах, на балконе, в гараже, у лучшего друга Сережи? Добавьте все места хранения товара, и вы всегда будете знать, где, что и сколько у вас хранится. ",
        en: "Do you have your goods stored in different warehouses, in different stores, on the balcony, in the garage, at John? Add all the storage locations of the goods and you will always know where and what you have stored.",
        ua: "У вас товар зберігається на різних складах, в різних магазинах, на балконі, в гаражі, у Сергія? Додайте всі місця зберігання товару і ви завжди будете знати, де, що і скільки у вас зберігається."
    },
    "tips-four": {
        ru: "Добавляйте продажи",
        en: "Add orders",
        ua: "Додавайте продажі"
    },
    "tips-four-desk": {
        ru: "Добавляйте продажи с помощью сканера штрих-кодов, по названию или артикулу. Резервируйте товар для покупателей. Оформляйте отправки через службу доставки с наложенным платежом и без.",
        en: "Add sales with a barcode scanner, by name or SKU. Book items for buyers. Arrange your shipments using prepaid and non-prepaid orders.",
        ua: "Додавайте продажі за допомогою сканера штрих-кодів, за назвою або артикулом. Резервуйте товар для покупців. Оформляйте відправки через службу доставки з наложкой і без."
    },
    "tips-five": {
        ru: "Добавляйте платежи",
        en: "Add Payments",
        ua: "Додавайте платежі"
    },
    "tips-five-desk": {
        ru: "Фиксируйте все ваши платежи с разных счетов, контролируйте все расходы по категориям: Зарплата, Аренда, Коммунальные, Доставка. Создавайте свои категории. Добавляйте все поступления денег, не связанные с продажами.",
        en: "Add all your payments from different accounts, control all expenses by categories: Salaries, Rent, Utilities, Delivery, etc. Create your own categories. Add all receipts of money not related to sales!",
        ua: "Фіксуйте всі ваші платежі з різних рахунків, контролюйте всі витрати за категоріями: Зарплата, Оренда, Комунальні, Доставка. Створюйте свої категорії. Додавайте все надходження грошей не пов'язані з продажами"
    },
    "tips-six": {
        ru: "Смотрите отчеты",
        en: "See Reports",
        ua: "Дивіться звіти"
    },
    "tips-six-desk": {
        ru: "Держите руку на пульсе! Смотрите отчеты о прибыльности вашей компании. Следите за расходами, поступлениями и самыми прибыльными товарами. Вовремя заказывайте новые товары.",
        en: "Keep your finger on the pulse! See your company's profitability reports. Track expenses, receipts and top-grossing items. Order new items in time.",
        ua: "Тримайте руку на пульсі! Дивіться звіти про прибутковість вашої компанії. Слідкуйте за витратами, надходженнями і найприбутковішими товарами. Вчасно замовляйте нові товари."
    },
    "help-and-support": {
        ru: "Помощь и поддержка",
        en: "Help and Support",
        ua: "Допомога і підтримка"
    },
    "telegram-help": {
        ru: "Чтобы быть в курсе последних обновлений, подписывайтесь на Telegram-канал",
        en: "To keep abreast of the latest updates, subscribe to the Telegram channel",
        ua: "Щоб бути в курсі останніх оновлень, підписуйтесь на Telegram-канал"
    },
    "telegram-channel": {
        ru: "Telegram-канал",
        en: "Telegram channel",
        ua: "Telegram-канал"
    },
    "youtube-channel": {
        ru: "Смотреть инструкции",
        en: "View instructions",
        ua: "Переглянути інструкції"
    },
    "documentation": {
        ru: "Документация",
        en: "Documentation",
        ua: "Документація"
    },
    "documentation-link": {
        ru: "Перейти к документации",
        en: "Go to documentation",
        ua: "Перейти до документації"
    },
    "faq-link": {
        ru: "Перейти в FAQ",
        en: "Go to FAQ",
        ua: "Перейти в FAQ"
    },
    "_soon": {
        ru: "Скоро",
        en: "Soon",
        ua: "Скоро"
    },
    "documentation-desk": {
        ru: {plural: "Подробное описание функционала сервиса {name}"},
        en: {plural: "Detailed description of the functionality of the {name} service"},
        ua: {plural: "Детальне опис функціоналу сервісу {name}"}
    },
    "faq-desk": {
        ru: "Подборка ответов на часто задаваемые вопросы",
        en: "A selection of answers to frequently asked questions",
        ua: "Підбірка відповідей на часто задавані питання"
    },
    "video-help-desk": {
        ru: "Наглядные видео о том, как пользоваться сервисом: Как добавлять товары, оформлять поставки, добавлять продажи и т.д.",
        en: "Visual videos on how to use service: How to add products, check out deliveries, add sales, etc.",
        ua: "Наочні відео про те, як користуватися сервісом: Як додавати товари, оформляти поставки, додавати продажі та інше"
    },
    "help-and-support-desk": {
        ru: "Если у вас возникла какая-то сложность, что-то не понятно или у вас есть предложение, сразу пишите нам:",
        en: "If you have any difficulty, something is not clear or you have a suggestion, please write to us right away:",
        ua: "Якщо у вас виникла якась складність, щось не зрозуміло або у вас є пропозиція, відразу пишіть нам:"
    },

    "clear-account": {
        ru: "Стереть все данные",
        en: "Erase all data",
        ua: "Стерти всі дані"
    },
    "are-you-sure-to-clear-account": {
        ru: "Вы точно хотите стереть все данные с вашего аккаунта?",
        en: "Are you sure you want to erase all data from your account?",
        ua: "Ви точно хочете стерти всі дані з вашого облікового запису?"
    },
    "are-you-sure-to-clear-account-desk": {
        ru: "Это действие необратимо. Если вы сотрете все данные, то исчезнут все ваши товары, продажи, клиенты, расходы. Ваш аккаунт будет чист, как после регистрации.",
        en: "This action is irreversible. If you erase all data, then all your products, sales, customers, expenses will disappear. Your account will be clean as after registration.",
        ua: "Ця дія є незворотня. Якщо ви зітрете всі дані, то зникнуть всі ваші товари, продажі, клієнти, витрати. Ваш аккаунт буде чистий, як після реєстрації."
    },
    "are-you-sure-cancel the order": {
        ru: "Вы уверены, что хотите отменить заказ?",
        en: "Are you sure you want to cancel the order?",
        ua: "Ви впевнені, що хочете скасувати замовлення?"
    },
    "see-video": {
        ru: "Посмотреть видео",
        en: "Watch the video",
        ua: "Подивитись відео"
    },
    "change-warehouse": {
        ru: "Изм.\\доб. склад",
        en: "Mod.\\add warehouse",
        ua: "Змін.\\додати склад"
    },
    "bonus": {
        ru: "Бонус",
        en: "Bonus",
        ua: "Бонус"
    },
    "get-bonus": {
        ru: "Получить бонус",
        en: "Get Bonus",
        ua: "Отримати Бонус"
    },
    "your-bonus": {
        ru: "Ваш бонус",
        en: "Your Bonus",
        ua: "Ваш Бонус"
    },
    "FREE": {
        ru: "БЕСПЛАТНО",
        en: "FREE",
        ua: "БЕЗКОШТОВНО"
    },
    "_days": {
        ru: "дней",
        en: "days",
        ua: "днів"
    },
    "start_subscribe": {
        ru: "Дата первого списания за подписку",
        en: "Subscription start date",
        ua: "Дата першого списання за передплату"
    },
    "subscribe-bonus-days": {
        ru: {
            plural: "У вас {bonus_days} бонусных дней! После оформления подписки первая оплата будет снята только: {start_date}"
            },
        en: {
            plural: "You have {bonus_days} bonus days! After subscribing, the first payment will be charged only on: {start_date}"
            },
        ua: {
            plural: "У вас {bonus_days} бонусних днів! Після оформлення передплати перша оплата буде знята тільки: {start_date}"
            },
    },
    "next_subscribe": {
        ru: "Дата следующего списания",
        en: "Date of next withdrawal of funds",
        ua: "Дата наступного списання"
    },
    "left_sales": {
        ru: "В этом месяце Вы еще можете добавить",
        en: "This month you can still add",
        ua: "У цьому місяці Ви ще можете додати"
    },
    "next_subscribe_desc": {
        ru: "Не использованные средства за предыдущий тариф возвращены не будут. Новый тариф активируется с сегодняшнего дня и будет продлен автоматически в указанную дату",
        en: "Funds not used for the previous tariff will not be refunded. The new tariff is activated from today and will be renewed automatically on the specified date",
        ua: "Не використані кошти за попередній тариф повернуті не будуть. Новий тариф активується з сьогоднішнього дня і буде продовжений автоматично в зазначену дату"
    },
    "you_can_add": {
        ru: "Вы можете добавить",
        en: "You can add",
        ua: "Ви можете додати"
    },
    "next_payment_date": {
        ru: "Дата следующей оплаты",
        en: "Next payment date",
        ua: "Дата наступної оплати"
    },
    "downgrade_tarif": {
        ru: "Для понижения тарифного плана обратитесь к поддержке через online-чат",
        en: "To downgrade your tariff plan, contact support via online chat",
        ua: "Для зниження тарифного плану зверніться до підтримки через online-чат"
    },
    "mobile-onboarding-text": {
        ru: "Выполни 5 шагов и получи бонус",
        en: "Follow 5 steps and get a bonus",
        ua: "Виконай 5 кроків і отримай бонус"
    },
    "step": {
        ru: "Шаг",
        en: "Step",
        ua: "Крок"
    },
    "cleaning": {
        ru: "Идет очистка аккаунта...",
        en: "Clearing your account ...",
        ua: "Йде очищення аккаунта ..."
    },
    "change-password": {
        ru: "Изменить пароль",
        en: "Change Password",
        ua: "Змінити пароль"
    },
    "old-password-placeholder": {
        ru: "Старый пароль",
        en: "Old Password",
        ua: "Старий пароль"
    },
    "new-password-placeholder": {
        ru: "Новый пароль",
        en: "New Password",
        ua: "Новий пароль"
    },
    "enter-passwrod-error": {
        ru: "Введите пароль",
        en: "Enter the Password",
        ua: "Введіть пароль"
    },
    "password-success-change": {
        ru: "Пароль успешно изменен",
        en: "Password changed successfully",
        ua: "Пароль успішно змінений"
    },
    "create-novaposhta-ttn": {
        ru: "Создать ТТН Новой Почты",
        en: "Create NovaPoshta shipment label",
        ua: "Створити ТТН Нової Пошти"
    },
    "post office": {
        ru: "Почта",
        en: "Post Office",
        ua: "Пошта"
    },
    "create-ukrposhta-ttn": {
        ru: "Создать ТТН Укрпочты",
        en: "Create Ukrposhta shipment label",
        ua: "Створити ТТН Укрпошти"
    },
    "novaposhta-has-problem": {
        ru: "Проблема на стороне НовойПочты. Попробуйте немного позже.",
        en: "The problem is on the side of NovaPoshta. Please try again later.",
        ua: "Проблема на боці Нової Пошти. Спробуйте трохи пізніше."
    },
    "add-ttn": {
        ru: "Добавить ТТН",
        en: "Add tracking number",
        ua: "Додати ТТН"
    },
    "count-place": {
        ru: "Количество мест",
        en: "Number of places",
        ua: "Кількість місць"
    },
    "length": {
        ru: "Длина",
        en: "Length",
        ua: "Довжина"
    },
    "width": {
        ru: "Ширина",
        en: "Width",
        ua: "Ширина"
    },
    "heigth": {
        ru: "Высота",
        en: "Heigth",
        ua: "Висота"
    },
    "weight": {
        ru: "Вес",
        en: "Weight",
        ua: "Вага"
    },
    "dimensions": {
        ru: "Габариты",
        en: "Dimensions",
        ua: "Габарити"
    },
    "finance": {
        ru: "Финансы",
        en: "Finance",
        ua: "Фінанси"
    },
    "why-paid": {
        ru: "Кто платит за доставку",
        en: "Who pays for delivery",
        ua: "Хто платить за доставку"
    },
    "sender": {
        ru: "Отправитель",
        en: "Sender",
        ua: "Відправник"
    },
    "recipient": {
        ru: "Получатель",
        en: "Recipient",
        ua: "Одержувач"
    },
    "grade": {
        ru: "Оценка",
        en: "Grade",
        ua: "Оцінка"
    },
    "remittance": {
        ru: "Денежный перевод",
        en: "Remittance",
        ua: "Грошовий переказ"
    },
    "new_sender": {
        ru: "Добавить еще отправителя",
        en: "Add another sender",
        ua: "Додати ще відправника"
    },
    "new-sender-label": {
        ru: "Новый отправитель",
        en: "New sender",
        ua: "Новий відправник"
    },
    "warning-sender": {
        ru: "Добавьте отправителя!",
        en: "Add the sender",
        ua: "Додайте відправника!"
    },
    "desc-parcel": {
        ru: "Описание отправки (50 символов)",
        en: "Description of sending (50 symbol)",
        ua: "Опис відправки (50 символів)"
    },
    "delivery-time": {
        ru: "Ориентировочная дата доставки",
        en: "Estimated delivery date",
        ua: "Орієнтовна дата доставки"
    },
    "ttn-success-created": {
        ru: "ТТН успешно создана!",
        en: "Shipment Label success created",
        ua: "ТТН успішно створена!"
    },
    "set-integration": {
        ru: "Настроить интеграцию",
        en: "Set up integration",
        ua: "Налаштувати інтеграцію"
    },
    "not-enable-np": {
        ru: "У Вас еще не настроена интеграция с Новой Почтой",
        en: "You have not yet configured integration with Nova Poshta",
        ua: "У Вас ще не налаштована інтеграція з Новою Поштою"
    },
    "not-enable-up": {
        ru: "У Вас еще не настроена интеграция с Укрпочтой",
        en: "You have not yet configured integration with Ukrposhta",
        ua: "У Вас ще не налаштована інтеграція з Укрпоштою"
    },
    "delivery_info": {
        ru: "Инф. для доставки/TTH",
        en: "Info/Tracking Number",
        ua: "Інф. для доставки/ТТН"
    },
    "delivery_info-desc": {
        ru: "Информация для доставки/TTH",
        en: "Shipping Information/Tracking Number",
        ua: "Інформація для доставки/ТТН"
    },
    "other": {
        ru: "Другие",
        en: "Other",
        ua: "Інші"
    },

    "toshort-name": {
        ru: "Должно быть больше двух символов",
        en: "Must be more than two characters",
        ua: "Має бути більше двох символів"
    },
    "select-category": {
        ru: "Выберите категорию ",
        en: "Select Category",
        ua: "Оберіть категорію"
    },
    "select-client": {
        ru: "Выберите клиента ",
        en: "Select clients",
        ua: "Оберіть клієнта"
    },
    "ch_login-placeholder": {
        ru: "Введите логин кассира для доступа в Checkbox",
        en: "Enter your login to access the Checkbox",
        ua: "Введіть логін касира для доступу до Checkbox"
    },
    "ch_pass-placeholder": {
        ru: "Введите пароль кассира для доступа в Checkbox",
        en: "Enter your password to access the Checkbox",
        ua: "Введіть пароль касира для доступу до Checkbox"
    },
    "cashier-login": {
        ru: "Логин кассира",
        en: "Cashier login",
        ua: "Логін касира"
    },
    "cashier-password": {
        ru: "Пароль кассира",
        en: "Cashier password",
        ua: "Пароль касира"
    },
    "cashdesk-licence": {
        ru: "Ключ лицензии кассы",
        en: "Cashdesk license key",
        ua: "Ключ ліцензії каси"
    },
    "cashdesk-licence-placeholder": {
        ru: "Введите ключ лицензии кассы",
        en: "Enter your сashdesk license key",
        ua: "Введіть ключ ліцензії каси"
    },
    "send-to-dps": {
        ru: "Фискализировать чек",
        en: "Fiscalize a check",
        ua: "Фіскалізувати чек"
    },
    'send-to-dps-prepaid': {
        ru: "Фискализировать част. оплату",
        en: "Fiscalize partial payment",
        ua: "Фіскалізувати част. оплату"
    },
    "send-to-dps-prepaid-heading": {
        ru: "Фискализация част. оплаты на сумму",
        en: "Fiscalization of prepayments in the amount of",
        ua: "Фіскалізація част. оплати на суму"
    },
    "send-to-dps-again": {
        ru: "Фискализировать чек еще раз",
        en: "Fiscalize a check again",
        ua: "Фіскалізувати чек ще раз"
    },
    "dps-check": {
        ru: "Фискальний чек",
        en: "Fiscal a check",
        ua: "Фіскальний чек"
    },
    "dps-check-prepaid": {
        ru: "Фискальный чек част. оплаты",
        en: "Prepayment fiscal check",
        ua: "Фіскальний чек част. оплати"
    },
    "auto-send-to-checkbox": {
        ru: "Автоматически отсылать все продажи в налоговую",
        en: "Automatically send all sales to the tax office",
        ua: "Автоматично надсилати всі продажі до податкової"
    },
    "fiscalization-on-delivery": {
        ru: "Фискализация наложенного платежа в момент получения посылки",
        en: "Fiscalization of cash on delivery at the moment of parcel receipt",
        ua: "Фіскалізація післяплати під час отримання посилки"
    },
    "sending-to-dps": {
        ru: "Отправляем информацию в налоговую...",
        en: "We send information to the tax ...",
        ua: "Відправляємо інформацію до податкової..."
    },

    "dps-send-success": {
        ru: "Продажа успешно отправлена в налоговую",
        en: "The sale was successfully sent to the tax office",
        ua: "Продаж успішно відправлено до податкової"
    },
    "dps-send-error": {
        ru: "Продажу не удалось фискализировать",
        en: "The sale failed to be fiscalized",
        ua: "Продаж не вдалося фіскалізувати"
    },
    "not-dps": {
        ru: "Не фискал.",
        en: "Not fiscal.",
        ua: "Не фіскал."
    },
    "partial-dps": {
        ru: "Част. фискал.",
        en:"Partial Fiscal.",
        ua: "Част. фіскал.",
    },
    "type-account": {
        ru: "Тип счета",
        en: "Type Account",
        ua: "Тип рахунку"
    },

    "need_fiscal_sales": {
        ru: "Нужно фискализировать (отсылать в налоговую) продажи",
        en: "It is necessary to fiscalize (send to the tax office) sales",
        ua: "Потрібно фіскалізувати (відсилати до податкової) продажі"
    },

    "not-empty-account": {
        ru: "На балансе еще есть деньги. Удалить можно пустой счет.",
        en: "There is still money on the balance sheet. You can delete an empty account.",
        ua: "На балансі є ще гроші. Видалити можна порожній рахунок."
    },

    "change-price-btn": {
        ru: "Изменить цену продажи",
        en: "Change Sale Price",
        ua: "Змінити ціну продажу"
    },
    "change-netcost-btn": {
        ru: "Изменить себестоимость",
        en: "Change cost of sales",
        ua: "Змінити собівартість"
    },
    "delete-from-warehouse": {
        ru: "Удалить со склада",
        en: "To remove from inventory/stock",
        ua: "Видалити зі складу"
    },

    "change-stock-btn": {
        ru: "Изменить остатки",
        en: "Change Stock",
        ua: "Змінити залишки"
    },

    "change-stock-btn-warning": {
        ru: "Старые остатки затрутся и всем выбранным товарам будут установлено новое ОДИНАКОВОЕ количество",
        en: "Old stocks will be overwritten and all selected products will be set to a new SAME quantity",
        ua: "Старі залишки затруться і всім обраним товарам буде встановлена нова ОДНАКОВА кількість"
    },

    "select-items": {
        ru: "Выбрано",
        en: "Selected",
        ua: "Вибрано"
    },
    "select-files": {
        ru: {
            "plural": "Выбрано {count} файл{count, plural, =1{} =2{а} =3{а} =4{а} other{ов}}"
        },
        en: {
            "plural": "Selected {count} file{count, plural, =1{} other{s}}"
        },
        ua: {
            "plural": "Обрано {count} файл{count, plural, =1{} =2{а} =3{а} =4{а} other{ів}}"
        }
    },

      "select-product-button": {
        ru: "Выбрать товары",
        en: "Select Products",
        ua: "Вибрати товари"
    },
    "mass-edit-price": {
        ru: "Массовое изменение цены продажи",
        en: "Mass change of the selling price",
        ua: "Масова зміна ціни продажу"
    },
    "mass-edit-price-type-label": {
        ru: "Выберете тип изменений",
        en: "Choose a type of changes",
        ua: "Оберіть тип змін"
    },
    "mass-edit-price-type-1": {
        ru: "Изменить на %",
        en: "Сhange by %",
        ua: "Змінити на %"
    },
    "mass-edit-price-type-2": {
        ru: "Изменить на определенную сумму",
        en: "Сhange by a certain amount",
        ua: "Змінити на певну суму"
    },
    "mass-edit-price-type-3": {
        ru: "Указать новую сумму",
        en: "Enter a new amount",
        ua: "Вказати нову суму"
    },
    "mass-edit-order-status": {
        ru: "Массовое изменение статусов продажи",
        en: "Mass change of sale statuses",
        ua: "Масова зміна статусів продажу"
    },
    "change-mass-product-price-percentage": {
        ru: "Уменьшить или увеличить цену на %",
        en: "Decrease or increase the price by %",
        ua: "Зменшити чи збільшити ціну на %"
    },
    "change-mass-product-netprice-percentage": {
        ru: "Уменьшить или увеличить себестоимость на %",
        en: "Reduce or increase cost of sales by %",
        ua: "Зменшити або збільшити собівартість на %"
    },
    "change-mass-product-price-percentage-help": {
        ru: "Например: -10 (уменьшить цену на 10%) или 10 (увеличить цену на 10%)",
        en: "For example: -10 (Decrease price by 10%) or 10 (increase price by 10%)",
        ua: "Наприклад: -10 (зменшити ціну на 10%) або 10 (збільшити ціну на 10%)"
    },
    "change-mass-product-netprice-percentage-help": {
        ru: "Например: -10 (уменьшить себест. на 10%) или 10 (увеличить себест. на 10%)",
        en: "For example: -10 (reduce cost by 10%) or 10 (increase cost by 10%)",
        ua: "Наприклад: -10 (зменшити собівартість на 10%) або 10 (збільшити собівартість на 10%)"
    },
    "change-mass-product-price-difference": {
        ru: "Уменьшить или увеличить цену на определенную суму",
        en: "Decrease or increase the price by a certain amount",
        ua: "Зменшити чи збільшити ціну на певну суму"
    },
    "change-mass-product-netprice-difference": {
        ru: "Уменьшить или увеличить себестоимость на определенную сумму",
        en: "Decrease or increase cost of sales by a certain amount",
        ua: "Зменшити або збільшити собівартість на певну суму"
    },

    "change-mass-product-price-difference-help": {
        ru: { plural: "Например: -10 (уменьшить цену на 10 {currency}) или 10 (увеличить цену на 10 {currency})" },
        en: { plural: "For example: -10 (decrease price by 10 {currency}) or 10 (increase price by 10 {currency})" },
        ua: { plural: "Наприклад: -10 (зменшити ціну на 10 {currency}) або 10 (збільшити ціну на 10 {currency})" }
    },
    "change-mass-product-netprice-difference-help": {
        ru: { plural: "Например: -10 (уменьшить себестоимость на 10 {currency}) или 10 (увеличить себестоимость на 10 {currency})" },
        en: { plural: "For example: -10 (decrease cost of production by 10 {currency}) or 10 (increase cost of production by 10 {currency})" },
        ua: { plural: "Наприклад: -10 (зменшити собівартість на 10 {currency}) або 10 (збільшити собівартість на 10 {currency})" }
    },
    "change-mass-product-price-exact": {
        ru: "Установить новую цену в фиксированном размере",
        en: "Set a new price in a fixed amount",
        ua: "Встановити нову ціну у фіксованому розмірі"
    },

    "change-mass-product-netprice-exact": {
        ru: "Установить новую себестоимость в фиксированном размере",
        en: "Set the new cost of sales at a fixed amount",
        ua: "Встановити нову собівартість у фіксованому розмірі"
    },
    "change-mass-product-price-exact-help": {
        ru: { plural: "Например: 100 (новая цена 100 {currency}, независимо от предыдущей)" },
        en: { plural: "For example: 100 (new price is 100 {currency}, regardless of the previous one)" },
        ua: { plural: "Наприклад: 100 (нова ціна 100 {currency}, незалежно від попередньої)" }
    },
    "change-mass-product-netprice-exact-help": {
        ru: { plural: "Например: 100 (новая себестоимость 100 {currency}, независимо от предыдущей)" },
        en: { plural: "For example: 100 (the new cost price is 100 {currency}, regardless of the previous cost price)" },
        ua: { plural: "Наприклад: 100 (нова собівартість 100 {currency}, незалежно від попередньої)" }
    },
    "change-exact-price-error-message": {
        ru: "Цена продажи не может быть менше 0" ,
        en: "The sale price can not be less than 0",
        ua: "Ціна продажу не може бути меншою за 0"
    },

    "change-exact-netprice-error-message": {
        ru: "Себестоимость не может быть меньше 0" ,
        en: "Cost of sales cannot be less than 0",
        ua: "Собівартість не може бути меншою за 0"
    },
    "price-is-false-error-message": {
        ru: "Ввведите корректное значение. Значение не может быть равным 0" ,
        en: "Please enter a valid value. It cannot be equal to 0",
        ua: "Введіть корректне значення. Значення не може бути рівним 0"
    },

    "print-tags": {
        ru: "Печать ценников",
        en: "Printing Price Tags",
        ua: "Друк цінників"
    },
    "price-tags-print-label": {
        ru: "Сколько ценников напечатать для каждого товара?",
        en: "How many price tags should I print for each product?",
        ua: "Скільки цінників надрукувати для кожного товару?"
    },
    "pricetags": {
        ru: "Печать ценников",
        en: "Printing price tags",
        ua: "Друк цінників"
    },
    "pricetags-quantity": {
        ru: "Количество ценников на один товар",
        en: "Price tags for one item",
        ua: "Кількість цінників на один товар"
    },
    "pricetags-quantity-desk": {
        ru: "Если 0, то по количеству товара на складе ",
        en: "If 0, then by the quantity in the warehouse of the goods",
        ua: "Якщо 0, то за кількістю товару на складі"
    },
    "pricetags-quantity-desk-default": {
        ru: "Если 0, то количество будет таким, как указано в настройках",
        en: "If 0, the quantity will be as specified in the settings",
        ua: "Якщо 0, то кількість буде такою, як зазначено в налаштуваннях"
    },
    "pricetags-quantity-desk-with-sid": {
        ru: "Если 0, то по количеству товара в поставке",
        en: "If 0, then by the quantity of goods in the shipment",
        ua: "Якщо 0, то за кількістю товару в постачанні"
    },
    "cinstock": {
        ru: "Редактирование остатков",
        en: "Editing products balances",
        ua: "Редагування залишків"
    },

    "info-on-pricetags": {
        ru: "Информация на ценниках",
        en: "Information on the price tags",
        ua: "Інформація на цінниках"
    },

    "size-pricetags": {
        ru: "Размер ценников",
        en: "Price tag size",
        ua: "Розмір цінників"
    },
    "size-pricetag": {
        ru: "Размер ценника",
        en: "Price tag size",
        ua: "Розмір цінника"
    },
    "test-pricetags": {
        ru: "Распечатать пробный ценник",
        en: "Print a trial price tag",
        ua: "Роздрукувати пробний цінник"
    },

    "print": {
        ru: "Печать",
        en: "Printing",
        ua: "Друк"
    },

    "exchange-rate-success-change": {
        ru: "Курс валют успешно изменен",
        en: "The exchange rate has been successfully changed",
        ua: "Курс валют успішно змінено"
    },

    "confirm-action": {
        ru: "Подтвердить действие",
        en: "Confirm action",
        ua: "Підтвердити дію"
    },
    "termo-print": {
        ru: "Термопринтер чеков",
        en: "Thermal receipt printer",
        ua: "Термопринтер чеків"
    },
    "change-exchange-rates": {
        ru: "Изменить курс валют",
        en: "Change the exchange rate",
        ua: "Змінити курс валют"
    },
    "exchange-rate": {
        ru: "Курс валют",
        en: "Exchange Rates",
        ua: "Курс валют"
    },
    "import-product-from": {
        ru: "Импортировать товары из",
        en: "Import items from",
        ua: "Імпортувати товари з"
    },
    "import-items": {
        ru: "Импортировать товары",
        en: "Import items",
        ua: "Імпортувати товари"
    },
    "integration-2": {
        ru: "WooCommerce",
        en: "WooCommerce",
        ua: "WooCommerce"
    },
    "integration-4": {
        ru: "Checkbox.Ua",
        en: "Checkbox.Ua",
        ua: "Checkbox.Ua"
    },
    "integration-5": {
        ru: "Prom",
        en: "Prom",
        ua: "Prom"
    },
    "integration-6": {
        ru: "Rozetka",
        en: "Rozetka",
        ua: "Rozetka"
    },
    "integration-7": {
        ru: "OpenCart",
        en: "OpenCart",
        ua: "OpenCart"
    },
    "integration-8": {
        ru: "Хорошоп",
        en: "HoroShop",
        ua: "Хорошоп"
    },
    "integration-9": {
        ru: "Вчасно.Каса",
        en: "Vchasno.Kasa",
        ua: "Вчасно.Каса"
    },
    "integration-11": {
        ru: "TurboSms",
        en: "TurboSms",
        ua: "TurboSms"
    },
    "integration-13": {
        ru: "Telegram",
        en: "Telegram",
        ua: "Telegram"
    },
    "integration-14": {
        ru: "Instagram",
        en: "Instagram",
        ua: "Instagram"
    },
    "integration-15": {
        ru: "API",
        en: "API",
        ua: "API"
    },
    "integration-16": {
        ru: "Loyal Cards",
        en: "Loyal Cards",
        ua: "Loyal Cards"
    },
    "integration-17": {
        ru: "Viber",
        en: "Viber",
        ua: "Viber"
    },
    "integration-18": {
        ru: "Binotel",
        en: "Binotel",
        ua: "Binotel"
    },
    "integration-19": {
        ru: "Olx чаты",
        en: "Olx Chats",
        ua: "Olx чати"
    },
    "integration-20": {
        ru: "Ringostat",
        en: "Ringostat",
        ua: "Ringostat"
    },
    "integration-21": {
        ru: "Shop Express",
        en: "Shop Express",
        ua: "Shop Express"
    },
    "manual": {
        ru: "Ручной",
        en: "Manual",
        ua: "Ручний"
    },
    "shipment_excel": {
        ru: "Загрузка с Excel в поставках",
        en: "Import from Excel in Shipment",
        ua: "Завантаження з Excel в поставках"
    },
    "source": {
        ru: "Источник",
        en: "Source",
        ua: "Джерело"
    },
    "not-have-orders": {
        ru: "У вас нет активных заказов",
        en: "You have no active orders",
        ua: "У вас немає активних замовлень"
    },
    "confirm-order": {
        ru: "Подтвердить заказ",
        en: "Confirm the order",
        ua: "Підтвердити замовлення"
    },
    "confirm-order-shift": {
        ru: "Подтвердить",
        en: "Confirm",
        ua: "Підтвердити"
    },
    "decline-order": {
        ru: "Отклонить заказ",
        en: "Decline order",
        ua: "Відхилити замовлення"
    },
    "decline-order-short": {
        ru: "Отклонить",
        en: "Decline",
        ua: "Відхилити"
    },
    "orders-statuses": {
        ru: "Cтатусы заказов",
        en: "Orders statuses",
        ua: "Cтатуси замовлень"
    },
    "orders-status": {
        ru: "Cтатус заказов",
        en: "Orders status",
        ua: "Cтатус замовлень"
    },
    "add-status": {
        ru: "Добавить статус",
        en: "Add status",
        ua: "Додати статус"
    },
    "add-status-placeholder": {
        ru: "Введите новый статус",
        en: "Enter a new status",
        ua: "Введіть новий статус"
    },
    "status-successfully-added": {
        ru: "Новый статус успешно добавлен",
        en: "The new status has been successfully added",
        ua: "Новий статус успішно доданий"
    },
    "status-successfully-added-2": {
        ru: "Теперь он доступен для выбора при добавлении и редактировании продаж категории 'Доставка'",
        en: "Now it is available for selection when adding and editing sales of delivery type",
        ua: "Тепер він доступний для вибору при додаванні та редагуванні продажів з типом 'Доставка'"
    },
    "status-successfully-removed": {
        ru: "Cтатус успешно удален",
        en: "The status has been successfully removed",
        ua: "Статус успішн овидалено"
    },
    "change-order-status": {
        ru: "Изменить статус",
        en: "Change status",
        ua: "Змінити статус"
    },
    "you-cant-delete-status-in-use": {
        ru: "Вы не можете удалить статус, который используется. Сперва измените статус соответствующих продаж/заказов на другой путем их редактирования.",
        en: "You cannot delete a status that is in use. First, change the status of the relevant sale/order to another one by editing it.",
        ua: "Ви не можете видалити статус, який використовується. Спершу змініть статус відповідних продажів/замовлень на інший шляхом їх редагування."
    },
    "binding-products": {
        ru: "Связать товар",
        en: "Link item",
        ua: "Зв'язати товар"
    },
    "linked-product": {
        ru: "Связанный товар",
        en: "Linked product",
        ua: "Пов'язаний товар"
    },
    "integration": {
        ru: "Интеграция",
        en: "Integration",
        ua: "Інтеграція"
    },
    "product-from-integration": {
        ru: "Продукт от интеграции",
        en: "Product from integration",
        ua: "Продукт від інтеграції"
    },
    "select-active-integration": {
        ru: "Выберите активную интеграцию",
        en: "Select the active integration",
        ua: "Оберіть активну інтеграцію"
    },
    "remove-binding": {
      ru:"Удалить привязку",
      en:"Remove the binding",
      ua:"Видалити прив'язку"
    },
    "not-sync-product": {
        ru: "Товара нет в системе или он не связан с существующим",
        en: "The product is not in the system or it is not associated with an existing one",
        ua: "Товару немає в системі або він не пов'язаний з існуючим"
    },
    "not-have-orders-desk": {
        ru: "Получайте заказы с ваших торговых площадок: WooCommerce, Prom.ua, Rozetka",
        en: "Receive orders from your marketplaces: WooCommerce, Prom.ua, Rozetka",
        ua: "Отримуйте замовлення з торгових майданчиків: WooCommerce, Prom.ua, Rozetka"
    },
    "change-language": {
        ru: "Изменить язык",
        en: "Change language",
        ua: "Змінити мову"
    },
    "count-edit-items": {
        ru: {
            plural: "Изменение затронет {count} товар"
        },
        en: {
            plural: "The change will affect {count} items"
        },
        ua: {
            plural: "Зміна торкнеться {count} одиниць товару"
        }
    },
    "count-copy-items": {
        ru: {
            plural: "Копирование затронет {count} товар"
        },
        en: {
            plural: "Copying will affect {count} products"
        },
        ua: {
            plural: "Копіювання торкнеться {count} товару"
        }
    },
    "count-edit-sale": {
        ru: {
            plural: "Изменение затронет {count} продаж{count, plural, =1{у} =2{и} =3{и} =4{и} other{}}"
        },
        en: {
            plural: "The change will affect {count} sale{count, plural, =1{} other{s}}"
        },
        ua: {
            plural: "Зміна торкнеться {count} продаж{count, plural, =1{у} =2{і} =3{і} =4{і} other{}}"
        }
    },
    "confirm-pids-count": {
        ru: {
            plural: "Напечатать {count} ценник{count, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}"
        },
        en: {
            plural: "Print {count} price tag{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "Надрукувати {count} цінник{count, plural, =1{} =2{а} =3{а}  =4{а} other{ів}}"
        }
    },
    "decrease-count-edit-items-btn": {
        ru: {
            plural: "Уменьшить цену продажи товара на {count}%"
        },
        en: {
            plural: "Reduce the sale price of a product by {count}%"
        },
        ua: {
            plural: "Зменшити ціну продажу товару на {count}%"
        }
    },
    "decrease-netprice-count-edit-items-btn": {
        ru: {
            plural: "Уменьшить себестоимость товара на {count}%"
        },
        en: {
            plural: "Reduce the cost of products by {count}%"
        },
        ua: {
            plural: "Зменшити собівартість товару на {count}%"
        }
    },
    "increase-count-edit-items-btn": {
        ru: {
            plural: "Увеличить цену продажи товара на {count}%"
        },
        en: {
            plural: "Increase the selling price of a items by {count}%"
        },
        ua: {
            plural: "Збільшити ціну продажу товару на {count}%"
        }
    },
    "increase-netprice-count-edit-items-btn": {
        ru: {
            plural: "Увеличить себестоимость товара на {count}%"
        },
        en: {
            plural: "Increase the cost of products by {count}%"
        },
        ua: {
            plural: "Збільшити собівартість товару на {count}%"
        }
    },
    "decrease-count-edit-items-btn-difference": {
        ru: {
            plural: "Уменьшить цену продажи товара на {count} {currency}"
        },
        en: {
            plural: "Reduce the sale price of a product by {count} {currency}"
        },
        ua: {
            plural: "Зменшити ціну продажу товару на {count} {currency}"
        }
    },
    "decrease-netprice-count-edit-items-btn-difference": {
        ru: {
            plural: "Уменьшить себестоимость товара на {count} {currency}"
        },
        en: {
            plural: "Reduce the cost of products by {count} {currency}"
        },
        ua: {
            plural: "Зменшити собівартість товару на {count} {currency}"
        }
    },
    "increase-count-edit-items-btn-difference": {
        ru: {
            plural: "Увеличить цену продажи товара на {count} {currency}"
        },
        en: {
            plural: "Increase the selling price of a items by {count} {currency}"
        },
        ua: {
            plural: "Збільшити ціну продажу товару на {count} {currency}"
        }
    },
    "increase-netprice-count-edit-items-btn-difference": {
        ru: {
            plural: "Увеличить себестоимость товара на {count} {currency}"
        },
        en: {
            plural: "Increase the cost of products by {count} {currency}"
        },
        ua: {
            plural: "Збільшити собівартість товару на {count} {currency}"
        }
    },
    "change-count-edit-items-btn-exact": {
        ru: {
            plural: "Установить цену продажи товара в размере {count} {currency}"
        },
        en: {
            plural: "Set the sale price of a product in the amount {count} {currency}"
        },
        ua: {
            plural: "Встановити ціну продажу товару в розмірі {count} {currency}"
        }
    },
    "change-netprice-count-edit-items-btn-exact": {
        ru: {
            plural: "Установить себестоимость товара в размере {count} {currency}"
        },
        en: {
            plural: "Set the cost of products to {count} {currency}"
        },
        ua: {
            plural: "Встановити собівартість товару в розмірі {count} {currency}"
        }
    },
    "are-you-sure-to-delete-this-product-count": {
        ru: {
            plural: "Вы уверены что хотите удалить {count} товар{count, plural, =1{} =2{а} =3{а}  =4{а} other{ов}}?"
        },
        en: {
            plural: "Are you sure you want to delete {count} item{count, plural, =1{} =2{s} =3{s}  =4{s} other{s}}"
        },
        ua: {
            plural: "Ви впевнені що хочете видалити {count} товар{count, plural, =1{} =2{а} =3{а}  =4{а} other{ів}}"
        }
    },
    "how-i-can-get-integration": {
        ru: {
            plural: "Как мне сделать интеграцию с {0}?"
        },
        en: {
            plural: "How can I integrate with {0}"
        },
        ua: {
            plural: "Як мені зробити інтеграцію з {0}"
        }
    },
    "add-one-more": {
        ru: {
            plural: "Добавить еще один {0}?"
        },
        en: {
            plural: "Add another one {0}"
        },
        ua: {
            plural: "Додати ще один {0}"
        }
    },
    "site-url": {
        ru: {
            plural: "Ссылка на магазин {0}?"
        },
        en: {
            plural: "Store link {0}"
        },
        ua: {
            plural: "Посилання на магазин {0}"
        }
    },
    "consumer-key": {
        ru: "Пользовательский ключ",
        en: "Change language",
        ua: "Користувальницький ключ"
    },
    "consumer-secret": {
        ru: "Секретный код пользователя",
        en: "Consumer secret",
        ua: "Секретний код користувача"
    },
    "is_load_products": {
        ru: "Загрузить товары с сайта?",
        en: "Import products from the site?",
        ua: "Завантажити товари із сайту?"
    },
    "btn_load_products": {
        ru: "Загрузить товары с площадки",
        en: "Import products",
        ua: "Завантажити товари з майданчику"
    },
    "update-app": {
        ru: " ",
        en: "If you have any problems with display, please update the application in the AppStore or Google Play",
        ua: "Якщо у вас виникли проблеми з відображенням, оновіть, будь ласка, програму в AppStore або Google Play"
    },
    "telegram-subscribe": {
        ru: (<span>Как вы еще не подписаны на наш Telegram-канал? Вы пропускаете информацию о новых функциях и возможностях!  <a onClick={() => createMobileAppLink('https://t.me/huge_profit')}  href="https://t.me/huge_profit" rel="noopener noreferrer" target="_blank">Подписывайтесь!</a></span>),
        en: (<span>How come you haven’t subscribed to our Telegram channel yet? You are missing out on new features and functionality! <a onClick={() => createMobileAppLink('https://t.me/huge_profit')} href="https://t.me/huge_profit" rel="noopener noreferrer" target="_blank">Subscribe !</a></span>),
        ua: (<span>Як ви ще не підписані на наш Telegram-канал? Ви пропускаєте інформацію про нові функції та можливості! <a onClick={() => createMobileAppLink('https://t.me/huge_profit')} href="https://t.me/huge_profit" rel="noopener noreferrer" target="_blank">Підписуйтесь !</a></span>)
    },

    "update-instock": {
        ru: "Обновлять остатки",
        en: "Update Stock",
        ua: "Оновлювати залишки"
    },
    "update-price": {
        ru: "Обновлять цены",
        en: "Update Prices",
        ua: "Оновлювати ціни"
    },
    "no-update-instock": {
        ru: "Не обновлять остатки",
        en: "Do not update Stock",
        ua: "Не оновлювати залишки"
    },
    "export_products": {
        ru: "Выгрузить товары с CRM на сайт",
        en: "Export products from CRM to the site",
        ua: "Вивантажити товари із CRM на сайт"
    },
    "only_orders": {
        ru: "Только получать заказы",
        en: "Only receive orders",
        ua: "Тільки отримувати замовлення"
    },
    "you-can-delete": {
        ru: "Продажи можно удалить в разделе Продажи. Для отмены перевода средств, сделайте обратный перевод.",
        en: "Sales can be deleted in the Sales section. To cancel the transfer of funds, make a reverse transfer.",
        ua: "Продаж можна видалити у розділі Продаж. Для скасування переказу коштів зробіть зворотний переказ."
    },
    "export-excel": {
        ru: "Скачать Excel",
        en: "Download Excel",
        ua: "Вигрузити в Excel"
    },
    "export-pdf": {
        ru: "Скачать PDF",
        en: "Download PDF",
        ua: "Вигрузити в PDF"
    },
    "export-xml": {
        ru: "Скачать XML",
        en: "Download XML",
        ua: "XML"
    },
    "download": {
        ru: "Скачать документы",
        en: "Download documents",
        ua: "Завантажити документи"
    },
    "documents": {
        ru: "Документы",
        en: "Documents",
        ua: "Документи"
    },
    "understand": {
        ru: "Понятно",
        en: "Clear",
        ua: "Зрозумів"
    },
    "token": {
        ru: "Токен доступа",
        en: "Access token",
        ua: "Токен доступу"
    },

    "destination": {
        ru: "Назначение",
        en: "Destination",
        ua: "Призначення"
    },

    "from-product-marketplace": {
        ru: "Из Магазина\\Склада",
        en: "From Store\\Warehouse",
        ua: "З Магазину\\Складу"
    },
    "to-product-marketplace": {
        ru: "В Магазин\\Склад",
        en: "To Store\\Warehouse",
        ua: "До Магазину\\Складу"
    },
    "moving-count-item-added": {
        ru: "Перемещение товара",
        en: "Moving products",
        ua: "Переміщення товару"
    },
    "moving-items": {
        ru: "Перемещение товара",
        en: "Movement of items",
        ua: "Переміщення товару"
    },
    "packing-list": {
        ru: "Товарная накладная",
        en: "Packing list",
        ua: "Товарна накладна"
    },
    "payment-invoice": {
        ru: "Счет на оплату",
        en: "Payment invoice",
        ua: "Рахунок на оплату"
    },
    "sales-invoice": {
        ru: "Расходная накладная",
        en: "Sales Invoice",
        ua: "Видаткова накладна"
    },
    "finish-moving-btn": {
        ru: "Завершить перемещение товара",
        en: "Complete items transfer",
        ua: "Завершити переміщення товару"
    },
    "products-moving-btn": {
        ru: "Завершение перемещения товаров",
        en: "Completion of the movement of products",
        ua: "Завершення переміщення товарів"
    },
    "create-moving": {
        ru: "Переместить товар",
        en: "Move items",
        ua: "Перемістити товар"
    },
    "add-first-moving-btn": {
        ru: "Сделать первое перемещение товара",
        en: "Make the first movement of items",
        ua: "Зробити перше переміщення товару"
    },
    "moving-from": {
        ru: "Перемещение товара от",
        en: "Movement of items from",
        ua: "Переміщення товару від"
    },
    "not-have-moving": {
        ru: "Вы еще не сделали ни одного перемещения",
        en: "You haven't made any moves yet",
        ua: "Ви ще не зробили жодного переміщення"
    },
    "moving-created": {
        ru: "Перемещение товара подготовлено",
        en: "Items movement prepared",
        ua: "Переміщення товару підготовлено"
    },
    "moving-finished": {
        ru: "Перемещение товара запущено. Дождитесь окончания",
        en: "The movement of items has started. Wait until it is finished.",
        ua: "Переміщення товару запущено. Дочекайтесь закінчення"
    },
    "xml-link": {
        ru: "XML список товаров",
        en: "XML items list",
        ua: "XML список товарів"
    },
    "update": {
        ru: "Обновить",
        en: "Update",
        ua: "Оновити"
    },
    "inventory": {
        ru: "Инвентаризация",
        en: "Stocktaking",
        ua: "Інвентаризація"
    },
    "not-have-inventory": {
        ru: "Вы еще не проводили инвентаризацию",
        en: "You haven't taken inventory yet",
        ua: "Ви ще не проводили інвентаризацію"
    },
    "add-first-inventory-btn": {
        ru: "Провести вашу первую инвентаризацию",
        en: "Take your first inventory",
        ua: "Провести першу інвентаризацію"
    },
    "create-inventory": {
        ru: "Провести инвентаризацию",
        en: "Take inventory",
        ua: "Провести інвентаризацію"
    },
    "inventory-title-step-1": {
        ru: "Где будем проводить инвентаризацию?",
        en: "Where will we take inventory?",
        ua: "Де будемо проводити інвентаризацію?"
    },
    "inventory-title-step-2": {
        ru: "Добавление товара",
        en: "Adding a product",
        ua: "Додавання товару"
    },
    "should-inventory": {
        ru: "Должно быть",
        en: "Must be",
        ua: "Повинно бути"
    },
    "inventory-title-step-2-desc": {
        ru: "Добавьте все товары, которые есть у вас в наличии на этом складе и сколько единиц каждого.",
        en: "Add all the items you have in stock in this warehouse and how many of each.",
        ua: "Додайте всі товари, які є у вас на цьому складі і скільки одиниць кожного."
    },
    "shortage": {
        ru: "Недостача",
        en: "Shortage",
        ua: "Недолік"
    },
    "surplus": {
        ru: "Излишек",
        en: "Surplus",
        ua: "Надлишок"
    },
    "finish-inventory-btn": {
        ru: "Продолжить переучет ",
        en: "Continue inventory",
        ua: "Продовжити переоблік"
    },
    "continue-btn": {
        ru: "Продолжить ",
        en: "Continue",
        ua: "Продовжити"
    },
    "summarize-btn": {
        ru: "Подвести итоги ",
        en: "Summarize",
        ua: "Підбити підсумки"
    },
    "difference": {
        ru: "Разница",
        en: "Difference",
        ua: "Різниця"
    },
    "not-add-to-list": {
        ru: "Не добавили в список",
        en: "Not added to the list",
        ua: "Не додали до списку"
    },
    "not-add-to-list-desc": {
        ru: "У вас в базе есть товары, которые вы не добавили в список инвентаризации, но по ним есть ОСТАТКИ, они должны быть у вас на складе. Если их на складе нет, то нажмите кнопку Добавить в список и по ним обнуляться все остатки. Если вы их не добавите, то остатки изменены НЕ БУДУТ и они дальше будут числиться в системе. Если они находятся на складе, вернитесь на шаг назад и добавьте их в список инвентаризации.",
        en: "You have goods in the system that you have not added to the inventory list, but there are REMAINS for them, they must be in your warehouse. If they are not in stock, then click the Add to list button and all balances will be reset to zero. If you do not add them, then the balances WILL NOT be changed and they will continue to be listed in the system. If they are in stock, go back a step and add them to your inventory list.",
        ua: "У вас в базі є товари, які ви не додали до списку інвентаризації, але за ними є ЗАЛИШКИ, вони повинні бути у вас на складі. Якщо їх на складі немає, то натисніть кнопку Додати до списку та по них обнуляться всі залишки. Якщо ви їх не додасте, то залишки змінені НЕ БУДУТЬ і вони будуть числитися в системі далі. Якщо вони є на складі, поверніться на крок назад та додайте їх до списку інвентаризації."
    },
    "add-to-list": {
        ru: "Добавить в список",
        en: "Add to the list",
        ua: "Додати до списку"
    },
    "add-to-list-count": {
        ru: {
            plural: "Добавить в список инвентаризации {0} товаров? Если Вы их не добавите, остатки по этим товарам изменены не будут."
        },
        en: {
            plural: "Add {0} items to inventory list? If you do not add them, the balances for these products will not be changed."
        },
        ua: {
            plural: "Додати до списку інвентаризації {0} товарів? Якщо Ви їх не додасте, залишки цих товарів змінені не будуть."
        }
    },
    "confirm-text-inventory": {
        ru: "Не все товары в списке инвентаризации",
        en: "Not all items in the inventory list",
        ua: "Не всі товари у списку інвентаризації"
    },
    "inventory-finished": {
        ru: "Инвентаризация завершена. Остатки товаров будут обновлены через несколько минут",
        en: "Inventory completed. Remaining items will be updated in a few minutes",
        ua: "Інвентаризацію завершено. Залишки товарів будуть оновлені за декілька хвилин"
    },
    "complete-inventory-btn": {
        ru: "Обновить остатки товаров",
        en: "Update stock items",
        ua: "Оновити залишки товарів"
    },
    "add-inventory-expenses": {
        ru: "Добавить в расходы недостачу",
        en: "Add shortage to expenses",
        ua: "Додати у витрати нестачу"
    },
    "select-min-one-item-inventory": {
        ru: "Выберите товары, которые есть у вас в наличии",
        en: "Select the products you have in stock",
        ua: "Виберіть товари, які є у вас в наявності"
    },
    "confirm-text-inventory-text": {
        ru: {
            plural: "У вас не добавлено в список инвентаризации {0} товаров. Если Вы их не добавите, остатки по этим товарам изменены не будут. Продолжаем?"
        },
        en: {
            plural: "You have not added {0} items to your inventory list. If you do not add them, the balances for these products will not be changed. Are we continuing?"
        },
        ua: {
            plural: "Ви не додали до списку інвентаризації {0} товарів. Якщо Ви їх не додасте, залишки по цих товарах не буде змінено. Продовжуємо?"
        }
    },
    "inventory-expenses-comment": {
        ru: {
            plural: "Недостача {0} товаров по результатам инвентаризации від {1}"
        },
        en: {
            plural: "Shortage {0} of goods based on the results of inventory from {1}"
        },
        ua: {
            plural: "Нестача {0} товарів за результатами інвентаризації від {1}"
        }
    },
    "changed-inventory": {
        ru: "У вас есть товары, в которых изменилось количество после начала инвентаризации. Проверьте их наличие.",
        en: "You have items that have had their quantity changed since inventory started. Check for them.",
        ua: "Ви маєте товари, в яких змінилася кількість після початку інвентаризації. Перевірте їх наявність."
    },
    "inventory-comment-placeholder": {
        ru: "Введите комментарий относительно этой инвентаризации (необязательно)",
        en: "Enter a comment about this inventory (optional)",
        ua: "Введіть комантар щодо цієї інвентаризації (за бажанням)"
    },
    "is-complect": {
        ru: "Комплект (составной товар)",
        en: "Bundle (composite item)",
        ua: "Комплект (составний товар)"
    },
    "accessories": {
        ru: "Комплектующие",
        en: "Components",
        ua: "Комплектуючі"
    },
    "components": {
        ru: "Компонентов",
        en: "Components",
        ua: "Компонентів"
    },
    "is-simple": {
        ru: "Обычный",
        en: "Simple",
        ua: "Звичайний"
    },
    "unselect": {
        ru: "Снять выбор",
        en: "Deselect",
        ua: "Зняти вибір"
    },
     "select-one-products": {
        ru: "Выберите хотя бы 2 товара в комплект",
        en: "Please select at least 2 items per set",
        ua: "Виберіть хоча б 2 товари в комплект"
    },
    "additional-field": {
        ru: "Дополнителное поле",
        en: "Additional Field",
        ua: "Додаткове поле"
    },
    "additional-fields": {
        ru: "Дополнителные поля",
        en: "Additional Fields",
        ua: "Додаткові поля"
    },
    "additional-fields-short": {
        ru: "Доп. поля",
        en: "Additional fields",
        ua: "Дод. поля"
    },
    "add-field": {
        ru: "Добавить поле",
        en: "Add Field",
        ua: "Додати поле"
    },
    "field-name": {
        ru: "Название дополнительного поля",
        en: "Additional field name",
        ua: "Назва додаткового поля"
    },
    "revert-moving": {
        ru: "Отменить перемещение",
        en: "Cancel move",
        ua: "Скасувати переміщення"
    },
    "revert-inventory": {
        ru: "Отменить инвентаризацию",
        en: "Cancel inventory",
        ua: "Скасувати інвентаризацію"
    },
    "revert-inventory-desc": {
        ru: "После отмены инвентаризации все изменения остатков товара в рамках этой инвентаризации будут отменены.",
        en: "After the inventory is canceled, all changes to the balance of goods within this inventory will be canceled.",
        ua: "Після скасування інвентаризації всі зміни залишків товару в рамках цієї інвентаризації буде скасовано."
    },
    "moving-revert-desc": {
        ru: "Перемещение будет полностью отменено и товар возвращен на исходный склад. Вы сможете внести коррективы и завершить перемещение еще раз.",
        en: "The move will be completely canceled and the item returned to the original warehouse. You will be able to make adjustments and complete the move again.",
        ua: "Переміщення буде повністю скасовано та товар повернено на вихідний склад. Ви зможете внести корективи та завершити переміщення ще раз."
    },
    // "personal-pricetag": {
    //     ru: "Если вам нужен индивидуальный ценник, напишите к нам в поддержку, мы поможем :)",
    //     en: "If you need an individual price tag, write to us in support, we will help :)",
    //     ua: "Якщо вам потрібен індивідуальний цінник, напишіть нам до служби допомоги, ми допоможемо :)"
    // },
    "not_allow_calc": {
        ru: "Не учитывать в статистике продаж",
        en: "Do not include in sales statistics",
        ua: "Не враховувати в статистиці продажів"
    },

    "fixed-count": {
        ru: "Фиксированное количество",
        en: "Fixed quantity",
        ua: "Фіксована кількість"
    },

    "plus-count": {
        ru: "Добавить\\Вычесть от имеющегося количества",
        en: "Add\\Subtract from the available quantity",
        ua: "Додати\\Відняти від наявної кількості"
    },
    "order-has-beed-changed": {
        ru: "Заказ на источнике был изменен. Внесите изменения в Продажу вручную.",
        en: "The order at the source has been changed. Make changes to the Sales manually.",
        ua: "Замовлення на джерелі було змінено. Внесіть зміни до Продажу вручну."
    },
    "with": {
        ru: "c",
        en: "with",
        ua: "з"
    },
    "select-available-account": {
        ru: "Выберите хотя бы один доступный счет",
        en: "Please select at least one available account",
        ua: "Виберіть хоча б один доступний рахунок"
    },
    "select-available-warehouse": {
        ru: "Выберите хотя бы один доступный склад",
        en: "Please select at least one available warehouse",
        ua: "Виберіть хоча б один доступний склад"
    },
    "show-all-sales": {
        ru: "Просмотр продаж всех пользователей",
        en: "View sales of all users",
        ua: "Перегляд продажів всіх користувачів"
    },
    "show-all-expenses": {
        ru: "Просмотр расходов всех пользователей",
        en: "View expenses of all users",
        ua: "Перегляд витрат всіх користувачів"
    },
    "salesprice": {
        ru: "Просмотр цены продажи",
        en: "Viewing the selling price",
        ua: "Перегляд ціни продажу"
    },
    "with-netprice": {
        ru: "С закупочными ценами",
        en: "With purchase prices",
        ua: "Із закупівельними цінами"
    },
    "with-sale-price": {
        ru: "С ценами продажи",
        en: "With sale prices",
        ua: "З цінами продажу"
    },
    "public-offer": {
        ru: "Публичная оферта",
        en: "Public offer",
        ua: "Публічна оферта"
    },
    "consent-to-regular-debits": {
        ru: (<span>Я даю согласие на регулярные списания, на обработку персональных данных и принимаю условия <a href='/ru/agreement' target='_blank'>публичной оферты </a></span>),
        en: (<span>I consent to regular debits, to the processing of personal data and accept the terms of the <a href='/en/agreement' target='_blank'>public offer</a></span>),
        ua: (<span>Я даю згоду на регулярні списання, на обробку персональних даних і приймаю умови <a href='/ua/agreement' target='_blank'>публічної оферти</a></span>)
    },

    "continue-later": {
        ru: "Продолжить позже",
        en: "Continue later",
        ua: "Продовжити пізніше"
    },
    "additional-info": {
        ru: "Дополнительная информация",
        en: "Additional Information",
        ua: "Додаткова інформація"
    },
    "client-info": {
        ru: "Клиент Инфо",
        en: "Client Info",
        ua: "Клієнт Інфо"
    },
    "product-images": {
        ru: "Картинка товара",
        en: "Picture of the product",
        ua: "Картинка товару"
    },
    "integration-error": {
        ru: "Ошибка интеграции",
        en: "Integration error",
        ua: "Помилка інтеграції"
    },
    "error-while-get-order": {
        ru: "Ошибка при загрузке заказов. Обратитесь в тех.поддержку.",
        en: "Error loading orders. Contact technical support.",
        ua: "Помилка під час завантаження замовлень. Зверніться до технічної підтримки."
    },
    "(1205, 'Lock wait timeout exceeded; try restarting transaction')": {
        ru: "Не удалось закончить операцию. Пожалуйста, повторите еще раз",
        en: "Failed to complete the operation. Please repeat again",
        ua: "Не вдалось закінчити операцію. Будь ласка, повторіть ще раз"
    },
    "error-while-get-products": {
        ru: "Ошибка при загрузке товаров. Обратитесь в тех.поддержку.",
        en: "Error loading products. Contact technical support.",
        ua: "Помилка під час завантаження товарів. Зверніться до технічної підтримки."
    },
    "error-while-load-filed": {
        ru: "Ошибка при загрузке товаров. Проверьте корректность файла. Нельзя удалять и редактировать 1ю строчку.",
        en: "Error loading products. Check if the file is correct. You can not delete and edit the 1st line.",
        ua: "Помилка під час завантаження товарів. Перевірте правильність файлу. Не можна видаляти і редагувати перший рядок."
    },
    "error-while-xml-load-filed": {
        ru: "Ошибка при загрузке товаров. Проверьте правильно ли введен URL-адрес/выбран файл для загрузки",
        en: "Error loading products. Check if entered URL/selected file is correct",
        ua: "Помилка під час завантаження товарів з XML. Перевірте чи правильно введено URL-адресу / обрано файл для завантаження "
    },
    "error-while-update-stock": {
        ru: "Ошибка при обновлении остатков. Обратитесь в тех.поддержку.",
        en: "Error while updating stock. Contact technical support.",
        ua: "Помилка під час оновлення залишків. Зверніться до технічної підтримки."
    },
    "error-while-put-products": {
        ru: "Ошибка при выгрузке товаров. Обратитесь в тех.поддержку.",
        en: "Error sending products. Contact technical support.",
        ua: "Помилка під час вивантаження товарів. Зверніться до технічної підтримки."
    },
    "Unauthorized": {
        ru: "Ошибка авторизации. Проверьте правильность введенных данных.",
        en: "Authorisation Error. Check the correctness of the entered data.",
        ua: "Помилка авторизації. Перевірте правильність даних."
    },
    "incorrect_username_password": {
        ru: "Ошибка авторизации. Проверьте правильность введенных данных.",
        en: "Authorisation Error. Check the correctness of the entered data.",
        ua: "Помилка авторизації. Перевірте правильність даних."
    },
    "Forbidden": {
        ru: "Ошибка доступа. У нас нет прав на обновление данных. Проверьте, выставили ли вы все права доступа.",
        en: "Access error. We do not have rights to update data. Check if you have set all permissions.",
        ua: "Помилка доступу. Ми не маємо прав на оновлення даних. Перевірте, чи ви всі права доступу виставили."
    },
    "data-for-invoice": {
        ru: "Данные для чека\\счета",
        en: "Data for check\\account",
        ua: "Дані для чека\\рахунку"
    },
    "client-invoice-placeholder": {
        ru: "Введите дополнительные данные для отображения в счетах и чеках. Например, реквизиты банка, юр.адрес, едрпоу и т.д",
        en: "Enter additional data to display on invoices and checks. For example, bank details, legal address, etc.",
        ua: "Введіть додаткові дані для відображення у рахунках та чеках. Наприклад, реквізити банку, юр.адреса, єдрпоу тощо"
    },
    "User with such username/password not found": {
        ru: "Логин или пароль в интеграции указаны неверно. Введите правильные авторизационные данные",
        en: "The login or password in the integration is incorrect. Enter the correct authorization data",
        ua: "Логін або пароль в інтеграції вказано неправильно. Введіть правильні авторизаційні дані"
    },
    "dljaKogo": {
        ru: "Для кого",
        en: "For whom",
        ua: "Для кого"
    },
    "material": {
        ru: "Материал",
        en: "Material",
        ua: "Матеріал"
    },
    "krana": {
        ru: "Страна",
        en: "Country",
        ua: "Країна"
    },
    "success-load-products": {
        ru: "Товары успешно загружены!",
        en: "Items have been successfully downloaded!",
        ua: "Товари успішно завантажені!"
    },
    "start-load-products": {
        ru: "Начинаем загрузку товаров, это может занять какое-то время...",
        en: "We are loading the goods, it may take some time...",
        ua: "Починаємо завантаження товарів, це може зайняти якийсь час..."
    },
    "root-cat": {
        ru: "-- Корневая категория -- ",
        en: "-- Root category --",
        ua: "-- Коренева категорія --"
    },
    "leftovers": {
        ru: "Остатки",
        en: "Leftovers",
        ua: "Залишки"
    },
    "loading-products": {
        ru: "Загрузка товаров с площадки",
        en: "Loading goods from the site",
        ua: "Завантаження товарів з майданчика"
    },
    "download-products": {
        ru: "Загрузить новые товары без остатков",
        en: "Download new products without any leftovers",
        ua: "Завантажити нові товари без залишків"
    },
    "download-products-simple": {
        ru: "Загрузить БЕЗ создания товаров",
        en: "Download WITHOUT creating goods",
        ua: "Завантажити БЕЗ створення товарів"
    },
    "link-existing-products-without-creating": {
        ru: "Связать существующие товары БЕЗ создания новых",
        en: "Link existing products WITHOUT creating new ones",
        ua: "Зв'язати наявні товари БЕЗ створення нових"
    },
    "update-all-balances": {
        ru: "Обновить все остатки",
        en: "Update all balances",
        ua: "Оновити усі залишки"
    },
    "update-orders": {
        ru: "Обновлять статусы в заказах",
        en: "Update order statuses",
        ua: "Оновлювати статуси в замовленнях"
    },
    "last-download-date": {
        ru: "Дата последней загрузки товаров",
        en: "The date of the last download of goods",
        ua: "Дата останнього завантаження товарів"
    },
    "last-put-date": {
        ru: "Дата последней выгрузки товаров на сайт",
        en: "Date of the last unloading of goods on the site",
        ua: "Дата останнього вивантаження товарів на сайт"
    },
    "wrong-url": {
        ru: "Проверьте правильность ввода ссылки на сайт",
        en: "Check the correctness of entering the link to the site",
        ua: "Перевірте правильність введення посилання на сайт"
    },
    "loading-products-desc": {
         ru:  (
            <span>
            <p>При загрузке товаров система будет пытаться найти схожие товары в первую очередь по Артикулу (SKU), во вторую очередь по НАЗВАНИЮ. Если артикул или название совпадают, система сделает связку этих товаров и новый создавать НЕ будет. Если товара не было найдено, то будет создана новая карточка товара.</p>
            <p><b>Обновить все остатки</b> -  все ваши остатки будут <b className="has-text-danger">ПЕРЕЗАПИСАНЫ</b> данными с площадки.  <br />
            <b>Загрузить новые товары без остатков</b> -  Будут загружены все старые и новые товары, но остатки остануться те что сейчас в нашей системе, или будут нулевые, если это новый товар.<br />
            <b>Загрузить БЕЗ создания товаров</b> -  Товары будут загружены с площадки, но карточки товаров НЕ БУДУТ созданы. Вы сможете сделать связку товаров вручну к уже созданным ранее товарам.<br />
            <b className="has-text-danger">Будьте внимательны!</b> </p>
            </span>
            ),
        en: (
            <span>
                <p>When loading products the system will first attempt to find similar products by SKU (Article), and then by NAME. If the SKU or name matches, the system will link these products and a new product will NOT be created. If the product is not found, a new product card will be created.</p>
                <p><b>Update all balances</b> - all your balances will be <b className="has-text-danger">OVERWRITTEN</b> with data from the platform. <br />
                <b>Load new products without balances</b> - All old and new products will be loaded, but the balances will remain as they are in our system now, or will be zero if it is a new product.<br />
                <b>Load WITHOUT creating products</b> - Products will be loaded from the platform, but product cards WILL NOT BE created. You will be able to manually link products to previously created products.<br />
                <b className="has-text-danger">Be careful!</b> </p>
            </span>
            ),
        ua: (
            <span>
                <p>При завантаженні товарів система спробує знайти подібні товари спочатку за артикулом (SKU), а потім за НАЗВОЮ. Якщо артикул або назва співпадають, система об'єднає ці товари і новий товар НЕ буде створено. Якщо товар не було знайдено, буде створено нову товарну картку.</p>
                <br />
                <p><b>Оновити всі залишки</b> - всі ваші залишки будуть <b className="has-text-danger">ПЕРЕЗАПИСАНІ</b> даними з платформи. <br />
                <b>Завантажити нові товари без залишків</b> - Будуть завантажені всі старі і нові товари, але залишки залишаться такими, які є в нашій системі зараз, або будуть нульовими, якщо це новий товар.<br />
                <b>Завантажити БЕЗ створення товарів</b> - Товари будуть завантажені з платформи, але картки товарів НЕ БУДУТЬ створені. Ви зможете зробити об'єднання товарів вручну до вже створених раніше товарів.<br />
                <br />
                <big><b className="has-text-danger">Будьте уважні!</b></big> </p>
            </span>

            )
    },
    "download-products-desc": {
        ru: <div>Выгрузка товаров на сайт может занять до получаса, в зависимости от количества товаров.
            <br/>
            <br/>
            <b>Выгрузить все товары</b> - что будут выгружены все новые товары, а остатки и цены ко ВСЕМ товарам, независимо от выставленных галочек.
            <br/>
            <br/>
            <b>Выгрузить только новые товары</b> - будут выгружены только новые товары, которые не были выгружены до этого.
            <br/>
            <br/>
            <b>Выгрузить только остатки</b> - выгружает остатки товаров (кнопка появляется при включенной галочке на «Обновлять остатки» в настройках интеграции).
            <br/>
            <br/>
            <b>Выгрузить только цены</b> - выгружает цены товаров (кнопка появляется при включенной галочке на «Обновлять цены» в настройках интеграции).
            </div>,
        en: <div>Uploading items to the site can take up to half an hour,
            depending on the number of items.
            <br/>
            <br/>
            <b>Unload all products</b> - that all new products will be unloaded,
            and balances and prices to ALL products, regardless of the
            checkboxes checked.
            <br/>
            <br/>
            <b>Upload only new items</b> - only new items that have not been uploaded before will be uploaded.
            <br/>
            <br/>
            <b>Unload only residuals</b> - unloads product balances (the button appears when the “Update Stock” checkbox is enabled in the integration settings).
            <br/>
            <br/>
            <b>Unload only prices</b> - unloads prices of products (the button appears if the “Update prices” checkbox is enabled in the integration settings).
        </div>,
        ua: <div>Вивантаження товарів на сайт може зайняти до півгодини, залежно від кількості товарів.
            <br/>
            <br/>
            <b>Вивантажити всі товари</b> - що будуть вивантажені всі нові товари, а залишки і ціни до ВСІХ товарів, незалежно від виставлених галочок.
            <br/>
            <br/>
            <b>Вивантажити тільки нові товари</b> - будуть вивантажені тільки нові товари, які не були вивантажені до цього.
            <br/>
            <br/>
            <b>Вивантажити тільки залишки</b> - вивантажує залишки товарів ( кнопка з'являється при увімкненій галочці на "Оновлювати залишки" в налаштуваннях інтеграції).
            <br/>
            <br/>
            <b>Вивантажити тільки ціни</b> - вивантажує ціни товарів (кнопка з'являється при увімкненій галочці на "Оновлювати ціни" в налаштуваннях інтеграції).
            </div>
    },
    "moving-attemp-desc": {
         ru:  (
            <span>
            <p><b className="has-text-danger">Внимание!</b> Если у Вашего товара есть несколько себестоимостей (оформленые через разные Поставки с разной закупочной ценой), то при перемещении на другой склад будет вычислена СРЕДНЯЯ себестоимость.<br />
            <b className="has-text-danger">Будьте внимательны!</b> </p>
            </span>
            ),
        en: (
            <span>
            <p>If you select "Update All Remains", all of your remnants will be <b className="has-text-danger">OVERWRITTEN</b> with site data. <br />
             <b className="has-text-danger">Be careful!</b> </p>
           </span>
            ),
        ua: (
            <span>
            <p>Якщо Ви оберете "Оновити всі залишки", всі ваші залишки будуть <b className="has-text-danger">ПЕРЕЗАПИСАНІ</b> даними з майданчика. <br />
             <b className="has-text-danger">Будьте уважні!</b> </p>
             </span>
            )
    },
    "sms-nofifaction-setting": {
        ru: "Настройка СМС уведомлений по статусам",
        en: "Setting up SMS notifications by status",
        ua: "Налаштування СМС повідомлень за статусами"
    },
    "text-sms": {
        ru: "Текст СМС",
        en: "SMS Text",
        ua: "Текст СМС"
    },
    "save-sms": {
        ru: "Сохранить настройки СМС",
        en: "Save SMS settings",
        ua: "Зберегти налаштування СМС"
    },
    "send": {
        ru: "Отправлять",
        en: "Send",
        ua: "Надсилати"
    },

    "setting-sms-help": {
        ru: "Вы можете использовать такие шорт-коды в тексте смс, которые заменятся данными с добавленной продажи: {order_number}, {client_name}, {amount}, {tracking_number}, {np_number}. Где, amount - сумма заказа, np_number - это отделение Новой Почты.",
        en: "You can use the following shortcodes in the text of the SMS, which will be replaced by data from the added sale: {order_number}, {client_name}, {amount}, {tracking_number}, {np_number}. Where, amount is the amount of the order, np_number is the branch of Nova Poshta.",
        ua: "Ви можете використовувати такі шорт-коди в тексті смс, які заміняться даними з доданого продажу: {order_number}, {client_name}, {amount}, {tracking_number}, {np_number}. Де, amount – сума замовлення, np_number – це відділення Нової Пошти."
    },
    "postcode": {
        ru: "Индекс",
        en: "Zip",
        ua: "Індекс"
    },
    "postcode-placeholder": {
        ru: "Введите почтовый индекс",
        en: "Enter zip code",
        ua: "Введіть поштовий індекс"
    },
    "express": {
        ru: "Экспресс",
        en: "Express",
        ua: "Експрес"
    },
    "standart": {
        ru: "Стандарт",
        en: "Standard",
        ua: "Стандарт"
    },
    "problem with delete": {
        ru: "Не получилось отменить поставку. Обновите страницу и попробуйте еще раз.",
        en: "Unable to cancel delivery. Refresh the page and try again.",
        ua: "Не вдалося скасувати поставку. Оновіть сторінку та спробуйте ще раз."
    },
    "name-templates": {
        ru: "Название шаблона",
        en: "Template Name",
        ua: "Назва шаблону"
    },
    "production-count-item-added": {
        ru: {plural: "Себестоимость производства {count} ед."},
        en: {plural:"Cost of production {count} unit."},
        ua: {plural:"Собівартість виробництва {count} од."}
    },
    "production-netcost": {
        ru: {plural: "Себестоимость  1 ед."},
        en: {plural: "Cost of 1 unit"},
        ua: {plural: "Собівартість 1 од."}
    },
    "select-min-one-item-production": {
        ru: "Выберите хотя бы один компонент",
        en: "Please select at least one component",
        ua: "Виберіть хоча б один компонент"
    },
    "select-result-production": {
        ru: "Выберите товар, который вы производите",
        en: "Select the item you are producing",
        ua: "Виберіть товар, який ви виробляєте"
    },
    "select-min-one-item-production-or-expenses": {
        ru: "Выберите хотя бы один компонент или финансовый расход",
        en: "Please select at least one component or financial expense",
        ua: "Виберіть хоча б один компонент або фінансову витрату"
    },
    "not-have-production-time": {
        ru: "За этот период у вас нет произведенных товаров",
        en: "You have no products produced during this period",
        ua: "За цей період у вас немає вироблених товарів"
    },
    "not-have-production": {
        ru: "У вас еще нет шаблонов для производства",
        en: "You don't have production templates yet",
        ua: "У вас ще немає шаблонів для виробництва"
    },
    "create-production": {
        ru: "Создать шаблон производства",
        en: "Create Production Template",
        ua: "Створити шаблон виробництва"
    },
    "produce-goods": {
        ru: "Произвести товар",
        en: "Produce Goods",
        ua: "Виробити товар"
    },
    "edit-production": {
        ru: "Редактировать шаблон производства",
        en: "Edit Production Template",
        ua: "Редагувати шаблон виробництва"
    },
    "conponent-on-amount": {
        ru: "Стоимость компонентов",
        en: "The Cost of Components",
        ua: "Вартість компонентів"
    },
    "amount-on-producations": {
        ru: "Стоимость производства",
        en: "Manufacturing Cost",
        ua: "Вартість виробництва"
    },
    "expenses-productions": {
        ru: "Расходы на производство",
        en: "Production Expenses",
        ua: "Витрати на виробництво"
    },
    "result-production": {
        ru: "Товар, который производим",
        en: "The Product You Produce",
        ua: "Товар, який виготовляємо"
    },
    "count-production": {
        ru: "Сколько производим?",
        en: "How Much Do We Produce?",
        ua: "Скільки виготовляємо?"
    },
    "production-template": {
        ru: "Шаблоны производства",
        en: "Manufacturing Templates",
        ua: "Шаблони виробництва"
    },
    "production-template-deleted": {
        ru: "Удаленные шаблоны производства",
        en: "Deleted production templates",
        ua: "Видалені шаблони виробництва"
    },
    "history-production": {
        ru: "История производства",
        en: "Manufacturing history",
        ua: "Історія виробництва"
    },
    "production-quantity": {
        ru: "Произведенное количество",
        en: "Quantity Produced",
        ua: "Вироблена кількість"
    },
    "production-text": {
        ru: "У Вас небольшое производство и вы с разных компонентов создаете новые товары? Тогда, включайте этот раздел и Вы сможете высчитывать себестоимость производимых товаров и создавать их!",
        en: "Do you have a small production and you create new products from different components? Then, turn on this section and you will be able to calculate the cost of manufactured goods and create them!",
        ua: "Ви маєте невелике виробництво і ви з різних компонентів створюєте нові товари? Тоді, включайте цей розділ і Ви зможете вираховувати собівартість вироблених товарів та створювати їх!"
    },
    "production-created": {
        ru: "Шаблон для производства создан. Теперь можете перейти к производству этого товара.",
        en: "The production template has been created. Now you can proceed to the production of this product.",
        ua: "Шаблон для виробництва створено. Тепер можна перейти до виробництва цього товару."
    },
    "production-finished": {
        ru: "Товар произведен! Комплектующие списаны. Расходы добавлены.",
        en: "The product has been produced! Components have been written off. Costs added.",
        ua: "Товар виготовлений! Комплектуючі списані. Витрати додані."
    },
    "warehouse-need-fical": {
        ru: "Магазин, который нужно фискализировать",
        en: "A store that needs to be fiscalized",
        ua: "Магазин, який потрібно фіскалізувати"
    },
    "organisation": {
        ru: "Организация",
        en: "Organization",
        ua: "Організація"
    },
    "payment-control": {
        ru: "Контроль оплаты",
        en: "Payment Control",
        ua: "Контроль оплати"
    },
    "how-use-production": {
        ru: "Как использовать производство?",
        en: "How to Use Production?",
        ua: "Як використати виробництво?"
    },
    "AfterpaymentOnGoodsCost is unavailable": {
        ru: "Для использования Контроля оплат, у вас должен быть договор с Новой Почтой.",
        en: "To use Payment Control, you must have an agreement with Nova Poshta.",
        ua: "Для використання Контролю оплати, у вас має бути договір з Новою Поштою."
    },
    "meest": {
        ru: "Meest",
        en: "Meest",
        ua: "Meest"
    },
    "rozetka": {
        ru: "Розетка",
        en: "Rozetka",
        ua: "Розетка"
    },
    "justin": {
        ru: "Justin",
        en: "Justin",
        ua: "Justin"
    },
    "pickup": {
        ru: "Самовывоз",
        en: "Pickup",
        ua: "Самовивіз"
    },
    "city-delivery": {
        ru: "Доставка по городу",
        en: "Delivery around the city",
        ua: "Доставка по місту"
    },
    "outcity-delivery": {
        ru: "Доставка по країні",
        en: "Delivery by country",
        ua: "Доставка по Україні"
    },
    "binding-client-btn": {
        ru: "Привязать к существующему",
        en: "Link to existing",
        ua: "Прив'язати до існуючого"
    },
    "change-binding-client-btn": {
        ru: "Изменить привязку",
        en: "Change Binding",
        ua: "Змінити прив'язку"
    },
    "binding-clients": {
        ru: "Привязать чат к существующему клиенту",
        en: "Link chat to an existing client",
        ua: "Прив'язати чат до існуючого клієнта"
    },
    "active-orders": {
        ru: "В работе",
        en: "In progress",
        ua: "В роботі"
    },
    "all-orders": {
        ru: "Заказов",
        en: "Orders",
        ua: "Замовлень"
    },
    "old-orders": {
        ru: "Завершенные заказы",
        en: "Completed Orders",
        ua: "Завершені замовлення"
    },
    "price-list": {
        ru: "Прайс-лист",
        en: "Price list",
        ua: "Прайс-лист"
    },
    "generate-price-btn": {
        ru: "Сгенерировать ссылку на новый прайс на основе текущих фильтров",
        en: "Generate a link to a new price list based on current filters",
        ua: "Згенерувати посилання на новий прайс на основі поточних фільтрів"
    },
    "get-price-link": {
        ru: "Публичные прайс-листы на основе фильтров",
        en: "Public price lists based on filters",
        ua: "Публічні прайс-листи на основі фільтрів"
    },
    "brand": {
        ru: "Бренд",
        en: "Brand",
        ua: "Бренд"
    },
    "brand-name-label": {
        ru: "Название бренда",
        en: "Brand's name",
        ua: "Назва бренду"
    },
    "brand-name-placeholder": {
        ru: "Введите название бренда",
        en: "Enter a brand's name",
        ua: "Введіть назву бренду"
    },
    "mid": {
        ru: "Склад",
        en: "Warehouse",
        ua: "Склад"
    },
    "instock": {
        ru: "В наличии",
        en: "In stock",
        ua: "В наявності"
    },
    "search": {
        ru: "Поисковая фраза",
        en: "Search phrase",
        ua: "Пошукова фраза"
    },
    "date_start": {
        ru: "Дата добавления с",
        en: "Date added from",
        ua: "Дата додавання з"
    },
    "date_end": {
        ru: "Дата добавления по",
        en: "Date added to",
        ua: "Дата додавання по"
    },
    "generate": {
        ru: "Сгенерировать прайс-лист",
        en: "Generate a price list",
        ua: "Згенерувати прайс-лист"
    },
    "all-products": {
        ru: "Все товары",
        en: "All items",
        ua: "Всі товари"
    },
    "link": {
        ru: "Ссылка",
        en: "Link",
        ua: "Посилання"
    },
    "comment-for-post": {
        ru: "Комментарий к публикации",
        en: "Post comment",
        ua: "Коментар до публікації"
    },
    "created": {
        ru: "Новый",
        en: "New",
        ua: "Новий"
    },
    "type-message-here": {
        ru: "Пишите ваше сообщение здесь",
        en: "Type your message here",
        ua: "Введіть своє повідомлення тут"
    },
    "connected-page": {
        ru: "Подключенная страница",
        en: "Connected page",
        ua: "Підключена сторінка"
    },
    "connect": {
        ru: "Подключить",
        en: "Connect integration",
        ua: "Підключити"
    },
    "disconect": {
        ru: "Отключить",
        en: "Disconnect",
        ua: "Відключити"
    },
    "how-connect": {
        ru: "Как подключить?",
        en: "How to connect?",
        ua: "Як підключити?"
    },
    "desc-1": {
        ru: "Служба доставки по Украине. Создание ТТН, Отслеживание статуса посылки, Печать маркировок",
        en: "Delivery service across Ukraine. Creation of TTN, Tracking the status of the parcel, Printing stickers",
        ua: "Служба доставки по Україні. Створення ТТН, Відстеження статусу посилки, Друк маркувань"
    },
     "desc-2": {
        ru: "CMS для интернет-магазина на базе WordPress. Загрузка товаров, выгрузка товаров. Синхронизация остатков и цен в режиме онлайн.",
        en: "CMS for an online store based on WordPress. Loading of goods, unloading of goods. Synchronization of balances and prices online.",
        ua: "CMS для інтернет магазина на базі WordPress. Завантаження товарів, вивантаження товарів. Синхронизація залишків та цін в режимі онлайн."
    },
     "desc-3": {
        ru: "Национальная служба доставки по Украине. Создание ТТН при наличии договора с УкрПочтой. Отслеживание посылок, печать маркировки.",
        en: "National delivery service across Ukraine. Creation of TTN in the presence of a contract with UkrPoshta. Parcel tracking, labeling printing.",
        ua: "Національна служба доставки по Україні. Створення ТТН при наявності договору з УкрПоштою. Відстеження посилок, друк маркування."
    },
    "desc-4": {
        ru: "ПРРО. Фискализация чеков, печать фискальных чеков. Автоматическое открытие смен.",
        en: "PRRO Fiscalization of checks, printing of fiscal checks. Automatic opening of changes.",
        ua: "ПРРО. Фіскалізація чеків, друк фіскальних чеків. Автоматичне відкриття змін."
    },
     "desc-5": {
        ru: "Украинский маркетплейс для продажи товаров. Загрузка номенклатуры товаров, онлайн обновление остатков и цен. Подтягивание заказов и статусов",
        en: "Ukrainian marketplace for selling goods. Downloading the product range, online update of balances and prices. Pulling orders and statuses",
        ua: "Український маркетплейс для продажу товарів. Завантаження номенклатури товарів, онлайн оновлення залишків та цін. Підтягування замовлень і статусів"
    },
     "desc-6": {
        ru: "Маркетплейс для продажи товаров. Фулфилмент. Загрузка номенклатуры товаров. Обновление остатков и цен. Обработка заказов.",
        en: "Marketplace for selling goods. Fulfillment. Loads of goods nomenclature. Update balances and prices. Order processing.",
        ua: "Маркетплейс для продажу товарів. Фулфілмент. Завантаження номенклатури товарів. Оновлення залишків та цін. Обробка замовлень."
    },
     "desc-7": {
        ru: "CMS для интернет-магазина. Удобная платформа для создания своего собственного магазина. Загрузка товаров, обновление цен и остатков товаров в режиме онлайн. Обработка заказов.",
        en: "CMS for an online store. A convenient platform for creating your own store. Loading goods, updating prices and balances of goods online. Order processing.",
        ua: "CMS для інтернет-магазину Зручна платформа для створення власного магазину. Завантаження товарів, оновлення цін та залишків товарів у режимі онлайн. Обробка замовлень."
    },
     "desc-8": {
        ru: "Платформа для создания интернет-магазина. Загрузка номенклатуры товаров. Обновление остатков и цен в режиме онлайн. Обработка заказов.",
        en: "Platform for creating an online store. Loading the nomenclature of goods. Update balances and prices online. Order processing.",
        ua: "Платформа для створення інтернет-магазину. Завантаження номенклатури товарів. Оновлення залишків та цін у режимі онлайн. Обробка замовлень."
    },
    "desc-9": {
        ru: "ПРО. Фискализация чеков, печать фискальных чеков. Автоматическое открытие смен.",
        en: "PRRO Fiscalization of checks, printing of fiscal checks. Automatic opening of changes.",
        ua: "ПРРО. Фіскалізація чеків, друк фіскальних чеків. Автоматичне відкриття змін."
    },
     "desc-11": {
        ru: "Сервис для рассылки СМС. Возможность настроить смс-уведомления клиентам при смене статуса заказа",
        en: "Service for sending SMS. Ability to set up SMS notifications to customers when order status changes",
        ua: "Сервіс для розсилки СМС. Можливість налаштувати смс-повідомлення клієнтам при зміні статусу замовлення"
    },
     "desc-12": {
        ru: "",
        en: "",
        ua: ""
    },
     "desc-13": {
        ru: "Подключение Telegram-бота для общения с клиентами в онлайн-чате.",
        en: "Connecting a Telegram bot to communicate with clients in an online chat.",
        ua: "Підключення Telegram-бота для спілкування з клієнтами в онлайн-чаті."
    },
     "desc-14": {
        ru: "Подключение чатов с Facebook Messenger и Instagram Business. Возможность общаться с клиентами в онлайн-чате",
        en: "Connecting chats with Facebook Messenger and Instagram Business. Ability to communicate with customers in online chat",
        ua: "Підключення чатів з Facebook Messenger та Instagram Business. Можливість спілкуватися з клієнтами в онлайн-чаті"
    },
    "desc-15": {
        ru: "Если в списке наших интеграций нет нужной для вас интеграции, вы можете использовать наше публичное API, для создания собственной интеграции",
        en: "If our list of integrations does not include the integration you need, you can use our public API to create your own integration",
        ua: "Якщо у списку наших інтеграцій немає потрібної вам інтеграції, ви можете використовувати наше публічне API, для створення власної інтеграції"
    },
    "desc-16": {
        ru: "Бонусная и дисконтная программа лояльности на основе цифровых карт в Apple и Google Wallet или в Telegram. Автоматическое создание и обновление карт, неограниченные PUSH / GEO / iBeacon уведомления.",
        en: "Bonus and discount loyalty program based on digital cards in Apple and Google Wallet or in Telegram. Automatic card creation and updates, unlimited PUSH / GEO / iBeacon notifications.",
        ua: "Бонусна та дисконтна програма лояльності на базі цифрових карток Apple та Google Wallet або в Telegram. Автоматичне створення та оновлення карток, безлімітні PUSH / GEO / iBeacon сповіщення. "
    },
    "desc-17": {
        ru: "Подключение Viber-бота для общения с клиентами в онлайн-чате.",
        en: "Connecting a Viber bot to communicate with clients in an online chat.",
        ua: "Підключення Viber-бота для спілкування з клієнтами в онлайн-чаті."
    },
    "desc-18": {
        ru: "Подключение телефонии от Binotel. Отвечайте и инициируйте звонки клиентам прямо з CRM",
        en: "Connecting telephony from Binotel. Answer and initiate calls to customers directly from CRM",
        ua: "Підключення телефонії від Binotel. Відповідайте та ініціюйте дзвінки клієнтам прямо з CRM"
    },
    "desc-19": {
        ru: "Интеграция с чатами OLX. Отвечайте на сообщения по вашим объявлениям из раздела Чаты.",
        en: "Integration with OLX chats. Reply to messages about your ads from the Chats section.",
        ua: "Інтеграція з чатами OLX Відповідайте на повідомлення про ваші оголошення з розділу Чати."
    },
    "desc-20": {
        ru: "Подключение телефонии от Ringostat. Отвечайте и инициируйте звонки клиентам прямо з CRM",
        en: "Connecting telephony from Ringostat. Answer and initiate calls to customers directly from CRM",
        ua: "Підключення телефонії від Ringostat. Відповідайте та ініціюйте дзвінки клієнтам прямо з CRM"
    },
    "desc-21": {
        ru: "Современная платформа для презентации бренда и успешной онлайн-торговли. Здесь вы можете бесплатно создать интернет-магазин, настроить дизайн, настроить дизайн",
        en: "A modern platform for brand presentation and successful online trading. Here you can create an online store for free, customize the design",
        ua: "Сучасна платформа для презентації бренду та успішної онлайн-торгівлі. Тут ви можете безкоштовно створити інтернет-магазин, налаштувати дизайн."
    },
    "cms": {
        ru: "CMS",
        en: "CMS",
        ua: "CMS"
    },
    "sms": {
        ru: "SMS",
        en: "SMS",
        ua: "SMS"
    },
    "prro": {
        ru: "ПРРО",
        en: "POS",
        ua: "ПРРО"
    },
    "position": {
        ru: "Позиция в списке",
        en: "Position in the list",
        ua: "Позиція у списку"
    },
    "position-label": {
        ru: "Позиция",
        en: "Position",
        ua: "Позиція"
    },
    "position-placeholder": {
        ru: "Введите порядковый номер",
        en: "Enter the sequence number",
        ua: "Введіть порядковий номер"
    },
    "users-pass": {
        ru: "Пароль для пользователя",
        en: "Password for User",
        ua: "Пароль для користувача"
    },
    "refresh-messages": {
        ru: "Подтянуть последние разговоры",
        en: "Pull up recent conversations",
        ua: "Підтягнути останні розмови"
    },
    "all-account": {
        ru: "Все счета",
        en: "All accounts",
        ua: "Всі рахунки"
    },
    "remove-deleted-product": {
        ru: "Удалите из списка удаленный товар. К сожалению, редактировать продажи с удаленными товарами нельзя",
        en: "Remove the removed item from the list. Unfortunately, you can't edit sales with deleted products.",
        ua: "Приберіть зі списку видалений товар. На жаль, редагувати продажі з видаленими товарами неможливо."
    },
    "write-off": {
        ru: "Списание",
        en: "Write off",
        ua: "Списання"
    },
    "total-products": {
        ru: "Всего товара",
        en: "Total Products",
        ua: "Усього товару"
    },
    "write-off-label": {
        ru: "Списание",
        en: "Write off",
        ua: "Списання"
    },
    "write-off-product": {
        ru: "Списать товар",
        en: "Write off product",
        ua: "Списати товар"
    },
    "write-off-instock": {
        ru: "Ед. Будет списано",
        en: "Write off units",
        ua: "Од. Буде списано"
    },
    "write-off-instock-add": {
        ru: "Ед. Будет добавлено",
        en: "Added units",
        ua: "Од. Буде додано"
    },
    "not-have-write-off": {
        ru: "Вы еще не проводили списание",
        en: "You haven't done a write-off yet",
        ua: "Ви ще не проводили списання"
    },
    "add-first-write-off-btn": {
        ru: "Провести ваше первое списание",
        en: "Spend your first write-off",
        ua: "Провести ваше перше списання"
    },
    "revert-write-off": {
        ru: "Отменить списание",
        en: "Cancel the write off",
        ua: "Скасувати списання"
    },
    "finish-write-off-btn": {
        ru: "Завершить списание товара",
        en: "Complete write-off of product",
        ua: "Завершити списання товару"
    },
    "write-off-title-step-1": {
        ru: "Где будем списывать товары?",
        en: "Where do we write off the products?",
        ua: "Де будемо списувати товари?"
    },
    "write-off-count-item-added": {
        ru: "Списываем товар",
        en: "Writing off product",
        ua: "Списуємо товар",
    },
    "select-min-one-item-write-off": {
        ru: "Добавьте хотя бы один товар, который Вы хотите списать со склада\\магазина",
        en: "Add at least one item that you would like to write off from the store's\\inventory.",
        ua: "Додайте хоча б один товар, який Ви хочете списати зі складу\\магазину"
    },
    "finish-import-categories": {
        ru: "Импорт категорий успешно завершен",
        en: "Import of categories completed successfully",
        ua: "Імпорт категорій успішно завершено"
    },
    "write-off-from": {
        ru: "Списание товара от",
        en: "Write off of items from",
        ua: "Списання товару від"
    },
    "write-off-created": {
        ru: "Списание товаров подготовлено",
        en: "Write-off of products prepared",
        ua: "Списання товарів підготовлено"
    },
    "write-off-finished": {
        ru: "Списание товара завершено",
        en: "Items write off finished",
        ua: "Списання товару завершенно"
    },
    "write-off-revert-desc": {
        ru: "Списание будет полностью отменено и товар возвращен на исходный склад. Вы сможете внести коррективы и завершить списание еще раз.",
        en: "The write-off will be completely canceled and the item returned to the original warehouse. You will be able to make adjustments and complete the write-off again.",
        ua: "Списання буде повністю скасовано і товар повернуто на початковий склад. Ви зможете внести корективи і завершити списання ще раз."
    },
    "fewer-products-available": {
        ru: "На складе имеется меньшее количество товаров, чем вы пытаетесь добавить",
        en: "There are fewer items in stock than you are trying to add",
        ua: "На складі є менша кількість товарів, ніж ви намагаєтеся додати"
    },
    "write-off-comment-placeholder": {
        ru: "Введите комментарий об этом списание (необязательно)",
        en: "Enter a comment about this write-off (optional)",
        ua: "Введіть коментар про це списання (необов'язково)"
    },
    "write-off-category": {
        ru: "Категория списания",
        en: "Write off category",
        ua: "Категорія списання"
    },
    "write-off-categories": {
        ru: "Категории списания",
        en: "Write off categories",
        ua: "Категорії списання"
    },
    "select-write-off-category": {
        ru: "Выберите категорию списания",
        en: "select a write-off category",
        ua: "Виберіть категорію списання"
    },
    "write-off-category-name-label": {
        ru: "Название категории списания",
        en: "Name of the write off category",
        ua: "Назва категорії списання",
    },
    "write-off-category-placeholder": {
        ru: "Введите название категории списания",
        en: "Enter the name of the write off category",
        ua: "Введіть назву категорії списання"
    },
    "price-tag-warehouse": {
        ru: "Название склада\\магазина",
        en: "Name Warehouse\\Store",
        ua: "Назва складу\\крамниці"
    },
    "price-tag-barcode":{
        ru: "Штрих-код",
        en: "Barcode",
        ua: "Штрих-код"
    },
    "price-tag-height-barcode": {
        ru: "Высота штрих-кода",
        en: "Bar code height",
        ua: "Висота штрих-коду"
    },
    "price-tag-width-barcode": {
        ru: "Ширина полоски штрих-кода мин. 0.1 до макс. 0.49",
        en: "Bar code stripe width min. 0.1 to max. 0.49",
        ua: "Ширина смужки штрих-коду мін. 0.1 до макс. 0.49"
    },
    "price-tag-width-barcode-error": {
        ru: "Ширина полосы штрихкода должна быть больше 0,1 или меньше 0,49.",
        en: "The barcode stripe width must be greater than 0.1 or less than 0.49.",
        ua: "Ширина смуги штрихкоду має бути більшою за 0,1 або меншою за 0,49."
    },
    "price-tag-setting-print": {
        ru: "Настройки печати",
        en: "Print settings",
        ua: "Налаштування друку"
    },
    "price-tag-format-print": {
        ru: "Формат печати",
        en: "Print format",
        ua: "Формат друку"
    },
    "thermal-printing": {
        ru: "Термопечать",
        en: "Thermal printing",
        ua: "Термодрук"
    },
    "price-tag-date": {
        ru: "Дата",
        en: "Date",
        ua: "Дата"
    },
    "price-tag-sku": {
        ru: "Артикул",
        en: "Vendor Code",
        ua: "Артикул"
    },
    "price-tag-name": {
        ru: "Название товара",
        en: "Product's Name",
        ua: "Назва товару"
    },
    "price-tag-price": {
        ru: "Цена",
        en: "Price",
        ua: "Ціна"
    },
    "price-tag-position-label": {
        ru: "Позиция на ценнике",
        en: "Position on the price tag",
        ua: "Позиція на ціннику"
    },
    "revising-positioning-view": {
        ru: "Пересмотр вида позиционирования",
        en: "Revising the positioning view",
        ua: "Перегляд виду позиціонування"
    },
    "price-tag-position-1": {
        ru: "вверх-влево",
        en: "up-left",
        ua: "догори-вліво"
    },
    "price-tag-position-2": {
        ru: "вверх-центр",
        en: "up-center",
        ua: "догори-центр"
    },
    "price-tag-position-3": {
        ru: "вверх-вправо",
        en: "up-left",
        ua: "догори-вправо"
    },
    "price-tag-position-4": {
        ru: "центр-вверх",
        en: "center-up",
        ua: "центр-догори"
    },
    "price-tag-position-5": {
        ru: "центр-вниз",
        en: "center-down",
        ua: "центр-донизу"
    },
    "price-tag-position-6": {
        ru: "вниз-влево",
        en: "down-left",
        ua: "донизу-вліво"
    },
    "price-tag-position-7": {
        ru: "вниз-центр",
        en: "down-center",
        ua: "донизу-центр"
    },
    "price-tag-position-8": {
        ru: "вниз-вправо",
        en: "down-right",
        ua: "донизу-вправо"
    },
    "top": {
        ru: "Вверх",
        en: "Top",
        ua: "Вгору"
    },
    "bottom": {
        ru: "Вниз",
        en: "Bottom",
        ua: "Вниз"
    },
    "price-tag-font-size": {
        ru: "Размер шрифта",
        en: "Font size",
        ua: "Розмір шрифту"
    },
    "price-tag-setting": {
        ru: "Настройка ценников",
        en: "Customizing price tags",
        ua: "Налаштування цінників"
    },
    "price-tag-custom-label": {
        ru: "Пользовательский",
        en: "Custom",
        ua: "користувальницький"
    },
    "price-tag-templates-label": {
        ru: "Шаблон",
        en: "Template",
        ua: "Шаблон"
    },
    "type-price-tag": {
        ru: "Выберите тип ценника",
        en: "Select the type of price tag",
        ua: "Виберіть тип цінника"
    },
    "save-and-view-btn": {
        ru: "Посмотреть и сохранить",
        en: "View and save",
        ua: "Подивитися і зберегти"
    },
    "need-number-barcode": {
        ru: "Показывать номер на штрихкоде?",
        en: "Show number on barcode?",
        ua: "Показувати номер на штрихкоді?",
    },
    "yes-number-price-tag-label": {
      ru: "Да",
      en: "Yes",
      ua: "Так"
    },
    "no-number-price-tag-label": {
      ru: "Нет",
      en: "No",
      ua: "Ні"
    },
    "price-tag-error-font": {
        ru: "Размер шрифта не должен быть меньше 7pt и не больше 30pt",
        en: "The font size should be no smaller than 7pt and no larger than 30pt",
        ua: "Розмір шрифту не повинен бути меншим за 7pt і не більшим за 30pt"
    },
    "price-tag-error-font-5": {
        ru: "Размер шрифта не должен быть меньше 5pt и не больше 30pt",
        en: "The font size should be no smaller than 5pt and no larger than 30pt",
        ua: "Розмір шрифту не повинен бути меншим за 5pt і не більшим за 30pt"
    },
    "price-tag-error-height": {
        ru: "Высота штрихкода должна быть не менее 5 мм и не более 20 мм.",
        en: "The height of the barcode shall not be less than 5 mm and not more than 20 mm.",
        ua: "Висота штрихкоду має бути не менше 5 мм і не більше 20 мм."
    },
    "price-tag-error-size": {
        ru: "Высота и ширина печати не должна быть больше 10см",
        en: "The height and width of the seal should not be greater than 10cm",
        ua: "Висота і ширина печатки не повинна бути більшою за 10 см"
    },
    "detailed-customization": {
        ru: "Детальная настройка",
        en: "Detailed customization",
        ua: "Детальне налаштування"
    },
    "inventory-write-off-act": {
        ru: "Акт списания запасов",
        en: "Inventory write-off act",
        ua: "Акт списання запасів"
    },
    "price-growth": {
        ru: (<span>Повышение тарифов с 1го октября. Успей продлить с 20% скидкой по текущим тарифам. <a href="https://h-profit.com/blog/ru/povyshenie-tarifov-bolshe-ne-budet-tarifov-stoimostyu-dve-chashki-kofe/" rel="noopener noreferrer" target="_blank">Подробнее</a></span>),
        en: (<span>Increase in tariffs from October 1st. Hurry up to renew with a 20% discount on current tariffs. <a href="https://h-profit.com/blog/pidvyshhennya-taryfiv-bilshe-ne-bude-taryfiv-vartistyu-dvi-chashky-kavy/" rel="noopener noreferrer" target="_blank">More</a></span>),
        ua: (<span>Підвищення тарифів з 1го жовтня. Встигни подовжити з 20% знижкою за поточними тарифами. <a href="https://h-profit.com/blog/pidvyshhennya-taryfiv-bilshe-ne-bude-taryfiv-vartistyu-dvi-chashky-kavy/" rel="noopener noreferrer" target="_blank">Докладніше</a></span>)
    },
    "no-elements-in-list": {
        ru: "В этом списке пока отсутствуют элементы",
        en: "There are no items in this list yet",
        ua: "В цьому списку поки відсутні елементи"
    },
    "edit-sales": {
        ru: "Редактирование продажи",
        en: "Editing a sale",
        ua: "Редагування продажу"
    },
    "show-cashflow": {
        ru: "Просмотр оборота средств",
        en: "Viewing cashflows",
        ua: "Перегляд обороту коштів"
    },
    "delete-client": {
        ru: "Удаление клиента",
        en: "Deleting client",
        ua: "Видалення клієнта"
    },
    "add-netprice": {
        ru: "Добавление себестоимости",
        en: "Adding netprice",
        ua: "Додавання собівартості"
    },
    "refund-products": {
        ru: "Возврат товара",
        en: "Refunding products",
        ua: "Повернення товару"
    },
    "this-check-dps": {
        ru: (<span>Эта продажа отправлена в налоговую (фискализовано)! Для смены статуса или добавления трекинг номера, используйте специализированные кнопки управления в списке Продаж. <a href="https://h-profit.com/blog/redaguvannya-fiskalnogo-cheka-v-ukrayini-mozhlyvosti-ta-obmezhennya/" rel="noopener noreferrer" target="_blank">Подробнее</a></span>),
        en: (<span>This sale has been sent to the tax office (fiscalized)!  To change the status or add a tracking number, use the specialized control buttons in the Sales list. <a href="https://h-profit.com/blog/redaguvannya-fiskalnogo-cheka-v-ukrayini-mozhlyvosti-ta-obmezhennya/" rel="noopener noreferrer" target="_blank">More</a></span>),
        ua: (<span>Цей продаж відправлено до податкової (фіскалізовано)!  Для зміни статусу або додавання трекінгу номера, використовуйте спеціалізовані кнопки управління у списку Продажів. <a href="https://h-profit.com/blog/redaguvannya-fiskalnogo-cheka-v-ukrayini-mozhlyvosti-ta-obmezhennya/" rel="noopener noreferrer" target="_blank">Докладніше</a></span>)
    },
    "setting-integration": {
        ru: {
            plural: "Настройка интеграции: {0}"
        },
        en: {
            plural: "Setting integration: {0}"
        },
        ua: {
            plural: "Налаштування інтеграції: {0}"
        },
    },
    "business_name": {
          ru: "Фирменное наименование",
          en: "business name",
          ua: "Фірмове найменування"
    },
    "api-key-integration-label": {
        ru: "Ключ",
        en: "Key",
        ua: "Ключ"
    },
    "api-key-integration-generate-key": {
        ru: "Перегенерировать ключ",
        en: "Regenerate key",
        ua: "Перегенерувати ключ"
    },
    "setting-integration-api": {
        ru: "Настройка интеграции API",
        en: "Setting integration API",
        ua: "Налаштування інтеграції API"
    },
    "api-integration-label": {
        ru: "Ключ API",
        en: "Key API",
        ua: "Ключ API"
    },
    "api-integration-generate-key": {
        ru: "Сгенерировать ключ API",
        en: "Generate API key",
        ua: "Згенерувати ключ API"
    },

    "fiscalize-label": {
        ru: "Фискализировать",
        en: "Fiscalize",
        ua: "Фіскалізувати"
    },
    "fiscalize-heading": {
        ru: "Фискализация продаж",
        en: "Fiscalization of sales",
        ua: "Фіскалізація продажу"
    },
    "fiscalize-as-label": {
      ru: "Фискализировать Наложеный Платеж как?",
      en: "Fiscalize Cash on Delivery how?",
      ua: "Фіскалізувати Післяплату як?"
    },
    "fiscalize-cash": {
      ru: "Наличные",
      en: "Cash",
      ua: "Готівка"
    },
    "fiscalize-non-cash": {
      ru: "Безналичные",
      en: "Non cash",
      ua: "Безготівкові"
    },
    "fiscalize-user": {
        ru: "Пользователей",
        en: "Users",
        ua: "Користувачів"
    },
    "fiscalize-sellers": {
        ru: "Продавцов",
        en: "Sellers",
        ua: "Продавців"
    },
    "fiscalize-warehouse": {
        ru: "Склады / Магазины",
        en: "Warehouses / Shops",
        ua: "Склади / Магазини"
    },
    "fiscalize-user-label": {
        ru: "Список пользователей",
        en: "List of users",
        ua: "Список користувачів"
    },
    "fiscalize-warehouse-label": {
        ru: "Список складов / магазинов",
        en: "List of warehouses / stores",
        ua: "Список складів / магазинів"
    },
    "add-integration-completed-title": {
        ru: "Добавление интеграции прошло успешно",
        en: "Adding the integration was successful",
        ua: "Додавання інтеграції пройшло успішно"
    },
    "deactivate-integration-completed-title": {
        ru: "Деактивация интеграции прошла успешно",
        en: "Deactivation of integration was successful",
        ua: "Деактивація інтеграції пройшла успішно"
    },
    "delete-integration-completed-title": {
        ru: "Удаление интеграции прошло успешно",
        en: "The removal of the integration was successful",
        ua: "Видалення інтеграції пройшло успішно"
    },
    "deactivate-btn": {
        ru: "Деактивировать",
        en: "Deactivate",
        ua: "Деактивувати"
    },
    "this check has already been fiscalized": {
        ru: "Этот чек уже фискализирован. Обновите страницу",
        en: "This check has already been fiscalized",
        ua: "Цей чек вже фіскалізовано. Оновіть сторінку."
    },
    "unconnected": {
        ru: "Не подключено",
        en: "Not connected",
        ua: "Не підключено"
    },
    "testing": {
        ru: "Не работает. В процессе тестирования. ",
        en: "Does not work. In the process of testing.",
        ua: "Не працює. У процесі тестування."
    },
    'add-another-integration': {
        ru: "Добавить еще одну интеграцию",
        en: "Add another integration",
        ua: "Додати ще одну інтеграцію"
    },
    "wrong-key": {
        ru: "Неправильный ключ",
        en: "Wrong key",
        ua: "Неправильний ключ"
    },
    "min-length-text": {
        ru: {
            plural: "Длина текста должна быть больше, чем 5 символов, для таких полей: {0}"
        },
        en: {
            plural: 'The text length must be longer than 5 characters for these fields: {0}'
        },
        ua: {
            plural: 'Довжина тексту має бути більшою, ніж 5 символів, для таких полів: {0}'
        }
    },
    "create-order": {
        ru: "Создать заказ",
        en: "Create order",
        ua: "Створити замовлення"
    },
    "create-order-title": {
        ru: "Создание заказа",
        en: "Creating an order",
        ua: "Створення замовлення"
    },
    "add-additional-field-btn": {
        ru: "Добавить дополнительное поле",
        en: "Add an additional field",
        ua: "Додати додаткове поле"
    },
		"edit-additional-field-btn": {
			ru: "Редактировать дополнительное поле",
			en: "Edit additional field",
			ua: "Редагувати додаткове поле"
		},
		"edit-additional-field-label": {
			ru: "Редактирование дополнительного поля",
			en: "Editing an additional field",
			ua: "Редагування додаткового поля"
		},
    "where-add-additional-field": {
        ru: "Куда добавить дополнительное поле?",
        en: "Where do I add an additional field?",
        ua: "Куди додати додаткове поле?"
    },
    "additional-field-product": {
        ru: "Поля товаров",
        en: "Product fields",
        ua: "Поля товарів"
    },
    "additional-field-order": {
        ru: "Поля заказов",
        en: "Order fields",
        ua: "Поля замовлень"
    },
    "additional-field-sale": {
        ru: "Поля продаж",
        en: "Sales fields",
        ua: "Поля продажів"
    },
    "responsible": {
        ru: "Ответственный",
        en: "Responsible",
        ua: "Відповідальний"
    },
    "rid": {
        ru: "Ответственный",
        en: "Responsible",
        ua: "Відповідальний"
    },
    "field-already-exists ": {
        ru: "Такое поле уже существует ",
        en: "Such a field already exists ",
        ua: "Таке поле вже існує "
    },
    "error-message-positions-different": {
        ru: "Расположение элементов должно быть различным",
        en: "The arrangement of the elements should be different",
        ua: "Розташування елементів має бути різним"
    },
    "error-message-discount-field": {
        ru: "Выберите дополнительную цену для скидки",
        en: "Select an additional price for a discount",
        ua: "Виберіть додаткову ціну для знижки"
    },
    "request-proccessing": {
        ru: "Слишком много запросов. Повторите попытку через 5 секунд.",
        en: "Too many requests. Please try again in 5 seconds.",
        ua: "Забагато запитів. Повторіть спробу через 5 секунд."
    },
    "price_from": {
        ru: "Цена продажи от",
        en: "Sale price from",
        ua: "Ціна продажу від"
    },
    "price_to": {
        ru: "Цена продажи до",
        en: "The sale price is up to",
        ua: "Ціна продажу до"
    },
    "transfer-from-us-site": {
        ru: "Передавать ОТ нас НА площадку",
        en: "Transfer from us to the site",
        ua: "Передавати ВІД нас НА майданчик"
    },
    "transfer-from-site-us": {
        ru: "Передавать С площадки к НАМ",
        en: "Transmit from the site to us",
        ua: "Передавати З майданчика до НАС"
    },
    "integration-is-description": {
        ru: "Описание",
        en: "Description",
        ua: "Опис"
    },
    "integration-is-price": {
        ru: "Цены",
        en: "Prices",
        ua: "Ціни"
    },
    "integration-is-dont-orders": {
        ru: "Не принимать заказы",
        en: "Do not take orders",
        ua: "Не приймати замовлення"
    },
    "integration-is-stock": {
        ru: "Остатки",
        en: "Residuals",
        ua: "Залишки"
    },
    "Access was denied to this resource. Api is not available. Integration is disabled": {
        ru: "Доступ по API к Вашей интеграции недоступен. Проверьте есть ли нужные разрешение и доступ по API к вашему аккаунту на площадке.",
        en: "API access to your integration is not available. Check whether you have the necessary permissions and API access to your account on the site.",
        ua: "Доступ по API до Вашої інтеграції недоступний. Перевірте, чи є потрібний дозвіл і доступ по API до вашого облікового запису на майданчику."
    },
    "commission-label": {
        ru: "Комиссия",
        en: "Commission",
        ua: "Комісія"
    },
    "commission-sales-chanel": {
        ru: "Комиссия канала продаж",
        en: "Sales channel commission",
        ua: "Комісія каналу продажу"
    },
    "commission-sum-sales-chanel": {
        ru: "Сумма комиссии за канал продаж",
        en: "Amount of commission per sales channel",
        ua: "Сума комісії за канал продажів"
    },
    "commission-sum": {
        ru: "Сумма комиссии",
        en: "Amount of commission",
        ua: "Сума комісії"
    },
    "commission-placeholder": {
        ru: "Введите комиссию",
        en: "Enter the commission",
        ua: "Введіть комісію"
    },
    "error-sale-add-channels-commission": {
        ru: "Нельзя добавлять больше одного канала с комиссией",
        en: "You cannot add more than one channel with a commission",
        ua: "Не можна додавати більше одного каналу з комісією"
    },
    "custom-prices": {
        ru: "Дополнительные цены",
        en: "Additional prices",
        ua: "Додаткові ціни"
    },
    "custom-prices-short": {
        ru: "Доп. цены",
        en: "Additional prices",
        ua: "Дод. ціни"
    },
    "add-custom-price-btn": {
        ru: 'Добавить цену',
        en: "Add price",
        ua: "Додати ціну"
    },
    "add-custom-price-label": {
        ru: "Добавление дополнительной цены",
        en: "Adding additional price",
        ua: "Додавання додаткової ціни"
    },
    "custom-price": {
        ru: "Дополнительная цена",
        en: "Additional price",
        ua: "Додаткова ціна"
    },
    "color-channel-label": {
        ru: "Цвет канала",
        en: "Channel color",
        ua: "Колір каналу"
    },
    "balance-help": {
        ru: "Чтобы списать деньги с баланса клиент, используйте фиксированную скидку",
        en: "To write off money from the client’s balance, use a fixed discount",
        ua: "Щоб списати гроші з балансу клієнта, використовуйте фіксовану знижку"
    },
    "purchases-credited-bonuses": {
        ru: "% от покупки начисляется на бонусы",
        en: "% of purchases are credited to bonuses",
        ua: "% від покупки нараховується на бонуси"
    },
    "bonus-amount-placeholder": {
        ru: "Введите размер бонусов",
        en: "Enter the amount of bonuses",
        ua: "Введіть розмір бонусів"
    },
    "discount-type": {
        ru: "Тип скидки",
        en: "Type of discount",
        ua: "Тип знижки"
    },
    "percentage-amount": {
        ru: "Процент от суммы",
        en: "Percentage of amount",
        ua: "Відсоток від суми"
    },
    "bonus-accumulation": {
        ru: "Накопление бонусов",
        en: "Bonus accumulation",
        ua: "Накопичення бонусів"
    },
    "hide-sale-products": {
        ru: "Скрыть товары",
        en: "Hide products",
        ua: "Приховати товари"
    },
    "show-sale-products": {
        ru: "Показать товары",
        en: "Show products",
        ua: "Показати товари"
    },
    "basic-price-label": {
        ru: "Базовая цена",
        en: "Base price",
        ua: "Базова ціна"
    },
    "hide-all-sales-btn": {
        ru: "Развернуть продажи",
        en: "Expand sales",
        ua: "Розгорнути продажі"
    },
    "show-all-sales-btn": {
        ru: "Свернуть продажи",
        en: "Collapse sales",
        ua: "Згорнути всі продажі"
    },
    "hide-all-orders-btn": {
        ru: "Свернуть заказы",
        en: "Collapse orders",
        ua: "Згорнути всі замовлення",
    },
    "show-all-order-btn": {
        ru: "Развернуть заказы",
        en: "Expand orders",
        ua: "Розгорнути замовлення",
    },
    "days-groceries-left": {
        ru: "Хватит на дней",
        en: "Enough for days",
        ua: "Вистачить на днів"
    },
    "average-inventory": {
        ru: "Сред. Инвентаризация товаров",
        en: "Among. Inventory of Products",
        ua: "Серед. Інвентаризація товарів"
    },
    "average-sales": {
        ru: "Сред. кол. продаж",
        en: "Average num. of sales",
        ua: "Серед. к-сть продажів"
    },
    "filter-reserv-label": {
        ru: "Резервация",
        en: "Reservation",
        ua: "Резервація"
    },
    "filter-reserv": {
        ru: "С резервацией",
        en: "With a reservation",
        ua: "З резервацією"
    },
    "filter-not-reserv": {
        ru: "Без резервации",
        en: "No reservations",
        ua: "Без резервації"
    },
    "debit-the-account-for-fee": {
        ru: "Списать комиссию сразу со счета",
        en: "Charge the fee directly from the account",
        ua: "Списати комісію відразу з рахунку"
    },
    "error-message-position": {
        ru: {
            plural: "Позиция номер {position} должна отличаться у следующих колонок: {labelsString}."
        },
        en: {
            plural: "The position number {position} must be different for the following columns: {labelsString}."
        },
        ua: {
            plural: "Позиція номер {position} має відрізнятися в таких колонок: {labelsString}."
        },
    },
    "error-message-position-value": {
        ru: {
            plural: "Позиция не может быть пустой или равняться 0 у таких полей: {field}"
        },
        en: {
            plural: "The position cannot be empty or equal to 0 for such fields: {field}"
        },
        ua: {
            plural: "Позиція не може бути порожньою або дорівнювати 0 у таких полів: {field}"
        }
    },
    "customize-columns": {
        ru: "Настройка колонок",
        en: "Customize columns",
        ua: "Налаштувати стовпці"
    },
    "reset-to-default-columns": {
        ru: "Сброс настроек по умолчанию",
        en: "Reset to default",
        ua: "Скидання налаштувань за замовчуванням"
    },
    "columns": {
        ru: "Колонки",
        en: "Columns",
        ua: "Стовпчики"
    },
    "selected": {
        ru: "Выбрано",
        en: "Selected",
        ua: "Обрано"
    },
    "select-all": {
        ru: "Выбрать всё",
        en: "Select all",
        ua: "Обрати все"
    },
    "remove-all": {
        ru: "Удалить все",
        en: "Remove all",
        ua: "Видалити всі"
    },
    "mass-delivery-label": {
        ru: "Массовая отправка посылок",
        en: "Bulk sending of parcels",
        ua: "Масове відправлення посилок"
    },
    "mass-finish-reserv-label": {
        ru: "Массовое завершение резерва",
        en: "Massive reserve finalization",
        ua: "Масове завершення резерву"
    },
    "obsolete": {
        ru: "Устаревший",
        en: "Obsolete",
        ua: "Застарілий"
    },
    "payment-type": {
        ru: "Тип оплаты",
        en: "Type of payment",
        ua: "Тип оплати"
    },
    "reserv-list-sale": {
        ru:  "Зарезервированные продажи",
        en:  "List of reserved sales",
        ua: "Зарезервовані продажі",
    },
    "reserv-list-order": {
        ru:  "Зарезервированные заказы",
        en:  "List of reserved orders",
        ua: "Зарезервовані замовлення",
    },
    "reserv-shipment-list": {
        ru: "Список поставок",
        en: "Shipment list",
        ua: "Список поставок"
    },
    "clear-list": {
        ru: "Список пуст",
        en: "The list is empty",
        ua: "Список порожній"
    },
    "detail-info-reserv-shipment": {
        ru: "Информация о резервах и поставках",
        en: "Information on reserves and supplies",
        ua: "Інформація про резерви та поставки"
    },
    "unknown-reserves": {
        ru: "Неизвестные резервы",
        en: "Unknown reserves",
        ua: "Невідомі резерви"
    },
    "not-enough-reserves": {
        ru: "Не хватает резервов",
        en: "Not enough reserves",
        ua: "Не вистачає резервів"
    },
    "release-reserves-btn": {
        ru: "Снять резвервы",
        en: "Release the reserves",
        ua: "Зняти резверви"
    },
    "reserve": {
        ru: "Зарезервировать",
        en: "Reserve",
        ua: "Зарезервувати"
    },
    "without-integration": {
        ru: "Без интеграции",
        en: "Without integration",
        ua: "Без інтеграції"
    },
    "start-period": {
        ru: "Начало периода",
        en: "Start of period",
        ua: "Початок періоду"
    },
    "end-period": {
        ru: "Конец периода",
        en: "End of period",
        ua: "Кінець періоду"
    },
    "count-transaction": {
        ru: "Количество операций",
        en: "Number of operations",
        ua: "Кількість операцій"
    },
    "sum-coming": {
        ru: "Сумма прихода",
        en: "Income amount",
        ua: "Сума приходу"
    },
    "sum-expense": {
        ru: "Сумма расхода",
        en: "Expenditure amount",
        ua: "Сума витрат"
    },
    "hide-account": {
        ru: "Свернуть счета",
        en: "Collapse accounts",
        ua: "Згорнути рахунки"
    },
    "show-account": {
        ru: "Развернуть счета",
        en: "Unfold accounts",
        ua: "Розгорнути рахунки"
    },
    "statistics-for": {
        ru: {
            plural: 'Статистика по счету: {account}'
        },
        en: {
            plural: 'Account statistics: {account}'
        },
        ua: {
            plural: 'Статистика за рахунком: {account}'
        }
    },
    "client-info-label": {
        ru: "Информация о клиенте",
        en: "Client information",
        ua: "Інформація про клієнта"
    },
    "sale-info-label": {
        ru: "Информация о продаже",
        en: "Sales information",
        ua: "Інформація про продаж"
    },
    "sale-products-info-label": {
        ru: "Информация о товарах",
        en: "Product information",
        ua: "Інформація про товари"
    },
    "sale-prepaid-label": {
        ru: "Передоплата на ",
        en: "Prepaid on",
        ua: "Передплата на "
    },
    "sale-paid-label": {
        ru: "Оплата на ",
        en: "Paid on ",
        ua: "Оплата на "
    },
    "choose-account": {
        ru: "Выберите счет",
        en: "Choose an account",
        ua: "Виберіть рахунок",
    },
    "model": {
        ru: "Модель",
        en: "Model",
        ua: "Модель"
    },
    'connection': {
        ru: "Соединение",
        en: "Connection",
        ua: "З'єднання"
    },
    "account-related": {
        ru: "Связанный со счетом",
        en: "Account related",
        ua: "Пов'язаний із рахунком"
    },
    "checkbox-list-terminal-label": {
        ru: "Список терминалов Checkbox",
        en: "List of Checkbox terminals",
        ua: "Список терміналів Checkbox"
    },
    "reload-terminal": {
        ru: "Обновить терминалы",
        en: "Reload terminals",
        ua: "Оновити термінали"
    },
    "not-have-terminal": {
        ru: "Нету терминалов для отображения ",
        en: "There are no terminals to display",
        ua: "Немає терміналів для відображення"
    },
    "link-account-to-terminal": {
        ru: "Привязать счет к терминалу",
        en: "To link an account to a terminal",
        ua: "Прив'язати рахунок до терміналу"
    },
    "terminal": {
        ru: "POS Терминал",
        en: "POS Terminal",
        ua: "POS Термінал"
    },
    "pay-by-terminal": {
        ru: "Оплатить терминалом",
        en: "Pay by terminal",
        ua: "Оплатити терміналом"
    },
    "select-terminal-send-payment": {
        ru: "Выберите терминал куда отправить оплату",
        en: "Select the terminal where to send the payment",
        ua: "Оберіть термінал куди відправити оплату"
    },
    "pending-payment": {
        ru: "Ожидаем оплату",
        en: "Pending payment",
        ua: "Очікуємо на оплату"
    },
    "error-payment": {
        ru: "Случилась какая то ошибка",
        en: "There's been some kind of error",
        ua: "Сталася якась помилка"
    },
    "error-not-completed-payment": {
        ru: "При добавлении транзакции произошла ошибка, она не будет сохранена, попробуйте снова или обратитесь в тех.поддержку. Сохраните RRN, если он вам понадобится.",
        en: "An error occurred when adding the transaction, it will not be saved, try again or contact technical support. Save the RRN if you need it.",
        ua: "Під час додавання транзакції сталася помилка, її не буде збережено, спробуйте знову або зверніться до тех.підтримки. Збережіть RRN, якщо він вам знадобиться."
    },
    "repeat-payment": {
        ru: "Повторить оплату",
        en: "Repeat payment",
        ua: "Повторити оплату"
    },
    "rrn-transaction": {
        ru: "RRN транзакции",
        en: "RRN transaction",
        ua: "RRN транзакції"
    },
    "id-transaction": {
        ru: "ID транзакции",
        en: "ID transaction",
        ua: "ID транзакції"
    },
    "undefined-rrn": {
        ru: "Номер RRN отсутствует",
        en: "No RRN number",
        ua: "Номер RRN відсутній",
    },
    "transfer-amount-incorrect": {
        ru: "Сумма перевода указана неверно",
        en: "The transfer amount is not correct",
        ua: "Сума переказу вказана невірно"
    },
    "terminal-list": {
        ru: "Список терминалов",
        en: "List of terminals",
        ua: "Список терміналів"
    },
    "terminal-receipts": {
        ru: "Список транзакций",
        en: "Transaction list",
        ua: "Список транзакцій"
    },
    "not-have-receipts": {
        ru: "Нет квитанций для отображения",
        en: "No receipts to display",
        ua: "Немає квитанцій для відображення"
    },
    "detail-info-receipt": {
        ru: "Детали квитанции",
        en: "Receipt Details",
        ua: "Деталі квитанції"
    },
    "fail-find-receipt": {
        ru: "Не нашли квитанцию с таким номером",
        en: "Couldn't find a receipt with that number on it.",
        ua: "Не знайшли квитанцію з таким номером"
    },
    "add-transaction-manually": {
        ru: "Добавить транзакцию вручную",
        en: "Add transaction manually",
        ua: "Додати транзакцію вручну"
    },
    "transaction-amount": {
        ru: "Сумма транзакции",
        en: "Transaction amount",
        ua: "Сума транзакції"
    },
    "try-again-add-receipt": {
        ru: "Ошибка сохранения. Повторить",
        en: "Save Error. Repeat",
        ua: "Помилка збереження. Повторити"
    },
    "add-order": {
        ru: "Добавить заказ",
        en: "Add an order",
        ua: "Додати замовлення"
    },
    "change-mass-account": {
        ru: "Измените массово счет",
        en: "Change your account in bulk",
        ua: "Змініть масово рахунок"
    },
    "moved": {
        ru: "Перемещено",
        en: "Moved",
        ua: "Переміщено"
    },
    "welcome-message-placeholder": {
        ru: "Приветственное сообщение",
        en: "Welcome message",
        ua: "Вітальне повідомлення"
    },
    "link-to-avatar-placeholder": {
        ru: "Ссылка на аватар",
        en: "Link to avatar",
        ua: "Посилання на аватар"
    },
    "not-have-chats": {
      ru: "У вас нету чатов",
      en: "You don't have chat rooms",
      ua: "У вас немає чатів"
    },
    "search-by-name": {
      ru: "Поиск по имени",
      en: "Search by name",
      ua: "Пошук за ім'ям"
    },
    "size-file-not-valid": {
        ru: "Файл не должен превышать 10мб.",
        en: "The file must not exceed 10mb.",
        ua: "Файл не повинен перевищувати 10мб."
    },
    "size-file-not-valid-with-video": {
        ru: "Файл не должен превышать 10мб. Видео 100мб.",
        en: "File must not exceed 10mb. Video 100mb.",
        ua: "Файл не повинен перевищувати 10мб. Відео 100мб."
    },
    "choose-a-file-10mb": {
        ru: "Выберите файл, не больше 10мб.",
        en: "Choose a file no larger than 10mb.",
        ua: "Виберіть файл, не більше 10мб."
    },
    "choose-a-file-10mb-video": {
        ru: "Выберите файл, не больше 10мб. Видео 100мб.",
        en: "Choose a file no larger than 10mb. Video 100mb.",
        ua: "Виберіть файл, не більше 10мб. Відео 100мб."
    },
    "scan-barcode": {
        ru: "Сканер штрих-кодов",
        en: "Barcode scanner",
        ua: "Сканер штрих-кодів"
    },
    "modules-disable-scan-barcode": {
        ru: "Не используете сканер штрих-кодов? Просто отключите этот раздел, чтобы упростить управление вашими настройками.",
        en: "Not using a barcode scanner? Simply disable this section to simplify the management of your settings.",
        ua: "Не використовуєте сканер штрих-кодів? Просто вимкніть цей розділ, щоб спростити керування вашими налаштуваннями."
    },
    "rounded_NBU": {
        ru: "Округлять сумму продажи до 10 копеек согласно НБУ",
        en: "To round the sale amount to 10 kopecks according to the NBU",
        ua: "Округляти суми продажу до 10 копійок згідно з НБУ"
    },
    "rounded_general": {
        ru: "Округлять сумму продажи до 10 копеек",
        en: "To round the sale amount to 10 kopecks",
        ua: "Округляти суми продажу до 10 копійок згідно з НБУ"
    },
    "change-your-account-currency-title": {
        ru: "Подтверждаете ли вы своё желание изменить валюту счета?",
        en: "Do you confirm your wish to change the currency of your account?",
        ua: "Чи підтверджуєте ви своє бажання змінити валюту рахунку?"
    },
    "change-your-account-currency-message": {
        ru: "В случае решения изменить валюту, важно иметь в виду, что денежные средства на счетах и цены сохранят свою неизменность. Никаких пересчетов не произойдет.",
        en: "If you decide to change the currency, it is important to keep in mind that the money in your accounts and prices will remain the same. No recalculations will take place.",
        ua: "У разі рішення змінити валюту, важливо мати на увазі, що грошові кошти на рахунках і ціни збережуть свою незмінність. Жодних перерахунків не відбудеться."
    },
    "base-currency": {
        ru: "Основная валюта",
        en: "Main currency",
        ua: "Основна валюта"
    },
    "change-currency": {
        ru: "Сменить валюту",
        en: "Change currency",
        ua: "Змінити валюту"
    },
    "unload-all-products": {
        ru: "Выгрузить все товары",
        en: "Unload all products",
        ua: "Вивантажити всі товари",
    },
    "unload-only-stock": {
        ru: "Выгрузить только остатки",
        en: "Unload only residuals",
        ua: "Вивантажити тільки залишки",
    },
    "unload-only-price": {
        ru: "Выгрузить только цены",
        en: "Unload only prices",
        ua: "Вивантажити тільки ціни",
    },
    "link-has-een-copied": {
        ru: "Ссылка скопирована!",
        en: "The link has been copied!",
        ua: "Посилання скопійовано!"
    },
    'delete-the-last-warehouse': {
        ru: "Невозможно удалить последний склад / магазин",
        en: "Unable to delete last warehouse / store",
        ua: "Неможливо видалити останній склад / магазин/"
    },
    "not-binding-label": {
        ru: "Без привязки",
        en: "Unbundled",
        ua: "Без прив'язки"
    },
    "binding-label": {
        ru: "С привязкой",
        en: "With reference",
        ua: "З прив'язкою"
    },
    "mass-binding": {
        ru: "Массовая привязка",
        en: "Mass binding",
        ua: "Масова прив'язка"
    },
    "binding-add-title": {
        ru: "Добавить привязку к интеграции",
        en: "Add a binding to the integration",
        ua: "Додати прив'язку до інтеграції"
    },
    "binding-edit-title": {
        ru: "Изменить привязку к интеграции",
        en: "Edit a binding to the integration",
        ua: "Змінити прив'язку до інтеграції"
    },
    "confirm-deactivation": {
        ru: "Подтвердить деактивацию",
        en: "Confirm deactivation",
        ua: "Підтвердити деактивацію"
    },
    "confirm-activate": {
        ru: "Подтвердить активацию",
        en: "Confirm activation",
        ua: "Підтвердити активацію"
    },
    "products-on": {
        ru: {
            plural: 'Товары на {name}'
        },
        en: {
            plural: 'Products on {name}'
        },
        ua: {
            plural: 'Товари на {name}'
        },
    },
    "birthday-clients": {
        ru: "Д.р клиентов",
        en: "Birthday clients",
        ua: "Д.н. клієнта"
    },
    "copy-product-title": {
        ru: "Создать копию товара",
        en: "Create a copy of the product",
        ua: "Створити копію товару"
    },
    'confirm-deletion-synchronization': {
        ru: "Подтвердите удаление синхронизации",
        en: "Confirm deletion of synchronization",
        ua: "Підтвердіть видалення синхронізації"
    },
    "name-accounts-check": {
        ru: "Название счетов в чеке",
        en: "Name of the accounts on the check",
        ua: "Назва рахунків у чеку"
    },
    "together": {
        ru: "Вместе",
        en: "Together",
        ua: "Разом"
    },
    "personal": {
        ru: "Личные",
        en: "Personal",
        ua: "Особисті"
    },
    "error-price-product": {
        ru: "Вы не указали цену для товара!",
        en: "You didn't specify a price for the product!",
        ua: "Ви не вказали ціну для товару!"
    },
    "accruals": {
        ru: "Начисления",
        en: "Accruals",
        ua: "Нарахування"
    },
    "num-days": {
        ru: "Кол.дней",
        en: "Number of days",
        ua: "К-сть. днів"
    },
    'show-on': {
        ru: "Показывать",
        en: "Show",
        ua: "Відображати"
    },
    "show-off": {
        ru: "Не показывать",
        en: "Do not show",
        ua: "Не показувати"
    },
    "deleted-transactions": {
        ru: "Удаленные транзакции",
        en: "Deleted transactions",
        ua: "Видалені транзакції"
    },
    "products-deleted": {
        ru: "Удаленные товары",
        en: "Deleted products",
        ua: "Видалені товари"
    },
    "employees": {
        ru: "Сотрудники",
        en: "Employees",
        ua: "Працівники"
    },
    "accrual-of-sales": {
        ru: "Начисление с продаж",
        en: "Accrual of of sales",
        ua: "Нарахування з продажів"
    },
    "add-accrual-of-sales": {
        ru: "Добавить начисление с продаж",
        en: "Add accrual of sales",
        ua: "Додати нарахування з продажів"
    },
    "add-accrual-of-sales-text": {
        ru: "Настройка отчисления с продаж Администратору. Отчет можно смотреть в разделе Сотрудники.",
        en: "Setting up sales deductions for the Administrator. The report can be viewed in the Employees section.",
        ua: "Налаштування відрахування з продажу Адміністратору. Звіт можна переглянути в розділі Співробітники."
    },
    "available-categories-accrual": {
        ru: "У вас нет доступных категорий для начисления",
        en: "You have no available categories for accrual",
        ua: "У вас немає доступних категорій для нарахування"
    },
    "available-brands-accrual": {
        ru: "У вас нет доступных брендов для начисления",
        en: "You don't have any available brands to charge",
        ua: "У вас немає доступних брендів для нарахування"
    },
    "accrual-list": {
        ru: "Список начислений",
        en: "List of accruals",
        ua: "Список нарахувань"
    },
    "calculation-accruals-from": {
       ru: "Расчет начислений от",
       en: "Calculation of accruals from",
       ua: "Розрахунок нарахувань від"
    },
    "turnover": {
        ru: "Оборота",
        en: "Turnover",
        ua: "Обороту"
    },
    "profits": {
        ru: "Доходов",
        en: "Profits",
        ua: "Доходів"
    },
    "sort": {
        ru: "Сортировка",
        en: "Sorting",
        ua: "Сортування"
    },
    "sorting-field-to": {
        ru: "Сортировать поле по",
        en: "Sort field by",
        ua: "Сортувати поле за"
    },
    "select-sorting-field": {
        ru: "Выберите поле сортировки",
        en: "Select a sorting field",
        ua: "Виберіть поле сортування"
    },
    "ASC": {
      ru: "По возрастанию",
      en: "Ascending",
      ua: "За зростанням"
    },
    "DESC": {
        ru: "По убыванию",
        en: "Descending",
        ua: "За спаданням"
    },
    "join-telegram-channel": {
        ru: <span>Присоединяйтесь к <br/><b>Telegram-каналу!</b></span>,
        en: <span>Join the <br/><b>Telegram channel!</b></span>,
        ua: <span>Приєднуйтесь до <br/><b>Telegram-каналу!</b></span>
    },
    "auto-print-label": {
        ru: "Автоматическая печать чеков",
        en: "Automatic check printing",
        ua: "Автоматичний друк чеків"
    },
    "auto-print-label-fiscal": {
        ru: "Автоматическая печать фискальных чеков",
        en: "Automatic printing of fiscal receipts",
        ua: "Автоматичний друк фіскальних чеків",
    },
    "error-file": {
        ru: "Ошибка! Добавьте файл.",
        en: "Error! Add file",
        ua: "Помилка! Додайте файл"
    },
    "edit-balance": {
        ru: "Редактировать счет",
        en: "Edit account",
        ua: "Редагувати рахунок"
    },
    "subscribe-telegram-bot": {
        ru: "Подписка на телеграм бот",
        en: "Subscribe to the Telegram bot",
        ua: "Підписка на телеграм бот"
    },
    "mass-printing": {
        ru: "Массовая печать",
        en: "Mass printing",
        ua: "Масовий друк"
    },
    "unload-reports": {
        ru: "Выгрузить отчеты",
        en: "Unload reports",
        ua: "Вивантажити звіти"
    },
    "setting-notifications": {
        ru: "Настройка уведомлений",
        en: "Setting up notifications",
        ua: "Налаштування сповіщень"
    },
    "notification-orders": {
        ru: "О новом заказе",
        en: "About the new order",
        ua: "Про нове замовлення",
    },
    "notification-sales": {
        ru: "О добавлении продажи",
        en: "About adding a sale",
        ua: "Про додавання продажу"
    },
    "notification-expenses": {
        ru: "О добавлении расхода",
        en: "About adding an expense",
        ua: "Про додавання витрати",
    },
    "notification-send-old-messages": {
        ru: "О непрочитанных сообщениях в чатах",
        en: "About unread messages in chats",
        ua: "Про непрочитані повідомлення в чатах"
    },
    "notification-new-chat": {
        ru: "О новом чате в чатах",
        en: "About the new chat room in the chat rooms",
        ua: "Про новий чат у чатах"
    },
    "notification-3-days-delivery": {
        ru: "Осталось 3 дня до платного хранения",
        en: "Three days left of paid storage",
        ua: "Залишилося 3 дні до платного зберігання"
    },
    'move-all-products': {
        ru: "Переместить Все товары",
        en: "Move All Products",
        ua: "Перемістити Усі товари"
    },
    "summarize-stock": {
        ru: "Суммировать остатки",
        en: "Summarize the stock",
        ua: "Підсумувати залишки"
    },
    "remove-items-warehouse": {
        ru: "Удалить товары со старого склада",
        en: "Remove items from the old warehouse",
        ua: "Видалити товари зі старого складу"
    },
    "reason-cancellation": {
        ru: "Причина отмены",
        en: "Reason for cancellation",
        ua: "Причина скасування"
    },
    "reflect-brand-in-name": {
        ru: "Отображать бренд в названии",
        en: "Reflect the brand in the name",
        ua: "Відображати бренд у назві"
    },
    "post-office-error": {
        ru: "Отсутствует номер отделения",
        en: "Branch number is missing",
        ua: "Відсутній номер відділення"
    },
    "client-first-last-name-error": {
        ru: "Имя или фамилия получателя отсутствуют",
        en: "The recipient's first or last name is missing",
        ua: "Ім'я або прізвище одержувача відсутні"
    },
    "dimensions-error": {
        ru: "Заполните все поля габаритов",
        en: "Fill in all dimensional fields",
        ua: "Заповніть усі поля габаритів"
    },
    "choose-package": {
        ru: "Выберите упаковку",
        en: "Choose a package",
        ua: "Оберіть упаковку"
    },
    "add-seat": {
        ru: "Добавить место",
        en: "Add a seat",
        ua: "Додати місце"
    },
    "text-copied": {
        ru: "Текст скопирован",
        en: "Text copied",
        ua: "Текст скопійовано"
    },
    "add-production-category": {
        ru: "Добавить категорию производства",
        en: "Add a production category",
        ua: "Додати категорію виробництва"
    },
    "production-category": {
        ru: "Категории производства",
        en: "Production categories",
        ua: "Категорії виробництва"
    },
    "minimum-balance-products": {
        ru: "Минимальный остаток товаров",
        en: "Minimum balance of products",
        ua: "Мінімальний залишок товарів"
    },
    "min-balance-products": {
        ru: "Мин. ост. товаров",
        en: "Min. balance products",
        ua: "Мін. зал. товарів"
    },
    "min-balance-label": {
        ru: "Мин. остаток",
        en: "Min. stock",
        ua: "Мін. залишок"
    },
    "requires-purchase": {
        ru: "Требует закупки",
        en: "Requires purchase",
        ua: "Потребує закупівлі"
    },
    "no-purchase-required": {
        ru: "Не требует закупки",
        en: "No purchase required",
        ua: "Не потребує закупівлі"
    },
    "add-min-balance-products": {
        ru: "Добавить мин. остаток",
        en: "Add a min. stock",
        ua: "Додати мін. залишок"
    },
    "configured-all-instocks": {
        ru: "Вы настроили все возможные минимальные остатки",
        en: "You have configured all possible minimum instocks",
        ua: "Ви налаштували всі можливі мінімальні залишки"
    },
    "numbering": {
        ru: "Нумерация",
        en: "Numbering",
        ua: "Нумерація"
    },
    "table-customization": {
        ru: "Настройка таблицы",
        en: "Table customization",
        ua: "Налаштування таблиці"
    },
    "which-fields-show-heading": {
        ru: "Данные для отображения в шапке документа",
        en: "Data to display in the document header",
        ua: "Дані для відображення в шапці документу"
    },
    "which-fields-show-table": {
        ru: "Какие поля показывать в таблице",
        en: "Which fields to show in the table",
        ua: "Які поля показувати в таблиці"
    },
    "header-customizing": {
        ru: "Настройка шапки",
        en: "Customizing the header",
        ua: "Налаштування шапки"
    },
    "name-customizing": {
        ru: "Настройка названия",
        en: "Customizing the name",
        ua: "Налаштування назви"
    },
    "short-codes-print-doc": {
        ru: "Вы можете использовать такие шорт-коды в тексте названия, которые заменятся данными с добавленной продажи: {order_id} {date_created}",
        en: "You can use such short codes in the title text, which will be replaced by the data from the added sale: {order_id} {date_created}",
        ua: "Ви можете використовувати такі шорт-коди в тексті назви, які заміняться даними з доданого продажу: {order_id} {date_created}"
    },
    "signature-seller": {
        ru: "Подпись продавца",
        en: "Seller's signature",
        ua: "Підпис продавця"
    },
    "price-words": {
        ru: "Цена словами",
        en: "Price in words",
        ua: "Ціна словами"
    },
    "signature-buyer": {
        ru: "Подпись покупателя",
        en: "Buyer's signature",
        ua: "Підпис покупця"
    },
    "message-success": {
        ru: "Настройки успешно сохранены",
        en: "Settings successfully saved",
        ua: "Налаштування успішно збережено"
    },
    "changing-tarif-success": {
        ru: "Тариф успешно изменен",
        en: "Tariff changed successfully",
        ua: "Тариф успішно змінено"
    },
    "failed-tarif-success": {
        ru: "Тариф изменить не удалось, обратитесь в тех.поддержку.",
        en: "The tariff could not be changed, contact technical support.",
        ua: "Тариф змінити не вдалося, зверніться до технічної підтримки."
    },
    "changing-tarif": {
        ru: "Изменение тарифа на",
        en: "Tariff change for",
        ua: "Зміна тарифу на"
    },
    "new-next-date": {
        ru: "Новая дата следующей оплаты",
        en: "New next payment date",
        ua: "Нова дата наступної оплати"
    },
    "paid-days": {
        ru: "Оплаченных дней",
        en: "Paid days",
        ua: "Сплачених днів"
    },
    "recalc-cost": {
        ru: "При переходе на новый тариф, неизрасходованные средства с прошлой оплаты будут перерасчитаны исходя из цены нового тарифа",
        en: "При переході на новий тариф, невитрачені кошти з попередньої оплати будуть перераховані, виходячи з ціни нового тарифу",
        ua: "When switching to a new tariff, unspent funds from the previous payment will be recalculated based on the price of the new tariff"
    },
    "font-error-doc": {
        ru: {
            plural:"Размер шрифта не должен быть меньше 3pt и больше 20pt в таких настройках: {list}"
        },
        en: {
            plural:"The font size should not be smaller than 3pt and larger than 20pt in these settings: {list}"
        },
        ua: {
            plural:"Розмір шрифту не має бути меншим за 3pt і більшим за 20pt у таких налаштуваннях: {list}"
        },
    },
    "add-all-products": {
        ru: "Добавить все товары",
        en: "Add all products",
        ua: "Додати всі товари"
    },
    "edit-discount": {
        ru: "Редактировать скидку",
        en: "Edit discount",
        ua: "Редагувати знижку"
    },
    "restore-product": {
        ru: "Восстановить товар",
        en: "Restore product",
        ua: "Відновити товар"
    },
    "services": {
        ru: "Услуги",
        en: "Services",
        ua: "Послуги"
    },
    "copy-label": {
        ru: "Копия",
        en: "Copy",
        ua: "Копія"
    },
    "services-tag": {
        ru: "УСЛУГА",
        en: "SERVICE",
        ua: "ПОСЛУГА"
    },
    "services-tag-short": {
        ru: "У",
        en: "S",
        ua: "П"
    },
    "bundle-tag": {
        ru: "КОМПЛЕКТ",
        en: "BUNDLE",
        ua: "КОМПЛЕКТ"
    },
    "bundle-tag-short": {
        ru: "К",
        en: "B",
        ua: "К"
    },
    "date-of-deletion": {
        ru: 'Дата удаления',
        en: "Date of deletion",
        ua: "Дата видалення"
    },
    "parcels-and-cargoes": {
        ru: "Посылки и грузы",
        en: "Parcels and cargoes",
        ua: "Посилки та вантажі"
    },
    'taxgrp-1': {
        ru: "НДС 20%",
        en: "VAT 20%",
        ua: "ПДВ 20%"
    },
    'taxgrp-2': {
        ru: "Без НДС",
        en: "Excluding VAT",
        ua: "Без ПДВ"
    },
    'taxgrp-3': {
        ru: "НДС 20% + акциз 5%",
        en: "VAT 20% + excise tax 5%",
        ua: "ПДВ 20% + акциз 5%"
    },
    'taxgrp-4': {
        ru: "НДС 7%",
        en: "VAT 7%.",
        ua: "ПДВ 7%"
    },
    'taxgrp-5': {
        ru: "НДС 0%",
        en: "VAT 0%.",
        ua: "ПДВ 0%"
    },
    'taxgrp-6': {
        ru: "Без НДС + акциз 5%",
        en: "Excluding VAT + 5% excise",
        ua: "Без ПДВ + акциз 5%"
    },
    'taxgrp-7': {
        ru: "Не является объектом НДС",
        en: "Not subject to VAT",
        ua: "Не є об`єктом ПДВ"
    },
    'taxgrp-8': {
        ru: "НДС 20% + ПФР 7.5%",
        en: "VAT 20% + PF 7.5%",
        ua: "ПДВ 20% + ПФ 7.5%"
    },
    'taxgrp-9': {
        ru: "НДС 14%",
        en: "VAT 14%.",
        ua: "ПДВ 14%"
    },
    'taxgrp-10': {
        ru: "НДФЛ 18% Военный сбор 1.5% (М)",
        en: "Personal income tax 18% Military duty 1.5% (M)",
        ua: "ПДФО 18% Військовий збір 1.5% (М)"
    },
    "tax-group-codes": {
        ru: "Коды налоговых групп",
        en: "Tax group codes",
        ua: "Коди податкових груп"
    },
    "tax-group-code-placeholder": {
        ru: "Выберите код налоговой группы",
        en: "Select the tax group code",
        ua: "Оберіть код податкової групи"
    },
    "not-have-notification": {
        ru: "У вас нет уведомлений",
        en: "You don't have notifications",
        ua: "У вас немає сповіщень",
    },
    "to-the-news": {
        ru: "К новости",
        en: "To the news",
        ua: "До новини",
    },
    "error-account": {
        ru: "Введите счет",
        en: "Enter the account",
        ua: "Введіть рахунок"
    },
    "expired-date-to": {
        ru: "Срок годности до",
        en: "Expiration date up to",
        ua: "Термін придатності до"
    },
    "expired-date": {
        ru: "Срок годности",
        en: "Expiration date",
        ua: "Термін придатності"
    },
    "height": {
        ru: "Высота",
        en: "Height",
        ua: "Висота"
    },
    "storage-place": {
        ru: "Место хранения",
        en: "Storage place",
        ua: "Місце зберігання"
    },
    'ukzt': {
        ru: "УКТ ВЭД",
        en: "UKTZED",
        ua: "УКТ ЗЕД"
    },
    "more-info": {
        ru: "Дополнительная информация",
        en: "More info",
        ua: "Додаткова інформація"
    },
    'download-title': {
        ru: "Скачать",
        en: "Download",
        ua: "Завантажити"
    },
    "rotate": {
        ru: "Повернуть",
        en: "Rotate",
        ua: "Повернути"
    },
    "set-the-main-image": {
        ru: "Установить главное изображение",
        en: "Set the main image",
        ua: "Встановити головне зображення"
    },
    "logo": {
        ru: "Логотип",
        en: "Logo",
        ua: "Логотип"
    },
    "logo-upload": {
        ru: "Загрузить логотип",
        en: "Upload logo",
        ua: "Завантажити логотип"
    },
    "select-position": {
        ru: "Выберите позицию",
        en: "Select a position",
        ua: "Виберіть позицію"
    },
    "logo-width-error": {
        ru: "Ширина логотипа не должна быть меньше 50 и больше 400 пикселей",
        en: "The width of the logo should not be less than 50 and more than 400 pixels wide",
        ua: "Ширина логотипу має бути не менше 50 і не більше 400 пікселів завширшки"
    },
    "overdue-exp": {
        ru: "Истек срок",
        en: "Expired",
        ua: "Закінчився термін",
    },
    "expires-exp": {
        ru: "Истекает срок",
        en: "Expiration date",
        ua: "Закінчується термін"
    },
    "expiration-date-short": {
        ru: "ИС",
        en: "ED",
        ua: "ЗТ"
    },
    "reservation-will-added-minus": {
        ru: "Вы добавили товары которых не хватает на складе, резерв этих товаров будет добавлен в минус.",
        en: "You have added items that are out of stock, the reserve of these items will be added to the minus.",
        ua: "Ви додали товари яких не вистачає на складі, резерв цих товарів буде додано в мінус"
    },
    "thx-registration": {
        ru: "Спасибо за регистрацию",
        en: "Thank you for registering",
        ua: "Дякую за реєстрацію"
    },
    "welcome-heading": {
        ru: "Зарабатывай 15% от платежей пожизненно!",
        en: "Earn 15% on payments for life!",
        ua: "Заробляй 15% від платежів довічно!"
    },
    "welcome-message": {
        ru: (<div>
            <b>Приглашайте своих друзей</b> присоединиться к нам и получайте <b>15%</b> пожизненно
                с их оплат! Это отличная возможность помочь своим друзьям и иметь дополнительную прибыль :).
            <br/>
            <br/>
            <h1 className="title margin-bottom-15">Подписывайтесь на наш Telegram-канал!</h1>
            Чтобы быть в курсе всех новостей, обновлений и акций в нашем сервисе.
            Мы регулярно делаем доработки функционала, сообщаем о проблемах и принимаем пожелания :)
Так что подписывайтесь, чтобы не пропустить!
        </div>),
        en: (
         <div>
         <b>Invite your friends</b> to join us and receive <b>15%</b> for life
         from their payments! This is a great opportunity to help your friends and have additional income :).
         <br/>
         <br/>
         <h1 className="title margin-bottom-15">Subscribe to our Telegram channel!</h1>
         To keep abreast of all the news, updates and promotions in our service.
         We regularly improve functionality, report problems and accept suggestions :)
        So subscribe so you don't miss out!
         </div>
        ),
        ua: (
          <div>
 <b>Запрошуйте своїх друзів</b> приєднатися до нас та отримуйте <b>15%</b> довічно
 з їхньої оплати! Це чудова можливість допомогти своїм друзям та мати додатковий прибуток :).
 <br/>
 <br/>
 <h1 className="title margin-bottom-15">Підписуйтесь на наш Telegram-канал!</h1>
 Щоб бути в курсі всіх новин, оновлень та акцій у нашому сервісі.
 Ми регулярно робимо доопрацювання функціоналу, повідомляємо про проблеми та приймаємо побажання :)
Тож підписуйтесь, щоб не пропустити!
 </div>
        )
    },
    'notif-pr-doc-is-new': {
        ru: "Все настройки для примера, чтобы они заработали их нужно сохранить!",
        en: "All settings are for example, you need to save them to make them work!",
        ua: "Усі налаштування для прикладу, щоб вони запрацювали їх потрібно зберегти!"
    },
    "finance-by-category": {
        ru: "Финансы по категориям",
        en: "Finance by category",
        ua: "Фінанси за категоріями"
    },
    "tax-literal": {
        ru: "Налоговый литерал",
        en: "Tax literal",
        ua: "Податковий літерал"
    },
    "original-crossed-out": {
        ru: "Показать зачеркнутую оригинальную цену",
        en: "Show original price crossed out",
        ua: "Показати закреслену оригінальну ціну"
    },
    "font-size-crossed-out": {
        ru: "Размер шрифта зачеркнутой цены",
        en: "Font size of the crossed out price",
        ua: "Розмір шрифту закресленої ціни"
    },
    "awaiting-received": {
        ru: "Ожидается / Получено",
        en: "Awaiting / Received",
        ua: "Очікується / Отримано"
    },
    "view-shipments": {
        ru: "Просмотреть поставки",
        en: "View shipments",
        ua: "Переглянути поставки"
    },
    "basic": {
        ru: "Основная",
        en: "Basic",
        ua: "Основна"
    },
    "info-short": {
        ru: "Инфо",
        en: "Info",
        ua: "Інфо"
    },
    "paid-storage": {
        ru: "Платное хранение",
        en: "Paid storage",
        ua: "Платне зберігання"
    },
    "paid-storage-coming-soon": {
        ru: "Скоро платное хранение",
        en: "Paid storage is coming soon",
        ua: "Скоро платне зберігання"
    },
    "paid-storage-product": {
        ru: "Платное хранение товара",
        en: "Paid storage of product",
        ua: "Платне зберігання товару"
    },
    "deliveredKeeperDate": {
        ru: "Платное хранение товара",
        en: "Paid storage of product",
        ua: "Платне зберігання товару"
    },
    "finance-category": {
        ru: "Финансы по Категориям",
        en: "Finance by Category",
        ua: "Фінанси за категоріями"
    },
    "receipts": {
        ru: "Поступления",
        en: "Receipts",
        ua: "Надходження"
    },
    "choose-column": {
        ru: "Выберите колонку",
        en: "Choose a column",
        ua: "Оберіть колонку"
    },
    'hide-columns': {
        ru: "Скрыть колонки",
        en: "Hide columns",
        ua: "Приховати колонки"
    },
    'hidden-columns': {
        ru: "Скрытые колонки",
        en: "Hidden speakers",
        ua: "Приховані колонки"
    },
    "password-must-characters-long": {
        ru: "Пароль должен состоять более чем из 3 символов",
        en: "The password must be more than 3 characters long",
        ua: "Пароль повинен складатися більш ніж із 3 символів"
    },
    "canceled-orders": {
        ru: "Отм. заказы",
        en: "Canceled orders",
        ua: "Відм. замовлення"
    },
    "quantity-returns": {
        ru: "Кол. Возвратов",
        en: "Quantity Returns",
        ua: "К-сть. повернень"
    },
    "number-purchases": {
        ru: "Кол. покупок",
        en: "Number of purchases",
        ua: "К-сть покупок"
    },
    "stock-marketplaces": {
        ru: "Остатки на маркетплейсах",
        en: "Residuals on marketplaces",
        ua: "Залишки на маркетплейсах"
    },
    "products-request": {
        ru: "Товаров в запросе",
        en: "Products in the request",
        ua: "Товарів у запиті"
    },
    "update-label": {
        ru: "Обновление",
        en: "Update",
        ua: "Оновлення"
    },
    "status-created": {
        ru: "Создан",
        en: "Created",
        ua: "Створено"
    },
    "status-successful": {
        ru: "Успешный",
        en: "Successful",
        ua: "Успішний"
    },
    "status-error": {
        ru: "Ошибка",
        en: "Error",
        ua: "Помилка"
    },
    "status-successful-upload": {
      ru: "Выгрузка успешна",
      en: "Upload successful",
      ua: "Вивантаження успішне"
    },
    "status-error-upload": {
        ru: "Выгрузка ошибка",
        en: "Upload error",
        ua: "Вивантаження помилка"
    },
    "remote-identifier": {
        ru: "Удалённый идентификатор",
        en: "Remote id",
        ua: "Віддалений ідентифікатор"
    },
    "transferred-quantity": {
        ru: "Переданное количество",
        en: "Transferred quantity",
        ua: "Передана кількість"
    },
    "download-latest-dialogs": {
        ru: "Подгрузить последние диалоги",
        en: "Download the latest dialogs",
        ua: "Підвантажити останні діалоги"
    },
    "download-label": {
        ru: "Подгрузить",
        en: "Download",
        ua: "Підвантажити"
    },
    "number-dialogs": {
        ru: "Кол.Диалогов",
        en: "Number of dialogs",
        ua: "К-сть.Діалогів"
    },
    "type-product": {
        ru: "Тип товара",
        en: "Product type",
        ua: "Тип товару"
    },
    'confirm-edit-modal-title': {
        ru: "Вы сделали изменения, подтвердите закрытие!",
        en: "You have made changes, confirm the closure!",
        ua: "Ви зробили зміни, підтвердіть закриття!"
    },
    "change-account": {
        ru: "Изменить счет",
        en: "Change account",
        ua: "Змінити рахунок"
    },
    "prro-variable-attrs": {
        ru: "Добавление атрибутов вариативных товаров",
        en: "Adding attributes of variant products",
        ua: "Додавання атрибутів варіативних товарів"
    },
    "complete cash delivery": {
        ru: "Завершение наложки",
        en: "Finalizing the overlay",
        ua: "Завершення післяплати",
    },
    "complete delivery": {
        ru: "Завершение доставки",
        en: "Completion of delivery",
        ua: "Завершення доставки",
    },
    "in-comparison-to": {
        ru: {
            plural: "В сравнение с {start} по {end}"
        },
        en: {
            plural: "Comparing {start} with {end}"
        },
        ua: {
            plural: "Порівняння з {start} по {end}"
        }
    },
    "select-month": {
        ru: "Выберите месяц",
        en: "Select a month",
        ua: "Оберіть місяць"
    },
    "parcels-paid-storage": {
        ru: "У вас есть посылки с платным хранением",
        en: "You have parcels with paid storage.",
        ua: "У вас є посилки з платним зберіганням"
    },
    "soon-parcels-paid-storage": {
        ru: "У вас скоро будут посылки с платным хранением",
        en: "You will soon have parcels with paid storage",
        ua: "Незабаром у вас з'являться посилки з платним зберіганням"
    },
    "advertise-your-affiliate-link": {
        ru: "Для рекламы вашей партнёрской ссылки",
        en: "To advertise your affiliate link",
        ua: "Для реклами вашого партнерського посилання",
    },
    "advertise-your-affiliate-link-deck": {
        ru: "Мы рады представить вам набор баннеров и тезисов, которые вы можете легко интегрировать на свой сайт для рекламы вашей партнёрской ссылки. Эти компоненты позволят вам эффективно продвигать нашу систему и привлекать новых пользователей на вашу партнерскую программу.",
        en: "We are pleased to present you a set of banners and theses that you can easily integrate into your website to promote your affiliate link. These components will allow you to effectively promote our system and attract new users to your affiliate program.",
        ua: "Ми раді представити вам набір банерів і тез, які ви можете легко інтегрувати на свій сайт для реклами вашого партнерського посилання. Ці компоненти дадуть вам змогу ефективно просувати нашу систему і залучати нових користувачів на вашу партнерську програму.",
    },
    'confirm-production-cancel': {
        ru: "Подтвердите отмену производства",
        en: "Confirm the cancellation of production",
        ua: "Підтвердіть скасування виробництва"
    },
    'undo-production': {
        ru: "Отмена производства",
        en: "Cancellation of production",
        ua: "Скасування виробництва"
    },
    'auto': {
        ru: "Автоматический",
        en: "Automatic",
        ua: "Автоматичний"
    },
    "sales-count-subscribe": {
        ru:  {
            plural: "Еще {count} продаж"
        },
        en: {
            plural: "More {count} sales"
        },
        ua: {
            plural: 'Ще {count} продажів'
        }
    },
    'online-chat': {
        ru: "Online-чат",
        en: "Online chat",
        ua: "Online-чат",
    },
    'tarif-label': {
        ru: "Тариф",
        en: "Tariff",
        ua: "Тариф"
    },
    "pbx-number": {
        ru: "Номер на который поступают звонки",
        en: "The number to which calls are received",
        ua: "Номер на який надходять дзвінки"
    },
    "operator": {
        ru: "Оператор",
        en: "Operator",
        ua: "Оператор"
    },
    "select-an-operator": {
        ru: "Выберите оператора",
        en: "Select an operator",
        ua: "Оберіть оператора"
    },
    "creating-a-call": {
        ru: "Создание звонка",
        en: "Creating a call",
        ua: "Створення дзвінка"
    },
    "expenses-profit-sales": {
        ru: "На продажи",
        en: "On sales",
        ua: "На продажі"
    },
    "expenses-not-deducted": {
        ru: "Вложенные расходы",
        en: "Costs involved",
        ua: "Вкладені витрати"
    },
    "operating-profit": {
        ru: "Операционная прибыль",
        en: "Operating profit",
        ua: "Операційний прибуток"
    },
    "share": {
        ru: "Поделиться",
        en: "Share",
        ua: "Поділитися"
    },
    "write-off-commission-manually": {
        ru: "Списывать комиссию вручную",
        en: "Write off the commission manually",
        ua: "Списувати комісію вручну"
    },
    "automatic-entry-of-expenses": {
        ru: "Автоматическое внесение расхода (без повторного учета в доходах)",
        en: "Automatic entry of expenses (without re-accounting in income)",
        ua: "Автоматичне внесення витрати (без повторного урахування в доходах)"
    },
    "amount-account-minus-fee": {
        ru: "Сумма зачислится на счет за вычетом комиссии",
        en: "The amount will be credited to the account minus the fee",
        ua: "Сума зарахується на рахунок за вирахуванням комісії"
    },
    "cost-of-sales": {
        ru: "Сумма себестоимости",
        en: "Cost of sales",
        ua: "Сума собівартості"
    },
    "change-name-store": {
        ru: "Изменить название магазина",
        en: "Change the name of the store",
        ua: "Змінити назву магазину"
    },
    "binding-warehouse": {
        ru: "Привязка к складу",
        en: "Binding to the warehouse",
        ua: "Прив'язка до складу"
    },
    "unload-product": {
        ru: "Выгрузить товар",
        en: "Unload product",
        ua: "Вивантажити товар"
    },
    "amount-from": {
        ru: "Сумма от",
        en: "Amount from",
        ua: "Сума від"
    },
    "amount-to": {
        ru: "Сумма до",
        en: "Amount to",
        ua: "Сума до"
    },
    "transfers": {
        ru: "Переводы",
        en: "Transfers",
        ua: "Перекази"
    },
    "confirm-edit-delete-expense-fee": {
        ru: "Если вы хотите редактировать или удалить этот расход, вы должны его это сделать через продажу",
        en: "If you want to edit or delete this expense, you must do so through the sale",
        ua: "Якщо ви хочете редагувати або видалити цю витрату, ви повинні це зробити через продаж"
    },
    "confirm-edit-delete-expense-category-delivery": {
        ru: "Если вы хотите редактировать или удалить этот расход, вы должны его это сделать через поставку",
        en: "If you want to edit or delete this expense, you must do so through the shipments",
        ua: "Якщо ви хочете редагувати або видалити цю витрату, ви повинні це зробити через поставку"
    },
    "shipment-delivery": {
        ru: "Доставка поставок",
        en: "Delivery of supplies",
        ua: "Доставка поставок"
    },
    "special-category": {
        ru: "Специальная категория",
        en: "Special category",
        ua: "Спеціальна категорія"
    },
    "warehouse-deleted": {
        ru: "Склад удален",
        en: "Warehouse deleted",
        ua: "Склад видалено"
    },
    "recovery-products-by-stock": {
        ru: "Восстановление товара по складу",
        en: "Recovery of products by stock",
        ua: "Відновлення товару за складом"
    },
    "restore-all": {
        ru: "Восстановить все",
        en: "Restore all",
        ua: "Відновити все"
    },
    "add-least-one-warehouse": {
        ru: "Добавьте хотя бы один склад",
        en: "Add at least one warehouse",
        ua: "Додайте хоча б один склад"
    },
    "select-warehouse-label": {
        ru: "Выберите склад",
        en: "Select a warehouse",
        ua: "Оберіть склад"
    },
    "a-call-from": {
        ru: "Звонок от",
        en: "A call from",
        ua: "Дзвінок від"
    },
    "a-call-to": {
        ru: "Звонок к",
        en: "A call to",
        ua: "Дзвінок до"
    },
    "unknown-phone": {
        ru: "Неизвестный номер",
        en: "Unknown phone",
        ua: "Невідомий номер"
    },
    "binotel-telephone-line": {
        ru: "Телефонная линия Binotel",
        en: "Binotel telephone line",
        ua: "Телефонна лінія Binotel"
    },
    "binotel-telephone-line-admin": {
        ru: "Телефонная линия для Администратора",
        en: "Telephone line for the Administrator",
        ua: "Телефонна лінія для Адміністратора"
    },
    "check-fiscalized-check-return": {
        ru: "Этот чек был фискализирован, хотите ли вы сделать возврат фискального чека? ",
        en: "This check has been fiscalized, do you want to make a fiscal check return? ",
        ua: "Цей чек був фіскалізований, чи хочете ви зробити повернення фіскального чека? "
    },
    "auth-olx": {
        ru: "Авторизация OLX",
        en: "OLX authorization",
        ua: "Авторизація OLX"
    },
    "re-auth-olx": {
        ru: "Повторная авторизация OLX",
        en: "OLX re-authorization",
        ua: "Повторна авторизація OLX"
    },
    "to-return": {
        ru: "Вернуть",
        en: "To return",
        ua: "Повернути"
    },
    "no-refunds": {
        ru: "Не возвращать",
        en: "No refunds",
        ua: "Не повертати"
    },
    "start-put-products": {
        ru: "Начинаем выгрузку товаров, это может занять какое-то время...",
        en: "We are unloading the goods, it may take some time...",
        ua: "Починаємо вивантаження товарів, це може зайняти якийсь час..."
    },
    "finish-put-products": {
        ru: "Товары успешно выгружены!",
        en: "The products have been successfully unloaded!",
        ua: "Товари успішно вивантажено!"
    },
    "finish-put-product": {
        ru: "Товар успешно выгружен!",
        en: "The product have been successfully unloaded!",
        ua: "Товар успішно вивантажено!"
    },
    "start-put-stock": {
        ru: "Начинаем выгрузку остатков, это может занять какое-то время...",
        en: "We are starting to unload the remaining stock, which may take some time...",
        ua: "Починаємо вивантаження залишків, це може зайняти якийсь час..."
    },
    "start-update-stock": {
        ru: "Начинаем выгрузку остатков, это может занять какое-то время...",
        en: "We are starting to unload the remaining stock, which may take some time...",
        ua: "Починаємо вивантаження залишків, це може зайняти якийсь час..."
    },
    "finish-put-stock": {
        ru: "Остатки успешно выгружены!",
        en: "The stocks have been successfully unloaded!",
        ua: "Залишки успішно вивантажено!"
    },
    "finish-update-stock": {
        ru: "Остатки успешно выгружены!",
        en: "The stocks have been successfully unloaded!",
        ua: "Залишки успішно вивантажено!"
    },
    "start-put-price": {
        ru: "Начинаем выгрузку цен, это может занять какое-то время...",
        en: "Starting to upload prices, this may take a while.....",
        ua: "Починаємо вивантаження цін, це може зайняти якийсь час..."
    },
    "finish-put-price": {
        ru: "Цены успешно выгружены!",
        en: "Prices have been successfully uploaded!",
        ua: "Ціни успішно вивантажено!"
    },
    "warning-put-nodata": {
        ru: "Не удалось выгрузить данные, посколько ничего не изменилось!",
        en: "Unable to upload data because nothing has changed!",
        ua: "Не вдалося вивантажити дані, оскільки нічого не змінилося!"
    },
    "warning-updates-nodata": {
        ru: "Не удалось выгрузить данные, посколько ничего не изменилось!",
        en: "Unable to upload data because nothing has changed!",
        ua: "Не вдалося вивантажити дані, оскільки нічого не змінилося!"
    },
    "try-again": {
        ru: "Попробовать снова",
        en: "Try again",
        ua: "Спробувати ще раз"
    },
    "customer-not-match": {
        ru: "Клиент не совпадает с тем, что пришел в заказе ",
        en: "The customer does not match what came in the order ",
        ua: "Клієнт не збігається з тим, що прийшов у замовленні "
    },
    "client-company": {
        ru: "Компания клиента",
        en: "The customer does not match what came in the order ",
        ua: "Компанія клієнта"
    },
    "with-commission": {
        ru: "С комиссией",
        en: "With the commission",
        ua: "З комісією"
    },
    "without-commission": {
        ru: "Без комиссии",
        en: "Without commission",
        ua: "Без комісії"
    },
    "withdraw-all-reserves": {
        ru: "Снять все резервы",
        en: "Withdraw all reserves",
        ua: "Зняти всі резерви"
    },
    'load-only-active-prom': {
        ru: "Загружать только активные товары",
        en: "Only enabled products",
        ua: "Завантажувати тільки активні товари"
    },
    "add-comment": {
        ru: "Добавить коментарий",
        en: "Add comment",
        ua: "Додати коментар",
    },
    "edit-comment": {
        ru: "Редактировать комментарий",
        en: "Edit comment",
        ua: "Редагувати коментар",
    },
    "select-status": {
        ru: "Выберите статус",
        en: "Select status",
        ua: "Оберіть статус"
    },
    "chats-status": {
        ru: "Статусы для чатов",
        en: "Statuses for chat rooms",
        ua: "Статуси для чатів"
    },
    "change-price-type-bulk": {
        ru: "Изменить массово тип цены",
        en: 'Change price type in bulk',
        ua: "Змінити масово тип ціни"
    },
    "delivery-amount": {
        ru: "Сумма доставки",
        en: "Delivery amount",
        ua: "Сума доставки"
    },
    "involved": {
        ru: "Задействовано",
        en: "Involved",
        ua: "Задіяно"
    },
    "product-are-exist": {
        ru: "Невозможно восстановить товар, поскольку его копия уже существует!",
        en: "It is not possible to restore the item because a copy of it already exists!",
        ua: "Неможливо відновити товар, оскільки його копія вже існує!"
    },
    "error-long-comment": {
        ru: "К сожалению, ваш комментарий слишком длинный. Пожалуйста, сократите его до 2000 символов и попробуйте снова.",
        en: "Unfortunately, your comment is too long. Please shorten it to 2000 characters and try again.",
        ua: "На жаль, ваш коментар занадто довгий. Будь ласка, скоротіть його до 2000 символів і спробуйте знову."
    },
    "confirm-delete-reserve": {
        ru: "Подтвердите снятие резерва",
        en: "Confirm the withdrawal of the reserve",
        ua: "Підтвердіть зняття резерву"
    },
    "account-commission-label": {
        ru: "Комиссия счета",
        en: 'Account commission',
        ua: "Комісія рахунку",
    },
    "accounts-commission-label": {
        ru: "Комиссия счетов",
        en: 'Accounts commission',
        ua: "Комісія рахунків",
    },
    'create-call': {
        ru: "Создать звонок",
        en: "Create a call",
        ua: "Створити дзвінок"
    },
    "download-list-categories": {
        ru: "Загрузить список категорий",
        en: "Download the list of categories",
        ua: "Завантажити список категорій"
    },
    "download-integration-status": {
        ru: "Загрузить список статусов",
        en: "Download a list of statuses",
        ua: "Завантажити список статусів",
    },
    "error-import-categories": {
        ru: "Не удалось загрузить категории, случилась какая то ошибка",
        en: "Failed to load categories, some error occurred",
        ua: "Не вдалося завантажити категорії, сталася якась помилка"
    },
    "start-import-categories": {
        ru: "Начинаем импорт категорий, это может занять какое-то время...",
        en: "Let's start import categories, this may take a while....",
        ua: "Починаємо імпорт категорій, це може зайняти якийсь час..."
    },
    "virtual-warehouse": {
        ru: "Виртуальный склад",
        en: "Virtual warehouse",
        ua: "Віртуальний склад"
    },
    "fill-in-the-virtual-lineups": {
        en: "Fill in the virtual lineups",
        ru: "Заполните виртуальные склады",
        ua: "Заповніть віртуальні склади"
    },
    "delivery-city": {
        ru: "Город доставки",
        en: "Delivery city",
        ua: "Місто доставки"
    },
    "deliveryCity": {
        ru: "Город доставки",
        en: "Delivery city",
        ua: "Місто доставки"
    },
    "start-import-order-status": {
        ru: "Начинаем импорт статусов, это может занять какое-то время...",
        en: "Let's start import statuses, this may take a while....",
        ua: "Починаємо імпорт статусів, це може зайняти якийсь час..."
    },
    "error-import-order-status": {
        ru: "Не удалось загрузить статусы, случилась какая то ошибка",
        en: "Failed to load statuses, some error occurred",
        ua: "Не вдалося завантажити статуси, сталася якась помилка"
    },
    "finish-import-order-status": {
        ru: "Импорт статусов успешно завершен",
        en: "Import of statuses completed successfully",
        ua: "Імпорт статусів успішно завершено"
    },
    "read-all": {
        ru: "Прочитать все",
        en: "Read all",
        ua: "Прочитати все"
    },
    "warehouse-change": {
        ru: "Смена склада",
        en: "Warehouse change",
        ua: "Зміна складу"
    },
    "client-invoice": {
        ru: "Счет клиента",
        en: "Client account",
        ua: "Рахунок клієнта"
    },
    "name-must-not-two": {
        ru: "Название не должно быть меньше чем 2 символа",
        en: "The name must not be less than 2 characters",
        ua: "Назва не повинна бути меншою ніж 2 символи"
    },
    "create-field-similar-name": {
        ru: "Нельзя создать поле с похожим названием",
        en: "You cannot create a field with a similar name",
        ua: "Не можна створити поле зі схожою назвою"
    },
    "cost-price-average": {
        ru: "Себестоимость на результирующем складе будет браться усредненная",
        en: "The cost price at the resulting warehouse will be taken as an average",
        ua: "Собівартість на результуючому складі буде братись усереднена"
    },
    "add-facebook-link": {
        ru: "Что бы работала ссылка на Facebook, вы должны добавить в таком формате: https://www.facebook.com/nickname",
        en: "For the link to Facebook to work, you must add the following format https://www.facebook.com/nickname",
        ua: "Що б працювало посилання на Facebook, ви повинні додати в такому форматі https://www.facebook.com/nickname"
    },
    "add-nickname-link": {
        ru: "Для того чтобы ссылка работала, необходимо добавить nickname",
        en: "In order for the link to work you must add nickname",
        ua: "Для того, щоб посилання працювало, ви повинні додати нікнейм",
    },
    "add-linkedin-link": {
        ru: "Что бы работала ссылка на Linkedin, вы должны добавить в таком формате: https://www.linkedin.com/in/nickname",
        en: "For the link to Linkedin to work, you must add the following format https://www.linkedin.com/in/nickname",
        ua: "Що б працювало посилання на Linkedin, ви повинні додати в такому форматі https://www.linkedin.com/in/nickname"
    },
    "You have unfinished moves. Wait until they finish.": {
        ru: "У вас есть незавершенные перемещения, дождитесь окончания.",
        en: "You have unfinished moves. Wait until they finish.",
        ua: "У вас є незавершені переміщення, дочекайтеся закінчення."
    },
    'add-phone-link': {
        ru: "Добавляя телефон вы получите возможность открыть его в Viber",
        en: "By adding a phone you will get an opportunity to open it in Viber",
        ua: "Додаючи телефон ви отримаєте можливість відкрити його у Viber"
    },
    "cant-complete-move-dont-have-product": {
        ru: "Вы не можете завершить перемещение, потому что у вас не хватает товара",
        en: "You can't complete the move because you don't have enough product",
        ua: "Ви не можете завершити переміщення, тому що у вас не вистачає товару"
    },
    'upload-new-product': {
        ru: "Выгрузить только новые товары",
        en: "Unload only new goods",
        ua: "Вивантажити тільки нові товари"
    },
    "date-of-registration": {
        ru: "Дата регистрации",
        en: "Date of registration",
        ua: "Дата реєстрації"
    },
    "customizing-bottom-menu": {
        ru: "Настройка нижнего меню телефона",
        en: "Customizing the bottom menu of your phone",
        ua: "Налаштування нижнього меню телефону"
    },
    "cant-add-fifth-element": {
        ru: "Вы не можете добавить пятый элемент",
        en: "You can't add the fifth element",
        ua: "Ви не можете додати п'ятий елемент"
    },
    "add-least-one-element": {
        ru: "Добавьте хотя бы один элемент",
        en: "Add at least one element",
        ua: "Добавьте хотя бы один элемент"
    },
    "product-report": {
        ru: "Отчет по товарам",
        en: "Product report",
        ua: "Звіт за товарами"
    },
    "orders-report": {
        ru: "Отчет по заказам",
        en: "Order report",
        ua: "Звіт по замовленнях"
    },
    "change-index": {
        ru: "Изменить индекс",
        en: "Change the index",
        ua: "Змінити індекс"
    },
    "disable-reserv-marketplace": {
        ru: "Отключить резервирования товара",
        en: "Disable product reservations",
        ua: "Відключити резервування товару"
    },
    'set_price': {
        ru: "Изменение цены вручную",
        en: "Manual price change",
        ua: "Ручна зміна ціни"
    },
    "list-products": {
        ru: "Список товаров",
        en: "List of products",
        ua: "Список товарів"
    },
    "mass_change_price_increase": {
        ru: "Массовое увеличение цены на определенную сумму",
        en: "Massive price increase by a certain amount",
        ua: "Масове збільшення ціни на певну суму"
    },
    "mass_change_price_decrease": {
        ru: "Массовое уменьшение цены на определенную сумму",
        en: "Massive price reduction by a certain amount",
        ua: "Масове зменшення ціни на певну суму"
    },
    "increase_update_mass_price": {
        ru: "Массовое увеличение цены на процент",
        en: "Massive price increase by a percentage",
        ua: "Масове збільшення ціни на відсоток"
    },
    "decrease_update_mass_price": {
        ru: "Массовое уменьшение цены на процент",
        en: "Massive price reduction by a percentage",
        ua: "Масове зменшення ціни на відсоток"
    },
    "mass_change_price_exact": {
        ru: "Массовое изменение цены",
        en: "Massive price change",
        ua: "Масова зміна ціни"
    },
    "automatic-markup": {
        ru: "Авто. наценка",
        en: "Auto. markup",
        ua: "Авто. націнка"
    },
    "automatic-net-prices": {
        ru: "Авто. изменение себест.",
        en: "Auto. cost of sales",
        ua: "Авто. зміна собівар."
    },
    "formula": {
        ru: "Формула",
        en: "Formula",
        ua: "Формула"
    },
    "unbranded": {
        ru: "Без бренда",
        en: "Unbranded",
        ua: "Без бренду"
    },
    "we-find-the-sale-deleted": {
        ru: "Мы не смогли найти продажу, возможно она удалена.",
        en: "We couldn't find the sale, it may have been deleted",
        ua: "Ми не змогли знайти продаж, можливо його видалено."
    },
    "sold-on-account-filter": {
        ru: {
            plural: 'Продано за фильтром счета {account}'
        },
        en: {
            plural: 'Sold Behind the Account Filter {account}'
        },
        ua: {
            plural: 'Продано за фільтром рахунку {account}'
        },
    },
    "city-branch-number-missing": {
        ru: "Отсутствует город или номер отделения",
        en: "City or branch number is missing",
        ua: "Відсутнє місто або номер відділення"
    },
    "mass-edit-net-price": {
        ru: "Массовое изменение себестоимости товаров",
        en: "Massive change in cost of products",
        ua: "Масова зміна собівартості товарів"
    },
    "sure-you-want-move": {
        ru: {
            plural: "Вы уверены, что хотите переместить {count} шт."
        },
        en: {
            plural: "Are you sure you want to move {count} pcs."
        },
        ua: {
            plural: "Ви впевнені, що хочете перемістити {count} од."
        }
    },
    "partial-payment-not-full": {
        ru: "Сумма частичной оплаты не может быть полной оплатой.",
        en: "The amount of partial payment may not be full payment.",
        ua: "Сума часткової оплати не може бути повною оплатою."
    },
    "create-moving-title": {
        ru: "Создание перемещения товаров",
        en: "Creating a movement of products",
        ua: "Створення переміщення товарів"
    },
    "edit-moving-title": {
        ru: "Редактирование перемещения товаров",
        en: "Edit the movement of products",
        ua: "Редагування переміщення товарів"
    },
    "display-sales-from": {
        ru: "Отобразить продажи с",
        en: "Display sales from",
        ua: "Відобразити продажі з"
    },
    "info-order-chats": {
        ru: "Информация заказу",
        en: "Order information",
        ua: "Інформація замовлення"
    },
    "go-to-order": {
        ru: "Перейти к заказу",
        en: "Go to order",
        ua: "Перейти до замовлення"
    },
    "mass-change-cost-products": {
        ru: "Массовое изменение себестоимости товаров",
        en: "Mass change in cost of products",
        ua: "Масова зміна собівартості товарів"
    },
    "my-own-version": {
        ru: "Свой вариант",
        en: "My own version",
        ua: "Свій варіант"
    },
    "products-with-error": {
        ru: "Товары с не актуальным количеством",
        en: "Products with an out-of-date quantity",
        ua: "Товари з не актуальною кількістю"
    },
    "not-found-allowed-pages": {
        ru: "Не найдено разрешенных страниц",
        en: "No allowed pages found",
        ua: "Не знайдено дозволених сторінок"
    },
    "voting-should-blank-slate": {
        ru: "Голосование не должно быть пустым",
        en: "Voting shouldn't be a blank slate",
        ua: "Голосування не повинно бути порожнім"
    },
    "order-price": {
        ru: "Цена заказа",
        en: "Order price",
        ua: "Ціна замовл."
    },
    "confirm-recovery": {
        ru: "Подтвердите восстановление",
        en: "Confirm recovery",
        ua: "Підтвердіть відновлення"
    },
    "sales-deleted": {
        ru: "Удаленные продажи",
        en: "Deleted sales",
        ua: "Видалені продажі"
    },
    "mass-recovery-del-prod": {
        ru: "Массовое восстановление удаленных продуктов",
        en: "Mass recovery of deleted products",
        ua: "Масове відновлення видалених продуктів"
    },
    "no-products-to-recover": {
        ru: "Нету товаров для восстановления",
        en: "No products to recover",
        ua: "Немає товарів для відновлення"
    },
    "warehouse-for-recovery": {
        ru: "Склад для восстановления",
        en: "Warehouse for recovery",
        ua: "Склад для відновлення"
    },
    "mass-copying-warehouse": {
        ru: "Массовое копирование на склад",
        en: "Mass copying to the warehouse",
        ua: "Масове копіювання на склад"
    },
    "warehouse-which-copied": {
        ru: "Склад на который копируется",
        en: "The warehouse to which it is copied",
        ua: "Склад на який копіюється"
    },
    "no-products-available-to-copy": {
        ru: "Нет доступных товаров для копирования. Возможно товар уже существует на складе который вы выбрали.",
        en: "There are no products available to copy. Perhaps the product you selected is already in stock.",
        ua: "Немає доступних товарів для копіювання. Можливо товар вже існує на складі який ви обрали."
    },
    "copy-to-warehouse": {
        ru: "Копировать на склад",
        en: "Copy to warehouse",
        ua: "Копіювати на склад"
    },
    "language-id": {
        ru: "ID Языка",
        en: "Language ID",
        ua: "ID Мови"
    },
    "comment-on-the-post": {
        ru: "Комментарий к публикации",
        en: "Comment on the post",
        ua: "Коментар до публикації"
    },
    "update-the-operators": {
        ru: "Обновить операторов",
        en: "Update the operators",
        ua: "Оновити операторів"
    },
    "no-operators-available": {
        ru: "Нет доступных операторов",
        en: "No operators available",
        ua: "Немає доступних операторів"
    },
    "are-no-accounts-available": {
        ru: "Нету доступных счетов",
        en: "There are no accounts available",
        ua: "Немає доступних рахунків"
    },
    "account-already-exists": {
        ru: "Аккаунт уже существует",
        en: "The account already exists",
        ua: "Акаунт вже існує"
    },
    "telephony": {
        ru: 'Телефония',
        en: "Telephony",
        ua: "Телефонія"
    },
    "no-name": {
        ru: "Нет названия",
        en: 'No name',
        ua: "Немає назви"
    },
    'promo-code': {
        ru: "Промокод",
        en: "Promo code",
        ua: "Промокод"
    }
};
export default messages;
