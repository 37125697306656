import React, {useEffect, useState} from "react";
import ProductsStore from "../../../products-store";
import s from "./styles.module.scss";
import T from "../../../../../components/i18n";
import FieldDesc from "../common/field-desc";
import ReportWithWarehouse from "./report-with-warehouse";
import StatisticsBlock from "../common/statistics-block";
import SalesStore from "../../../../sales/sales-store";
import User from "../../../../../user/user-store";
import InstokBlock from "./instok-block";
import Money from "../../../../../components/money";
import {formattedDate} from "../../../../../tools/formatted-date-dd-mm-yy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bulma-components";
import AppStore from "../../../../../app-store";
import EditProductsModal from "../../../edit-products-modal";
import ProductsActions from "../../../products-actions";
import IntegrationField from "./integration-field";

const DescriptionBlock = ({ pid , integrationList}) => {
  const data = ProductsStore.getProduct(pid);
  const url = ProductsStore.makeImageUrl(pid, 0, 0);
  const getAdditionalInfo = ProductsStore.getAdditionalInfoByPid(pid)
  const [refreshImage, setRefreshImage] = useState(0)

  const sizeProduct = getAdditionalInfo["size_width"] && getAdditionalInfo["size_height"] && getAdditionalInfo["size_length"] && `${getAdditionalInfo["size_width"]}x${getAdditionalInfo["size_height"]}x${getAdditionalInfo["size_length"]} ${T('cm-short')}`

  const fields = [
    { name: "ID", value: pid },
    { name: "product-brand", value: data["brand"] },
    {
      name: "category",
      value: ProductsStore.getCategoryName(data["category_id"]),
    },
    { name: "product-barcode", value: data["asin"] },
    { name: "product-vendor-code", value: data["skus"] },
    {name: 't-size', value: sizeProduct},
    {name: 'weight', value: getAdditionalInfo["weight"] ? `${getAdditionalInfo["weight"]} ${T('kg-short')}`: null},
    {name: 'ukzt', value: getAdditionalInfo["ukzt"] },
    {name: 'storage-place', value: getAdditionalInfo["storage_place"] },
    {name: 'expired-date-to', value: getAdditionalInfo["expired_date"] && formattedDate(getAdditionalInfo["expired_date"]) },
  ];

  const calcStatistics = Object.values(data.stock).reduce(
    (acc, curr) => {
      const reports = SalesStore.getSaleReportsByProduct(pid, curr.mid);
      acc.sum_instock += curr.instock;
      acc.sum_quantity += curr.quantity;
      acc.sum_waiting += curr.waiting;
      acc.sum_profit += reports.sales_profit;
      acc.sum_saled += reports.items_count;

      return acc;
    },
    {
      sum_instock: 0,
      sum_quantity: 0,
      sum_waiting: 0,
      sum_profit: 0,
      sum_saled: 0,
    }
  );

  const statisticsList = [
    {
      name: "available",
      value: (
        <InstokBlock
          pid={pid}
          quantity={calcStatistics.sum_quantity}
          instock={calcStatistics.sum_instock}
          waiting={calcStatistics.sum_waiting}
          withoutCursor
        />
      ),
    },
    {
      name: "saled",
      value: calcStatistics.sum_saled,
    },
  ];

  if (User.getPermissionEnable("incomeinformation")) {
    statisticsList.push({
      name: "sales-profit",
      value: <Money amount={calcStatistics["sum_profit"]} />,
    });
  }

  const handleEditImgProduct = () => {
    AppStore.openModal(<EditProductsModal modal='edit' pid={pid} step={1}/>)
  }

  useEffect(() => {
    const editImage = ProductsActions.uploadImages.completed.listen(() => {
      setRefreshImage(prev => prev + 1)
    })
    const deleteImage = ProductsActions.deleteImage.completed.listen(() => {
      setRefreshImage(prev => prev + 1)
    })
    const rotateImage = ProductsActions.rotateImage.completed.listen(() => {
      setRefreshImage(prev => prev + 1)
    })

    return () => {
      editImage()
      deleteImage()
      rotateImage()
    }
  }, []);

  return (
    <>
      <StatisticsBlock list={statisticsList} />
      <div className="display-flex-column">
        <div className={s.description_wrapper}>
          <div className={s.img} key={`image-${refreshImage}`}>
            <img src={`${url}${refreshImage >= 1 ? `?t=${Date.now()}` : ''}`} alt={data.name} style={{ width: "100%" }} />
            <span className={s.edit_img}>
            <Button className='button-link-without-style' onClick={handleEditImgProduct}>
              <FontAwesomeIcon icon='edit'/>
            </Button>
            </span>
          </div>
          <div className={s.description}>
            <div className={s.fields_wrapper}>
              {fields.map((item) => item.value && (
                <FieldDesc key={item.name} {...item} withoutTwoDots />
              ))}
              {!!integrationList && !!integrationList.length && <>
              <span className="silver">
                {T('integrations')}
              </span>
                <span>
                {integrationList.map(iid => (
                  <IntegrationField key={iid} iid={iid} pid={pid}/>
                ))}
              </span>
              </>}
            </div>

            {!!data["description"] && (
              <span>
                <span className="bold font-size-120">{T("description")}:</span>
                <div className={s.description_filed}
                     dangerouslySetInnerHTML={{__html: "<div>" + data['description'] + "</div>"}}/>
              </span>
              )}
          </div>
        </div>
        <ReportWithWarehouse pid={pid} stocks={data.stock} />
      </div>
    </>
  );
};

export default DescriptionBlock;
